import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Heading from '@openloop/limbic/Heading';
import styles from './WelcomeText.module.scss';

const intlMessages = defineMessages({
  heading: {
    defaultMessage: 'Thank you for your interest.',
    description: 'Declined network status heading text',
    id: 'Modules.provider.Home.Welcome.WelcomeText.Declined.heading',
  },
  firstParagraph: {
    defaultMessage:
      'Unfortunately, we do not have any open spots that align with your skillsets. For this reason, we cannot admit you to the clinician network at this time. If anything changes, we will be sure to contact you.',
    description: 'Declined network status first paragraph',
    id: 'Modules.provider.Home.Welcome.WelcomeText.Declined.firstParagraph',
  },
});

const Declined = () => {
  const { formatMessage } = useIntl();
  const heading = formatMessage(intlMessages.heading);
  const firstParagraph = formatMessage(intlMessages.firstParagraph);

  return (
    <div>
      <Heading className={styles.welcomeTextHeading} level="h2">
        {heading}
      </Heading>
      <p>{firstParagraph}</p>
    </div>
  );
};

export default Declined;
