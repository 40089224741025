import {
  AppProfessionalContext,
  NurseProfessionalContext,
  PhysicianProfessionalContext,
  TherapistProfessionalContext,
  UserRole,
  ProviderProfessionalContext,
  AppProfessionalContextFieldsFragment,
  NurseProfessionalContextFieldsFragment,
  PhysicianProfessionalContextFieldsFragment,
  TherapistProfessionalContextFieldsFragment,
} from '~Data';

export const getFullnameForDisplay = (
  firstName: string | null | undefined,
  lastName: string | null | undefined,
): string | null => {
  if (!firstName && !lastName) {
    return null;
  }
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return firstName || lastName || null;
};

export const getNpiLink = (npi: string | number) =>
  `https://npiregistry.cms.hhs.gov/registry/provider-view/${npi}`;

export const getProviderSpecialty = (
  professionalContext: ProviderProfessionalContext,
): string | null | undefined => {
  const { type } = professionalContext;
  switch (type) {
    case UserRole.App:
      return (professionalContext as AppProfessionalContext).specialty;
    case UserRole.Nurse:
      return (professionalContext as NurseProfessionalContext).specialty;
    case UserRole.Physician:
      return (professionalContext as PhysicianProfessionalContext).specialty;
    case UserRole.Therapist:
      return (professionalContext as TherapistProfessionalContext).specialty;
    default:
      return null;
  }
};

export const getProviderLevelOfTraining = (
  professionalContext: ProviderProfessionalContext,
): string | null | undefined => {
  const { type } = professionalContext;
  switch (type) {
    case UserRole.App:
      return (professionalContext as AppProfessionalContextFieldsFragment).appLevelOfTraining;
    case UserRole.Nurse:
      return (professionalContext as NurseProfessionalContextFieldsFragment).nurseLevelOfTraining;
    case UserRole.Physician:
      return (professionalContext as PhysicianProfessionalContextFieldsFragment)
        .physicianLevelOfTraining;
    case UserRole.Therapist:
      return (professionalContext as TherapistProfessionalContextFieldsFragment)
        .therapistLevelOfTraining;
    default:
      return null;
  }
};
