import React from 'react';
import { Form as AntForm, Divider } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import * as yup from 'yup';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Button from '@openloop/limbic/Button';
import Grid from '@openloop/limbic/Grid';
import Heading from '@openloop/limbic/Heading';
import { useIntl } from 'react-intl';

import images from '~Constants/images';
import messages from '~Constants/messages';
import { guestRoutes, providerRoutes } from '~Constants/routes';
import { useAuthContext } from '~Context/AuthContext';
import Link from '~Core/Link';
import SEO from '~Core/SEO';
import { AppMessages } from '~I18n';
import { intlMessages } from './intlMessages';

import styles from './Login.module.scss';

const { Row, Col } = Grid;

interface FormValues {
  email: string;
  password: string;
}

const validationSchema = yup.object({
  email: yup.string().required(messages.emailRequired).email('Please enter a valid email address'),
  password: yup.string().required(messages.passwordRequired),
});

const Login = () => {
  const { formatMessage } = useIntl();
  const { login, loginLoading } = useAuthContext();

  const handleSubmit = (input: FormValues) => {
    login({ input });
  };

  return (
    <main className={styles.main}>
      <SEO
        title={formatMessage(intlMessages.metaTitle)}
        description={formatMessage(intlMessages.metaDescription)}
      />

      <Row className={styles.logoRow}>
        <img className={styles.logo} src={images.logo} alt="OpenLoop Logo" />
      </Row>

      <Row className={styles.contentRow} align="middle">
        <Col className={styles.imageColumn}>
          <img className={styles.bg} src={images.loginBackground} alt="" />
          <div className={styles.hashtag}>
            <span>#{formatMessage(intlMessages.healing)}</span>
            <span>{formatMessage(intlMessages.anywhere)}</span>
          </div>
        </Col>

        <Col className={styles.formColumn}>
          <Row justify="center" className={styles.formRow}>
            <Col xs={22} lg={24} className={styles.form}>
              <Heading level="h1" size="h2">
                {formatMessage(intlMessages.pageHeading)}
              </Heading>
              <Formik<FormValues>
                initialValues={{
                  email: '',
                  password: '',
                }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ dirty, isValid }) => (
                  <Form>
                    <Form.Item name="email">
                      <Input
                        name="email"
                        placeholder={formatMessage(intlMessages.formInputLabelEmail)}
                        prefix={<UserOutlined />}
                        size="large"
                        type="email"
                      />
                    </Form.Item>
                    <Form.Item name="password">
                      <Input
                        name="password"
                        placeholder={formatMessage(intlMessages.formInputLabelPassword)}
                        prefix={<LockOutlined />}
                        size="large"
                        type="password"
                      />
                    </Form.Item>
                    <div>
                      <Link to={guestRoutes.forgotPassword}>
                        {formatMessage(intlMessages.linkForgotPassword)}
                      </Link>
                    </div>
                    <br />
                    <AntForm.Item>
                      <Button
                        block
                        disabled={!dirty || !isValid}
                        type="submit"
                        loading={loginLoading}
                        size="lg"
                        variant="primary"
                      >
                        {formatMessage({ id: AppMessages.btnLogIn })}
                      </Button>
                    </AntForm.Item>
                  </Form>
                )}
              </Formik>
              <Divider>{formatMessage(intlMessages.ctaCreateAccountClinician)}</Divider>
              <Row gutter={[24, 16]}>
                <Col span={24}>
                  <Link block buttonVariant="primary-outline" to={providerRoutes.register}>
                    {formatMessage(intlMessages.linkRegisterClinician)}
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default Login;
