import axios from 'axios';
import decode from 'jwt-decode';

import { accessTokenReactiveVar } from '~Data/reactiveVars';

export const TOKEN_KEY = 'userAccessToken';

// Time in Milliseconds that token can still be valid and we still refresh
const REFRESH_TOKEN_LEGROOM = 300;

export const getPersistedAccessToken = (): string | null => {
  return localStorage.getItem(TOKEN_KEY);
};

export const setPersistedAccessToken = (token: string): void => {
  accessTokenReactiveVar(token);
  localStorage.setItem(TOKEN_KEY, token);
};

export const unsetPersistedAccessToken = (): void => {
  accessTokenReactiveVar(null);
  localStorage.removeItem(TOKEN_KEY);
};

export const shouldRefresh = (token?: string | null): boolean => {
  if (!token) {
    return true;
  }
  const decodedToken = decode<{ exp?: number }>(token);

  if (!decodedToken || typeof decodedToken !== 'object') {
    return true;
  }

  if (
    !decodedToken.exp ||
    Date.now() > decodedToken.exp * 1000 ||
    Date.now() + REFRESH_TOKEN_LEGROOM > decodedToken.exp * 1000
  ) {
    return true;
  }

  return false;
};

export const refreshAuthToken = async () => {
  const response = await axios({
    url: `${import.meta.env.VITE_SERVER_ENDPOINT}/graphql`,
    method: 'POST',
    withCredentials: true,
    data: {
      query: `
      mutation {
        refresh {
          token
          userId
        }
      }
    `,
    },
  });

  const newToken = response?.data?.data?.refresh?.token;
  if (newToken) {
    setPersistedAccessToken(newToken);
  } else {
    unsetPersistedAccessToken();
  }
  return newToken;
};
