import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  notes: {
    defaultMessage: 'Notes',
    description: 'Notes',
    id: 'components.admin.UserDetail.Notes.notes',
  },
  addNote: {
    defaultMessage: 'Add a Note',
    description: 'Add a Note',
    id: 'components.admin.UserDetail.Notes.addNote',
  },
  comment: {
    defaultMessage: 'Comment',
    description: 'Comment',
    id: 'components.admin.UserDetail.Notes.comment',
  },
});
