import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import Header from '../Header';
import Footer from '../Footer';

import styles from './Wrapper.module.less';

const { Content, Footer: AntFooter, Header: AntHeader } = Layout;

const Wrapper = () => (
  <Layout>
    <AntHeader className={styles.header}>
      <Header />
    </AntHeader>
    <Content className={styles.content}>
      <Outlet />
    </Content>
    <AntFooter className={styles.footer}>
      <Footer />
    </AntFooter>
  </Layout>
);

export default Wrapper;
