import { JobStatus } from '~Data';

export const jobApplicationsNotAllowed = (jobStatus: JobStatus) => {
  return (
    jobStatus === JobStatus.OnHold ||
    jobStatus === JobStatus.Filled ||
    jobStatus === JobStatus.Cancelled ||
    jobStatus === JobStatus.Declined ||
    jobStatus === JobStatus.Inactive
  );
};
