import React from 'react';
import { Formik } from 'formik';
import Form from '@openloop/limbic/Form';
import Button from '@openloop/limbic/Button';

import * as yup from 'yup';
import { useIntl } from 'react-intl';

import PhoneInput from '~Core/PhoneInput';
import FormItem from '~Core/Form/FormItem/FormItem';
import { ClinicContact } from '~Data';
import { intlMessages } from './intlMessages';

interface FormValues {
  email?: string;
  name?: string;
  phone?: string;
  title?: string;
}

interface Props {
  initialEmail?: ClinicContact['email'];
  initialName?: ClinicContact['name'];
  initialPhone?: ClinicContact['phone'];
  initialTitle?: ClinicContact['title'];
  handleSubmit: (values: FormValues) => void;
  loading: boolean;
}

const validationSchema = yup.object().shape({
  email: yup.string().email('Please enter a valid email address'),
  name: yup.string(),
  phone: yup.string().phone('Please enter a valid phone number'),
  title: yup.string(),
});

const ClinicContactModalFields = ({
  initialName,
  initialEmail,
  initialPhone,
  initialTitle,
  handleSubmit,
  loading,
}: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Formik<FormValues>
      initialValues={{
        name: initialName || undefined,
        email: initialEmail || undefined,
        phone: initialPhone || undefined,
        title: initialTitle || undefined,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, isValid, values }) => {
        const { email, name, phone } = values;
        const allContactFieldsEmpty = !email && !phone && !name;
        return (
          <Form>
            <FormItem label={formatMessage(intlMessages.name)} name="name">
              {({ inputId }) => <Form.Input id={inputId} name="name" />}
            </FormItem>
            <FormItem label={formatMessage(intlMessages.email)} name="email">
              {({ inputId }) => <Form.Input id={inputId} name="email" />}
            </FormItem>
            <FormItem label={formatMessage(intlMessages.phone)} name="phone">
              {({ inputId }) => <PhoneInput id={inputId} name="phone" />}
            </FormItem>
            <FormItem label={formatMessage(intlMessages.title)} name="title">
              {({ inputId }) => <Form.Input id={inputId} name="title" />}
            </FormItem>
            <Button
              disabled={!dirty || !isValid || allContactFieldsEmpty}
              loading={loading}
              type="submit"
            >
              {formatMessage(intlMessages.save)}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ClinicContactModalFields;
