import moment, { Moment } from 'moment';

export const enumerateDaysBetweenDates = (
  startDate: Moment,
  endDate: Moment,
  inclusive = true,
): Moment[] => {
  const dates = [];

  if (inclusive) {
    dates.push(startDate.clone().startOf('day'));
  }

  const currDate = startDate.clone().startOf('day');
  const lastDate = endDate.clone().startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone());
  }

  if (inclusive) {
    dates.push(endDate.startOf('day'));
  }

  return dates;
};

export const getMaybeMomentDateString = (
  maybeMoment: Moment | Date | string | undefined,
  format: string,
): string | void => {
  if (!maybeMoment) {
    return maybeMoment;
  }
  let newMoment = maybeMoment;
  if (!moment.isMoment(newMoment)) {
    newMoment = moment(newMoment);
  }
  return newMoment.format(format);
};
