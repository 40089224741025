import React from 'react';
import { useIntl } from 'react-intl';
import Card from '@openloop/limbic/Card';
import Icon from '@openloop/limbic/Icon';

import ClinicianLicenseTag from '~Components/ClinicianLicenseTag';
import { LicenseFieldsFragment, LicenseStatus } from '~Data';
import { intlMessages } from './intlMessages';

import styles from './LicensesCard.module.scss';

interface Props {
  licenses: LicenseFieldsFragment[];
}

const LicensesCard = ({ licenses }: Props) => {
  const { formatMessage } = useIntl();

  const selfReportedPendingLicenses = licenses.filter(
    (license) => license.status === LicenseStatus.SelfReportedPending,
  );
  const selfReportedActiveLicenses = licenses.filter(
    (license) => license.status === LicenseStatus.SelfReportedActive,
  );
  const verifiedActiveLicenses = licenses.filter(
    (license) => license.status === LicenseStatus.VerifiedActive,
  );

  return (
    <Card>
      <div className={styles.mainRow}>
        <div className={styles.colLicenseCount}>
          <span className={styles.licenseCount}>
            <Icon name="Certificate" variant="secondary" size="medium" />
            <span>
              {formatMessage(intlMessages.licenses, {
                b: (str) => <span className={styles.bold}>{str}</span>,
                licenseCount: licenses.length,
              })}
            </span>
          </span>
        </div>
        <div className={styles.licensesContainer}>
          {selfReportedPendingLicenses.length > 0 && (
            <>
              <div className={styles.colLicenseList}>
                <span className={styles.bold}>{formatMessage(intlMessages.pendingSpan)}:</span>
                {selfReportedPendingLicenses.map(({ state, stateId, status }) => (
                  <ClinicianLicenseTag key={stateId} state={state} status={status} />
                ))}
              </div>
            </>
          )}
          {selfReportedActiveLicenses.length > 0 && (
            <>
              <div className={styles.colLicenseList}>
                <span className={styles.bold}>{formatMessage(intlMessages.activeSpan)}:</span>
                {selfReportedActiveLicenses.map(({ state, stateId, status }) => (
                  <ClinicianLicenseTag key={stateId} state={state} status={status} />
                ))}
              </div>
            </>
          )}
          {verifiedActiveLicenses.length > 0 && (
            <>
              <div className={styles.colLicenseList}>
                <span className={styles.bold}>{formatMessage(intlMessages.verifiedSpan)}:</span>
                {verifiedActiveLicenses.map(({ state, stateId, status }) => (
                  <ClinicianLicenseTag key={stateId} state={state} status={status} />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default LicensesCard;
