import React from 'react';
import { Row, Col, Typography } from 'antd';
import Button from '@openloop/limbic/Button';
import Heading from '@openloop/limbic/Heading';
import { useIntl } from 'react-intl';

import images from '~Constants/images';
import { useAuthContext } from '~Context/AuthContext';
import { intlMessages } from './intlMessages';

import styles from './UnderConstruction.module.less';

const { Paragraph } = Typography;

const UnderConstruction = () => {
  const { formatMessage } = useIntl();
  const { logout } = useAuthContext();

  return (
    <main className={styles.main}>
      <Row justify="center">
        <Col xs={20} sm={16} md={10} lg={8}>
          <img src={images.logo} className={styles.logo} alt="OpenLoop Logo" />
          <div className={styles.details}>
            <Heading level="h1">{formatMessage(intlMessages.underConstruction)}</Heading>
            <Paragraph>{formatMessage(intlMessages.paragraph)}</Paragraph>
            <Button onClick={logout} variant="secondary">
              {formatMessage(intlMessages.returnMessage)}
            </Button>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default UnderConstruction;
