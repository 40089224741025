import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useApolloClient } from '@apollo/client';
import { toast } from '@openloop/limbic';

import {
  CurrentUserQuery,
  ServerLogInMutationOptions,
  ServerLogInMutationVariables,
  useCurrentUserLazyQuery,
  useServerLogInMutation,
} from '~Data';
import { useAccessToken } from '~Hooks/useAccessToken';
import LoadingScreen from '~Modules/shared/LoadingScreen';

type LoginFn = ({
  input,
  onCompleted,
}: {
  input: ServerLogInMutationVariables['input'];
  onCompleted?: ServerLogInMutationOptions['onCompleted'];
}) => void;

type LogoutFn = () => void;

interface CommonProps {
  login: LoginFn;
  loginLoading: boolean;
  logout: LogoutFn;
}

type AuthContextProps = CommonProps &
  (
    | {
        currentUser?: never;
        isAuthenticated: false;
      }
    | {
        currentUser: CurrentUserQuery['currentUser'];
        isAuthenticated: true;
      }
  );

const AuthContext = createContext<AuthContextProps>({
  currentUser: undefined,
  isAuthenticated: false,
  login: () => {},
  loginLoading: false,
  logout: () => {},
});

export const useAuthContext = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('auth context must be used within AuthProvider');
  }

  return authContext;
};

type AuthProviderProps = {
  children: ReactNode | Array<ReactNode>;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [accessToken, setAccessToken, unsetAccessToken] = useAccessToken();
  const client = useApolloClient();
  const [fetchCurrentUser, { data }] = useCurrentUserLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [authenticate, { loading: loginLoading }] = useServerLogInMutation({
    onCompleted: ({ logIn }) => {
      if (logIn) {
        setAccessToken(logIn.token);
      }
    },
  });

  const login = useCallback<LoginFn>(
    ({ input: { email, password, rememberMe }, onCompleted }) => {
      authenticate({
        onCompleted,
        onError: () => {
          toast({ variant: 'danger', message: 'Invalid login credentials.' });
        },
        variables: { input: { email, password, rememberMe } },
      });
    },
    [authenticate],
  );

  const logout = useCallback(() => {
    unsetAccessToken();
    client.clearStore();
  }, [client, unsetAccessToken]);

  useEffect(() => {
    if (accessToken) {
      fetchCurrentUser();
    }
  }, [accessToken, fetchCurrentUser]);

  const value = useMemo<AuthContextProps>(() => {
    const commonProps: CommonProps = {
      login,
      loginLoading,
      logout,
    };
    if (accessToken && data?.currentUser) {
      return {
        ...commonProps,
        currentUser: data.currentUser,
        isAuthenticated: true,
      };
    }
    return {
      ...commonProps,
      isAuthenticated: false,
    };
  }, [accessToken, data?.currentUser, login, loginLoading, logout]);

  return accessToken && !data?.currentUser ? (
    <LoadingScreen />
  ) : (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
