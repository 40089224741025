import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  clientTableHeader: {
    defaultMessage: 'Client',
    description: 'Table column client header',
    id: 'Modules.admin.UserDetail.Jobs.clientTableHeader',
  },
  jobTitleTableHeader: {
    defaultMessage: 'Job Title',
    description: 'Table column job title header',
    id: 'Modules.admin.UserDetail.Jobs.jobTitleTableHeader',
  },
  clinicianTypeTableHeader: {
    defaultMessage: 'Clinician Type',
    description: 'Table column clinician header',
    id: 'Modules.admin.UserDetail.Jobs.clinicianTypeTableHeader',
  },
  rulingTableHeader: {
    defaultMessage: 'Ruling',
    description: 'Table column ruling header',
    id: 'Modules.admin.UserDetail.Jobs.rulingTableHeader',
  },
  sharingTokensTableHeader: {
    defaultMessage: 'Sharing Tokens',
    description: 'Table column sharing tokens header',
    id: 'Modules.admin.UserDetail.Jobs.sharingTokensTableHeader',
  },
  utmParametersHeader: {
    defaultMessage: 'UTM',
    description: 'Table column UTM parameters header',
    id: 'Modules.admin.UserDetail.Jobs.utmParametersHeader',
  },
  rulingSelectLabel: {
    defaultMessage: 'Ruling',
    description: 'Label for ruling select',
    id: 'Modules.admin.UserDetail.Jobs.rulingSelectLabel',
  },
  userLoadingErrorMessage: {
    defaultMessage: 'There was an error loading this user.',
    description: 'Error message for user loading error',
    id: 'Modules.admin.UserDetail.Jobs.userLoadingErrorMessage',
  },
  tableTitle: {
    defaultMessage: 'Applied Jobs',
    description: 'Table heading',
    id: 'Modules.admin.UserDetail.Jobs.tableTitle',
  },
  comingSoon: {
    defaultMessage: 'Coming soon',
    description: 'Coming soon',
    id: 'Modules.admin.UserDetail.Jobs.comingSoon',
  },
  legacySharingTokens: {
    defaultMessage: 'legacy sharing tokens',
    description: 'legacy sharing tokens',
    id: 'Modules.admin.UserDetail.Jobs.legacySharingTokens',
  },
  untilThen: {
    defaultMessage: 'Until then, use',
    description: 'Until then, use',
    id: 'Modules.admin.UserDetail.Jobs.untilThen',
  },
  suggestJobs: {
    defaultMessage: 'Suggest Job(s)',
    description: 'Suggest Job(s)',
    id: 'Modules.admin.UserDetail.Jobs.suggestJobs',
  },
});
