import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  yourNameLabel: {
    defaultMessage: 'Your Name',
    description: 'Your Name label',
    id: 'components.provider.Forms.BasicInfoFields.yourNameLabel',
  },
  pleaseCallLabel: {
    defaultMessage: 'Please call me',
    description: 'Please call me label',
    id: 'components.provider.Forms.BasicInfoFields.pleaseCallLabel',
  },
  phone: {
    defaultMessage: 'Phone',
    description: 'Phone label',
    id: 'components.provider.Forms.BasicInfoFields.phone',
  },
  birthDateLabel: {
    defaultMessage: 'Birth Date',
    description: 'Birth Date label',
    id: 'components.provider.Forms.BasicInfoFields.birthDateLabel',
  },
  miniBioLabel: {
    defaultMessage: 'Mini Bio',
    description: 'Mini Bio label',
    id: 'components.provider.Forms.BasicInfoFields.miniBioLabel',
  },
});
