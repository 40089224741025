import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  findAndSelect: {
    defaultMessage: 'Find & Select Jobs for',
    description: 'Find & Select Jobs for',
    id: 'components.admin.InviteAndSuggest.JobSearchAndSelectScreen.findAndSelect',
  },
  selectClinic: {
    defaultMessage: 'Select Clinic',
    description: 'Select Clinic',
    id: 'components.admin.InviteAndSuggest.JobSearchAndSelectScreen.selectClinic',
  },
  selectJob: {
    defaultMessage: 'Select Job',
    description: 'Select Job',
    id: 'components.admin.InviteAndSuggest.JobSearchAndSelectScreen.selectJob',
  },
  previousButton: {
    defaultMessage: 'Previous',
    description: 'Previous Button',
    id: 'components.admin.InviteAndSuggest.JobSearchAndSelectScreen.previousButton',
  },
  nextButton: {
    defaultMessage: 'Next',
    description: 'Next Button',
    id: 'components.admin.InviteAndSuggest.JobSearchAndSelectScreen.nextButton',
  },
});
