import React, { useState, useEffect, ReactElement } from 'react';

import { message, Spin } from 'antd';
import { useCurrentUserQuery, UserNetworkStatus } from '~Data';
import NetworkStatus from '~Components/NetworkStatus';
import images from '~Constants/images';

import SectionCard from '../SectionCard';

import { Incomplete, Declined, NotNow, Pending, Accepted } from './WelcomeText';

import styles from './Welcome.module.scss';

const Welcome = () => {
  const [networkStatus, setNetworkStatus] = useState<UserNetworkStatus>();
  const {
    data: currentUserData,
    error: currentUserError,
    loading: currentUserLoading,
  } = useCurrentUserQuery();
  const WelcomeTextByStatus: { [key in UserNetworkStatus]: ReactElement } = {
    Incomplete: <Incomplete />,
    NoNetwork: <Declined />,
    NotNowNetwork: <NotNow />,
    PendingReview: <Pending />,
    YesNetwork: <Accepted />,
  };

  useEffect(() => {
    if (currentUserData && !currentUserLoading && !currentUserError) {
      setNetworkStatus(currentUserData.currentUser?.profile?.networkStatus);
    }
    if (currentUserError) {
      message.error('There was an error loading user data');
    }
  }, [currentUserData, currentUserLoading, currentUserError]);

  if (currentUserLoading) {
    return <Spin />;
  }

  return (
    <SectionCard>
      {networkStatus && (
        <div className={styles.welcomeContainer}>
          {WelcomeTextByStatus[networkStatus]}
          <div className={styles.welcomeBottomContainer}>
            <div className={styles.networkStatus}>
              <NetworkStatus status={networkStatus} />
            </div>
            <div className={styles.welcomeIcon}>
              <img alt="Welcome Visual" src={images.welcomeVisual} />
            </div>
          </div>
        </div>
      )}
    </SectionCard>
  );
};

export default Welcome;
