import React, { useMemo } from 'react';
import { useParams, generatePath, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import {
  Button,
  Form as LimbicForm,
  Heading,
  Table,
  TableProps,
  TableTypes,
  Spinner,
  toast,
  Icon,
} from '@openloop/limbic';

import Form from '~Core/Form';
import Link from '~Core/Link';
import FormItem from '~Core/Form/FormItem';
import { jobRulingChoices } from '~Constants/jobRuling';
import {
  JobApplicationRulingDecision,
  useAdminUserQuery,
  useCreateJobApplicationRulingDecisionEventMutation,
  useCreateJobApplicationRulingDecisionResetEventMutation,
  UserNetworkStatus,
  UserRole,
} from '~Data';
import { adminRoutes, clinicRoutes, sharedRoutes } from '~Constants/routes';
import { convertUtmParamsToAString } from '~Helpers/utmParams';
import { FeatureFlag, useFeatureFlag } from '~Hooks/useFeatureFlag';

import SharableProfileTokenCell from './SharableProfileTokenCell';
import { intlMessages } from './intlMessages';

import styles from './Jobs.module.scss';

const { Select } = LimbicForm;

type ClinicianSourceData = {
  id: string;
  profile: {
    firstName: string;
    lastName: string;
    networkStatus: string;
  };
  email: string;
  role: string;
  clinicianAdvocate: {
    id: string;
    profile: {
      displayName: string;
    };
  };
};

type DataSourceItem = {
  key: string;
  jobApplicationId: string;
  job: {
    jobId: string;
    title: string;
  };
  client: {
    client: string;
    clientId: string;
  };
  clinicianType: UserRole;
  sharableProfileToken?: {
    clientDescription?: string | null;
    createdAt: Date;
    createdBy: string;
    expiresAt: Date;
    token: string;
  };
  ruling: {
    latestRuling: any;
    applicationId: string;
  };
  utm?: Record<string, string> | null;
};

type Columns = TableProps<DataSourceItem>['columns'];

interface JobApplicationRulingFormValues {
  decision: JobApplicationRulingDecision | 'NotYetRuled';
}

const SELECT_JOB_RULING_OPTIONS = Object.entries(jobRulingChoices).map(([value, label]) => ({
  label,
  value,
}));

const StatusUpdaterForm = ({
  jobApplicationId,
  latestRuling,
}: {
  jobApplicationId: string;
  latestRuling: JobApplicationRulingDecision;
}) => {
  const { formatMessage } = useIntl();
  const { userId } = useParams<{ userId: string }>();
  const { data } = useAdminUserQuery({ variables: { userId } });
  const [createJobApplicationRulingDecisionEvent, { loading }] =
    useCreateJobApplicationRulingDecisionEventMutation({
      onCompleted: () => {
        toast({ message: 'Successfully set job ruling.', variant: 'success' });
      },
      onError: () => {
        toast({ message: 'There was an error setting the job ruling', variant: 'danger' });
      },
    });
  const [createJobApplicationRulingDecisionResetEvent, { loading: resetLoading }] =
    useCreateJobApplicationRulingDecisionResetEventMutation({
      onCompleted: () => {
        toast({ message: 'Successfully unset the job ruling.', variant: 'success' });
      },
      onError: () => {
        toast({ message: 'There was an error unsetting the job ruling', variant: 'danger' });
      },
    });

  const isInviteAndSuggestEnabled = useFeatureFlag(FeatureFlag.InviteAndSuggest);

  const handleJobApplicationRulingFormSubmit = (
    { decision }: JobApplicationRulingFormValues,
    { resetForm }: FormikHelpers<JobApplicationRulingFormValues>,
  ) => {
    const modalInstance = Modal.confirm({
      okButtonProps: {
        onClick: () => {
          if (decision !== 'NotYetRuled') {
            createJobApplicationRulingDecisionEvent({
              variables: { input: { decision, jobApplicationId } },
            });
          } else {
            createJobApplicationRulingDecisionResetEvent({
              variables: { input: { jobApplicationId } },
            });
          }
          modalInstance.destroy();
        },
      },
      cancelButtonProps: {
        onClick: () => {
          resetForm();
          modalInstance.destroy();
        },
      },
      okText: 'Confirm',
      autoFocusButton: 'ok',
      icon: <ExclamationCircleOutlined className={styles.brandColor} />,
      title: 'Are you sure you want to update the ruling decision?',
    });
  };

  return (
    <Form<JobApplicationRulingFormValues>
      initialValues={{ decision: latestRuling || 'NotYetRuled' }}
      onSubmit={handleJobApplicationRulingFormSubmit}
    >
      {({ handleSubmit }) => (
        <FormItem
          label={formatMessage(intlMessages.rulingSelectLabel)}
          name="decision"
          screenReaderOnly
        >
          {({ inputId }) => (
            <Select
              isDisabled={data?.adminUser.profile.networkStatus !== 'YesNetwork'}
              id={inputId}
              isLoading={loading || resetLoading}
              name="decision"
              options={
                isInviteAndSuggestEnabled
                  ? SELECT_JOB_RULING_OPTIONS
                  : SELECT_JOB_RULING_OPTIONS.filter(
                      ({ label }) => label !== jobRulingChoices.Suggested,
                    )
              }
              onChange={() => handleSubmit()}
            />
          )}
        </FormItem>
      )}
    </Form>
  );
};

const Jobs = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { userId } = useParams<{ userId: string }>();
  const { data, error, loading } = useAdminUserQuery({ variables: { userId } });

  const isSharingTokenV2Enabled = useFeatureFlag(FeatureFlag.SharingTokensV2);
  const isInviteAndSuggestEnabled = useFeatureFlag(FeatureFlag.InviteAndSuggest);

  const columns = useMemo<Columns>(
    () => [
      {
        Header: formatMessage(intlMessages.clientTableHeader),
        accessor: 'client',
        disableSortBy: true,
        Cell: ({
          cell: {
            value: { client, clientId: clinicId },
          },
        }: TableTypes.CellProps<DataSourceItem, DataSourceItem['client']>) => (
          <Link
            // need inline style b/c of zillion a {} rules in app
            style={{ color: '#2469FF' }}
            to={generatePath(clinicRoutes.clinicDetail, { clinicId })}
            target="_blank"
          >
            {client}
          </Link>
        ),
      },
      {
        Header: formatMessage(intlMessages.jobTitleTableHeader),
        accessor: 'job',
        disableSortBy: true,
        Cell: ({
          cell: {
            value: { jobId, title },
          },
        }: TableTypes.CellProps<DataSourceItem, DataSourceItem['job']>) => (
          <Link
            // need inline style b/c of zillion a {} rules in app
            style={{ color: '#2469FF' }}
            to={generatePath(sharedRoutes.jobDescription, { jobId })}
            target="_blank"
          >
            {title}
          </Link>
        ),
      },
      {
        Header: formatMessage(intlMessages.clinicianTypeTableHeader),
        accessor: 'clinicianType',
        disableSortBy: true,
      },
      {
        Header: formatMessage(intlMessages.rulingTableHeader),
        accessor: 'ruling',
        disableSortBy: true,
        Cell: ({
          cell: {
            value: { latestRuling, applicationId: jobApplicationId },
          },
        }: TableTypes.CellProps<DataSourceItem, DataSourceItem['ruling']>) => (
          <StatusUpdaterForm latestRuling={latestRuling} jobApplicationId={jobApplicationId} />
        ),
      },
      {
        Header: formatMessage(intlMessages.sharingTokensTableHeader),
        accessor: 'sharableProfileToken',
        disableSortBy: true,
        Cell: ({
          cell: { value: sharableProfileToken },
          row: {
            original: {
              client: { client },
              jobApplicationId,
            },
          },
        }: TableTypes.CellProps<DataSourceItem, DataSourceItem['sharableProfileToken']>) => {
          if (!isSharingTokenV2Enabled) {
            return (
              <p>
                {formatMessage(intlMessages.comingSoon)}!
                <br />
                {formatMessage(intlMessages.untilThen)}{' '}
                <Link to={generatePath(adminRoutes.userSharingTokens, { userId })}>
                  {formatMessage(intlMessages.legacySharingTokens)}
                </Link>
                .
              </p>
            );
          }
          return (
            <span className={styles.tokenCell}>
              <SharableProfileTokenCell
                clinicName={client}
                jobApplicationId={jobApplicationId}
                sharableProfileToken={sharableProfileToken}
              />
            </span>
          );
        },
      },
      {
        Header: formatMessage(intlMessages.utmParametersHeader),
        accessor: 'utm',
        disableSortBy: true,
        Cell: ({
          cell: { value: utm },
        }: TableTypes.CellProps<DataSourceItem, DataSourceItem['utm']>) => {
          const utmText = utm ? convertUtmParamsToAString(utm) : '';
          return <p>{utmText}</p>;
        },
      },
    ],
    [formatMessage, isSharingTokenV2Enabled, userId],
  );

  const dataSource = useMemo<DataSourceItem[]>(() => {
    if (!data?.adminUser.jobApplications) {
      return [];
    }

    return data.adminUser.jobApplications.map(
      ({
        id: jobApplicationId,
        job: {
          id: jobId,
          title,
          clinic: { id: clientId, name: clientName },
        },
        latestRuling,
        sharableProfileToken,
        utm,
      }) => {
        const clinicianType = data.adminUser.role;
        return {
          key: jobApplicationId,
          jobApplicationId,
          job: { jobId, title },
          client: { client: clientName, clientId },
          clinicianType,
          ruling: {
            latestRuling: latestRuling && (latestRuling.decision || 'NotYetRuled'),
            applicationId: jobApplicationId,
          },
          sharableProfileToken: sharableProfileToken
            ? {
                ...sharableProfileToken,
                createdBy: sharableProfileToken?.createdBy.profile.displayName,
              }
            : undefined,
          utm,
        };
      },
    );
  }, [data]);

  const {
    id,
    profile: { firstName, lastName, networkStatus },
    email,
    role,
    clinicianAdvocate,
  } = data?.adminUser as ClinicianSourceData;

  const routerData = {
    id,
    firstName,
    lastName,
    email,
    clinicianType: role,
    clinicianAdvocateId: clinicianAdvocate?.id,
    clinicianAdvocateName: clinicianAdvocate?.profile.displayName,
  };

  return (
    <>
      {error && <h1>{formatMessage(intlMessages.userLoadingErrorMessage)}</h1>}
      <Heading level="h2" className={styles.tableHeading}>
        {formatMessage(intlMessages.tableTitle)}
      </Heading>
      {loading && <Spinner />}
      <Table<DataSourceItem>
        data={dataSource}
        columns={columns}
        className={styles.jobsTable}
        striped
      />
      {isInviteAndSuggestEnabled && (
        <Button
          disabled={
            networkStatus === UserNetworkStatus.NoNetwork ||
            networkStatus === UserNetworkStatus.NotNowNetwork ||
            !clinicianAdvocate
          }
          className={styles.suggestJobsButton}
          variant="secondary"
          icon={<Icon name="PlusCircle" size="medium" />}
          onClick={() =>
            navigate(adminRoutes.suggestJobs, {
              state: routerData,
            })
          }
        >
          {formatMessage(intlMessages.suggestJobs)}
        </Button>
      )}
    </>
  );
};

export default Jobs;
