import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  copiedText: {
    defaultMessage: 'Copied',
    description: 'Text for when the sharable profile link is copied',
    id: 'Modules.admin.UserDetail.Jobs.SharableProfileTokenCell.copiedText',
  },
  copyText: {
    defaultMessage: 'Copy',
    description: 'Text for when the sharable profile link is copied',
    id: 'Modules.admin.UserDetail.Jobs.SharableProfileTokenCell.copyText',
  },
  copyLink: {
    defaultMessage: 'Copy Link',
    description: 'Copy Link',
    id: 'components.admin.UserDetail.SharableProfileTokenCell.copyLink',
  },
  editSharingToken: {
    defaultMessage: 'Edit sharing token',
    description: 'Edit sharing token',
    id: 'components.admin.UserDetail.SharableProfileTokenCell.editSharingToken',
  },
  deleteSharingToken: {
    defaultMessage: 'Delete sharing token',
    description: 'Delete sharing token',
    id: 'components.admin.UserDetail.SharableProfileTokenCell.deleteSharingToken',
  },
  createLink: {
    defaultMessage: 'Create Link',
    description: 'Create Link',
    id: 'components.admin.UserDetail.SharableProfileTokenCell.createLink',
  },
});
