import React, { useMemo } from 'react';
import Combobox from '@openloop/limbic/Form/Select/Combobox';

import { useStatesQuery } from '~Data';

interface Props {
  id: string;
  name?: string;
}

const StatesSelect = ({ id, name = 'stateId' }: Props) => {
  const { data, loading } = useStatesQuery();
  const options = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.states.map(({ id: stateId, name: stateName }) => ({
      value: stateId,
      label: stateName,
    }));
  }, [data]);
  return (
    <Combobox
      id={id}
      name={name}
      placeholder="Select states"
      isClearable
      isLoading={loading}
      options={options}
    />
  );
};
export default StatesSelect;
