import React from 'react';
import Form from '@openloop/limbic/Form';
import { DatePicker } from 'formik-antd';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { Row, Col } from 'antd';
import { useIntl } from 'react-intl';

import common from '~Constants/common';
import EmploymentTypeCheckboxes from '~Core/EmploymentTypeCheckboxes';
import FormItem from '~Core/Form/FormItem';
import { AvailabilityShifts } from '~Data';
import AvailabilityShiftsInput from '~Core/AvailabilityShiftsInput';
import { intlMessages } from './intlMessages';

const { Input } = Form;

export { defaultShifts } from '~Core/AvailabilityShiftsInput';

export interface FormValues {
  availabilityMaxNumber: string;
  availabilityShifts: AvailabilityShifts;
  availabilityStartAt: string;
  jobTypeSeeking: string[];
}

export const validationSchema = yup.object().shape({
  availabilityMaxNumber: yup.number().nullable().typeError('Must be a number'),
  availabilityShifts: yup.object().shape({
    sun: yup.array().nullable(),
    mon: yup.array().nullable(),
    tue: yup.array().nullable(),
    wed: yup.array().nullable(),
    thu: yup.array().nullable(),
    fri: yup.array().nullable(),
    sat: yup.array().nullable(),
  }),
  availabilityStartAt: yup.string().nullable(),
  jobTypeSeeking: yup.array().of(yup.string()),
});

const JobPreferenceFields = <V extends FormValues>() => {
  const { formatMessage } = useIntl();
  const { setFieldValue } = useFormikContext<V>();
  return (
    <>
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <FormItem label={formatMessage(intlMessages.preferredLabel)} name="jobTypeSeeking">
            <EmploymentTypeCheckboxes name="jobTypeSeeking" />
          </FormItem>
        </Col>
      </Row>
      <FormItem label={formatMessage(intlMessages.potentialLabel)} name="availabilityStartAt">
        <DatePicker
          name="availabilityStartAt"
          format={common.dateFormat}
          className="Profile-Form__Datepicker"
          placeholder="Select start date"
          onChange={(_, dateString) => {
            setFieldValue('availabilityStartAt', dateString);
          }}
          style={{ width: '100%' }}
        />
      </FormItem>
      <FormItem label={formatMessage(intlMessages.maximumLabel)} name="availabilityMaxNumber">
        <Input
          min={0}
          name="availabilityMaxNumber"
          placeholder="Maximum number of hours"
          type="number"
        />
      </FormItem>
      <FormItem label={formatMessage(intlMessages.availabilityLabel)} name="availabilityShifts">
        <AvailabilityShiftsInput name="availabilityShifts" />
      </FormItem>
    </>
  );
};

export default JobPreferenceFields;
