import React from 'react';
import Button from '@openloop/limbic/Button';
import { defineMessages, useIntl } from 'react-intl';

import Form from '@openloop/limbic/Form';
import Checkbox from '@openloop/limbic/Form/Checkbox';
import Heading from '@openloop/limbic/Heading';
import { message } from 'antd';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import messages from '~Constants/messages';
import { guestRoutes, providerRoutes } from '~Constants/routes';
import { useAuthContext } from '~Context/AuthContext';
import Link from '~Core/Link';
import Paragraph from '~Core/Paragraph';
import { useApplyToJobMutation } from '~Data';
import cacheUpdates from '~Data/cacheUpdates';

import EmailFormItem from './EmailFormItem';
import PasswordFormItem from './PasswordFormItem';

import emailPasswordValidation from './validation';

import styles from './LoginAndApplyForm.module.scss';
import sharedStyles from './shared.module.scss';

const intlMessages = defineMessages({
  existingAccountMessage: {
    defaultMessage: 'Hi! It looks like you already have an account.',
    description: 'Existing account message',
    id: 'Components.jobApplicationFormContainer.LoginAndApplyForm.existingAccountMessage',
  },
  existingAccountMessageScreenReaderText: {
    defaultMessage: 'press tab to navigate to the password field',
    description: 'Existing account message screen reader text',
    id: 'Components.jobApplicationFormContainer.LoginAndApplyForm.existingAccountMessageScreenReaderText',
  },
  existingAccountMessageHeading: {
    defaultMessage: 'Login to Apply to This Role',
    description: 'Existing account message heading',
    id: 'Components.jobApplicationFormContainer.LoginAndApplyForm.existingAccountMessageHeading',
  },
  rememberMeLabel: {
    defaultMessage: 'Remember Me',
    description: 'Remember me label',
    id: 'Components.jobApplicationFormContainer.LoginAndApplyForm.rememberMeLabel',
  },
  forgotPasswordLabel: {
    defaultMessage: 'Forgot Password?',
    description: 'Forgot password label',
    id: 'Components.jobApplicationFormContainer.LoginAndApplyForm.forgotPasswordLabel',
  },
  loginApplyButtonLabel: {
    defaultMessage: 'Login &amp; Apply',
    description: 'Login & apply button label',
    id: 'Components.jobApplicationFormContainer.LoginAndApplyForm.LoginApplyButtonLabel',
  },
  notYouLabel: {
    defaultMessage: 'Not you? Click',
    description: 'Not you label',
    id: 'Components.jobApplicationFormContainer.LoginAndApplyForm.notYouLabel',
  },
  notYouLinkLabel: {
    defaultMessage: 'here',
    description: 'Not you link label',
    id: 'Components.jobApplicationFormContainer.LoginAndApplyForm.notYouLinkLabel',
  },
  notYouAriaLabel: {
    defaultMessage: 'Not you? Click here to go back',
    description: 'Not you aria label',
    id: 'Components.jobApplicationFormContainer.LoginAndApplyForm.notYouAriaLabel',
  },
  goBackText: {
    defaultMessage: 'to go back',
    description: 'Go back text',
    id: 'Components.jobApplicationFormContainer.LoginAndApplyForm.goBackText',
  },
});

interface Props {
  email: string;
  resetEmail: () => void;
  toggleSignUpForm: () => void;
}

type FormValues = {
  email: Props['email'];
  password: string;
  rememberMe: boolean;
};

const validationSchema = Yup.object().shape({
  ...emailPasswordValidation,
  password: Yup.string().required(messages.passwordRequired),
});

const LoginAndApplyForm = ({ email, resetEmail, toggleSignUpForm }: Props) => {
  const { formatMessage } = useIntl();
  const { jobId } = useParams<{ jobId: string }>();

  const navigate = useNavigate();
  const { currentUser: loggedIn, login, loginLoading } = useAuthContext();

  const [applyToJob, { loading: applyToJobLoading }] = useApplyToJobMutation({
    onCompleted: () => {
      message.success('Applied!');
      navigate(providerRoutes.dashboard);
    },
    update: cacheUpdates.applyToJob(jobId),
  });

  const handleSubmit = (formValues: FormValues) => {
    login({
      input: {
        email: formValues.email,
        password: formValues.password,
        rememberMe: formValues.rememberMe,
      },
      onCompleted: () => {
        applyToJob({
          variables: {
            input: {
              jobId,
            },
          },
        });
      },
    });
  };

  if (loggedIn) {
    return null;
  }

  return (
    <div className={sharedStyles.applyForm}>
      <Paragraph role="alert" className={styles.existingAccount}>
        {formatMessage(intlMessages.existingAccountMessage)}{' '}
        <span className={sharedStyles.screenReaderOnly}>
          {formatMessage(intlMessages.existingAccountMessageScreenReaderText)}
        </span>
      </Paragraph>
      <Heading level="h2" size="h3" role="alert" style={{ marginBottom: '2.25rem' }}>
        {formatMessage(intlMessages.existingAccountMessageHeading)}
      </Heading>

      <Formik<FormValues>
        initialValues={{
          email,
          password: '',
          rememberMe: false,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors }) => (
          <Form className={styles.form}>
            <EmailFormItem disabled />
            <PasswordFormItem />
            <div className={`${styles.loginOptions} ${errors.password ? styles.mt1em : ''}`}>
              <Checkbox name="rememberMe" label={formatMessage(intlMessages.rememberMeLabel)} />
              <Link to={guestRoutes.forgotPassword}>
                {formatMessage(intlMessages.forgotPasswordLabel)}
              </Link>
            </div>
            <Button
              type="submit"
              className={sharedStyles.submitBtn}
              loading={loginLoading || applyToJobLoading}
            >
              {formatMessage(intlMessages.loginApplyButtonLabel)}
            </Button>
            <Paragraph>
              {formatMessage(intlMessages.notYouLabel)}
              <Link
                aria-label={formatMessage(intlMessages.notYouAriaLabel)}
                to={`/jobs/${jobId}`}
                onClick={() => {
                  resetEmail();
                  toggleSignUpForm();
                }}
              >
                {formatMessage(intlMessages.notYouLinkLabel)}
              </Link>
              {formatMessage(intlMessages.goBackText)}
            </Paragraph>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginAndApplyForm;
