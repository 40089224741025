import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  listItem1: {
    defaultMessage: 'Minimum 8 characters in length',
    description: 'List Item 1',
    id: 'components.guest.ResetPassword.listItem1',
  },
  listItem2: {
    defaultMessage: 'At least one upper case letter',
    description: 'List Item 2',
    id: 'components.guest.ResetPassword.listItem2',
  },
  listItem3: {
    defaultMessage: 'At least one numerical',
    description: 'List Item 3',
    id: 'components.guest.ResetPassword.listItem3',
  },
  setNewPassword: {
    defaultMessage: 'Set a new password',
    description: 'Set a new password',
    id: 'components.guest.ResetPassword.setNewPassword',
  },
  setNewPasswordDesc: {
    defaultMessage: 'Please set a new password for your account',
    description: 'Set a new password description',
    id: 'components.guest.ResetPassword.setNewPasswordDesc',
  },
  email: {
    defaultMessage: 'Email',
    description: 'Email',
    id: 'components.guest.ResetPassword.email',
  },
  changePassword: {
    defaultMessage: 'Change my password',
    description: 'Change my password',
    id: 'components.guest.ResetPassword.changePassword',
  },
  goBack: {
    defaultMessage: 'Go Back to Log In',
    description: 'Go Back to Log In',
    id: 'components.guest.ResetPassword.goBack',
  },
});
