import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { UserNetworkStatus } from '~Data/generated';
import StatusBadge from '~Components/StatusBadge';

import styles from './NetworkStatus.module.scss';

const intlMessages = defineMessages({
  networkHeading: {
    defaultMessage: 'OpenLoop',
    description: 'Network Status heading',
    id: 'Components.NetworkStatus.networkHeading',
  },
  networkHeadingText: {
    defaultMessage: 'Network Status',
    description: 'Network Status heading',
    id: 'Components.NetworkStatus.networkHeadingText',
  },
});

interface NetworkStatusProps {
  status: UserNetworkStatus;
}

type StatusBadgePropsMap = Record<
  UserNetworkStatus,
  {
    iconType: string;
    text: string;
  }
>;

const statusColorText: StatusBadgePropsMap = {
  Incomplete: { iconType: styles.incomplete, text: 'Incomplete' },
  NoNetwork: { iconType: styles.declined, text: 'Declined' },
  NotNowNetwork: { iconType: styles.notNow, text: 'Not At Moment' },
  PendingReview: { iconType: styles.pending, text: 'Pending Review' },
  YesNetwork: { iconType: styles.accepted, text: 'Accepted' },
};

const NetworkStatus = ({ status }: NetworkStatusProps) => {
  const { formatMessage } = useIntl();
  const { iconType, text } = statusColorText[status];
  return (
    <>
      <p className={styles.statusTextHeading}>
        {formatMessage(intlMessages.networkHeading)}
        <br />
        {formatMessage(intlMessages.networkHeadingText)}
      </p>
      <div className={styles.networkStatusbadge}>
        <StatusBadge iconType={iconType} text={<span className={styles.badgeText}>{text}</span>} />
      </div>
    </>
  );
};

export default NetworkStatus;
