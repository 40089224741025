import React from 'react';
import classnames from 'classnames';
import Heading from '@openloop/limbic/Heading';
import Tag from '@openloop/limbic/Tag';

import { employmentTypeChoices } from '~Constants/employmentTypes';
import { JobQuery } from '~Data';

import styles from './JobTitle.module.scss';

const cx = classnames.bind(styles);

interface Props {
  title: JobQuery['job']['title'];
  employmentType?: JobQuery['job']['employmentType'];
  isAuthenticated: boolean;
}

const JobTitle = ({ title, isAuthenticated, employmentType }: Props) => (
  <div>
    <Heading
      level="h1"
      className={cx(styles.defaultJobTitle, {
        [styles.guestJobTitle]: !isAuthenticated,
        [styles.spacedTitle]: !employmentType || !employmentType.length,
      })}
    >
      {title}
    </Heading>
    {employmentType &&
      employmentType.map((label) => (
        <span className={styles.tag} key={label}>
          <Tag>{employmentTypeChoices[label]}</Tag>
        </span>
      ))}
  </div>
);

export default JobTitle;
