import React from 'react';
import Select, { SelectProps } from '@openloop/limbic/Form/Select/Select';

import { employmentStatusChoices } from '~Constants/employmentStatus';
import { PartialBy } from '~Types/utils';

interface Props extends PartialBy<Omit<SelectProps, 'options'>, 'placeholder'> {}

const employmentStatusOptions = Object.entries(employmentStatusChoices).map(([value, label]) => ({
  value,
  label,
}));

const EmploymentStatusSelect = ({ id, name, placeholder = 'Select a Status', ...rest }: Props) => (
  <Select
    id={id}
    name={name}
    placeholder={placeholder}
    options={employmentStatusOptions}
    {...rest}
  />
);

export default EmploymentStatusSelect;
