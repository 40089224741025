import React from 'react';

import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { Wizard } from 'react-use-wizard';
import { Form, toast } from '@openloop/limbic';

import { useCreateJobSuggestionsMutation } from './SuggestJobsWizard.generated';
import JobSearchAndSelectScreen from '../../JobSearchAndSelectScreen';
import { ConfirmInfoScreen, SuccessScreen } from '../../ConfirmationScreens';
import ClinicianSearchPlaceholder from '../../ClinicianSearchPlaceholder';
import { PreloadedClinicianData, SuggestJobsValues } from '../../types';
import { suggestJobsInitialValues, suggestJobsValidationSchema } from '../../utils';

interface Location {
  state?: PreloadedClinicianData;
}

const SuggestJobsWizard = () => {
  const { state: clinicianData } = useLocation() as Location;
  const [createJobSuggestions, { loading }] = useCreateJobSuggestionsMutation();

  return (
    <Formik<SuggestJobsValues>
      initialValues={
        clinicianData
          ? { ...clinicianData, clinicId: '', jobsToSuggestIds: [] }
          : suggestJobsInitialValues
      }
      initialStatus={{
        isSubmited: false,
        clinicianAdvocate: clinicianData ? clinicianData.clinicianAdvocateName : undefined,
      }}
      validationSchema={suggestJobsValidationSchema}
      onSubmit={(values, { setStatus }) => {
        const { clinicianAdvocateId, jobsToSuggestIds, id: userId } = values;

        if (clinicianAdvocateId && userId) {
          createJobSuggestions({
            variables: {
              input: {
                clinicianAdvocateId,
                jobsToSuggestIds,
                userId,
              },
            },
            onCompleted: () => {
              setStatus({ isSubmited: true });
            },
            onError: (e) => {
              toast({ variant: 'danger', message: e.message });
            },
          });
        }
      }}
    >
      {({ status: { isSubmited } }) =>
        loading || isSubmited ? (
          <SuccessScreen flowType="suggest" isLoading={loading} />
        ) : (
          <Form>
            <Wizard>
              {clinicianData ? null : <ClinicianSearchPlaceholder />}
              <JobSearchAndSelectScreen />
              <ConfirmInfoScreen />
            </Wizard>
          </Form>
        )
      }
    </Formik>
  );
};

export default SuggestJobsWizard;
