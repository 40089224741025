import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Modal from '@openloop/limbic/Modal';
import toast from '@openloop/limbic/Toast';

import { useDeleteJobApplicationSharableProfileTokenMutation } from './DeleteSharingTokenModal.generated';

const intlMessages = defineMessages({
  messageSharingTokenDeletedSuccessfully: {
    defaultMessage: 'Sharing token deleted successfully',
    description: 'Sharing token deleted successfully message',
    id: 'Modules.admin.UserDetail.Jobs.DeleteSharingTokenModal.messageSharingTokenDeletedSuccessfully',
  },
  modalAriaLabel: {
    defaultMessage: 'Delete sharing token modal',
    description: 'Delete sharing token modal aria label text',
    id: 'Modules.admin.UserDetail.Jobs.DeleteSharingTokenModal.modalAriaLabel',
  },
  modalBody: {
    defaultMessage: `Are you sure you want to delete this sharing token? Anyone with this link will no longer be able to access this clinician's profile.`,
    description: 'Delete sharing token modal title',
    id: 'Modules.admin.UserDetail.Jobs.DeleteSharingTokenModal.modalBody',
  },
  modalButtonDelete: {
    defaultMessage: 'Yes, Delete',
    description: 'Delete sharing token modal button text',
    id: 'Modules.admin.UserDetail.Jobs.DeleteSharingTokenModal.modalButtonDelete',
  },
  modalTitle: {
    defaultMessage: 'Delete sharing token',
    description: 'Delete sharing token modal title',
    id: 'Modules.admin.UserDetail.Jobs.DeleteSharingTokenModal.modalTitle',
  },
});

interface Props {
  handleModalClose: () => void;
  isVisible: boolean;
  jobApplicationId: string;
}

const DeleteSharingTokenModal = ({ handleModalClose, isVisible, jobApplicationId }: Props) => {
  const { formatMessage } = useIntl();

  const [deleteJobApplicationSharableProfileToken, { loading }] =
    useDeleteJobApplicationSharableProfileTokenMutation({
      onCompleted: () => {
        toast({
          variant: 'success',
          message: formatMessage(intlMessages.messageSharingTokenDeletedSuccessfully),
        });
        handleModalClose();
      },
      variables: { input: { jobApplicationId } },
    });

  return (
    <Modal
      ariaLabel={formatMessage(intlMessages.modalAriaLabel)}
      cancelButtonProps={{
        disabled: loading,
      }}
      heading={formatMessage(intlMessages.modalTitle)}
      isVisible={isVisible}
      okButtonText={formatMessage(intlMessages.modalButtonDelete)}
      okButtonProps={{
        loading,
        variant: 'danger',
      }}
      onCancel={handleModalClose}
      onOk={() => {
        deleteJobApplicationSharableProfileToken();
      }}
    >
      {formatMessage(intlMessages.modalBody)}
    </Modal>
  );
};

export default DeleteSharingTokenModal;
