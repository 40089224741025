import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { Col, Row, message, Spin } from 'antd';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import Button from '@openloop/limbic/Button';
import Heading from '@openloop/limbic/Heading';
import { useIntl } from 'react-intl';

import { clinicRoutes } from '~Constants/routes';
import messages from '~Constants/messages';
import Link from '~Core/Link';
import SEO from '~Core/SEO';
import { useClinicQuery, useEditClinicMutation } from '~Data';
import FullScreen from '~Templates/FullScreen';

import ClinicFields, {
  validationSchema as clinicFieldsValidationSchema,
} from '../Forms/ClinicFields';
import { intlMessages } from './intlMessages';

import styles from './EditClinic.module.less';

interface FormValues {
  name: string;
  phone: string;
  websiteUrl: string;
  zohoUrl: string;
}

const EditClinic = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { clinicId } = useParams<{ clinicId: string }>();

  const { data: currentClinicData, loading: currentClinicLoading } = useClinicQuery({
    variables: { id: clinicId },
  });

  const [editClinic, { data: editClinicData, error: editClinicError, loading: editClinicLoading }] =
    useEditClinicMutation();

  const handleSubmit = useCallback(
    (values: FormValues) => {
      editClinic({ variables: { input: { ...values, id: clinicId } } });
    },
    [clinicId, editClinic],
  );

  useEffect(() => {
    if (editClinicData && editClinicData.editClinic) {
      message.success(messages.clinicUpdateSuccessful);
      navigate(generatePath(clinicRoutes.clinicDetail, { clinicId }));
    }
  }, [clinicId, editClinicData, navigate]);

  useEffect(() => {
    if (editClinicError && !editClinicLoading && !editClinicData) {
      message.error(messages.clinicUpdateError);
    }
  }, [editClinicData, editClinicError, editClinicLoading]);

  if (!currentClinicData || currentClinicLoading) {
    return <Spin />;
  }

  const {
    clinic: { name, phone, websiteUrl, zohoUrl },
  } = currentClinicData;

  return (
    <FullScreen withHeader={false}>
      <SEO title="Edit Clinic" />
      <div className={styles.main}>
        <Row justify="center">
          <Col xs={18} sm={16} md={14} lg={12} xl={10} xxl={8}>
            <Formik<FormValues>
              initialValues={{
                name,
                phone: phone || '',
                websiteUrl: websiteUrl || '',
                zohoUrl: zohoUrl || '',
              }}
              onSubmit={handleSubmit}
              validationSchema={clinicFieldsValidationSchema}
              enableReinitialize
            >
              {({ dirty, isValid }) => (
                <Form layout="vertical">
                  <Heading level="h2">{formatMessage(intlMessages.editClinic)}</Heading>
                  <ClinicFields />
                  <div className={styles.formFooter}>
                    <Link
                      to={generatePath(clinicRoutes.clinicDetail, { clinicId })}
                      buttonVariant="secondary-outline"
                    >
                      {formatMessage(intlMessages.cancelButton)}
                    </Link>
                    <Button
                      disabled={!isValid || !dirty}
                      type="submit"
                      loading={editClinicLoading}
                      variant="primary"
                      size="lg"
                    >
                      {formatMessage(intlMessages.saveButton)}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    </FullScreen>
  );
};

export default EditClinic;
