import * as Types from '../../../Data/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClinicsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ClinicsQuery = { clinics: Array<{ createdAt: any, id: string, name: string, numJobsActive?: number | null, phone?: string | null, accountManager?: { id: string, profile: { displayName: string, firstName?: string | null, lastName?: string | null, userId: string } } | null, contacts?: Array<{ email?: string | null, id: string, isPrimary: boolean, name?: string | null, phone?: string | null }> | null }> };


export const ClinicsDocument = gql`
    query Clinics {
  clinics {
    accountManager {
      id
      profile {
        displayName @client
        firstName
        lastName
        userId
      }
    }
    contacts {
      email
      id
      isPrimary
      name
      phone
    }
    createdAt
    id
    name
    numJobsActive
    phone
  }
}
    `;

/**
 * __useClinicsQuery__
 *
 * To run a query within a React component, call `useClinicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClinicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClinicsQuery(baseOptions?: Apollo.QueryHookOptions<ClinicsQuery, ClinicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClinicsQuery, ClinicsQueryVariables>(ClinicsDocument, options);
      }
export function useClinicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClinicsQuery, ClinicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClinicsQuery, ClinicsQueryVariables>(ClinicsDocument, options);
        }
export type ClinicsQueryHookResult = ReturnType<typeof useClinicsQuery>;
export type ClinicsLazyQueryHookResult = ReturnType<typeof useClinicsLazyQuery>;
export type ClinicsQueryResult = Apollo.QueryResult<ClinicsQuery, ClinicsQueryVariables>;