import * as Types from '../../../../../Data/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteJobApplicationSharableProfileTokenMutationVariables = Types.Exact<{
  input: Types.DeleteJobApplicationSharableProfileTokenInput;
}>;


export type DeleteJobApplicationSharableProfileTokenMutation = { deleteJobApplicationSharableProfileToken: { id: string, sharableProfileToken?: { clientDescription?: string | null, createdAt: any, createdById: string, expiresAt: any, token: string, createdBy: { id: string, email: string, profile: { userId: string, firstName?: string | null, lastName?: string | null, displayName: string } } } | null } };


export const DeleteJobApplicationSharableProfileTokenDocument = gql`
    mutation DeleteJobApplicationSharableProfileToken($input: DeleteJobApplicationSharableProfileTokenInput!) {
  deleteJobApplicationSharableProfileToken(input: $input) {
    id
    sharableProfileToken {
      clientDescription
      createdAt
      createdById
      createdBy {
        id
        email
        profile {
          userId
          firstName
          lastName
          displayName @client
        }
      }
      expiresAt
      token
    }
  }
}
    `;
export type DeleteJobApplicationSharableProfileTokenMutationFn = Apollo.MutationFunction<DeleteJobApplicationSharableProfileTokenMutation, DeleteJobApplicationSharableProfileTokenMutationVariables>;

/**
 * __useDeleteJobApplicationSharableProfileTokenMutation__
 *
 * To run a mutation, you first call `useDeleteJobApplicationSharableProfileTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobApplicationSharableProfileTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobApplicationSharableProfileTokenMutation, { data, loading, error }] = useDeleteJobApplicationSharableProfileTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteJobApplicationSharableProfileTokenMutation(baseOptions?: Apollo.MutationHookOptions<DeleteJobApplicationSharableProfileTokenMutation, DeleteJobApplicationSharableProfileTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteJobApplicationSharableProfileTokenMutation, DeleteJobApplicationSharableProfileTokenMutationVariables>(DeleteJobApplicationSharableProfileTokenDocument, options);
      }
export type DeleteJobApplicationSharableProfileTokenMutationHookResult = ReturnType<typeof useDeleteJobApplicationSharableProfileTokenMutation>;
export type DeleteJobApplicationSharableProfileTokenMutationResult = Apollo.MutationResult<DeleteJobApplicationSharableProfileTokenMutation>;
export type DeleteJobApplicationSharableProfileTokenMutationOptions = Apollo.BaseMutationOptions<DeleteJobApplicationSharableProfileTokenMutation, DeleteJobApplicationSharableProfileTokenMutationVariables>;