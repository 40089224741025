import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Heading from '@openloop/limbic/Heading';
import styles from './WelcomeText.module.scss';

const intlMessages = defineMessages({
  heading: {
    defaultMessage: "We're all set!",
    description: 'Pending network status heading text',
    id: 'Modules.provider.Home.Welcome.WelcomeText.Pending.heading',
  },
  firstParagraph: {
    defaultMessage:
      'Thank you for taking the time to build out your profile with OpenLoop! It has been passed over to our Clinician Advocates for review, so please keep an eye out for updates.',
    description: 'Pending network status first paragraph',
    id: 'Modules.provider.Home.Welcome.WelcomeText.Pending.firstParagraph',
  },
});

const Pending = () => {
  const { formatMessage } = useIntl();
  const heading = formatMessage(intlMessages.heading);
  const firstParagraph = formatMessage(intlMessages.firstParagraph);

  return (
    <div>
      <Heading className={styles.welcomeTextHeading} level="h2">
        {heading}
      </Heading>
      <p>{firstParagraph}</p>
    </div>
  );
};

export default Pending;
