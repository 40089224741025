import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { providerRoutes } from '~Constants/routes';
import { useAuthContext } from '~Context/AuthContext';
import Paragraph from '~Core/Paragraph';
import { Scalars } from '~Data';

import ClickToApply from './ClickToApply';
import { intlMessagesLoggedInForm } from './intlMessages';

interface Props {
  alreadyApplied: boolean;
  applicationDate: Scalars['DateTime'];
}

const LoggedInJobApplicationForm = ({ alreadyApplied, applicationDate }: Props) => {
  const { formatMessage } = useIntl();
  const { currentUser: loggedIn } = useAuthContext();

  if (!loggedIn) {
    return null;
  }

  if (alreadyApplied) {
    return (
      <Paragraph>
        {formatMessage(intlMessagesLoggedInForm.paragraph, {
          moment: moment(applicationDate).format('M/D/YYYY'),
          link: (
            <Link to={providerRoutes.dashboard}>
              {formatMessage(intlMessagesLoggedInForm.jobApplicationTracker)}
            </Link>
          ),
        })}
      </Paragraph>
    );
  }
  return <ClickToApply />;
};

export default LoggedInJobApplicationForm;
