import React from 'react';
import { Row, Col, Form as AntForm, Result } from 'antd';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import Button from '@openloop/limbic/Button';
import Input from '@openloop/limbic/Form/Input';
import Heading from '@openloop/limbic/Heading';
import { useIntl } from 'react-intl';

import images from '~Constants/images';
import messages from '~Constants/messages';
import { guestRoutes } from '~Constants/routes';
import Link from '~Core/Link';
import Paragraph from '~Core/Paragraph';
import { useRequestPasswordResetMutation } from '~Data';
import { useErrorDisplay } from '~Hooks/useErrorDisplay';
import { intlMessages } from './intlMessages';

import styles from './ForgotPassword.module.less';

interface FormValues {
  email: string;
}

const validationSchema = yup.object().shape({
  email: yup.string().required(messages.emailRequired).email(messages.emailInvalid),
});

const ForgotPassword = () => {
  const { formatMessage } = useIntl();
  const [
    requestPasswordReset,
    { data: requestPasswordData, error: requestPasswordError, loading: requestPasswordLoading },
  ] = useRequestPasswordResetMutation();

  const onSubmit = ({ email }: FormValues) => {
    requestPasswordReset({ variables: { input: { email } } });
  };

  useErrorDisplay(requestPasswordError);

  return (
    <div className={styles.main}>
      <Row justify="center">
        <Col xs={20} sm={16} md={10} lg={8}>
          <img src={images.logo} className={styles.logo} alt="OpenLoop Logo" />
          {!requestPasswordData ? (
            <>
              <div className={styles.formIntroContent}>
                <Heading level="h1">{formatMessage(intlMessages.resetPassword)}</Heading>
                <Paragraph>{formatMessage(intlMessages.paragraph)}</Paragraph>
              </div>
              <Formik<FormValues>
                initialValues={{ email: '' }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {({ dirty, isValid }) => (
                  <Form layout="vertical">
                    <Form.Item required name="email" label={formatMessage(intlMessages.email)}>
                      <Input name="email" placeholder="john.doe@example.com" />
                    </Form.Item>
                    <AntForm.Item className={styles.submitButton}>
                      <Button
                        block
                        disabled={!dirty || !isValid}
                        type="submit"
                        loading={requestPasswordLoading}
                        size="lg"
                        variant="primary"
                      >
                        {formatMessage(intlMessages.sendResetEmail)}
                      </Button>
                    </AntForm.Item>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <Result
              status="success"
              subTitle="To reset your password, follow the intructions in the email we've just sent you from OpenLoop."
              title="Please check your inbox"
            />
          )}
          <div className={styles.signInLink}>
            <Link to={guestRoutes.login}>{formatMessage(intlMessages.returnMessage)}</Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
