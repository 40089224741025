import React, { useEffect } from 'react';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { message } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import Grid from '@openloop/limbic/Grid';
import Heading from '@openloop/limbic/Heading';
import { useIntl } from 'react-intl';

import common from '~Constants/common';
import messages from '~Constants/messages';
import { clinicRoutes } from '~Constants/routes';
import WizardFooter from '~Components/WizardFooter/WizardFooter';
import SEO from '~Core/SEO';
import { ClinicDocument, ClinicQueryVariables, useClinicQuery, useCreateJobMutation } from '~Data';
import { getMaybeMomentDateString } from '~Helpers/dates';
import { useInitialValuesJob } from '~Hooks/useInitialValuesJob';
import LoadingScreen from '~Modules/shared/LoadingScreen';
import FullScreen from '~Templates/FullScreen';

import { JobFields, JobFormValues, jobValidationSchema } from '../Forms/JobFields';
import { intlMessages } from './intlMessages';

import styles from './CreateJob.module.scss';

const { Col, Row } = Grid;

const CreateJob = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { clinicId } = useParams<{ clinicId: string }>();
  const { data: clinicData, loading: clinicLoading } = useClinicQuery({
    variables: { id: clinicId },
  });
  const [createJob, { data, error, loading: createJobLoading }] = useCreateJobMutation();

  const initialValues = useInitialValuesJob(null);

  useEffect(() => {
    if (data && data.createJob) {
      message.success(messages.jobCreateSuccessful);
      navigate(generatePath(clinicRoutes.clinicDetail, { clinicId }));
    }
  }, [clinicId, data, navigate]);

  useEffect(() => {
    if (error && !createJobLoading && !data) {
      message.error(messages.jobCreateError);
    }
  }, [error, createJobLoading, data]);

  const performJobCreation = (snapshot: JobFormValues) => {
    const { desiredTelehealthExperience, numberToFill, startAt, ...initialStep } = snapshot;
    const clinicQueryVariables: ClinicQueryVariables = { id: clinicId };
    const input = {
      clinicId,
      desiredTelehealthExperience: desiredTelehealthExperience || undefined,
      numberToFill: numberToFill || undefined,
      startAt: getMaybeMomentDateString(startAt, common.requestDateFormat),
      ...initialStep,
    };
    createJob({
      variables: { input },
      refetchQueries: [{ query: ClinicDocument, variables: clinicQueryVariables }],
    });
  };

  if (clinicLoading) {
    return <LoadingScreen />;
  }

  if (!clinicData) {
    return null;
  }

  return (
    <FullScreen withHeader={false}>
      <SEO title="Create Job" />
      <div className={styles.main}>
        <Row justify="center">
          <Col>
            <Heading level="h1" size="h3" className={styles.heading}>
              {formatMessage(intlMessages.createJob)}
            </Heading>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <Formik
              initialValues={initialValues}
              onSubmit={performJobCreation}
              validationSchema={jobValidationSchema}
            >
              {({ dirty, isValid }) => (
                <Form>
                  <JobFields />
                  <WizardFooter
                    cancelRoute={generatePath(clinicRoutes.clinicDetail, { clinicId })}
                    nextButtonText="Create Job"
                    nextButtonProps={{
                      disabled: !(dirty && isValid),
                      loading: createJobLoading,
                      type: 'submit',
                      variant: 'primary',
                    }}
                  />
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    </FullScreen>
  );
};

export default CreateJob;
