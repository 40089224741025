import React, { useState } from 'react';
import Card from '@openloop/limbic/Card';
import classnames from 'classnames/bind';
import Icon from '@openloop/limbic/Icon';
import Button from '@openloop/limbic/Button';
import { motion, useCycle } from 'framer-motion';

import styles from './AccordionCard.module.scss';

interface AccordionCardProps {
  children?: React.ReactNode;
}

const cx = classnames.bind(styles);

const AccordionCard = ({ children }: AccordionCardProps) => {
  const [showContent, setShowContent] = useState<boolean>(true);
  const [heightSetting, cycle] = useCycle({ height: 'auto' }, { height: '6.8rem' });

  const cardClassNames = cx(styles.patientInfoCardContainer, styles.patientInfoCardBorder);

  const cycleExpandCollapse = () => {
    cycle();
    setShowContent(!showContent);
  };

  return (
    <>
      <motion.div animate={heightSetting} className={cardClassNames}>
        <Card padding="none">{children}</Card>
      </motion.div>
      <Button
        aria-expanded={showContent}
        aria-controls="infoCardBody"
        className={styles.toggleBtn}
        onClick={cycleExpandCollapse}
      >
        <Icon name={showContent ? 'ChevronUp' : 'ChevronDown'} className={styles.accordionIcon} />
      </Button>
    </>
  );
};

export default AccordionCard;
