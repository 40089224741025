import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Upload, message, Progress, Typography } from 'antd';
import { Formik, useFormikContext, useField } from 'formik';
import * as Yup from 'yup';
import { useParams, useNavigate } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';

import Button from '@openloop/limbic/Button';
import Form from '@openloop/limbic/Form';
import Heading from '@openloop/limbic/Heading';

import StatesSelect from '~Components/StatesSelect';
import SpecialtySelect from '~Core/SpecialtySelect';
import ClinicianTypeSelect from '~Core/ClinicianTypeSelect';
import EmrCompatabilitySelect from '~Core/EmrCompatibilitySelect';
import FormItem from '~Core/Form/FormItem';
import PhoneInput from '~Core/PhoneInput/PhoneInput';
import { specialtiesByType } from '~Constants/specialties';
import {
  ProviderUserRole,
  UserRole,
  useNonAdminUserExistsLazyQuery,
  useSignUpAndApplyMutation,
} from '~Data';
import useDebounce from '~Hooks/useDebounce';
import useQuerystringParams from '~Hooks/useQuerystringParams';
import messages from '~Constants/messages';
import common from '~Constants/common';
import { providerRoutes } from '~Constants/routes';
import { useAuthContext } from '~Context/AuthContext';
import cacheUpdates from '~Data/cacheUpdates';
import { getUtmParams } from '~Helpers/utmParams';

import EmailFormItem from './EmailFormItem';
import PasswordFormItem from './PasswordFormItem';
import emailPasswordValidation from './validation';

import { FormType } from './types';

import { ReactComponent as Attachment } from '../../Assets/icons/attachment.svg';

import styles from './shared.module.scss';

const { Input } = Form;
const { Paragraph } = Typography;

const intlMessages = defineMessages({
  fieldLabelUsername: {
    defaultMessage: 'Username',
    description: 'Username field label',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.fieldLabelUsername',
  },
  formTitle: {
    defaultMessage: 'Submit Your Application',
    description: 'Form title',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.formTitle',
  },
  fieldLabelFirstName: {
    defaultMessage: 'First Name',
    description: 'First name field label',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.fieldLabelFirstName',
  },
  fieldLabelLastName: {
    defaultMessage: 'Last Name',
    description: 'Last name field label',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.fieldLabelLastName',
  },
  fieldLabelPhone: {
    defaultMessage: 'Phone',
    description: 'Phone field label',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.fieldLabelPhone',
  },
  fieldLabelResume: {
    defaultMessage: 'Resumé',
    description: 'Resume field label',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.fieldLabelResume',
  },
  buttonLabelUpload: {
    defaultMessage: 'Upload',
    description: 'Upload button label',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.buttonLabelUpload',
  },
  buttonLabelRemoveFile: {
    defaultMessage: 'Remove File',
    description: 'Remove file button label',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.buttonLabelRemoveFile',
  },
  fieldLabelClinicianType: {
    defaultMessage: 'Clinician Type',
    description: 'Clinician type field label',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.fieldLabelClinicianType',
  },
  fieldLabelSpecialty: {
    defaultMessage: 'Specialty',
    description: 'Specialty field label',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.fieldLabelSpecialty',
  },
  fieldLabelCurrentStateLicenses: {
    defaultMessage: 'In which states are you currently licensed to provide care?',
    description: 'Emr providers field label',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.fieldLabelCurrentStateLicenses',
  },
  fieldLabelEmrSystems: {
    defaultMessage: 'In which Electronic Medical Record (EMR) systems are you proficient?',
    description: 'Emr systems field label',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.fieldLabelEmrSystems',
  },
  fieldLabelConfirmPassword: {
    defaultMessage: 'Confirm Password',
    description: 'Confirm password field label',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.fieldLabelConfirmPassword',
  },
  buttonLabelSubmitApplication: {
    defaultMessage: 'Submit Application',
    description: 'Submit application button label',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.buttonLabelSubmitApplication',
  },
  headingJoinNetwork: {
    defaultMessage: 'Join Our Network',
    description: 'Join our network heading',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.headingJoinNetwork',
  },
  contentPrivacyPolicy: {
    defaultMessage: 'By clicking Submit Application, you agree to the OpenLoop',
    description: 'Privacy Policy content',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.contentPrivacyPolicy',
  },
  contentNewWindowText: {
    defaultMessage: 'Opens a new window',
    description: 'Screen reader text for opening a new window',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.contentNewWindowText',
  },
  contentPrivacyConjuntionText: {
    defaultMessage: 'and',
    description: 'Privacy Policy conjuntion text',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.contentPrivacyConjuntionText',
  },
  linkLabelPrivacyPolicy: {
    defaultMessage: 'Privacy Policy',
    description: 'Privacy Policy link label',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.linkLabelPrivacyPolicy',
  },
  linkLabelTermsOfUse: {
    defaultMessage: 'Terms of Use',
    description: 'Terms of use link label',
    id: 'Components.JobApplicationFormContainer.ApplyAndSignupForm.linkLabelTermsOfUse',
  },
});

interface Props {
  setFormType: Dispatch<SetStateAction<FormType>>;
  setEmail: Dispatch<SetStateAction<string>>;
}
interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  resume: any;
  clinicianType: ProviderUserRole;
  specialty: string;
  currentStateLicenses: [];
  emrProviders: [];
  username: string;
  password: string;
  confirmPassword: string;
}

const validationSchema = Yup.object().shape({
  ...emailPasswordValidation,
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phone: Yup.string().phone('Must be a valid phone number').required('Phone number is required'),
  clinicianType: Yup.string().required('Clinician Type is required'),
  specialty: Yup.string().when('clinicianType', {
    is: (value: UserRole) => [UserRole.Therapist, UserRole.Other].includes(value),
    then: Yup.string().nullable(),
    otherwise: Yup.string().required('Speciality is required'),
  }),
  currentStateLicenses: Yup.array().of(Yup.string()).required('This field is required'),
  emrProviders: Yup.array().of(Yup.string()).required('This field is required'),
  resume: Yup.mixed().required('A resumé is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Password does not match')
    .required('Please confirm your password'),
});

const SyncUsersname = () => {
  const { formatMessage } = useIntl();
  const {
    values: { email },
    setFieldValue,
  } = useFormikContext<FormValues>();

  useEffect(() => {
    setFieldValue('username', email);
  }, [email, setFieldValue]);

  return (
    <FormItem label={formatMessage(intlMessages.fieldLabelUsername)} name="username">
      {({ inputId }) => <Input id={inputId} name="username" disabled readOnly />}
    </FormItem>
  );
};

const EmailField = ({ setFormType, setEmail }: Props) => {
  const [, { error: emailValidationError, value: email }] = useField('email');

  const [checkIfUserExists, { called, data, error, loading, refetch }] =
    useNonAdminUserExistsLazyQuery();

  const debouncedEmail = useDebounce<string>(emailValidationError ? '' : email, 500);

  useEffect(() => {
    if (debouncedEmail && !loading) {
      if (called) {
        refetch({ email: debouncedEmail });
      } else {
        checkIfUserExists({ variables: { email: debouncedEmail } });
      }
    }
  }, [called, checkIfUserExists, debouncedEmail, loading, refetch, error]);

  useEffect(() => {
    if (data && data.nonAdminUserExists) {
      setEmail(email);
      setFormType('login');
    }
  }, [data, setFormType, email, setEmail]);

  return <EmailFormItem />;
};

const ApplyAndSignupForm = ({ setFormType, setEmail }: Props) => {
  const navigate = useNavigate();
  const queryParams = useQuerystringParams();
  const { formatMessage } = useIntl();
  const { jobId } = useParams<{ jobId: string }>();
  const [percentUploaded, setPercentUploaded] = useState<number>(-1);
  const utm = getUtmParams(queryParams);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (percentUploaded >= 0 && percentUploaded < 100) {
        setPercentUploaded(percentUploaded + 25);
      }
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [percentUploaded]);

  const [signUpAndApply, { loading: signUpAndApplyLoading }] = useSignUpAndApplyMutation({
    onCompleted: () => {
      message.success('Applied!');
    },
    update: cacheUpdates.applyToJob(jobId),
  });

  const { currentUser, login, loginLoading } = useAuthContext();
  const loggedIn = !!currentUser;

  const handleSubmit = ({
    email,
    password,
    clinicianType,
    currentStateLicenses,
    emrProviders,
    firstName,
    lastName,
    phone,
    specialty,
    resume,
  }: FormValues) => {
    signUpAndApply({
      onCompleted: () => {
        login({
          onCompleted: () => {
            navigate(providerRoutes.dashboard);
          },
          input: {
            email,
            password,
          },
        });
      },
      variables: {
        applyToJobInput: {
          jobId,
        },
        cv: resume.originFileObj,
        signUpInput: {
          email,
          password,
          role: clinicianType,
        },
        statesLicensedIn: currentStateLicenses,
        userProfileInput: {
          firstName,
          lastName,
          phone,
          specialty,
          EHRCompatibility: emrProviders,
          ...(utm && { utm }),
        },
      },
    });
  };

  if (loggedIn) {
    return null;
  }

  return (
    <div className={styles.applyForm}>
      <Heading level="h2" size="h3" style={{ marginBottom: '2.25rem' }}>
        {formatMessage(intlMessages.formTitle)}
      </Heading>
      <Formik<FormValues>
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          phone: '',
          resume: null,
          clinicianType: '' as ProviderUserRole,
          specialty: '',
          currentStateLicenses: [],
          emrProviders: [],
          username: '',
          password: '',
          confirmPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form aria-labelledby="Submit your application" className={styles.form}>
            <EmailField setFormType={setFormType} setEmail={setEmail} />
            <FormItem
              label={formatMessage(intlMessages.fieldLabelFirstName)}
              name="firstName"
              required
            >
              {({ inputId }) => <Input id={inputId} name="firstName" />}
            </FormItem>
            <FormItem
              label={formatMessage(intlMessages.fieldLabelLastName)}
              name="lastName"
              required
            >
              {({ inputId }) => <Input id={inputId} name="lastName" />}
            </FormItem>
            <FormItem label={formatMessage(intlMessages.fieldLabelPhone)} name="phone" required>
              {({ inputId }) => <PhoneInput id={inputId} name="phone" />}
            </FormItem>
            <FormItem
              name="resume"
              label={formatMessage(intlMessages.fieldLabelResume)}
              aria-label={`upload resume in ${common.acceptDocumentExtensions}`}
              inlineDescription={common.acceptDocumentExtensions}
              required
            >
              {!values.resume ? (
                <Upload
                  data-testid="resume-input"
                  className={styles.uploadButton}
                  showUploadList={false}
                  name="resume"
                  accept={common.acceptDocumentExtensions}
                  maxCount={1}
                  fileList={values.resume ? [{ ...values.resume, status: 'done' }] : []}
                  onChange={(info) => {
                    const [file] = [...info.fileList];
                    if (file) {
                      const { size } = file;
                      const isLtSize =
                        typeof size === 'number' && size / 1024 / 1024 < common.limitCvSizeInMb;

                      if (isLtSize) {
                        setFieldValue('resume', file);
                        setPercentUploaded(0);
                      } else {
                        message.error(messages.cvFileSizeTooLarge);
                      }
                    }
                  }}
                >
                  <Button icon={<Attachment />} block>
                    {formatMessage(intlMessages.buttonLabelUpload)}
                  </Button>
                </Upload>
              ) : (
                <div className={styles.uploadingContainer}>
                  {percentUploaded === 100 ? (
                    <>
                      <div className={styles.uploadLeft}>
                        <Paragraph ellipsis className={styles.fileName}>
                          {values.resume.name}
                        </Paragraph>
                      </div>
                      <div className={styles.uploadRight}>
                        <Button
                          block
                          variant="secondary"
                          onClick={() => {
                            setFieldValue('resume', null);
                            message.success('File removed');
                          }}
                        >
                          {formatMessage(intlMessages.buttonLabelRemoveFile)}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <Progress
                      percent={percentUploaded}
                      showInfo={false}
                      strokeColor="#576e9f"
                      className={styles.progressBar}
                    />
                  )}
                </div>
              )}
            </FormItem>
            <FormItem
              label={formatMessage(intlMessages.fieldLabelClinicianType)}
              name="clinicianType"
              required
            >
              {({ inputId }) => <ClinicianTypeSelect id={inputId} name="clinicianType" />}
            </FormItem>
            {specialtiesByType[values.clinicianType] && (
              <FormItem
                label={formatMessage(intlMessages.fieldLabelSpecialty)}
                name="specialty"
                required
              >
                {({ inputId }) => (
                  <SpecialtySelect
                    id={inputId}
                    isClearable
                    name="specialty"
                    userRole={values.clinicianType}
                  />
                )}
              </FormItem>
            )}
            <FormItem
              name="emrProviders"
              label={formatMessage(intlMessages.fieldLabelCurrentStateLicenses)}
              required
            >
              <StatesSelect id="current-state-licenses" name="currentStateLicenses" />
            </FormItem>
            <FormItem
              label={formatMessage(intlMessages.fieldLabelEmrSystems)}
              name="emrProviders"
              required
            >
              {({ inputId }) => (
                <EmrCompatabilitySelect id={inputId} isClearable name="emrProviders" />
              )}
            </FormItem>
            <Heading level="h2" size="h3">
              {formatMessage(intlMessages.headingJoinNetwork)}
            </Heading>
            <SyncUsersname />
            <PasswordFormItem showHint />
            <FormItem
              label={formatMessage(intlMessages.fieldLabelConfirmPassword)}
              name="confirmPassword"
              required
            >
              {({ inputId }) => <Input type="password" id={inputId} name="confirmPassword" />}
            </FormItem>
            <Button type="submit" loading={signUpAndApplyLoading || loginLoading}>
              {formatMessage(intlMessages.buttonLabelSubmitApplication)}
            </Button>
          </Form>
        )}
      </Formik>
      <p className={styles.finePrint}>
        {formatMessage(intlMessages.contentPrivacyPolicy)}{' '}
        <a href="https://openloophealth.com/privacy-policy" target="_blank" rel="noreferrer">
          {formatMessage(intlMessages.linkLabelPrivacyPolicy)}
          <span className={styles.screenReaderOnly}>
            {formatMessage(intlMessages.contentNewWindowText)}
          </span>
        </a>{' '}
        {formatMessage(intlMessages.contentPrivacyConjuntionText)}
        <a href="https://openloophealth.com/terms-of-use" target="_blank" rel="noreferrer">
          {formatMessage(intlMessages.linkLabelTermsOfUse)}
          <span className={styles.screenReaderOnly}>
            {formatMessage(intlMessages.contentNewWindowText)}
          </span>
        </a>
        .
      </p>
    </div>
  );
};

export default ApplyAndSignupForm;
