import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  ctaInviteTitle: {
    defaultMessage: "Hi {name}, {br} you're almost there",
    description: 'Call-to-action text for creating a clinician account',
    id: 'Modules.provider.Invite.inviteTitle',
  },
  ctaInviteSubtitle: {
    defaultMessage: "Let's finish setting up your profile.",
    description: 'Call-to-action text for creating a clinician account',
    id: 'Modules.provider.Invite.inviteSubtitle',
  },
  ctaFormTitle: {
    defaultMessage: 'Create password to get started',
    description: 'Call-to-action text for creating a clinician account',
    id: 'Modules.provider.Invite.ctaFormTitle',
  },
  formInputLabelPassword: {
    defaultMessage: 'Create a Password',
    description: 'Label for password input',
    id: 'Modules.provider.Invite.formInputLabelPassword',
  },
  formInputLabelVerifyPassword: {
    defaultMessage: 'Verify Password',
    description: 'Label for verify password input',
    id: 'Modules.provider.Invite.formInputLabelVerifyPassword',
  },
  formCheckboxRememberMe: {
    defaultMessage: 'Remember me',
    description: 'Label for remember me checkbox',
    id: 'Modules.provider.Invite.formCheckboxRememberMe',
  },
  formTermsAgreementNotice: {
    defaultMessage:
      'By clicking Log In, you agree to the {privacyPolicyLink} and {termsOfUseLink}.',
    description: 'Information notice about terms of agreement of the service after login',
    id: 'Modules.guest.Invite.formTermsAgreementNotice',
  },
  requestError: {
    defaultMessage: 'Error loading user invitation',
    description: 'Error trying to verify user invitation',
    id: 'Modules.guest.Invite.requestError',
  },
  openloopPrivacPolicy: {
    defaultMessage: 'Openloop Privacy Policy',
    description: 'Openloop Privacy Policy',
    id: 'Modules.guest.Invite.openloopPrivacPolicy',
  },
  termsOfUse: {
    defaultMessage: 'Terms of Use',
    description: 'Terms of Use',
    id: 'Modules.guest.Invite.termsOfUse',
  },
});
