import React, { useMemo } from 'react';
import Select from '@openloop/limbic/Form/Select';

import { useStatesQuery } from '~Data';

interface Props {
  id: string;
  name?: string;
}

const StateSelect = ({ id, name = 'stateId' }: Props) => {
  const { data, loading } = useStatesQuery();
  const options = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.states.map(({ id: stateId, name: stateName }) => ({
      value: stateId,
      label: stateName,
    }));
  }, [data]);
  return (
    <Select
      id={id}
      name={name}
      placeholder="Select a state"
      isClearable
      isLoading={loading}
      options={options}
    />
  );
};
export default StateSelect;
