import React from 'react';

import Select, { SelectProps } from '@openloop/limbic/Form/Select/Select';

import { specialtiesByType } from '~Constants/specialties';
import { ProviderUserRole } from '~Data';

interface Props extends Omit<SelectProps, 'options'> {
  userRole: ProviderUserRole;
}

const SpecialtySelect = ({ placeholder = 'Choose your specialty', userRole, ...rest }: Props) => {
  const specialties = specialtiesByType[userRole];

  if (!specialties) {
    return null;
  }

  const clinicianTypeOptions = specialties.map((specialty) => ({
    value: specialty,
    label: specialty,
  }));

  return <Select placeholder={placeholder} options={clinicianTypeOptions} {...rest} />;
};

export default SpecialtySelect;
