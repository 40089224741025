import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  specialty: {
    defaultMessage: 'Specialty',
    description: 'Specialty',
    id: 'components.provider.StepMoreInfo.specialty',
  },
  typeOfProfession: {
    defaultMessage: 'Type of Profession',
    description: 'Type of Profession',
    id: 'components.provider.StepMoreInfo.typeOfProfession',
  },
  profession: {
    defaultMessage: 'Profession',
    description: 'Profession',
    id: 'components.provider.StepMoreInfo.profession',
  },
  physicianType: {
    defaultMessage: 'Physician Type',
    description: 'Physician Type',
    id: 'components.provider.StepMoreInfo.physicianType',
  },
  heading: {
    defaultMessage: "Apply to Join the Nation's Top Clinician Network",
    description: 'Heading',
    id: 'components.provider.StepMoreInfo.heading',
  },
  paragraph: {
    defaultMessage:
      "OpenLoop is a vetted network of the nation's most skilled clinicians. We provide transparent access to the best companies hiring in the world of digital health.",
    description: 'Paragraph',
    id: 'components.provider.StepMoreInfo.paragraph',
  },
  iAmA: {
    defaultMessage: 'I am a',
    description: 'I am a',
    id: 'components.provider.StepMoreInfo.iAmA',
  },
});

export const intlMessagesUserName = defineMessages({
  createAccount: {
    defaultMessage: 'Create your unique account',
    description: 'Create your unique account',
    id: 'components.provider.StepUsername.createAccount',
  },
  paragraph: {
    defaultMessage: "We'll use this information for your OpenLoop clinical profile.",
    description: 'Paragraph',
    id: 'components.provider.StepUsername.paragraph',
  },
  firstName: {
    defaultMessage: 'First name',
    description: 'First name',
    id: 'components.provider.StepUsername.firstName',
  },
  lastName: {
    defaultMessage: 'Last name',
    description: 'Last name',
    id: 'components.provider.StepUsername.lastName',
  },
  titleSalutation: {
    defaultMessage: 'Title/Salutation',
    description: 'Title/Salutation',
    id: 'components.provider.StepUsername.titleSalutation',
  },
  phone: {
    defaultMessage: 'Phone',
    description: 'Phone',
    id: 'components.provider.StepUsername.phone',
  },
  email: {
    defaultMessage: 'Email',
    description: 'Email',
    id: 'components.provider.StepUsername.email',
  },
  password: {
    defaultMessage: 'Password',
    description: 'Password',
    id: 'components.provider.StepUsername.password',
  },
});

export const intlMessagesUserType = defineMessages({
  heading: {
    defaultMessage: "Apply to Join the Nation's Top Clinician Network",
    description: 'Heading',
    id: 'components.provider.StepUserType.heading',
  },
  iAmA: {
    defaultMessage: 'I am a',
    description: 'I am a',
    id: 'components.provider.StepUserType.iAmA',
  },
  paragraph: {
    defaultMessage:
      "OpenLoop is a vetted network of the nation's most skilled clinicians. We provide transparent access to the best companies hiring in the world of digital health.",
    description: 'Paragraph',
    id: 'components.provider.StepUserType.paragraph',
  },
  questionSpan: {
    defaultMessage: 'What kind of clinician are you?',
    description: 'Question Span',
    id: 'components.provider.StepUserType.questionSpan',
  },
});

export const intlMessagesRegister = defineMessages({
  metaTitle: {
    defaultMessage: 'Apply to our Clinician Network | OpenLoop Health',
    description: 'Register page browser meta title',
    id: 'components.provider.Register.metaTitle',
  },
  metaDescription: {
    defaultMessage:
      "OpenLoop is a vetted network of the nation's most skilled clinicians. We provide transparent access to the best companies hiring in the world of digital health.",
    description: 'Register page browser meta description',
    id: 'components.provider.Register.metaDescription',
  },
  whyQuestion: {
    defaultMessage: 'Why OpenLoop?',
    description: 'Why Question',
    id: 'components.provider.Register.whyQuestion',
  },
  alreadyQuestion: {
    defaultMessage: 'Already have an account?',
    description: 'Already Question',
    id: 'components.provider.Register.alreadyQuestion',
  },
  loginLink: {
    defaultMessage: 'Login',
    description: 'Login Link',
    id: 'components.provider.Register.loginLink',
  },
  paragraphAgree: {
    defaultMessage: 'By clicking {signupButtonCopy}, you agree to the OpenLoop',
    description: 'Paragraph Agree',
    id: 'components.provider.Register.paragraphAgree',
  },
  privacyPolicy: {
    defaultMessage: 'Privacy Policy',
    description: 'Privacy Policy',
    id: 'components.provider.Register.privacyPolicy',
  },
  and: {
    defaultMessage: 'and',
    description: 'and',
    id: 'components.provider.Register.and',
  },
  termsOfUse: {
    defaultMessage: 'Terms of Use',
    description: 'Terms of Use',
    id: 'components.provider.Register.termsOfUse',
  },
});
