import hospitalCover from '~Assets/img/hospitalCover.png';
import laptopPhone from '~Assets/img/laptop-phone.png';
import laptopLamp from '~Assets/img/laptop-lamp.png';
import logo from '~Assets/img/openloopLogo.png';
import pill from '~Assets/img/pill.png';
import loginBackground from '~Assets/img/loginBackground.png';
import pinkWhiteOpenLoopLogo from '~Assets/img/pinkWhiteOpenLoopLogo.png';
import placeholderClinic from '~Assets/img/placeholderClinic.png';
import placeholderContact from '~Assets/img/placeholderContact.png';
import placeholderDoctor from '~Assets/img/placeholderDoctor.png';
import transparentLogo from '~Assets/img/openloopLogoTransparent.png';
import welcomeVisual from '~Assets/img/welcome-visual.png';
import telehealthVisit from '~Assets/img/telehealth-visit.png';
import resumeTips from '~Assets/img/resume-tips.png';
import whitepaperVisual from '~Assets/img/locum-tenens-whitepaper.png';
import boldHealth from '~Assets/img/BoldHealth.png';
import minuteMd from '~Assets/img/MinuteMD1.png';

const images = Object.freeze({
  hospitalCover,
  laptopLamp,
  laptopPhone,
  logo,
  loginBackground,
  pill,
  pinkWhiteOpenLoopLogo,
  placeholderClinic,
  placeholderContact,
  placeholderDoctor,
  transparentLogo,
  welcomeVisual,
  telehealthVisit,
  resumeTips,
  whitepaperVisual,
  boldHealth,
  minuteMd,
});

export default images;
