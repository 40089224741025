import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  pageTitle: {
    defaultMessage: 'Users',
    description: 'Page title',
    id: 'Modules.admin.UserList.pageTitle',
  },
  pageTitleSeo: {
    defaultMessage: 'Users (Admin)',
    description: 'Browser page title',
    id: 'Modules.admin.UserList.pageTitleSeo',
  },
  totalNumUsers: {
    defaultMessage: `
      {itemCount, plural,
        =0 {# Users}
        one {# User}
        other {# Users}
      }`,
    description: 'Total number of users',
    id: 'Modules.admin.UserList.totalNumUsers',
  },
  showFewerLicensesAriaLabel: {
    defaultMessage: 'Show fewer licenses',
    description: 'Aria label for the show less button',
    id: 'Modules.admin.UserList.showFewerLicensesAriaLabel',
  },
  showMoreLicensesAriaLabel: {
    defaultMessage: `Show {numMoreLicenses, plural,
      one {# more license}
      other {# more licenses}
    }`,
    description: 'Aria label for the show more licenses button',
    id: 'Modules.admin.UserList.showMoreLicensesAriaLabel',
  },
  resetButton: {
    defaultMessage: 'Reset',
    description: 'Reset Button',
    id: 'components.UserList.resetButton',
  },
  searchButton: {
    defaultMessage: 'Search',
    description: 'Search Button',
    id: 'components.UserList.searchButton',
  },
  clinicianSpecialities: {
    defaultMessage: 'Clinician Specialities',
    description: 'Clinician Specialities',
    id: 'components.UserList.clinicianSpecialities',
  },
  stateLicenses: {
    defaultMessage: 'State Licenses',
    description: 'State Licenses',
    id: 'components.UserList.stateLicenses',
  },
  verifiedStateLicenses: {
    defaultMessage: 'Verified State Licenses',
    description: 'Verified State Licenses',
    id: 'components.UserList.verifiedStateLicenses',
  },
});
