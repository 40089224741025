const admin = '/admin';
const provider = '/provider';
const clinic = '/clinic';

const userDetail = `${admin}/users/:userId`;
const inviteAndSuggest = `${admin}/invite-and-suggest`;

export const adminRoutes = {
  dashboard: admin,
  clinics: `${admin}/clinics`,
  jobs: `${admin}/jobs`,
  matchRequests: `${admin}/match-requests`,
  users: `${admin}/users`,
  inviteAndSuggest,
  inviteClinician: `${inviteAndSuggest}/invite`,
  suggestJobs: `${inviteAndSuggest}/suggest`,
  userDetail,
  userJobs: `${userDetail}/jobs`,
  userLicenses: `${userDetail}/licenses`,
  userNotes: `${userDetail}/notes`,
  userSharingTokens: `${userDetail}/sharing-tokens`,
};

export const guestRoutes = {
  forgotPassword: '/forgot-password',
  login: '/login',
  resetPassword: '/set-new-password/:resetToken',
};

export const providerRoutes = {
  dashboard: provider,
  myProfile: `${provider}/profile`,
  editProfile: `${provider}/profile/edit`,
  licenses: `${provider}/licenses`,
  patients: `${provider}/patients`,
  prescribe: `${provider}/patients/prescribe`,
  register: `${provider}/register`,
  inviteRegister: `${provider}/invite/:token`,
};

const clinicDetail = `/clinic/:clinicId`;
const clinicJobDetail = `${clinicDetail}/jobs/:jobId`;

export const clinicRoutes = {
  dashboard: clinic,
  clinicCreate: `${clinic}/create`,
  clinicUserRegister: `${clinic}/register`,
  clinicDetail,
  clinicEdit: `${clinicDetail}/edit`,
  clinicIntegrations: `${clinicDetail}/integrations`,
  clinicIntegrationDetail: `${clinicDetail}/integrations/:integrationSlug`,
  clinicJobCreate: `${clinicDetail}/jobs/create`,
  clinicJobEdit: `${clinicJobDetail}/edit`,
};

export const sharedRoutes = {
  reviewClinician: '/review/:tokenId',
  jobDescription: '/jobs/:jobId',
};

export const externalRoutes = {
  app: 'https://app.openloophealth.com',
  blog: 'https://openloophealth.com/blog',
  facebook: 'https://facebook.com/openloophealth',
  instagram: 'https://instagram.com/openloophealth',
  privacyPolicy: 'https://openloophealth.com/privacy-policy',
  linkedIn: 'https://linkedin.com/company/openloophealth',
  termsOfUse: 'https://openloophealth.com/terms-of-use',
  twitter: 'https://twitter.com/openloophealth',
  integrationsDomain:
    import.meta.env.VITE_OL_INTEGRATIONS_DOMAIN || 'https://integrations.openloophealth.com',
};
