import React, { useState } from 'react';

import ApplyAndSignupForm from './ApplyAndSignupForm';
import LoginAndApplyForm from './LoginAndApplyForm';
import { FormType } from './types';

const JobApplicationForm = () => {
  const [formType, setFormType] = useState<FormType>('application');
  const [email, setEmail] = useState<string>('');

  return (
    <>
      {formType === 'login' ? (
        <LoginAndApplyForm
          email={email}
          resetEmail={() => setEmail('')}
          toggleSignUpForm={() => setFormType('application')}
        />
      ) : (
        <ApplyAndSignupForm setEmail={setEmail} setFormType={setFormType} />
      )}
    </>
  );
};

export default JobApplicationForm;
