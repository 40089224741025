import React from 'react';
import { BackTop } from 'antd';
import { defineMessage, useIntl } from 'react-intl';
import Icon from '@openloop/limbic/Icon';

import styles from './BackToTopButton.module.scss';

const backToTopMessage = defineMessage({
  id: 'BackToTopButton.backToTop',
  defaultMessage: 'Top',
  description: 'Back to top button text',
});

const BackToTopButton = () => {
  const { formatMessage } = useIntl();
  return (
    <BackTop>
      <div className={styles.backToTopContents}>
        <Icon name="ChevronUp" size="medium" />
        <br />
        {formatMessage(backToTopMessage)}
      </div>
    </BackTop>
  );
};

export default BackToTopButton;
