import React, { ComponentProps } from 'react';
import { Divider } from 'antd';

import Comment from '~Core/Comment/Comment';

interface CommentItem extends ComponentProps<typeof Comment> {
  id: string;
}

interface Props {
  comments: CommentItem[];
}

const CommentsList = ({ comments }: Props) => (
  <>
    {comments.map(({ id, createdAt, author, message }, idx) => (
      <div key={id}>
        {idx > 0 && <Divider />}
        <Comment author={author} createdAt={createdAt} message={message} />
      </div>
    ))}
  </>
);

export default CommentsList;
