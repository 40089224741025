import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  cancelAndExit: {
    defaultMessage: 'Cancel and Exit Wizard',
    description: 'Cancel and Exit Wizard',
    id: 'components.admin.InviteAndSuggest.ExitWizardButton.cancelAndExit',
  },
  exitQuestion: {
    defaultMessage: 'Are you sure you want to exit the wizard?',
    description: 'Exit Question',
    id: 'components.admin.InviteAndSuggest.ExitWizardButton.exitQuestion',
  },
  exitDescription: {
    defaultMessage: 'All your changes will be lost.',
    description: 'Exit Description',
    id: 'components.admin.InviteAndSuggest.ExitWizardButton.exitDescription',
  },
  exitButton: {
    defaultMessage: 'Exit Wizard',
    description: 'Exit Button',
    id: 'components.admin.InviteAndSuggest.ExitWizardButton.exitButton',
  },
});
