import React, { KeyboardEvent, MutableRefObject, useEffect, useRef, useState } from 'react';
import { NavLink, useMatch, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames/bind';

import Button from '@openloop/limbic/Button';
import Icon from '@openloop/limbic/Icon/Icon';
import { Keys } from '~Constants/keys';
import { MenuItemType } from '~Components/Navigation';

import styles from './MenuLiWithSub.module.scss';

const motionVariantsSubMenu = {
  initial: {
    opacity: 0,
    y: -15,
  },
  animate: {
    opacity: 1,
    y: 4,
    transition: { type: 'tween' },
  },
  exit: {
    opacity: 0,
    y: -15,
    transition: { duration: 0.8 },
  },
};

const cx = classNames.bind(styles);

interface SubItemProps {
  index: number;
  subItem: MenuItemType;
  onClick?: () => void;
  navLinkOnClick?: () => void;
  handleKeydown: (event: KeyboardEvent<any>, menuItem: MenuItemType) => void;
  setRefByIndex: (ref: React.MutableRefObject<HTMLElement>, index: number) => void;
  subLiClassName?: string;
  subButtonClassName?: string;
  subAnchorClassName?: string;
}
interface SubMenuProps extends MenuItemType {
  subItems: MenuItemType[];
  isRightMenu?: boolean;
  isMobile?: boolean;
  navLinkOnClick?: () => void;
  liClassName?: string;
  buttonClassName?: string;
  anchorClassName?: string;
  subClassName?: string;
  subLiClassName?: string;
  subButtonClassName?: string;
  subAnchorClassName?: string;
}

const SubItem = ({
  index,
  subItem: { title, icon, route, onClick: subOnClick },
  subItem,
  navLinkOnClick,
  handleKeydown,
  onClick,
  setRefByIndex,
  subLiClassName,
  subButtonClassName,
  subAnchorClassName,
}: SubItemProps) => {
  const activePath = useMatch({ path: `${route}`, end: true });
  const ref = useRef(null as any);

  useEffect(() => {
    setRefByIndex(ref, index);
  }, [index, setRefByIndex]);

  return (
    <li
      key={title}
      className={cx(styles.subMenuItem, activePath && styles.active, subLiClassName)}
      role="menuitem"
    >
      {route ? (
        <NavLink
          ref={ref}
          to={route}
          onClick={navLinkOnClick ? () => navLinkOnClick() : undefined}
          onKeyDown={(event) => handleKeydown(event, subItem)}
          className={cx(styles.subNavLink, subAnchorClassName)}
        >
          {icon && <Icon className={styles.menuItemIcon} name={icon} ariaHidden />}
          {title}
        </NavLink>
      ) : (
        <Button
          ref={ref}
          variant="primary-borderless"
          icon={icon && <Icon name={icon} ariaHidden />}
          onClick={subOnClick || onClick}
          onKeyDown={(event) => handleKeydown(event, subItem)}
          className={cx(styles.subButtonLink, subButtonClassName)}
        >
          {title}
        </Button>
      )}
    </li>
  );
};

export const MenuLiWithSub = ({
  title,
  icon,
  route,
  onClick,
  subItems,
  isRightMenu,
  isMobile,
  navLinkOnClick,
  liClassName,
  buttonClassName,
  anchorClassName,
  subClassName,
  ...rest
}: SubMenuProps) => {
  const navigate = useNavigate();
  const activePath = useMatch({ path: `${route}`, end: true });
  const [openKey, setOpenKey] = useState<MenuItemType['title']>('');
  const [isSubOpen, setIsSubOpen] = useState<boolean>(false);
  const mainMenuItemRef = useRef<HTMLButtonElement>(null);
  const firstRun = useRef(true);
  const clickedOpen = useRef(false);
  const [refs, setRefs] = useState([] as MutableRefObject<HTMLElement>[]);
  const setRefByIndex = (ref: MutableRefObject<HTMLElement>, idx: number) => {
    refs[idx] = ref;
    setRefs(refs);
  };

  const closeSubOnTab = () => {
    setOpenKey('');
  };
  const toggleSubMenu = (key: MenuItemType['title']) => {
    setOpenKey(openKey !== key ? key : '');
  };

  const selectItem = (item: MenuItemType) => {
    const index = subItems.indexOf(item);
    refs[index].current.focus();
  };

  const previousItem = (item: MenuItemType) => {
    const index = subItems.indexOf(item);
    if (index > 0) {
      selectItem(subItems[index - 1]);
    } else {
      selectItem(subItems[subItems.length - 1]);
    }
  };

  const nextItem = (item: MenuItemType) => {
    const index = subItems.indexOf(item);
    if (index < subItems.length - 1) {
      selectItem(subItems[index + 1]);
    } else {
      selectItem(subItems[0]);
    }
  };

  const handleKeydown = (event: KeyboardEvent<HTMLElement>, item: MenuItemType) => {
    switch (event.key) {
      case Keys.ArrowUp:
        event.preventDefault();
        previousItem(item);
        break;

      case Keys.ArrowDown:
        event.preventDefault();
        nextItem(item);
        break;

      case Keys.Enter:
        if (navLinkOnClick) {
          toggleSubMenu(title);
          if (item.onClick) {
            item.onClick();
          }
          if (item.route) {
            navigate(item.route);
          }
          navLinkOnClick();
          closeSubOnTab();
        }
        break;

      case Keys.Tab:
      case Keys.Escape:
        closeSubOnTab();
        break;

      default:
        break;
    }
  };

  const onMouseEnter = (item: MenuItemType['title']) => {
    clickedOpen.current = true;
    setIsSubOpen(true);
    setOpenKey(item);
  };

  const onMouseLeave = () => {
    clickedOpen.current = false;
    setIsSubOpen(false);
    setOpenKey('');
  };

  useEffect(() => {
    if (openKey === title) {
      setIsSubOpen(true);
    } else {
      setIsSubOpen(false);
    }
  }, [isSubOpen, title, openKey]);

  useEffect(() => {
    // Stop if this is the first fire of the Hook, and update the ref
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }

    if (isSubOpen) {
      refs[0].current.focus();
    } else if (!isSubOpen) {
      clickedOpen.current = false;
      mainMenuItemRef.current?.focus();
    }
  }, [isSubOpen, refs]);

  return (
    <li
      key={title}
      className={cx(styles.menuItem, activePath && styles.active, liClassName)}
      role="menuitem"
      aria-haspopup={subItems.length > 0}
      onMouseLeave={!isMobile && !route && !onClick ? () => onMouseLeave() : undefined}
    >
      {route ? (
        <NavLink
          to={route}
          onClick={navLinkOnClick ? () => navLinkOnClick() : undefined}
          onKeyDown={() => handleKeydown}
          className={cx(styles.navLi, anchorClassName)}
        >
          {icon && <Icon className={styles.menuItemIcon} name={icon} ariaHidden />}
          {title}
        </NavLink>
      ) : (
        <Button
          ref={mainMenuItemRef}
          variant="primary-borderless"
          icon={icon && <Icon name={icon} ariaHidden />}
          onClick={onClick || (() => toggleSubMenu(title))}
          onKeyDown={() => handleKeydown}
          onMouseEnter={!isMobile && !route && !onClick ? () => onMouseEnter(title) : undefined}
          className={cx(styles.buttonLi, buttonClassName)}
        >
          {title}
          {subItems && !route && !onClick && (
            <span className={cx(styles.caretIcon, !icon && styles.caretWithoutIcon)}>
              <Icon
                className={cx(styles.subMenuIndicator, openKey === title && styles.expanded)}
                name="CaretDownFill"
                ariaHidden
              />
            </span>
          )}
        </Button>
      )}
      {openKey === title && !route && !onClick && (
        <AnimatePresence>
          <motion.ul
            key={title}
            className={cx(styles.subMenu, isRightMenu && styles.rightMenu, subClassName)}
            role="menu"
            variants={motionVariantsSubMenu}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            {subItems.map((item: MenuItemType, idx: number) => (
              <SubItem
                key={item.title}
                subItem={item}
                index={idx}
                onClick={item.onClick}
                navLinkOnClick={navLinkOnClick || (() => toggleSubMenu(''))}
                handleKeydown={handleKeydown}
                setRefByIndex={setRefByIndex}
                {...rest}
              />
            ))}
          </motion.ul>
        </AnimatePresence>
      )}
    </li>
  );
};
