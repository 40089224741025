import { Icons } from '~Core/Icon';
import { ProviderUserRole, UserRole } from '~Data';

type UserTypeChoices = Record<ProviderUserRole, string>;

/**
 * @NOTE Object keys are to remain in this order for `select.option` element purposes
 *
 * Please do not order these alphabetically / re-order them unless you're intentionally changing how they're ordered.
 */
export const userTypeChoices: UserTypeChoices = {
  [UserRole.Physician]: 'Physician',
  [UserRole.App]: 'APP',
  [UserRole.Nurse]: 'Nurse',
  [UserRole.Therapist]: 'Therapist',
  [UserRole.Other]: 'Other',
};

export const userTypeDescriptions: UserTypeChoices = {
  [UserRole.App]: 'ARNP, CRNA, PA, etc.',
  [UserRole.Nurse]: 'DNP, CNS, CNM, etc.',
  [UserRole.Other]: 'EMT, Med Student, Pharmacist, etc.',
  [UserRole.Physician]: 'MD, DO, etc.',
  [UserRole.Therapist]: 'PT, PTA, OT, OTA, SLP, BCBA, LCSW, etc.',
};

export const userTypeIcons: Record<ProviderUserRole, Icons> = {
  [UserRole.App]: Icons.MedicalProtectionOutlined,
  [UserRole.Nurse]: Icons.NurseOutlined,
  [UserRole.Other]: Icons.FirstAidKitOutlined,
  [UserRole.Physician]: Icons.StethoscopeOutlined,
  [UserRole.Therapist]: Icons.ChatOutlined,
};
