import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  createClinic: {
    defaultMessage: 'Create Clinic',
    description: 'Create Clinic title',
    id: 'components.clinic.CreateClinic.createClinic',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: 'Cancel button',
    id: 'components.clinic.CreateClinic.cancelButton',
  },
  createButton: {
    defaultMessage: 'Create',
    description: 'Create button',
    id: 'components.clinic.CreateClinic.createButton',
  },
});
