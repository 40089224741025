import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  errorMessage: {
    defaultMessage: 'There was an error loading this user.',
    description: 'Error Message',
    id: 'components.UserDetail.Licenses.errorMessage',
  },
  subTitle1: {
    defaultMessage: 'All Licenses',
    description: 'SubTitle 1',
    id: 'components.UserDetail.Licenses.subTitle1',
  },
  licenseTitle1: {
    defaultMessage: 'Verified & Credentialed ({total} TOTAL, {dea} DEA)',
    description: 'License Title 1',
    id: 'components.UserDetail.Licenses.licenseTitle1',
  },
  licenseTitle2: {
    defaultMessage: 'Self-reported (active) ({total} TOTAL, {dea} DEA)',
    description: 'License Title 2',
    id: 'components.UserDetail.Licenses.licenseTitle2',
  },
  licenseTitle3: {
    defaultMessage: 'Self-reported (pending) ({total} TOTAL, {dea} DEA)',
    description: 'License Title 3',
    id: 'components.UserDetail.Licenses.licenseTitle3',
  },
  subTitle2: {
    defaultMessage: 'Verified & Credentialed',
    description: 'SubTitle 2',
    id: 'components.UserDetail.Licenses.subTitle2',
  },
  isCredentialed: {
    defaultMessage: 'is credentialed in the following geographies',
    description: 'Is Credentialed',
    id: 'components.UserDetail.Licenses.isCredentialed',
  },
  license: {
    defaultMessage: 'License',
    description: 'License',
    id: 'components.UserDetail.Licenses.license',
  },
  issued: {
    defaultMessage: 'Issued',
    description: 'Issued',
    id: 'components.UserDetail.Licenses.issued',
  },
  expires: {
    defaultMessage: 'Expires',
    description: 'Expires',
    id: 'components.UserDetail.Licenses.expires',
  },
});
