import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import LimbicIcon from '@openloop/limbic/Icon';
import Heading from '@openloop/limbic/Heading';
import classNames from 'classnames/bind';
import Button from '@openloop/limbic/Button';
import moment from 'moment';

import samplePatientIdSrc from '~Assets/img/sampleId.png';
import Icon, { Icons } from '~Core/Icon';
import { PatientCardListItemProps } from '~Components/PatientCardListItem';
import AccordionCard from '~Components/AccordionCard';

import styles from './PatientInfoBox.module.scss';
import PatientIdModal from './PatientIdModal';
import { intlMessages } from './intlMessages';

// TODO: Move these once we have a standardized type for the data
export type PatientProfile = {
  state: string;
  dob: string;
  gender: string;
  medications: Array<string>;
  allergies: { [key: string]: string };
  patientID: string;
};

interface PatientInfoBoxProps {
  patient:
    | (PatientCardListItemProps & {
        isAvailable?: boolean;
      } & Partial<{ profile: PatientProfile }>)
    | undefined;
}

const cx = classNames.bind(styles);

const PatientAge = ({ dob }: { dob: string }) => {
  const { formatMessage } = useIntl();

  const birthday = moment(dob, 'MM-DD-YYYY');
  const current = moment(new Date());

  const years = current.diff(birthday, 'year');
  birthday.add(years, 'years');
  const months = current.diff(birthday, 'months');

  const redTextClass = cx({
    redText: years < 18,
  });

  return (
    <span className={redTextClass}>
      {formatMessage(intlMessages.patientAgeText, { years, months })}
    </span>
  );
};

const PatientInfoBox = ({ patient }: PatientInfoBoxProps) => {
  const { formatMessage } = useIntl();
  const [showIdMiniModal, setShowIdMiniModal] = useState(false);

  const showIdModal = () => setShowIdMiniModal(true);
  const closeIdModal = () => setShowIdMiniModal(false);

  if (!patient || !patient.profile) {
    return null;
  }

  const iconContainerClass = cx(styles.iconContainer);
  const verticalDividerClass = cx(styles.verticalDivider);
  const horizontalDividerClass = cx(styles.horizontalDivider);

  const {
    id,
    profile: { state, dob, gender, medications, allergies },
  } = patient;

  return (
    <AccordionCard key={id}>
      <div className={styles.infoCardContainer}>
        <div className={styles.infoCardHeader}>
          <div className={styles.patientName}>
            <span className={iconContainerClass}>
              <Icon name={Icons.Profile} className={styles.profileIcon} />
            </span>
            <div>{patient?.name}</div>
          </div>
          <div className={styles.rightAlignedPatientInfo}>
            <div className={styles.state}>{state}</div>
            <span className={verticalDividerClass} />
            <div className={styles.dobGenderSection}>
              <div className={styles.dob}>
                <PatientAge dob={dob} />
                {dob}
              </div>
              <div className={styles.gender}>
                {formatMessage(intlMessages.genderText)}: {gender}
              </div>
            </div>
            <span className={verticalDividerClass} />
            <Button type="button" className={styles.callPatient}>
              <span className={iconContainerClass}>
                <LimbicIcon name="Telephone" className={styles.phoneIcon} />
              </span>
              {formatMessage(intlMessages.callPatientButtonText)}
            </Button>
          </div>
        </div>
        <span className={horizontalDividerClass} />
        <div id="infoCardBody" className={styles.infoCardBody}>
          <div className={styles.medications}>
            <div className={iconContainerClass}>
              <Icon name={Icons.MedicationsPill} className={styles.medicationIcon} />
            </div>
            <div>
              <Heading uppercase size="h6" level="h6">
                {formatMessage(intlMessages.medicationsHeading)}
              </Heading>
              {medications?.map((medicine, idx) => (
                <div key={idx}>
                  <span className={styles.cardBodyContent}>{medicine}</span>
                  <span className={horizontalDividerClass} />
                </div>
              ))}
            </div>
          </div>
          <span className={verticalDividerClass} />
          <div className={styles.allergies}>
            <div className={iconContainerClass}>
              <LimbicIcon className={styles.allergyIcon} name="AlertCircleFill" />
            </div>
            <div>
              <Heading uppercase size="h6" level="h6">
                {formatMessage(intlMessages.allergiesHeading)}
              </Heading>
              {Object.entries(allergies)?.map((allergy, idx) => (
                <div key={idx}>
                  <span className={styles.cardBodyContent}>
                    <span className={styles.bold}>{allergy[0]}: </span>
                    {allergy[1]}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <span className={verticalDividerClass} />
          <div className={styles.driversLicense}>
            <Heading uppercase size="h6" level="h6">
              {formatMessage(intlMessages.patientIdHeading)}
            </Heading>
            <div>
              <div className={styles.patientIdPreviewImgContainer}>
                <img
                  className={styles.patientIdPreviewImg}
                  src={samplePatientIdSrc}
                  alt="Patient ID Preview"
                />
                <Button
                  aria-label={formatMessage(intlMessages.enlargePatientIdCardButtonAriaLabel)}
                  className={styles.enlargeImageIconButton}
                  icon={<LimbicIcon name="EnlargeImage" size="small" variant="light" />}
                  onClick={showIdModal}
                  shape="circle"
                  size="sm"
                  type="button"
                />
              </div>

              {showIdMiniModal && (
                <PatientIdModal
                  close={closeIdModal}
                  isOpen={showIdMiniModal}
                  idImgSrc={samplePatientIdSrc}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </AccordionCard>
  );
};

export default PatientInfoBox;
