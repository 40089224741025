import React from 'react';
import { generatePath } from 'react-router-dom';
import { Divider, List } from 'antd';
import { useIntl } from 'react-intl';

import { jobStatusChoices } from '~Constants/jobStatus';
import { providerUserRoleChoices } from '~Constants/providerUserRoles';
import { adminRoutes, clinicRoutes, sharedRoutes } from '~Constants/routes';
import Link from '~Core/Link';
import { ClinicQuery, JobApplicationRulingDecision, ProviderUserRole } from '~Data';
import { intlMessages } from './intlMessages';

interface Props {
  job: ClinicQuery['clinic']['jobs'][number];
}

const ClinicJobsListItem = ({
  job: {
    applications,
    clinicianAdvocate,
    clinicId,
    clinicianType,
    id: jobId,
    specialties,
    status,
    title,
  },
}: Props) => {
  const { formatMessage } = useIntl();
  const numActiveApplications: number =
    applications && applications.length > 0
      ? applications.filter(
          ({ latestRuling }) => latestRuling?.decision !== JobApplicationRulingDecision.No,
        ).length
      : 0;

  const descriptionContent: Array<string | null | undefined> = [
    providerUserRoleChoices[clinicianType as ProviderUserRole],
    (specialties || []).join(', '),
    jobStatusChoices[status],
    clinicianAdvocate ? clinicianAdvocate.profile.displayName : undefined,
    formatMessage(intlMessages.activeJobApplications, { numActiveApplications }),
  ].filter((x) => !!x);

  return (
    <List.Item
      actions={[
        <Link
          data-testid="clinic-jobs-list-edit-job-link"
          to={generatePath(clinicRoutes.clinicJobEdit, { clinicId, jobId })}
        >
          {formatMessage(intlMessages.editJob)}
        </Link>,
        <a
          href={generatePath(sharedRoutes.jobDescription, { jobId })}
          target="_blank"
          rel="noreferrer"
        >
          {formatMessage(intlMessages.viewListing)}
        </a>,
        <Link to={`${adminRoutes.users}?userAppliedToJobIds=${jobId}`}>
          {formatMessage(intlMessages.viewCandidates)}
        </Link>,
      ]}
      key={jobId}
    >
      <List.Item.Meta
        description={descriptionContent.map((item, idx) => (
          // Using index is find here since it won't ever be re-ordered
          <span key={`item-${idx}`}>
            {idx > 0 && <Divider type="vertical" />}
            {item}
          </span>
        ))}
        title={<span data-testid="clinic-jobs-list-job-title">{title}</span>}
      />
    </List.Item>
  );
};

export default ClinicJobsListItem;
