import React from 'react';
import { Button, Card, Heading } from '@openloop/limbic';
import { useIntl } from 'react-intl';

import { useNavigate } from 'react-router-dom';
import Link from '~Core/Link';

import { adminRoutes } from '~Constants/routes';
import styles from '../InviteAndSuggest.module.scss';
import ExitWizardButton from '../ExitWizardButton';
import { intlMessages } from './intlMessages';

export const ClinicianSearchPlaceholder = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <div className={styles.main}>
      <Card className={styles.card}>
        <div className={styles.cardInnerContainer}>
          <Heading level="h2" size="h3" className={styles.heading}>
            {formatMessage(intlMessages.heading)}
          </Heading>
          <div>
            <p className={styles.placeholderText}>
              {formatMessage(intlMessages.paragraph, {
                applicationsTab: (
                  <span className={styles.bold}>{formatMessage(intlMessages.applicationsTab)}</span>
                ),
                clinicianProfile: (
                  <span className={styles.bold}>
                    {formatMessage(intlMessages.clinicianProfile)}
                  </span>
                ),
              })}
            </p>
          </div>
          <footer>
            <div className={styles.buttonRow}>
              <Button
                variant="secondary"
                className={styles.button}
                onClick={() => navigate(adminRoutes.inviteAndSuggest)}
              >
                {formatMessage(intlMessages.previous)}
              </Button>
              <Link buttonVariant="primary" to={adminRoutes.users} className={styles.button}>
                {formatMessage(intlMessages.goToUsers)}
              </Link>
            </div>
            <ExitWizardButton />
          </footer>
        </div>
      </Card>
    </div>
  );
};

export default ClinicianSearchPlaceholder;
