import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  homeLink: {
    defaultMessage: 'Home',
    description: 'Home',
    id: 'components.provider.Footer.homeLink',
  },
  profileLink: {
    defaultMessage: 'Profile',
    description: 'Profile',
    id: 'components.provider.Footer.profileLink',
  },
  blogLink: {
    defaultMessage: 'Blog',
    description: 'Blog',
    id: 'components.provider.Footer.blogLink',
  },
});
