import React, { useState } from 'react';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';

import common from '~Constants/common';
import SEO from '~Core/SEO';

import Metrics from '../Metrics';

const { RangePicker } = DatePicker;

const defaultRange: [Moment, Moment] = [
  moment().startOf('day').add(-7, 'days'),
  moment().endOf('day'),
];
const predefinedRanges: Record<string, [Moment, Moment]> = {
  'This Week': [moment().startOf('day').startOf('week'), moment().endOf('day').endOf('week')],
  'Past Week': [moment().startOf('day').subtract(7, 'days'), moment().endOf('day')],
  'This Month': [moment().startOf('day').startOf('month'), moment().endOf('day').endOf('month')],
  'Past 30 days': [moment().startOf('day').subtract(30, 'days'), moment().endOf('day')],
};

// Disable days after today
const disabledDate = (current: Moment) => current && current > moment().endOf('day');

const Dashboard = () => {
  const [range, setRange] = useState<[Moment, Moment]>(defaultRange);

  return (
    <>
      <SEO title="Dashboard (Admin)" />
      <div>
        <RangePicker
          allowClear={false}
          defaultValue={defaultRange}
          disabledDate={disabledDate}
          onChange={(_, dateStrings) => {
            setRange(dateStrings.map((r) => moment(r, common.dateFormat)) as [Moment, Moment]);
          }}
          format={common.dateFormat}
          ranges={predefinedRanges}
          size="large"
        />
      </div>
      <br />
      <Metrics range={range} />
    </>
  );
};

export default Dashboard;
