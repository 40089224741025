import React from 'react';
import { Row, Col } from 'antd';
import { useFormikContext } from 'formik';
import { Form, DatePicker } from 'formik-antd';
import moment, { Moment } from 'moment';
import * as yup from 'yup';
import { useIntl } from 'react-intl';

import LimbicForm from '@openloop/limbic/Form';

import common from '~Constants/common';
import CityStateSelect from '~Core/CityStateSelect';
import NameInputGroup from '~Core/NameInputGroup';
import PhoneInput from '~Core/PhoneInput';
import PreferredNameInput from '~Core/PreferredNameInput';
import { intlMessages } from './intlMessages';

const { Textarea } = LimbicForm;

export interface FormValues {
  bio?: string;
  cityId?: string;
  dateOfBirth?: Date | string | null;
  firstName?: string;
  lastName?: string;
  preferredName?: string;
  phone?: string;
  stateId?: string;
}

const disabledDate = (date: Moment) => moment.isMoment(date) && date.isSameOrAfter(moment.now());

export const validationSchema: yup.SchemaOf<FormValues> = yup.object().shape({
  bio: yup.string(),
  cityId: yup.string().when('stateId', {
    is: (value?: string) => typeof value === 'string' && value.length > 0,
    then: yup.string().required('City is required when specifying a state'),
    otherwise: yup.string().oneOf([''], 'Please select a state first'),
  }),
  dateOfBirth: yup.date().typeError('Please enter a valid date').nullable(),
  firstName: yup.string(),
  lastName: yup.string(),
  preferredName: yup.string(),
  phone: yup.string().phone('Must be a valid phone number'),
  stateId: yup.string(),
});

const BasicInfoFields = <V extends FormValues>() => {
  const { formatMessage } = useIntl();
  const { errors, setFieldValue } = useFormikContext<V>();

  return (
    <>
      <NameInputGroup
        firstNameProps={{ name: 'firstName' }}
        label={formatMessage(intlMessages.yourNameLabel)}
        lastNameProps={{ name: 'lastName' }}
      />
      <Form.Item
        help={errors.preferredName}
        validateStatus={errors.preferredName ? 'error' : undefined}
        label={formatMessage(intlMessages.pleaseCallLabel)}
        name="preferredName"
      >
        <PreferredNameInput id="BasicInfoFieldsPreferredName" name="preferredName" />
      </Form.Item>
      <Form.Item
        help={errors.phone}
        validateStatus={errors.phone ? 'error' : undefined}
        label={formatMessage(intlMessages.phone)}
        name="phone"
      >
        <PhoneInput id="BasicInfoFieldsPhone" name="phone" />
      </Form.Item>
      <CityStateSelect />
      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <Form.Item label={formatMessage(intlMessages.birthDateLabel)} name="dateOfBirth">
            <DatePicker
              disabledDate={disabledDate}
              format={common.dateFormat}
              onChange={(_, dateString) => {
                setFieldValue('dateOfBirth', dateString);
              }}
              name="dateOfBirth"
              placeholder="Select your date of birth"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={formatMessage(intlMessages.miniBioLabel)} name="bio">
        <Textarea name="bio" />
      </Form.Item>
    </>
  );
};

export default BasicInfoFields;
