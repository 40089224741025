import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  heading: {
    defaultMessage: 'NICE TO MEET YOU!',
    description: 'Incomplete network status heading text',
    id: 'Modules.provider.Home.Welcome.WelcomeText.Incomplete.heading',
  },
  firstParagraph: {
    defaultMessage:
      'We pair leading clinicians with innovative telehealth companies across the US to power more visits and deliver healing {anywhere}.',
    description: 'Incomplete network status first paragraph',
    id: 'Modules.provider.Home.Welcome.WelcomeText.Incomplete.firstParagraph',
  },
  secondParagraph: {
    defaultMessage:
      'To be admitted into our clinician network, please complete all open tasks on this page. Once done, our team of Clinician Advocates will review your profile and follow up with next steps for getting you matched.',
    description: 'Incomplete network status second paragraph',
    id: 'Modules.provider.Home.Welcome.WelcomeText.Incomplete.secondParagraph',
  },
  anywhere: {
    defaultMessage: 'anywhere',
    description: 'anywhere',
    id: 'Modules.provider.Home.Welcome.WelcomeText.Incomplete.anywhere',
  },
  atOpenloop: {
    defaultMessage: "We're OpenLoop.",
    description: 'At Openloop',
    id: 'Modules.provider.Home.Welcome.WelcomeText.Incomplete.atOpenloop',
  },
});
