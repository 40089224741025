import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import { Row, Col, message } from 'antd';
import Button from '@openloop/limbic/Button';
import Heading from '@openloop/limbic/Heading';
import { useIntl } from 'react-intl';

import { clinicRoutes } from '~Constants/routes';
import { useCreateClinicMutation, ClinicsQueryVariables, ClinicsDocument } from '~Data';
import FullScreen from '~Templates/FullScreen';
import Link from '~Core/Link';
import messages from '~Constants/messages';
import SEO from '~Core/SEO';
import { intlMessages } from './intlMessages';

import ClinicFields, {
  validationSchema as clinicFieldsValidationSchema,
} from '../Forms/ClinicFields';

import styles from './CreateClinic.module.less';

interface FormValues {
  name: string;
  phone: string;
  websiteUrl: string;
  zohoUrl: string;
}

const CreateClinic = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const clinicRefetchVars: ClinicsQueryVariables = {};
  const [createClinic, { data, error, loading }] = useCreateClinicMutation({
    refetchQueries: [{ query: ClinicsDocument, variables: clinicRefetchVars }],
  });

  const onSubmit = useCallback(
    (values: FormValues) => {
      createClinic({ variables: { input: values } });
    },
    [createClinic],
  );

  useEffect(() => {
    if (data && data.createClinic) {
      const {
        createClinic: { id: newClinicId },
      } = data;
      navigate(`/clinic/${newClinicId}`);
    } else if (error) {
      message.warn(messages.failedClinicCreation);
    }
  }, [data, error, navigate]);

  return (
    <FullScreen withHeader={false}>
      <SEO title="Create Clinic" />
      <div className={styles.main}>
        <Row justify="center">
          <Col xs={18} sm={16} md={14} lg={12} xl={10} xxl={8}>
            <Heading level="h1">{formatMessage(intlMessages.createClinic)}</Heading>
            <Formik<FormValues>
              initialValues={{
                name: '',
                phone: '',
                websiteUrl: '',
                zohoUrl: '',
              }}
              onSubmit={onSubmit}
              validationSchema={clinicFieldsValidationSchema}
            >
              {({ dirty, isValid }) => (
                <Form layout="vertical">
                  <ClinicFields />
                  <div className={styles.formFooter}>
                    <Link to={clinicRoutes.dashboard} buttonVariant="primary-borderless" size="lg">
                      {formatMessage(intlMessages.cancelButton)}
                    </Link>
                    <Button
                      size="lg"
                      type="submit"
                      loading={loading}
                      variant="primary"
                      disabled={!dirty || !isValid}
                    >
                      {formatMessage(intlMessages.createButton)}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    </FullScreen>
  );
};

export default CreateClinic;
