import gql from 'graphql-tag';

export default gql`
  extend type UserProfile {
    displayName: String!
  }

  extend type Mutation {
    logOut: Boolean!
  }
`;
