import React, { useCallback, useEffect } from 'react';
import { Spin, Upload, message, Image } from 'antd';
import type { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { UserOutlined } from '@ant-design/icons';
import Button from '@openloop/limbic/Button';

import common from '~Constants/common';
import images from '~Constants/images';
import messages from '~Constants/messages';
import { useUploadUserProfileImageMutation } from '~Data';

import styles from './ProfilePhoto.module.less';

interface Props {
  currentImage?: string;
}

const ProfilePhoto = ({ currentImage }: Props) => {
  const [uploadUserProfileImage, { data, error, loading }] = useUploadUserProfileImageMutation();

  const handleImageUpload = useCallback(
    ({ file }: UploadChangeParam<UploadFile>) => {
      uploadUserProfileImage({ variables: { input: { file } } });
    },
    [uploadUserProfileImage],
  );

  useEffect(() => {
    if (data && data.uploadUserProfileImage) {
      message.success(messages.imageUpdateSuccessful);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      message.error(messages.imageUpdateError);
    }
  }, [error]);

  return (
    <div>
      <Upload
        accept={common.acceptImageExtensions}
        beforeUpload={() => false}
        multiple={false}
        onChange={handleImageUpload}
        showUploadList={false}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <div className={styles.imageContainer}>
            <Image
              alt="Profile"
              className={styles.image}
              preview={false}
              src={currentImage || images.placeholderDoctor}
            />
            <div className={styles.uploadButtonContainer}>
              <Button icon={<UserOutlined />} block>
                {currentImage ? 'Replace' : 'Upload'}
              </Button>
            </div>
          </div>
        )}
      </Upload>
    </div>
  );
};

export default ProfilePhoto;
