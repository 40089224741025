import React from 'react';
import { useMatch } from 'react-router-dom';
import { Alert } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import { adminRoutes } from '~Constants/routes';
import { useAuthContext } from '~Context/AuthContext';
import Link from '~Core/Link';
import { UserRole } from '~Data';
import { intlMessages } from '~intlMessages';

const AdminBar = () => {
  const { formatMessage } = useIntl();
  const { currentUser } = useAuthContext();
  const isAdminRoute = useMatch({ path: adminRoutes.dashboard, end: false });

  const isAdmin = currentUser && currentUser.role === UserRole.Admin;

  if (!isAdmin || isAdminRoute) {
    return null;
  }

  return (
    <Alert
      banner
      message={
        <div style={{ textAlign: 'center' }}>
          {formatMessage(intlMessages.adminLoggedIn)}
          <Link to={adminRoutes.dashboard}>
            {formatMessage(intlMessages.visitDashboard)} <DoubleRightOutlined />
          </Link>
        </div>
      }
      showIcon={false}
      type="error"
    />
  );
};

export default AdminBar;
