import React, { HTMLAttributes, ReactNode } from 'react';
import { useId } from '@reach/auto-id';
import classnames from 'classnames/bind';

import useClassNames from '~Hooks/useClassNames';

import styles from './FormItemControl.module.less';

const cx = classnames.bind(styles);

interface RenderProps {
  inputId: string;
  labelId: string;
}

interface Props {
  ariaLabelledBy?: string;
  children?: ((props: RenderProps) => React.ReactNode) | ReactNode;
  className?: string;
  error?: string;
  helpText?: string;
  inputId?: string;
  label?: ReactNode;
  inlineDescription?: ReactNode;
  required?: boolean;
  role?: HTMLAttributes<HTMLDivElement>['role'];
  screenReaderOnly?: boolean;
}

const FormItemControl = ({
  children,
  className,
  error,
  helpText,
  inputId: inputIdProp,
  label,
  inlineDescription,
  required,
  screenReaderOnly,
  ...rest
}: Props) => {
  const inputId = useId(inputIdProp)?.toString() || '';
  const labelId = useId()?.toString() || '';
  const classNames = useClassNames(styles, className, { hasError: !!error });

  const WrapperElement = inputId && label ? 'label' : 'div';

  const labelContents = (
    <>
      {label}
      {required && (
        <span aria-hidden className={styles.required}>
          *
        </span>
      )}
    </>
  );

  return (
    // `htmlFor` is required, throws error because eslint can't assume that an input is nested here (via `children`)
    // eslint-disable-next-line jsx-a11y/label-has-for
    <WrapperElement className={classNames} htmlFor={inputId} {...rest}>
      <div className={cx(styles.labelCopy, screenReaderOnly && styles.srOnly)} id={labelId}>
        {labelContents}
        <span className={styles.inlineDescription}>{inlineDescription}</span>
      </div>
      <div>
        {typeof children === 'function' ? children({ inputId, labelId }) : children}
        <div className={styles.controlStatus}>{error || helpText}</div>
      </div>
    </WrapperElement>
  );
};

export default FormItemControl;
