import React, { FC, useEffect } from 'react';
import { useWizard } from 'react-use-wizard';
import { useIntl } from 'react-intl';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useId } from '@reach/auto-id';
import Input from '@openloop/limbic/Form/Input';
import Heading from '@openloop/limbic/Heading';
import { MixedSchema } from 'yup/lib/mixed';
import * as yup from 'yup';

import { useFormikContext } from 'formik';
import otherProfessions from '~Constants/otherProfessions';
import physicianTypes from '~Constants/physicianTypes';
import { appSpecialties, nurseSpecialties, physicianSpecialties } from '~Constants/specialties';
import {
  trainingLevelsApp,
  trainingLevelsNurse,
  trainingLevelsTherapist,
} from '~Constants/trainingLevels';
import { userTypeChoices, userTypeIcons } from '~Constants/userTypes';
import Form from '~Core/Form';
import Icon from '~Core/Icon';
import OtherProfessionSelect from '~Core/OtherProfessionSelect';
import Paragraph from '~Core/Paragraph';
import PhysicianTypeSelect from '~Core/PhysicianTypeSelect';
import Radio from '~Core/Radio';
import SpecialtySelect from '~Core/SpecialtySelect';
import TrainingLevelSelect from '~Core/TrainingLevelSelect';
import { UserRole, ProviderUserRole, LevelOfTrainingApp, LevelOfTrainingNurse } from '~Data';

import { intlMessages } from './intlMessages';

import styles from './StepMoreInfo.module.less';

type AppSpecialty = typeof appSpecialties[number];
type NurseSpecialty = typeof nurseSpecialties[number];
type PhysicianSpecialty = typeof physicianSpecialties[number];
type OtherProfession = typeof otherProfessions[number];
type ProviderType = typeof physicianTypes[number];
type LevelOfTrainingTherapist = keyof typeof trainingLevelsTherapist;

export type FormValues = {
  userType: ProviderUserRole | '';
  levelOfTraining?: LevelOfTrainingApp | LevelOfTrainingNurse | LevelOfTrainingTherapist | '';
  specialty?: AppSpecialty | NurseSpecialty | PhysicianSpecialty | '';
  profession?: OtherProfession | '';
  professionOther?: string;
  providerType?: ProviderType | '';
};

export const validationSchema: yup.SchemaOf<FormValues> = yup.object().shape({
  userType: yup
    .mixed<ProviderUserRole>()
    .oneOf(Object.keys(userTypeChoices) as ProviderUserRole[], 'User role not valid')
    .required('User type is required'),
  levelOfTraining: yup
    .mixed<LevelOfTrainingApp | LevelOfTrainingNurse>()
    .when(
      'userType',
      (
        userType: ProviderUserRole,
        schema: MixedSchema<
          LevelOfTrainingApp | LevelOfTrainingNurse | LevelOfTrainingTherapist | undefined
        >,
      ) => {
        switch (userType) {
          case UserRole.App:
            return schema
              .required('APP type is required')
              .oneOf(
                Object.keys(trainingLevelsApp) as LevelOfTrainingApp[],
                'Must be one of the supported types',
              );
          case UserRole.Nurse:
            return schema
              .required('Nurse type is required')
              .oneOf(
                Object.keys(trainingLevelsNurse) as LevelOfTrainingNurse[],
                'Must be one of the supported types',
              );
          case UserRole.Therapist:
            return schema
              .required('Therapist type is required')
              .oneOf(
                Object.keys(trainingLevelsTherapist) as LevelOfTrainingTherapist[],
                'Must be one of the supported types',
              );

          default:
            return schema;
        }
      },
    ),
  specialty: yup
    .mixed<AppSpecialty | NurseSpecialty | PhysicianSpecialty>()
    .when(
      'userType',
      (
        userType: ProviderUserRole,
        schema: MixedSchema<AppSpecialty | NurseSpecialty | PhysicianSpecialty | undefined>,
      ) => {
        switch (userType) {
          case UserRole.App:
            return schema
              .required('Specialty is required')
              .oneOf([...appSpecialties], 'Must be one of the supported specialties');
          case UserRole.Nurse:
            return schema
              .required('Specialty is required')
              .oneOf([...nurseSpecialties], 'Must be one of the supported specialties');
          case UserRole.Physician:
            return schema
              .required('Specialty is required')
              .oneOf([...physicianSpecialties], 'Must be one of the supported specialties');
          default:
            return schema;
        }
      },
    ),
  profession: yup.mixed<OtherProfession>().when('userType', {
    is: UserRole.Other,
    then: (schema) =>
      schema
        .required('Profession is required')
        .oneOf([...otherProfessions], 'Must be one of the supported professions'),
  }),
  professionOther: yup.string().when('profession', {
    is: 'Other',
    then: (schema) => schema.required('Profession is required'),
  }),
  providerType: yup.mixed<ProviderType>().when('userType', {
    is: UserRole.Physician,
    then: (schema) =>
      schema
        .required('Physician type is required')
        .oneOf([...physicianTypes], 'Must be one of the supported physician types'),
  }),
});

const base = {
  levelOfTraining: undefined,
  specialty: undefined,
  profession: undefined,
  professionOther: undefined,
  providerType: undefined,
};

export const initialValues: Record<ProviderUserRole, FormValues> = {
  [UserRole.App]: {
    ...base,
    userType: UserRole.App,
    levelOfTraining: '',
    specialty: '',
  },
  [UserRole.Nurse]: {
    ...base,
    userType: UserRole.Nurse,
    levelOfTraining: '',
    specialty: '',
  },
  [UserRole.Other]: {
    ...base,
    userType: UserRole.Other,
    profession: '',
    professionOther: '',
  },
  [UserRole.Physician]: {
    ...base,
    userType: UserRole.Physician,
    providerType: '',
    specialty: '',
  },
  [UserRole.Therapist]: {
    ...base,
    userType: UserRole.Therapist,
    levelOfTraining: '',
  },
};

const AppFields = () => {
  const { formatMessage } = useIntl();
  const specialtyId = useId()?.toString() || '';
  return (
    <>
      <TrainingLevelSelect name="levelOfTraining" userRole={UserRole.App} required />
      <Form.Item
        inputId={specialtyId}
        label={formatMessage(intlMessages.specialty)}
        name="specialty"
        required
      >
        <SpecialtySelect id={specialtyId} name="specialty" userRole={UserRole.App} />
      </Form.Item>
    </>
  );
};

const NurseFields = () => {
  const { formatMessage } = useIntl();
  const specialtyId = useId()?.toString() || '';
  return (
    <>
      <TrainingLevelSelect name="levelOfTraining" userRole={UserRole.Nurse} required />
      <Form.Item
        inputId={specialtyId}
        label={formatMessage(intlMessages.specialty)}
        name="specialty"
        required
      >
        <SpecialtySelect id={specialtyId} name="specialty" userRole={UserRole.Nurse} />
      </Form.Item>
    </>
  );
};

const OtherFields = () => {
  const {
    values: { profession },
  } = useFormikContext<FormValues>();
  const { formatMessage } = useIntl();
  const professionId = useId()?.toString() || '';
  const porfessionOtherId = useId()?.toString() || '';
  return (
    <>
      <Form.Item
        inputId={professionId}
        label={formatMessage(intlMessages.typeOfProfession)}
        name="profession"
        required
      >
        <OtherProfessionSelect id={professionId} name="profession" />
      </Form.Item>
      {profession === 'Other' && (
        <Form.Item
          inputId={porfessionOtherId}
          label={formatMessage(intlMessages.profession)}
          name="professionOther"
          required
        >
          <Input
            id={porfessionOtherId}
            name="professionOther"
            placeholder="Enter your profession type"
          />
        </Form.Item>
      )}
    </>
  );
};

const PhysicianFields = () => {
  const { formatMessage } = useIntl();
  const physicianTypeId = useId()?.toString() || '';
  const specialtyId = useId()?.toString() || '';
  return (
    <>
      <Form.Item
        inputId={physicianTypeId}
        label={formatMessage(intlMessages.physicianType)}
        name="providerType"
        required
      >
        <PhysicianTypeSelect id={physicianTypeId} name="providerType" />
      </Form.Item>
      <Form.Item
        inputId={specialtyId}
        label={formatMessage(intlMessages.specialty)}
        name="specialty"
        required
      >
        <SpecialtySelect id={specialtyId} name="specialty" userRole={UserRole.Physician} />
      </Form.Item>
    </>
  );
};

const TherapistFields = () => {
  return <TrainingLevelSelect name="levelOfTraining" userRole={UserRole.Therapist} required />;
};

const userRoleFields: Record<ProviderUserRole, FC> = {
  [UserRole.App]: AppFields,
  [UserRole.Nurse]: NurseFields,
  [UserRole.Physician]: PhysicianFields,
  [UserRole.Other]: OtherFields,
  [UserRole.Therapist]: TherapistFields,
};

const StepMoreInfo = () => {
  const { values, setTouched, validateForm } = useFormikContext<FormValues>();
  const { previousStep, handleStep } = useWizard();
  const { formatMessage } = useIntl();
  const disabledRadioId = useId()?.toString() || '';
  const { md } = useBreakpoint();
  const desktopOnly = md;
  const { userType } = values;

  useEffect(() => {
    setTouched({
      levelOfTraining: false,
      specialty: false,
      profession: false,
      professionOther: false,
      providerType: false,
    });
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userType) previousStep();
  }, [userType, previousStep]);

  if (!userType) return null;

  const UserFieldsComponent = userRoleFields[userType];

  handleStep(() => {}); // to reset the handler used in previous step

  return (
    <>
      <Heading level="h1" size="h2">
        {formatMessage(intlMessages.heading)}
      </Heading>
      <Paragraph>{formatMessage(intlMessages.paragraph)}</Paragraph>
      <div className={styles.selectContainer}>
        {desktopOnly && (
          <Form.Item
            inputId={disabledRadioId}
            name="userType"
            label={`${formatMessage(intlMessages.iAmA)}...`}
            required
          >
            {({ labelId }) => (
              <Radio arialabelledBy={labelId} name="userType" type="button">
                <Radio.Item
                  className={styles.userType}
                  name="userType"
                  value={userType}
                  icon={<Icon name={userTypeIcons[userType as ProviderUserRole]} />}
                  disabled
                >
                  {userType}
                </Radio.Item>
              </Radio>
            )}
          </Form.Item>
        )}
        {UserFieldsComponent && <UserFieldsComponent />}
      </div>
    </>
  );
};

export default StepMoreInfo;
