import React, { ComponentProps } from 'react';
import { Col, Row, Typography } from 'antd';
import { useField } from 'formik';
import Form from '@openloop/limbic/Form';
import { useIntl } from 'react-intl';

import { Shift, AvailabilityShifts } from '~Data';
import { capitalise } from '~Helpers/strings';
import { intlMessages } from './intlMessages';

import styles from './AvailabilityShiftsInput.module.less';

const { Checkbox } = Form;
const { Text } = Typography;

export const defaultShifts: AvailabilityShifts = {
  mon: [],
  tue: [],
  wed: [],
  thu: [],
  fri: [],
  sat: [],
  sun: [],
};

const shiftPhases: (keyof typeof Shift)[] = [Shift.Day, Shift.Evening, Shift.Night, Shift.Full];

const dayOrder = Object.keys(defaultShifts);

const sortByDefaultShifts = (a: string, b: string) => dayOrder.indexOf(a) - dayOrder.indexOf(b);

const AvailabilityShiftsInput = ({
  name,
  ...checkboxProps
}: Omit<ComponentProps<typeof Checkbox>, 'label' | 'value'>) => {
  const { formatMessage } = useIntl();
  const [{ value }] = useField<AvailabilityShifts>(name);

  return (
    <fieldset className={styles.main}>
      <legend className={styles.legend}>{formatMessage(intlMessages.shiftAvailability)}</legend>
      {Object.keys(value)
        .sort(sortByDefaultShifts)
        .map((dayOfWeek) => (
          <Row align="middle" gutter={[0, 12]} key={dayOfWeek}>
            <Col span={6}>
              <Text strong>{capitalise(dayOfWeek)}</Text>
            </Col>
            <Col span={18}>
              {Object.values(shiftPhases).map((timeOfDay, idx) => (
                <Checkbox
                  inline
                  key={`${name}.${dayOfWeek}.${idx}`}
                  label={timeOfDay}
                  name={`${name}.${dayOfWeek}`}
                  value={timeOfDay}
                  {...checkboxProps}
                />
              ))}
            </Col>
          </Row>
        ))}
    </fieldset>
  );
};

export default AvailabilityShiftsInput;
