import { JobStatus } from '~Data';

export const jobStatusChoices: { [key in JobStatus]: string } = {
  [JobStatus.Cancelled]: 'Cancelled',
  [JobStatus.Declined]: 'Declined',
  [JobStatus.Filled]: 'Filled',
  [JobStatus.Active]: 'Active',
  [JobStatus.Inactive]: 'Inactive',
  [JobStatus.OnHold]: 'On Hold',
  [JobStatus.RequiresApproval]: 'Requires Approval',
};
