import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useParams, generatePath } from 'react-router-dom';
import { Col, Empty, Row, Spin, Tabs, Typography, Upload, Card } from 'antd';
import type { UploadFile } from 'antd/lib/upload/interface';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import Button from '@openloop/limbic/Button';
import Heading from '@openloop/limbic/Heading';
import toast from '@openloop/limbic/Toast';

import ClinicAccountManagerSelect from '~Components/ClinicAccountManagerSelect';
import ClinicJobsList from '~Components/ClinicJobsList';
import ClinicContactsList from '~Components/ClinicContactsList';
import CreateClinicContactModal from '~Components/CreateClinicContactModal';
import images from '~Constants/images';
import messages from '~Constants/messages';
import { clinicRoutes } from '~Constants/routes';
import Form from '~Core/Form';
import FormItem from '~Core/Form/FormItem';
import Link from '~Core/Link';
import {
  JobStatus,
  useClinicQuery,
  useRemoveClinicImageMutation,
  useSetClinicAccountManagerMutation,
  useUploadClinicImageMutation,
} from '~Data';
import { formatPhone } from '~Helpers/phoneNumber';
import LoadingScreen from '~Modules/shared/LoadingScreen';
import FullScreen from '~Templates/FullScreen/FullScreen';

import styles from './ClinicDetail.module.less';

const { Dragger } = Upload;
const { TabPane } = Tabs;
const { Paragraph, Text } = Typography;

const intlMessages = defineMessages({
  btnNewClinicContact: {
    id: 'clinic.ClinicDetail.btnNewClinicContact',
    defaultMessage: 'New Contact',
    description: 'Button to create a new clinic contact',
  },
  btnRemoveClinicImage: {
    id: 'clinic.ClinicDetail.btnRemoveClinicImage',
    defaultMessage: 'Remove Image',
    description: 'Button to remove clinic image',
  },
  fieldPhone: {
    id: 'clinic.ClinicDetail.fieldPhone',
    defaultMessage: '<b>Phone:</b> {phone}',
    description: 'Clinic phone number',
  },
  fieldWebsite: {
    id: 'clinic.ClinicDetail.fieldWebsite',
    defaultMessage: '<b>Website:</b> <link>{website}</link>',
    description: 'Clinic website address',
  },
  fieldZohoUrl: {
    id: 'clinic.ClinicDetail.fieldZohoUrl',
    defaultMessage: '<b>Zoho URL:</b> <link>{zohoUrl}</link>',
    description: 'Clinic Zoho CRM URL',
  },
  formLabelAccountManager: {
    id: 'clinic.ClinicDetail.formLabelAccountManager',
    defaultMessage: 'Account Manager',
    description: 'Clinic account manager label',
  },
  imageRemoveError: {
    id: 'clinic.ClinicDetail.imageRemoveError',
    defaultMessage: 'Error removing image',
    description: 'Clinic image removal error message',
  },
  imageRemoveSuccess: {
    id: 'clinic.ClinicDetail.imageRemoveSuccess',
    defaultMessage: 'Image removed successfully',
    description: 'Clinic image removal success message',
  },
  linkCreateJob: {
    id: 'clinic.ClinicDetail.linkCreateJob',
    defaultMessage: 'Create a Job',
    description: 'Link to create a job',
  },
  linkEditClinic: {
    id: 'clinic.ClinicDetail.linkEditClinic',
    defaultMessage: 'Edit Clinic',
    description: 'Link to edit clinic',
  },
  noInactiveJobs: {
    id: 'clinic.ClinicDetail.noInactiveJobs',
    defaultMessage: 'No Inactive Jobs',
    description: 'Description text when there are no inactive jobs',
  },
  sectionTitleActiveJobs: {
    id: 'clinic.ClinicDetail.sectionTitleActiveJobs',
    defaultMessage: 'Active Jobs',
    description: 'Section title for active jobs',
  },
  sectionTitleAdmin: {
    id: 'clinic.ClinicDetail.sectionTitleAdmin',
    defaultMessage: 'Admin',
    description: 'Section title for admin info',
  },
  sectionTitleClinicContacts: {
    id: 'clinic.ClinicDetail.sectionTitleClinicContacts',
    defaultMessage: 'Clinic Contacts',
    description: 'Section title for clinic contacts',
  },
  sectionTitleGeneral: {
    id: 'clinic.ClinicDetail.sectionTitleGeneral',
    defaultMessage: 'General Info',
    description: 'Section title for general information',
  },
  sectionTitleInactiveJobs: {
    id: 'clinic.ClinicDetail.sectionTitleInactiveJobs',
    defaultMessage: 'Inactive Jobs',
    description: 'Section title for inactive jobs',
  },
  sectionTitleJobs: {
    id: 'clinic.ClinicDetail.sectionTitleJobs',
    defaultMessage: 'Jobs',
    description: 'Section title for jobs',
  },
  setAccountManagerError: {
    id: 'clinic.ClinicDetail.setAccountManagerError',
    defaultMessage: 'Error setting account manager',
    description: 'Error message when setting account manager fails',
  },
  setAccountManagerSuccess: {
    id: 'clinic.ClinicDetail.setAccountManagerSuccess',
    defaultMessage: 'Account manager set successfully',
    description: 'Clinic account manager set success message',
  },
});

interface AccountManagerFormValues {
  accountManagerId: string;
}

const NoActiveJobs = ({ clinicId }: { clinicId: string }) => {
  const { formatMessage } = useIntl();
  return (
    <Empty
      className={styles.empty}
      description="No Active Jobs"
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    >
      <Link to={generatePath(clinicRoutes.clinicJobCreate, { clinicId })} buttonVariant="primary">
        {formatMessage(intlMessages.linkCreateJob)}
      </Link>
    </Empty>
  );
};

const NoInactiveJobs = () => {
  const { formatMessage } = useIntl();
  return (
    <Empty
      className={styles.empty}
      description={formatMessage(intlMessages.noInactiveJobs)}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  );
};

const ClinicDetail = () => {
  const { formatMessage } = useIntl();
  const { clinicId } = useParams<{ clinicId: string }>();
  const [isClinicContactModalOpen, setIsClinicContactModalOpen] = useState<boolean>(false);

  const { data: currentClinic, loading: currentClinicLoading } = useClinicQuery({
    variables: { id: clinicId },
  });

  const [
    removeClinicImage,
    {
      data: removeClinicImageData,
      error: removeClinicImageError,
      loading: removeClinicImageLoading,
    },
  ] = useRemoveClinicImageMutation();
  const [
    uploadClinicImage,
    {
      data: uploadClinicImageData,
      error: uploadClinicImageError,
      loading: uploadClinicImageLoading,
    },
  ] = useUploadClinicImageMutation();
  const [
    setAccountManager,
    {
      data: setAccountManagerData,
      error: setAccountManagerError,
      loading: setAccountManagerLoading,
    },
  ] = useSetClinicAccountManagerMutation();

  const handleAccountManagerSubmit = (values: AccountManagerFormValues) => {
    setAccountManager({ variables: { input: { ...values, clinicId } } });
  };

  const handleImageUpload = (file: UploadFile) => {
    uploadClinicImage({ variables: { input: { id: clinicId, file } } });
  };

  const handleRemoveImage = () => {
    removeClinicImage({ variables: { input: { id: clinicId } } });
  };

  useEffect(() => {
    if (
      uploadClinicImageData &&
      uploadClinicImageData.uploadClinicImage &&
      !uploadClinicImageError
    ) {
      toast({ variant: 'success', message: messages.imageUpdateSuccessful });
    }
  }, [uploadClinicImageData, uploadClinicImageError]);

  useEffect(() => {
    if (!uploadClinicImageData && uploadClinicImageError) {
      toast({ variant: 'danger', message: messages.imageUpdateError });
    }
  }, [uploadClinicImageData, uploadClinicImageError]);

  useEffect(() => {
    if (removeClinicImageData && !removeClinicImageLoading) {
      toast({ variant: 'success', message: formatMessage(intlMessages.imageRemoveSuccess) });
    }
  }, [formatMessage, removeClinicImageData, removeClinicImageLoading]);

  useEffect(() => {
    if (removeClinicImageError && !removeClinicImageLoading) {
      toast({ variant: 'danger', message: formatMessage(intlMessages.imageRemoveError) });
    }
  }, [formatMessage, removeClinicImageError, removeClinicImageLoading]);

  useEffect(() => {
    if (setAccountManagerData && !setAccountManagerLoading) {
      toast({ variant: 'success', message: formatMessage(intlMessages.setAccountManagerSuccess) });
    }
  }, [formatMessage, setAccountManagerData, setAccountManagerLoading]);

  useEffect(() => {
    if (setAccountManagerError && !setAccountManagerLoading) {
      toast({ variant: 'danger', message: formatMessage(intlMessages.setAccountManagerError) });
    }
  }, [formatMessage, setAccountManagerError, setAccountManagerLoading]);

  if (!currentClinic || currentClinicLoading) {
    return <LoadingScreen />;
  }

  const { clinic } = currentClinic;

  const activeJobs = clinic.jobs.filter(({ status }) => status === JobStatus.Active);
  const inactiveJobs = clinic.jobs.filter(({ status }) =>
    [
      JobStatus.Cancelled,
      JobStatus.Declined,
      JobStatus.Filled,
      JobStatus.Inactive,
      JobStatus.OnHold,
      JobStatus.RequiresApproval,
    ].includes(status),
  );

  return (
    <FullScreen withHeader={false}>
      <div className={styles.clinics}>
        <Row justify="center" gutter={[0, 24]}>
          <Col xs={{ span: 22, order: 2 }} md={{ span: 13, order: 1 }}>
            <Row justify="center">
              <Col span={16}>
                <Heading level="h1">{clinic.name}</Heading>
              </Col>
              <Col className={styles.editClinic} span={8}>
                <Link
                  to={generatePath(clinicRoutes.clinicEdit, { clinicId })}
                  buttonVariant="secondary-outline"
                >
                  {formatMessage(intlMessages.linkEditClinic)}
                </Link>
              </Col>
            </Row>

            <div className={styles.clinicInfo}>
              <Row align="stretch" gutter={[12, 12]}>
                <Col xs={24} lg={8}>
                  <Card
                    className={styles.clinicCard}
                    extra={
                      <Link
                        aria-label={formatMessage(intlMessages.linkEditClinic)}
                        buttonVariant="secondary-outline"
                        icon={<EditOutlined />}
                        size="sm"
                        to={generatePath(clinicRoutes.clinicEdit, { clinicId })}
                      />
                    }
                    title={formatMessage(intlMessages.sectionTitleGeneral)}
                  >
                    {clinic.phone && (
                      <Paragraph>
                        {formatMessage(intlMessages.fieldPhone, {
                          b: (str) => <Text strong>{str}</Text>,
                          // @TODO: Use new phone number component once merged
                          phone: formatPhone(clinic.phone),
                        })}
                      </Paragraph>
                    )}
                    {clinic.websiteUrl && (
                      <Paragraph>
                        {formatMessage(intlMessages.fieldWebsite, {
                          b: (str) => <Text strong>{str}</Text>,
                          website: clinic.websiteUrl,
                          link: (str) => (
                            <a
                              href={clinic.websiteUrl || ''}
                              target="_blank"
                              rel="nofollow noreferrer"
                            >
                              {str}
                            </a>
                          ),
                        })}
                      </Paragraph>
                    )}
                  </Card>
                </Col>
                <Col xs={24} lg={8}>
                  <Card
                    bodyStyle={{ maxHeight: '290px', overflowY: 'auto' }}
                    className={styles.clinicCard}
                    extra={
                      <Button
                        ariaLabel={formatMessage(intlMessages.btnNewClinicContact)}
                        icon={<PlusOutlined />}
                        onClick={() => setIsClinicContactModalOpen(true)}
                        size="sm"
                        variant="secondary-outline"
                      />
                    }
                    title={formatMessage(intlMessages.sectionTitleClinicContacts)}
                  >
                    <ClinicContactsList clinicContacts={clinic.contacts} clinicId={clinicId} />
                  </Card>
                </Col>
                <Col xs={24} lg={8}>
                  <Card
                    className={styles.clinicCard}
                    title={formatMessage(intlMessages.sectionTitleAdmin)}
                  >
                    <Form<AccountManagerFormValues>
                      initialValues={{
                        accountManagerId: clinic.accountManagerId || '',
                      }}
                      onSubmit={handleAccountManagerSubmit}
                    >
                      {({ handleSubmit }) => (
                        <FormItem
                          label={formatMessage(intlMessages.formLabelAccountManager)}
                          name="accountManagerId"
                        >
                          {({ inputId }) => (
                            <ClinicAccountManagerSelect
                              inputId={inputId}
                              isLoading={setAccountManagerLoading}
                              name="accountManagerId"
                              onChange={() => handleSubmit()}
                            />
                          )}
                        </FormItem>
                      )}
                    </Form>
                    {clinic.zohoUrl && (
                      <Paragraph>
                        {formatMessage(intlMessages.fieldZohoUrl, {
                          b: (str) => <Text strong>{str}</Text>,
                          zohoUrl: clinic.zohoUrl,
                          link: (str) => (
                            <a
                              href={clinic.zohoUrl || ''}
                              target="_blank"
                              rel="nofollow noreferrer"
                            >
                              {str}
                            </a>
                          ),
                        })}
                      </Paragraph>
                    )}
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={{ span: 22, order: 1 }} md={{ span: 4, order: 2, offset: 1 }}>
            <div className={styles.profileImageDragger}>
              <Dragger beforeUpload={handleImageUpload} multiple={false} showUploadList={false}>
                {uploadClinicImageLoading ? (
                  <div className={styles.uploadClinicImageLoading}>
                    <Spin size="large" />
                  </div>
                ) : (
                  <img
                    alt=""
                    className={styles.profileImage}
                    src={clinic.image ? clinic.image.location : images.placeholderClinic}
                  />
                )}
              </Dragger>
              {clinic.image && (
                <>
                  <br />
                  <Button
                    loading={removeClinicImageLoading}
                    onClick={handleRemoveImage}
                    size="sm"
                    variant="danger-outline"
                  >
                    {formatMessage(intlMessages.btnRemoveClinicImage)}
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={22} md={18}>
            <div className={styles.jobs}>
              <Heading level="h2">{formatMessage(intlMessages.sectionTitleJobs)}</Heading>
              <Tabs
                animated={false}
                defaultActiveKey="1"
                size="large"
                tabBarExtraContent={
                  <Link
                    to={generatePath(clinicRoutes.clinicJobCreate, { clinicId })}
                    buttonVariant="primary"
                  >
                    {formatMessage(intlMessages.linkCreateJob)}
                  </Link>
                }
              >
                <TabPane tab={formatMessage(intlMessages.sectionTitleActiveJobs)} key="1">
                  {!activeJobs.length && <NoActiveJobs clinicId={clinicId} />}
                  <ClinicJobsList jobs={activeJobs} />
                </TabPane>
                <TabPane tab={formatMessage(intlMessages.sectionTitleInactiveJobs)} key="2">
                  {!inactiveJobs.length && <NoInactiveJobs />}
                  <ClinicJobsList jobs={inactiveJobs} />
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      </div>
      <CreateClinicContactModal
        clinicId={clinic.id}
        isVisible={isClinicContactModalOpen}
        setIsOpen={setIsClinicContactModalOpen}
      />
    </FullScreen>
  );
};

export default ClinicDetail;
