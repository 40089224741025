import React, { useState } from 'react';
import { Button, Heading, Modal } from '@openloop/limbic';
import { useNavigate } from 'react-router-dom';
import { useWizard } from 'react-use-wizard';
import { useIntl } from 'react-intl';

import { adminRoutes } from '~Constants/routes';
import { intlMessages } from './intlMessages';

import styles from './InviteAndSuggest.module.scss';

const ExitWizardButton = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [isExitModalOpen, setExitCVModalOpen] = useState(false);
  const { isLastStep } = useWizard();

  const handleExit = () => {
    navigate(adminRoutes.inviteAndSuggest);
  };

  return isLastStep ? (
    <>
      <Button
        className={styles.exitLink}
        variant="secondary-borderless"
        size="sm"
        onClick={() => setExitCVModalOpen(true)}
      >
        {formatMessage(intlMessages.cancelAndExit)}
      </Button>
      {isExitModalOpen && (
        <Modal
          dialogContentClassName={styles.exitModal}
          isVisible={isExitModalOpen}
          onCancel={() => setExitCVModalOpen(false)}
          onOk={handleExit}
          okButtonText="Confirm"
          ariaLabel="confirm exit"
        >
          <div>
            <Heading level="h4" size="h5" className={styles.bold}>
              {formatMessage(intlMessages.exitQuestion)}
            </Heading>
            <p>{formatMessage(intlMessages.exitDescription)}</p>
          </div>
        </Modal>
      )}
    </>
  ) : (
    <Button
      className={styles.exitLink}
      variant="secondary-borderless"
      size="sm"
      onClick={handleExit}
    >
      {formatMessage(intlMessages.exitButton)}
    </Button>
  );
};

export default ExitWizardButton;
