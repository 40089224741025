import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  editProfile: {
    defaultMessage: 'Edit Profile',
    description: 'Edit Profile',
    id: 'components.provider.EditProfile.editProfile',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: 'Cancel Button',
    id: 'components.provider.EditProfile.cancelButton',
  },
  saveButton: {
    defaultMessage: 'Save',
    description: 'Save Button',
    id: 'components.provider.EditProfile.saveButton',
  },
  alertMsg1: {
    defaultMessage:
      'All fields must be completed before your application will be reviewed. Please complete your missing fields.',
    description: 'Alert Message 1',
    id: 'components.provider.EditProfile.alertMsg1',
  },
  alertMsg2: {
    defaultMessage: 'You have missing fields in the following form sections',
    description: 'Alert Message 2',
    id: 'components.provider.EditProfile.alertMsg1',
  },
  basics: {
    defaultMessage: 'Basics',
    description: 'Basics',
    id: 'components.provider.EditProfile.basics',
  },
  education: {
    defaultMessage: 'Education',
    description: 'Education',
    id: 'components.provider.EditProfile.education',
  },
  professional: {
    defaultMessage: 'Professional',
    description: 'Professional',
    id: 'components.provider.EditProfile.professional',
  },
  jobPreferences: {
    defaultMessage: 'Job Preferences',
    description: 'Job Preferences',
    id: 'components.provider.EditProfile.jobPreferences',
  },
});

export const intlMessagesFormSection = defineMessages({
  missingFields: {
    defaultMessage: 'Missing field(s)',
    description: 'Missing field',
    id: 'components.provider.EditProfile.FormSection.missingFields',
  },
});
