import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  error: {
    defaultMessage: 'There was an error loading this user.',
    description: 'Error message',
    id: 'components.admin.UserDetail.SharingTokens.error',
  },
  newToken: {
    defaultMessage: 'New Token',
    description: 'New Token',
    id: 'components.admin.UserDetail.SharingTokens.newToken',
  },
  paragraph: {
    defaultMessage: 'Use sharing tokens to present candidates to hiring managers.',
    description: 'Paragraph',
    id: 'components.admin.UserDetail.SharingTokens.paragraph',
  },
  created: {
    defaultMessage: 'Created',
    description: 'Created',
    id: 'components.admin.UserDetail.SharingTokens.created',
  },
  by: {
    defaultMessage: 'by',
    description: 'by',
    id: 'components.admin.UserDetail.SharingTokens.by',
  },
  expires: {
    defaultMessage: 'Expires',
    description: 'Expires',
    id: 'components.admin.UserDetail.SharingTokens.expires',
  },
});
