import React from 'react';
import { Outlet } from 'react-router-dom';
import { Row, Col, Layout } from 'antd';

import LegacyNavigation from '../LegacyNavigation';

const { Content } = Layout;

const Wrapper = () => (
  <Layout>
    <LegacyNavigation />
    <Content>
      <Row style={{ margin: '20px 0' }}>
        <Col offset={1} span={22}>
          <Outlet />
        </Col>
      </Row>
    </Content>
  </Layout>
);

export default Wrapper;
