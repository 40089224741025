import React, { useCallback, useEffect, useMemo } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Combobox from '@openloop/limbic/Form/Select/Combobox';
import Heading from '@openloop/limbic/Heading';
import { Col, Input, Row, Table } from 'antd';
import type { ColumnProps } from 'antd/lib/table';
import type { FilterDropdownProps, SortOrder } from 'antd/lib/table/interface';
import { Formik } from 'formik';
import { generatePath, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Spinner } from '@openloop/limbic';
import ClinicianAdvocateSelect from '~Components/ClinicianAdvocateSelect';
import ClinicianLicenseTag from '~Components/ClinicianLicenseTag';
import SpecialtiesSelect from '~Components/SpecialtiesSelect';
import TimeRelative from '~Components/TimeRelative';
import { networkStatusChoices } from '~Constants/networkStatuses';
import { adminRoutes, clinicRoutes, sharedRoutes } from '~Constants/routes';
import AllUsersDownloadButton from '~Core/AllUsersDownloadButton';
import FormItem from '~Core/Form/FormItem';
import SEO from '~Core/SEO';
import ShowMore from '~Core/ShowMore';
import {
  AppProfessionalContext,
  JobApplicationRulingDecision,
  JobStatus,
  License,
  LicenseStatus,
  NurseProfessionalContext,
  PhysicianProfessionalContext,
  ProviderUserRole,
  providerUserRoles,
  SortDirection,
  State,
  useAdminAdminUsersLazyQuery,
  useClinicsLazyQuery,
  UserNetworkStatus,
  UserRole,
  UsersSortBy,
  useStatesQuery,
} from '~Data';
import { formatPhone } from '~Helpers/phoneNumber';
import { stripRole } from '~Helpers/stripRole';
import { convertUtmParamsSearchedToObject, convertUtmParamsToAString } from '~Helpers/utmParams';
import { usePrevious } from '~Hooks/usePrevious';
import { useRouteState } from '~Hooks/useRouteState';
import { FeatureFlag, useFeatureFlag } from '~Hooks/useFeatureFlag';
import { jobRulingChoices } from '../../../Constants/jobRuling';
import {
  CliniciansSearchQueryVariables,
  useCliniciansSearchLazyQuery,
  useJobsLazyQuery,
} from './UserList.generated';
import { intlMessages } from './intlMessages';
import styles from './UserList.module.scss';

type UserLicense = Pick<License, 'stateId' | 'status'>;

interface DataSourceItem {
  clinicianAdvocateId: string | null | undefined;
  completion: number | null | undefined;
  createdAt: string;
  userAppliedToClinicIds: { clinicName: string; clinicId: string }[] | undefined;
  userAppliedToJobIds: { jobTitle: string; jobId: string; appliedDate?: string }[] | undefined;
  userAppliedToJobRuling:
    | (JobApplicationRulingDecision | 'Suggested' | undefined | null)[]
    | undefined
    | null;
  name: string | null;
  email: string;
  phone: string | null;
  role: string;
  specialty: string | null;
  status: UserNetworkStatus;
  statesDEALicensedIn: UserLicense[] | null;
  statesLicensedIn: UserLicense[] | null;
  licenseCount: number | null | undefined;
  lastActive: string | 'N/A';
  id: string;
  key: string;
  verifiedStatesLicensedIn: UserLicense[] | null;
  verifiedLicenseCount: number | null | undefined;
  utm: string | null | undefined;
}

interface RouteState {
  createdAt: string;
  name: string;
  email: string;
  phone: string;
  role: ProviderUserRole[];
  specialty: string[];
  statesDEALicensedIn: string[];
  statesLicensedIn: string[];
  lastActive: string;
  licenseCount: number;
  clinicianAdvocateId: string;
  sortBy: UsersSortBy;
  sortDirection: SortDirection;
  status: string[];
  page: number;
  pageSize: number;
  userAppliedToJobIds: string[];
  userAppliedToClinicIds: string[];
  userAppliedToJobRuling: JobApplicationRulingDecision | '';
  verifiedLicenseCount: number;
  verifiedStatesLicensedIn: string[];
  utm: string[];
}

type SortableColumnKey = keyof Pick<
  DataSourceItem,
  | 'clinicianAdvocateId'
  | 'name'
  | 'role'
  | 'specialty'
  | 'licenseCount'
  | 'lastActive'
  | 'createdAt'
  | 'verifiedLicenseCount'
>;

type JobDataObject = {
  text: string;
  value: string;
};

const sortFieldsByColumnKey: Record<SortableColumnKey, UsersSortBy> = {
  clinicianAdvocateId: UsersSortBy.ClinicianAdvocate,
  createdAt: UsersSortBy.DateJoined,
  role: UsersSortBy.Role,
  specialty: UsersSortBy.Specialty,
  name: UsersSortBy.Name,
  licenseCount: UsersSortBy.LicenseCount,
  lastActive: UsersSortBy.LastActive,
  verifiedLicenseCount: UsersSortBy.VerifiedLicenseCount,
};

const getSortDirectionProp = (order: SortOrder | undefined): SortDirection | void => {
  if (!order) {
    return undefined;
  }
  return order === 'ascend' ? SortDirection.Asc : SortDirection.Desc;
};

const jobsAscSortOrder = (a: JobDataObject, b: JobDataObject) => {
  if (a.text < b.text) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }
  return 0;
};

const UserList = () => {
  const { formatDate, formatMessage } = useIntl();
  const { data: statesData } = useStatesQuery();
  const [fetchClinics, { data: clientsData, loading: clientsLoading }] = useClinicsLazyQuery();
  const [fetchJobs, { data: jobsData, loading: jobsLoading }] = useJobsLazyQuery();
  const [fetchUsers, { data, loading }] = useCliniciansSearchLazyQuery();
  const [
    fetchClinicianAdvocates,
    { data: clinicianAdvocatesData, loading: clinicianAdvocatesLoading },
  ] = useAdminAdminUsersLazyQuery();

  const isInviteAndSuggestEnabled = useFeatureFlag(FeatureFlag.InviteAndSuggest);

  const [{ values: valuesFromQs }, { setValues }] = useRouteState<RouteState>({
    defaultValues: {
      clinicianAdvocateId: '',
      createdAt: '',
      name: '',
      email: '',
      phone: '',
      role: [],
      specialty: [],
      statesDEALicensedIn: [],
      statesLicensedIn: [],
      lastActive: '',
      licenseCount: 0,
      page: 1,
      pageSize: 10,
      sortBy: UsersSortBy.DateJoined,
      sortDirection: SortDirection.Desc,
      status: [],
      userAppliedToClinicIds: [],
      userAppliedToJobIds: [],
      userAppliedToJobRuling: '',
      verifiedStatesLicensedIn: [],
      verifiedLicenseCount: 0,
      utm: [],
    },
  });

  const previousValuesFromQs = usePrevious(valuesFromQs);

  const getSortOrder = useCallback<(sortKey: UsersSortBy) => SortOrder | undefined>(
    (sortKey) => {
      if (valuesFromQs.sortBy !== sortKey) {
        return undefined;
      }
      return valuesFromQs.sortDirection === SortDirection.Asc ? 'ascend' : 'descend';
    },
    [valuesFromQs.sortBy, valuesFromQs.sortDirection],
  );

  const jobsList = useCallback(() => {
    const jobsDataList = jobsData?.jobs.map(({ title, id }) => {
      return {
        text: title,
        value: id,
      };
    });

    return jobsDataList?.sort(jobsAscSortOrder);
  }, [jobsData]);

  const filteredJobs = useCallback(() => {
    const appliedJobsList = valuesFromQs.userAppliedToJobIds.map((value) => ({
      text: value,
      value,
    }));

    return appliedJobsList.sort(jobsAscSortOrder);
  }, [valuesFromQs.userAppliedToJobIds]);

  const { Suggested, ...jobRulingChoicesNoSuggested } = jobRulingChoices;

  const columns = useMemo<ColumnProps<DataSourceItem>[]>(
    () => [
      {
        sortOrder: getSortOrder(UsersSortBy.Name),
        sorter: true,
        width: '5%',
        dataIndex: 'name',
        key: 'name',
        render: (name, { id }) => (
          <Link target="_blank" to={generatePath(adminRoutes.userDetail, { userId: id })}>
            {name || 'N/A'}
          </Link>
        ),
        title: 'Name',
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys = [valuesFromQs.name],
          confirm,
          clearFilters,
        }: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search by name"
              value={selectedKeys[0]}
              onChange={(e) => {
                if (setSelectedKeys) {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                }
              }}
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <div className="ant-table-filter-dropdown-btns">
              <button
                type="button"
                disabled={selectedKeys.length === 0}
                onClick={clearFilters}
                className="ant-btn ant-btn-link ant-btn-sm"
              >
                {formatMessage(intlMessages.resetButton)}
              </button>
              <button
                type="button"
                onClick={() => confirm()}
                className="ant-btn ant-btn-primary ant-btn-sm"
              >
                {formatMessage(intlMessages.searchButton)}
              </button>
            </div>
          </div>
        ),
        filteredValue: valuesFromQs.name ? [valuesFromQs.name] : [],
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#ed0256' : undefined }} />
        ),
        fixed: 'left',
      },
      {
        title: 'Jobs',
        dataIndex: 'userAppliedToJobIds',
        key: 'userAppliedToJobIds',
        filteredValue: valuesFromQs.userAppliedToJobIds,
        filterMultiple: true,
        render: (userJobs: { jobTitle: string; jobId: string }[] = []) => {
          return userJobs.map(({ jobTitle, jobId }) => (
            <p key={jobId}>
              <Link to={generatePath(sharedRoutes.jobDescription, { jobId })} target="_blank">
                {jobTitle}
              </Link>
            </p>
          ));
        },
        filterDropdown: jobsLoading ? <Spinner /> : undefined,
        filters: jobsList() || filteredJobs(),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible && !jobsLoading && !jobsData?.jobs) {
            fetchJobs();
          }
        },
        width: '10%',
      },
      {
        title: 'Applied Date',
        dataIndex: 'userAppliedToJobIds',
        key: 'appliedDate',
        render: (userJobs: { appliedDate: string; jobId: string }[] = []) => {
          return userJobs.map(({ appliedDate, jobId }) => (
            <p key={jobId}>{appliedDate ? formatDate(appliedDate) : '-'}</p>
          ));
        },
      },
      {
        title: 'Ruling',
        dataIndex: 'userAppliedToJobRuling',
        key: 'userAppliedToJobRuling',
        filterMultiple: false,
        render: (userAppliedToJobRulings = []) =>
          userAppliedToJobRulings.map((ruling: JobApplicationRulingDecision, index: number) => (
            <p key={`${ruling}.${index}`}>{jobRulingChoices[ruling] || 'Not Yet Ruled'}</p>
          )),
        filteredValue: valuesFromQs.userAppliedToJobRuling
          ? [valuesFromQs.userAppliedToJobRuling]
          : [],
        filters: Object.entries(
          isInviteAndSuggestEnabled ? jobRulingChoices : jobRulingChoicesNoSuggested,
        )
          .map(([value, label]) => ({
            text: label,
            value,
          }))
          .sort(jobsAscSortOrder),
        width: '6%',
      },
      {
        title: 'Client',
        dataIndex: 'userAppliedToClinicIds',
        key: 'userAppliedToClinicIds',
        filteredValue: valuesFromQs.userAppliedToClinicIds,
        filterMultiple: true,
        render: (clinicInfo: { clinicName: string; clinicId: string }[] = []) =>
          clinicInfo.map(({ clinicName, clinicId }, index: number) => (
            <p key={`${clinicName}.${index}`}>
              <Link to={generatePath(clinicRoutes.clinicDetail, { clinicId })}>{clinicName}</Link>
            </p>
          )),
        filters:
          clientsData?.clinics
            .map(({ id, name }) => {
              return {
                text: name,
                value: id,
              };
            })
            .sort(jobsAscSortOrder) ||
          valuesFromQs.userAppliedToClinicIds.map((value) => ({ text: value, value })),
        filterDropdown: clientsLoading ? <Spinner /> : undefined,
        onFilterDropdownVisibleChange: (visible) => {
          if (visible && !clientsLoading && !clientsData?.clinics) {
            fetchClinics();
          }
        },
        width: '6%',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search by email"
              value={selectedKeys[0]}
              onChange={(e) => {
                if (setSelectedKeys) {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                }
              }}
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <div className="ant-table-filter-dropdown-btns">
              <button
                type="button"
                disabled={selectedKeys.length === 0}
                onClick={clearFilters}
                className="ant-btn ant-btn-link ant-btn-sm"
              >
                {formatMessage(intlMessages.resetButton)}
              </button>
              <button
                type="button"
                onClick={() => confirm()}
                className="ant-btn ant-btn-primary ant-btn-sm"
              >
                {formatMessage(intlMessages.searchButton)}
              </button>
            </div>
          </div>
        ),
        filteredValue: valuesFromQs.email ? [valuesFromQs.email] : [],
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#ed0256' : undefined }} />
        ),
        width: '7%',
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        filteredValue: valuesFromQs.phone ? [valuesFromQs.phone] : [],
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search by phone"
              value={selectedKeys[0]}
              onChange={(e) => {
                if (setSelectedKeys) {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                }
              }}
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <div className="ant-table-filter-dropdown-btns">
              <button
                type="button"
                disabled={selectedKeys.length === 0}
                onClick={clearFilters}
                className="ant-btn ant-btn-link ant-btn-sm"
              >
                {formatMessage(intlMessages.resetButton)}
              </button>
              <button
                type="button"
                onClick={() => confirm()}
                className="ant-btn ant-btn-primary ant-btn-sm"
              >
                {formatMessage(intlMessages.searchButton)}
              </button>
            </div>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#ed0256' : undefined }} />
        ),
      },
      {
        title: 'Type',
        dataIndex: 'role',
        key: 'role',
        filteredValue: valuesFromQs.role,
        filters: Object.values(UserRole)
          .filter((role) => role !== UserRole.Admin && role !== UserRole.Clinic)
          .map((value) => ({ text: value, value })),
        sortOrder: getSortOrder(UsersSortBy.Role),
        sorter: true,
      },
      {
        title: 'Specialty',
        dataIndex: 'specialty',
        key: 'specialty',
        sortOrder: getSortOrder(UsersSortBy.Specialty),
        sorter: true,
        filteredValue: valuesFromQs.specialty,
        filterDropdown: ({
          selectedKeys,
          confirm,
          setSelectedKeys,
          clearFilters,
        }: FilterDropdownProps) => {
          return (
            <div className={styles.specialtyWrapper}>
              <Formik
                initialValues={{ specialties: valuesFromQs.specialty }}
                enableReinitialize
                onSubmit={() => undefined}
              >
                <FormItem
                  label={formatMessage(intlMessages.clinicianSpecialities)}
                  name="specialties"
                >
                  {({ inputId }) => (
                    <SpecialtiesSelect
                      id={inputId}
                      isClearable
                      name="specialties"
                      userRole={
                        valuesFromQs.role.length === 0 ? providerUserRoles : valuesFromQs.role
                      }
                      onChange={(selectedOptions) => {
                        setSelectedKeys(
                          selectedOptions !== null
                            ? selectedOptions.map((option: { value: string }) => option.value)
                            : [],
                        );
                      }}
                      onBlur={() => {
                        confirm();
                      }}
                    />
                  )}
                </FormItem>
              </Formik>
              <div className="ant-table-filter-dropdown-btns">
                <button
                  type="button"
                  disabled={selectedKeys.length === 0}
                  onClick={clearFilters}
                  className="ant-btn ant-btn-link ant-btn-sm"
                >
                  {formatMessage(intlMessages.resetButton)}
                </button>
                <button
                  type="button"
                  onClick={() => confirm()}
                  className="ant-btn ant-btn-primary ant-btn-sm"
                >
                  {formatMessage(intlMessages.searchButton)}
                </button>
              </div>
            </div>
          );
        },
        width: '6%',
      },
      {
        title: 'Self-Reported Licenses',
        dataIndex: 'statesLicensedIn',
        key: 'statesLicensedIn',
        width: '5%',
        filteredValue: valuesFromQs.statesLicensedIn,
        render: (statesLicensedInIds = []) => {
          type StatesMap = {
            [stateId: string]: State;
          };
          const statesMap: StatesMap = {};
          if (statesData?.states) {
            statesData.states.forEach((state) => {
              statesMap[state.id] = state;
            });
          }
          const sortStatesByName = (a: UserLicense, b: UserLicense) =>
            statesMap[a.stateId].name.localeCompare(statesMap[b.stateId].name);

          return (
            <div className={styles.licensesContainer}>
              <ShowMore
                className={styles.licensesContainer}
                minItems={5}
                showLessAriaLabel={formatMessage(intlMessages.showFewerLicensesAriaLabel)}
                showMoreAriaLabel={(numMoreLicenses) =>
                  formatMessage(intlMessages.showMoreLicensesAriaLabel, { numMoreLicenses })
                }
              >
                {statesLicensedInIds.sort(sortStatesByName).map((license: UserLicense) => {
                  return (
                    <ClinicianLicenseTag
                      key={license.stateId}
                      state={statesMap[license.stateId]}
                      status={license.status}
                      tooltipPosition="right"
                    />
                  );
                })}
              </ShowMore>
            </div>
          );
        },
        filterDropdown: ({
          selectedKeys,
          confirm,
          setSelectedKeys,
          clearFilters,
        }: FilterDropdownProps) => {
          return (
            <div className={styles.specialtyWrapper}>
              <Formik
                initialValues={{ statesLicensedIn: valuesFromQs.statesLicensedIn }}
                enableReinitialize
                onSubmit={() => undefined}
              >
                <FormItem label={formatMessage(intlMessages.stateLicenses)} name="statesLicensedIn">
                  {({ inputId }) => (
                    <Combobox
                      id={inputId}
                      name="statesLicensedIn"
                      options={
                        statesData?.states
                          ? statesData.states.map((state) => {
                              return {
                                value: state.id,
                                label: state.shortName,
                              };
                            })
                          : []
                      }
                      onChange={(selectedOptions) => {
                        setSelectedKeys(
                          selectedOptions !== null
                            ? selectedOptions.map((option: { value: string }) => option.value)
                            : [],
                        );
                      }}
                      onBlur={() => confirm()}
                    />
                  )}
                </FormItem>
              </Formik>
              <div className="ant-table-filter-dropdown-btns">
                <button
                  type="button"
                  disabled={selectedKeys.length === 0}
                  onClick={clearFilters}
                  className="ant-btn ant-btn-link ant-btn-sm"
                >
                  {formatMessage(intlMessages.resetButton)}
                </button>
                <button
                  type="button"
                  onClick={() => confirm()}
                  className="ant-btn ant-btn-primary ant-btn-sm"
                >
                  {formatMessage(intlMessages.searchButton)}
                </button>
              </div>
            </div>
          );
        },
      },
      {
        title: 'Total Self-Reported Licenses',
        dataIndex: 'licenseCount',
        key: 'licenseCount',
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            <Input
              type="number"
              min={0}
              placeholder="> ="
              value={selectedKeys[0]}
              onChange={(e) => {
                if (setSelectedKeys) {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                }
              }}
              onPressEnter={() => confirm()}
              style={{ width: 100, marginBottom: 8, display: 'block' }}
            />
            <div className="ant-table-filter-dropdown-btns">
              <button
                type="button"
                disabled={selectedKeys.length === 0}
                onClick={clearFilters}
                className="ant-btn ant-btn-link ant-btn-sm"
              >
                {formatMessage(intlMessages.resetButton)}
              </button>
              <button
                type="button"
                onClick={() => confirm()}
                className="ant-btn ant-btn-primary ant-btn-sm"
              >
                {formatMessage(intlMessages.searchButton)}
              </button>
            </div>
          </div>
        ),
        filteredValue: valuesFromQs.licenseCount ? [valuesFromQs.licenseCount] : [],
        sortOrder: getSortOrder(UsersSortBy.LicenseCount),
        sorter: true,
        width: '6%',
      },
      {
        title: 'Verified Licenses',
        dataIndex: 'verifiedStatesLicensedIn',
        key: 'verifiedStatesLicensedIn',
        width: 125,
        filteredValue: valuesFromQs.verifiedStatesLicensedIn,
        render: (verifiedStatesLicensedIn = []) => {
          // eslint-disable-next-line no-console
          type StatesMap = {
            [stateId: string]: State;
          };
          const statesMap: StatesMap = {};
          if (statesData?.states) {
            statesData.states.forEach((state) => {
              statesMap[state.id] = state;
            });
          }

          return (
            <div className={styles.licensesContainer}>
              <ShowMore
                className={styles.licensesContainer}
                minItems={5}
                showLessAriaLabel={formatMessage(intlMessages.showFewerLicensesAriaLabel)}
                showMoreAriaLabel={(numMoreLicenses) =>
                  formatMessage(intlMessages.showMoreLicensesAriaLabel, { numMoreLicenses })
                }
              >
                {verifiedStatesLicensedIn.map((license: UserLicense) => {
                  return (
                    <ClinicianLicenseTag
                      key={license.stateId}
                      state={statesMap[license.stateId]}
                      status={license.status}
                      tooltipPosition="right"
                    />
                  );
                })}
              </ShowMore>
            </div>
          );
        },
        filterDropdown: ({
          selectedKeys,
          confirm,
          setSelectedKeys,
          clearFilters,
        }: FilterDropdownProps) => {
          return (
            <div className={styles.specialtyWrapper}>
              <Formik
                initialValues={{ verifiedStatesLicensedIn: valuesFromQs.verifiedStatesLicensedIn }}
                enableReinitialize
                onSubmit={() => undefined}
              >
                <FormItem
                  label={formatMessage(intlMessages.verifiedStateLicenses)}
                  name="verifiedStatesLicensedIn"
                >
                  {({ inputId }) => (
                    <Combobox
                      id={inputId}
                      name="verifiedStatesLicensedIn"
                      options={
                        statesData?.states
                          ? statesData.states.map((state) => {
                              return {
                                value: state.id,
                                label: state.shortName,
                              };
                            })
                          : []
                      }
                      onChange={(selectedOptions) => {
                        setSelectedKeys(
                          selectedOptions !== null
                            ? selectedOptions.map((option: { value: string }) => option.value)
                            : [],
                        );
                      }}
                      onBlur={() => confirm()}
                    />
                  )}
                </FormItem>
              </Formik>
              <div className="ant-table-filter-dropdown-btns">
                <button
                  type="button"
                  disabled={selectedKeys.length === 0}
                  onClick={clearFilters}
                  className="ant-btn ant-btn-link ant-btn-sm"
                >
                  {formatMessage(intlMessages.resetButton)}
                </button>
                <button
                  type="button"
                  onClick={() => confirm()}
                  className="ant-btn ant-btn-primary ant-btn-sm"
                >
                  {formatMessage(intlMessages.searchButton)}
                </button>
              </div>
            </div>
          );
        },
      },
      {
        title: 'Total Verified Licenses',
        dataIndex: 'verifiedLicenseCount',
        key: 'verifiedLicenseCount',
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            <Input
              type="number"
              min={0}
              placeholder="> ="
              value={selectedKeys[0]}
              onChange={(e) => {
                if (setSelectedKeys) {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                }
              }}
              onPressEnter={() => confirm()}
              style={{ width: 100, marginBottom: 8, display: 'block' }}
            />
            <div className="ant-table-filter-dropdown-btns">
              <button
                type="button"
                disabled={selectedKeys.length === 0}
                onClick={clearFilters}
                className="ant-btn ant-btn-link ant-btn-sm"
              >
                {formatMessage(intlMessages.resetButton)}
              </button>
              <button
                type="button"
                onClick={() => confirm()}
                className="ant-btn ant-btn-primary ant-btn-sm"
              >
                {formatMessage(intlMessages.searchButton)}
              </button>
            </div>
          </div>
        ),
        filteredValue: valuesFromQs.verifiedLicenseCount ? [valuesFromQs.verifiedLicenseCount] : [],
        sortOrder: getSortOrder(UsersSortBy.VerifiedLicenseCount),
        sorter: true,
        width: '5%',
      },
      {
        title: 'Self-Reported DEA Licenses',
        dataIndex: 'statesDEALicensedIn',
        key: 'statesDEALicensedIn',
        width: '5%',
        filteredValue: valuesFromQs.statesDEALicensedIn,
        render: (statesDEALicensedIn = []) => {
          type StatesMap = {
            [stateId: string]: State;
          };
          const statesMap: StatesMap = {};
          if (statesData?.states) {
            statesData.states.forEach((state) => {
              statesMap[state.id] = state;
            });
          }
          const sortStatesByName = (a: UserLicense, b: UserLicense) =>
            statesMap[a.stateId].name.localeCompare(statesMap[b.stateId].name);

          return (
            <div className={styles.licensesContainer}>
              <ShowMore
                className={styles.licensesContainer}
                minItems={5}
                showLessAriaLabel={formatMessage(intlMessages.showFewerLicensesAriaLabel)}
                showMoreAriaLabel={(numMoreLicenses) =>
                  formatMessage(intlMessages.showMoreLicensesAriaLabel, { numMoreLicenses })
                }
              >
                {statesDEALicensedIn.sort(sortStatesByName).map((license: UserLicense) => {
                  return (
                    <ClinicianLicenseTag
                      key={license.stateId}
                      state={statesMap[license.stateId]}
                      status={license.status}
                      tooltipPosition="right"
                    />
                  );
                })}
              </ShowMore>
            </div>
          );
        },
        filterDropdown: ({
          selectedKeys,
          confirm,
          setSelectedKeys,
          clearFilters,
        }: FilterDropdownProps) => {
          return (
            <div className={styles.specialtyWrapper}>
              <Formik
                initialValues={{ statesDEALicensedIn: valuesFromQs.statesDEALicensedIn }}
                enableReinitialize
                onSubmit={() => undefined}
              >
                <FormItem
                  label={formatMessage(intlMessages.stateLicenses)}
                  name="statesDEALicensedIn"
                >
                  {({ inputId }) => (
                    <Combobox
                      id={inputId}
                      name="statesDEALicensedIn"
                      options={
                        statesData?.states
                          ? statesData.states.map((state) => {
                              return {
                                value: state.id,
                                label: state.shortName,
                              };
                            })
                          : []
                      }
                      onChange={(selectedOptions) => {
                        setSelectedKeys(
                          selectedOptions !== null
                            ? selectedOptions.map((option: { value: string }) => option.value)
                            : [],
                        );
                      }}
                      onBlur={() => confirm()}
                    />
                  )}
                </FormItem>
              </Formik>
              <div className="ant-table-filter-dropdown-btns">
                <button
                  type="button"
                  disabled={selectedKeys.length === 0}
                  onClick={clearFilters}
                  className="ant-btn ant-btn-link ant-btn-sm"
                >
                  {formatMessage(intlMessages.resetButton)}
                </button>
                <button
                  type="button"
                  onClick={() => confirm()}
                  className="ant-btn ant-btn-primary ant-btn-sm"
                >
                  {formatMessage(intlMessages.searchButton)}
                </button>
              </div>
            </div>
          );
        },
      },
      {
        title: 'Last Active',
        dataIndex: 'lastActive',
        key: 'lastActive',
        render: (lastActive) => {
          return lastActive ? <TimeRelative value={lastActive} /> : 'N/A';
        },
        sortOrder: getSortOrder(UsersSortBy.LastActive),
        sorter: true,
      },
      {
        title: 'Network Status',
        dataIndex: 'status',
        width: 130,
        key: 'status',
        filteredValue: valuesFromQs.status,
        filters: Object.entries(networkStatusChoices).map(([key, value]) => ({
          text: value,
          value: key,
        })),
        render: (networkStatus: UserNetworkStatus) => <p>{networkStatusChoices[networkStatus]}</p>,
      },
      {
        dataIndex: 'clinicianAdvocateId',
        filterMultiple: false,
        filteredValue: valuesFromQs.clinicianAdvocateId ? [valuesFromQs.clinicianAdvocateId] : [],
        filters:
          clinicianAdvocatesData?.adminAdminUsers
            .map(({ id, profile: { displayName } }) => ({
              value: id,
              text: displayName,
            }))
            .sort(jobsAscSortOrder) || [],
        key: 'clinicianAdvocateId',
        render: (clinicianAdvocateId, record) => {
          return (
            <ClinicianAdvocateSelect userId={record.id} clinicianAdvocateId={clinicianAdvocateId} />
          );
        },
        filterDropdown: clinicianAdvocatesLoading ? <Spinner /> : undefined,
        onFilterDropdownVisibleChange: (visible) => {
          if (visible && !clinicianAdvocatesLoading && !clinicianAdvocatesData?.adminAdminUsers) {
            fetchClinicianAdvocates();
          }
        },
        sortOrder: getSortOrder(UsersSortBy.ClinicianAdvocate),
        sorter: true,
        title: 'Clinician Advocate',
        width: '5%',
      },
      {
        defaultSortOrder: 'descend',
        title: 'Created Date',
        width: 115,
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) => formatDate(createdAt),
        sortOrder: getSortOrder(UsersSortBy.DateJoined),
        sorter: true,
      },
      {
        title: 'UTM',
        dataIndex: 'utm',
        width: 115,
        key: 'utm',
        filteredValue: valuesFromQs.utm ? valuesFromQs.utm : [],
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: FilterDropdownProps) => {
          const disableConfirmButton = () => {
            let disabledButton = false;
            if (selectedKeys.length === 2) {
              const [param, value] = selectedKeys;
              if (!param && value) disabledButton = true;
              else if (param && !value) disabledButton = true;
            } else if (selectedKeys.length === 1) {
              disabledButton = true;
            }
            return disabledButton;
          };
          return (
            <div style={{ padding: 8 }}>
              <Input
                placeholder="Specify UTM parameter"
                value={selectedKeys[0]}
                onChange={(e) => {
                  if (setSelectedKeys) {
                    const newSelectedKeys = [...selectedKeys];
                    newSelectedKeys[0] = e.target.value ? e.target.value : '';
                    setSelectedKeys(newSelectedKeys);
                  }
                }}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Input
                placeholder="Specify UTM value"
                value={selectedKeys[1]}
                onChange={(e) => {
                  if (setSelectedKeys) {
                    const newSelectedKeys = [...selectedKeys];
                    newSelectedKeys[1] = e.target.value ? e.target.value : '';
                    setSelectedKeys(newSelectedKeys);
                  }
                }}
                onPressEnter={disableConfirmButton() ? undefined : () => confirm()}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <div className="ant-table-filter-dropdown-btns">
                <button
                  type="button"
                  disabled={selectedKeys.length === 0}
                  onClick={clearFilters}
                  className="ant-btn ant-btn-link ant-btn-sm"
                >
                  {formatMessage(intlMessages.resetButton)}
                </button>
                <button
                  type="button"
                  disabled={disableConfirmButton()}
                  onClick={() => confirm()}
                  className="ant-btn ant-btn-primary ant-btn-sm"
                >
                  {formatMessage(intlMessages.searchButton)}
                </button>
              </div>
            </div>
          );
        },
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#ed0256' : undefined }} />
        ),
      },
    ],
    [
      getSortOrder,
      valuesFromQs.name,
      valuesFromQs.userAppliedToJobIds,
      valuesFromQs.userAppliedToJobRuling,
      valuesFromQs.userAppliedToClinicIds,
      valuesFromQs.email,
      valuesFromQs.phone,
      valuesFromQs.role,
      valuesFromQs.specialty,
      valuesFromQs.statesDEALicensedIn,
      valuesFromQs.statesLicensedIn,
      valuesFromQs.licenseCount,
      valuesFromQs.verifiedStatesLicensedIn,
      valuesFromQs.verifiedLicenseCount,
      valuesFromQs.status,
      valuesFromQs.clinicianAdvocateId,
      valuesFromQs.utm,
      jobsLoading,
      jobsList,
      filteredJobs,
      isInviteAndSuggestEnabled,
      jobRulingChoicesNoSuggested,
      clientsData?.clinics,
      clientsLoading,
      clinicianAdvocatesData?.adminAdminUsers,
      clinicianAdvocatesLoading,
      jobsData?.jobs,
      fetchJobs,
      fetchClinics,
      statesData?.states,
      formatMessage,
      fetchClinicianAdvocates,
      formatDate,
    ],
  );

  const dataSource = useMemo<DataSourceItem[]>(() => {
    if (!data) {
      return [];
    }

    const { cliniciansSearch } = data;
    return cliniciansSearch.users.map(
      ({
        id,
        email,
        lastActive,
        createdAt,
        profile: {
          completion,
          displayName,
          DEALicenses,
          licenses,
          networkStatus,
          phone,
          professionalContext,
          utm,
        },
        role,
        clinicianAdvocateId,
        jobApplications,
        verifiedActiveLicenses,
        jobSuggestions,
      }) => {
        let specialty = null;
        if (professionalContext.type === UserRole.App) {
          specialty = (professionalContext as AppProfessionalContext).specialty || null;
        }
        if (professionalContext.type === UserRole.Physician) {
          specialty = (professionalContext as PhysicianProfessionalContext).specialty || null;
        }
        if (professionalContext.type === UserRole.Nurse) {
          specialty = (professionalContext as NurseProfessionalContext).specialty || null;
        }

        let userAppliedToJobIds:
          | { jobTitle: string; jobId: string; appliedDate: string }[]
          | undefined;
        let userAppliedToClinicIds: { clinicName: string; clinicId: string }[] | undefined;
        let userAppliedToJobRuling: (JobApplicationRulingDecision | undefined | null)[] | undefined;

        const jobApplicationsWithActiveStatuses = jobApplications?.filter(({ job: { status } }) =>
          [JobStatus.OnHold, JobStatus.Active].includes(status),
        );
        if (jobApplicationsWithActiveStatuses && jobApplicationsWithActiveStatuses.length > 0) {
          userAppliedToJobIds = jobApplicationsWithActiveStatuses?.map((app) => ({
            jobTitle: app.job.title,
            jobId: app.jobId,
            appliedDate: app.createdAt,
          }));
          userAppliedToClinicIds = jobApplicationsWithActiveStatuses?.map((app) => ({
            clinicName: app.job.clinic.name,
            clinicId: app.job.clinic.id,
          }));
          userAppliedToJobRuling = jobApplicationsWithActiveStatuses.map(
            (app) => app.latestRuling?.decision,
          );
        }
        let userJobSuggestions: { jobTitle: string; jobId: string }[] | undefined;
        let userJobSuggestionsClinicIds: { clinicName: string; clinicId: string }[] | undefined;
        let suggestedJobRuling:
          | (JobApplicationRulingDecision | 'Suggested' | undefined | null)[]
          | undefined;

        if (isInviteAndSuggestEnabled) {
          const userJobApplicationIds = userAppliedToJobIds?.map(({ jobId }) => jobId);
          const userUnappliedJobSuggestions = jobSuggestions?.filter(
            ({ job: { id: jobSuggestionId } }) => !userJobApplicationIds?.includes(jobSuggestionId),
          );
          if (userUnappliedJobSuggestions && userUnappliedJobSuggestions.length) {
            userJobSuggestions = userUnappliedJobSuggestions.map(
              ({ job: { id: jobId, title } }) => ({
                jobTitle: title,
                jobId,
              }),
            );
            userJobSuggestionsClinicIds = userUnappliedJobSuggestions.map(
              ({
                job: {
                  clinic: { id: clinicId, name },
                },
              }) => ({
                clinicName: name,
                clinicId,
              }),
            );
            suggestedJobRuling = userUnappliedJobSuggestions.map(() => 'Suggested') || [];
          }
        }
        const availableLicenses = licenses.filter(({ status }) =>
          [LicenseStatus.SelfReportedActive, LicenseStatus.SelfReportedPending].includes(status),
        );

        const availableDEALicenses = DEALicenses.filter(({ status }) =>
          [LicenseStatus.SelfReportedActive, LicenseStatus.SelfReportedPending].includes(status),
        );

        return {
          clinicianAdvocateId,
          createdAt,
          completion,
          userAppliedToJobIds: [...(userAppliedToJobIds || []), ...(userJobSuggestions || [])],
          userAppliedToClinicIds: [
            ...(userAppliedToClinicIds || []),
            ...(userJobSuggestionsClinicIds || []),
          ],
          userAppliedToJobRuling: [
            ...(userAppliedToJobRuling || []),
            ...(suggestedJobRuling || []),
          ],
          name: displayName,
          email,
          phone: phone ? formatPhone(phone) : null,
          role,
          specialty,
          status: networkStatus,
          statesDEALicensedIn: availableDEALicenses,
          statesLicensedIn: availableLicenses,
          licenseCount: availableLicenses.length,
          lastActive,
          id,
          key: id,
          verifiedStatesLicensedIn: verifiedActiveLicenses || [],
          verifiedLicenseCount: verifiedActiveLicenses?.length || 0,
          utm: utm && convertUtmParamsToAString(utm),
        };
      },
    );
  }, [data, isInviteAndSuggestEnabled]);

  const handleTableChange = useCallback(
    (pagination, filter, sorter) => {
      const { columnKey, order } = sorter;
      const sortDirection = getSortDirectionProp(order);
      const sortBy: UsersSortBy | undefined = sortDirection
        ? sortFieldsByColumnKey[columnKey as SortableColumnKey]
        : undefined;

      setValues({
        ...filter,
        page: pagination.current,
        pageSize: pagination.pageSize,
        sortBy,
        sortDirection,
      });
    },
    [setValues],
  );
  useEffect(() => {
    if (valuesFromQs !== previousValuesFromQs) {
      const variables: CliniciansSearchQueryVariables = {
        filter: {
          clinicianAdvocateId: valuesFromQs.clinicianAdvocateId,
          userAppliedToClinicIds: valuesFromQs.userAppliedToClinicIds,
          userAppliedToJobIds: valuesFromQs.userAppliedToJobIds,
          userAppliedToJobRuling:
            valuesFromQs.userAppliedToJobRuling !== ''
              ? valuesFromQs.userAppliedToJobRuling
              : undefined,
          name: valuesFromQs.name,
          email: valuesFromQs.email,
          phone: valuesFromQs.phone,
          role: valuesFromQs.role.length > 0 ? valuesFromQs.role : undefined,
          specialty:
            valuesFromQs.specialty.length > 0
              ? valuesFromQs.specialty.map((specialtyWithRole) => stripRole(specialtyWithRole))
              : undefined,
          networkStatus:
            valuesFromQs.status.length > 0
              ? valuesFromQs.status.map(
                  (status) => UserNetworkStatus[status as keyof typeof UserNetworkStatus],
                )
              : undefined,
          statesDEALicensedIn:
            valuesFromQs.statesDEALicensedIn.length > 0
              ? valuesFromQs.statesDEALicensedIn
              : undefined,
          statesLicensedIn:
            valuesFromQs.statesLicensedIn.length > 0 ? valuesFromQs.statesLicensedIn : undefined,
          licenseCount: valuesFromQs.licenseCount || 0,
          verifiedLicenseCount: valuesFromQs.verifiedLicenseCount || 0,
          verifiedStatesLicensedIn:
            valuesFromQs.verifiedStatesLicensedIn.length > 0
              ? valuesFromQs.verifiedStatesLicensedIn
              : undefined,
          utm:
            valuesFromQs.utm && valuesFromQs.utm.length === 2
              ? convertUtmParamsSearchedToObject(valuesFromQs.utm)
              : undefined,
        },
        paginate: {
          skip: (Number(valuesFromQs.page) - 1) * Number(valuesFromQs.pageSize) || 0,
          take: Number(valuesFromQs.pageSize || 10),
        },
        sort: {
          direction: valuesFromQs.sortDirection || SortDirection.Desc,
          sortBy: valuesFromQs.sortBy || UsersSortBy.DateJoined,
        },
      };
      fetchUsers({ variables });
    }
  }, [fetchUsers, previousValuesFromQs, valuesFromQs]);

  return (
    <>
      <SEO title={formatMessage(intlMessages.pageTitleSeo)} />
      <Row className={styles.headingRow}>
        <Col xs={24} sm={12}>
          <Heading level="h3" className={styles.pageTitle}>
            {formatMessage(intlMessages.pageTitle)}
          </Heading>
          <Heading level="h2" variant="secondary" className={styles.counterText}>
            {data ? (
              formatMessage(intlMessages.totalNumUsers, {
                itemCount: data.cliniciansSearch.totalRecords,
              })
            ) : (
              <>&nbsp;</>
            )}
          </Heading>
        </Col>
        <Col className={styles.actionButtons} flex={1}>
          <AllUsersDownloadButton />
        </Col>
      </Row>
      <Table<DataSourceItem>
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onChange={handleTableChange}
        className={styles.userListTable}
        pagination={
          data
            ? {
                current:
                  (data.cliniciansSearch.skip + data.cliniciansSearch.take) /
                    data.cliniciansSearch.take || 1,
                pageSizeOptions: ['10', '25', '50', '100', '250'],
                position: ['bottomRight', 'topRight'],
                total: data.cliniciansSearch.totalRecords,
                pageSize: data.cliniciansSearch.take,
              }
            : undefined
        }
        scroll={{ x: 2900, y: 'calc(100vh - 300px)' }}
      />
    </>
  );
};

export default UserList;
