import React from 'react';
import { RouteObject } from 'react-router-dom';

import { clinicRoutes } from '~Constants/routes';
import { UserRole } from '~Data';
import AuthGuard from '~Auth';

import ClinicDetail from '~Modules/clinic/ClinicDetail';
import CreateClinic from '~Modules/clinic/CreateClinic';
import CreateJob from '~Modules/clinic/CreateJob';
import Dashboard from '~Modules/clinic/Dashboard';
import EditClinic from '~Modules/clinic/EditClinic';
import EditJob from '~Modules/clinic/EditJob';
import IntegrationDetails from '~Modules/clinic/IntegrationDetails';
import Integrations from '~Modules/clinic/Integrations';
import Wrapper from '~Modules/clinic/Wrapper';

export const clinicRoutesObject: RouteObject[] = [
  {
    path: '/clinic',
    element: (
      <AuthGuard userTypesAllowed={[UserRole.Clinic, UserRole.Admin]}>
        <Wrapper />
      </AuthGuard>
    ),
    children: [
      { index: true, element: <Dashboard /> },
      { path: clinicRoutes.clinicCreate, element: <CreateClinic /> },
      { path: clinicRoutes.clinicJobCreate, element: <CreateJob /> },
      { path: clinicRoutes.clinicDetail, element: <ClinicDetail /> },
      { path: clinicRoutes.clinicEdit, element: <EditClinic /> },
      { path: clinicRoutes.clinicJobEdit, element: <EditJob /> },
      { path: clinicRoutes.clinicIntegrations, element: <Integrations /> },
      { path: clinicRoutes.clinicIntegrationDetail, element: <IntegrationDetails /> },
    ],
  },
];
