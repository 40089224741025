import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  errorMessage: {
    defaultMessage: 'There was an error loading this user.',
    description: 'Error Message',
    id: 'components.UserDetail.Profile.errorMessage',
  },
  availabilityMaxNumber: {
    defaultMessage: 'Max Number Of Hours',
    description: 'Max Number Of Hours',
    id: 'components.UserDetail.Profile.availabilityMaxNumber',
  },
  availabilityStartAt: {
    defaultMessage: 'Availability Starting',
    description: 'Availability Starting',
    id: 'components.UserDetail.Profile.availabilityStartAt',
  },
  specialty: {
    defaultMessage: 'Specialty',
    description: 'Specialty',
    id: 'components.UserDetail.Profile.specialty',
  },
  levelOfTraining: {
    defaultMessage: 'Level of Training',
    description: 'Level of Training',
    id: 'components.UserDetail.Profile.levelOfTraining',
  },
  compatibility: {
    defaultMessage: 'EHR Compatibility',
    description: 'EHR Compatibility',
    id: 'components.UserDetail.Profile.compatibility',
  },
  dateOfBirth: {
    defaultMessage: 'Date of Birth',
    description: 'Date of Birth',
    id: 'components.UserDetail.Profile.dateOfBirth',
  },
  employmentStatus: {
    defaultMessage: 'Current Employment Status',
    description: 'Current Employment Status',
    id: 'components.UserDetail.Profile.employmentStatus',
  },
  yearLastPracticed: {
    defaultMessage: 'Year Last Practiced',
    description: 'Year Last Practiced',
    id: 'components.UserDetail.Profile.yearLastPracticed',
  },
  employmentHistory: {
    defaultMessage: 'Employment History',
    description: 'Employment History',
    id: 'components.UserDetail.Profile.employmentHistory',
  },
  education: {
    defaultMessage: 'Education',
    description: 'Education',
    id: 'components.UserDetail.Profile.education',
  },
  degreeObtained: {
    defaultMessage: 'Degree Obtained',
    description: 'Degree Obtained',
    id: 'components.UserDetail.Profile.degreeObtained',
  },
  graduatingInstitution: {
    defaultMessage: 'Graduating Instituation',
    description: 'Graduating Instituation',
    id: 'components.UserDetail.Profile.graduatingInstitution',
  },
  jobPreferences: {
    defaultMessage: 'Job Preferences',
    description: 'Job Preferences',
    id: 'components.UserDetail.Profile.jobPreferences',
  },
  seeking: {
    defaultMessage: 'Seeking',
    description: 'Seeking',
    id: 'components.UserDetail.Profile.seeking',
  },
  bio: {
    defaultMessage: 'Bio',
    description: 'Bio',
    id: 'components.UserDetail.Profile.bio',
  },
  missingFields: {
    defaultMessage: 'Missing Fields',
    description: 'Missing Fields',
    id: 'components.UserDetail.Profile.missingFields',
  },
  verified: {
    defaultMessage: 'Verified',
    description: 'Verified',
    id: 'components.UserDetail.Profile.verified',
  },
  notVerified: {
    defaultMessage: 'Not Verified',
    description: 'Not Verified',
    id: 'components.UserDetail.Profile.notVerified',
  },
  zohoCandidateId: {
    defaultMessage: 'Zoho Recruit Candidate ID',
    description: 'Zoho Recruit Candidate ID',
    id: 'components.UserDetail.Profile.zohoCandidateId',
  },
  generalInfo: {
    defaultMessage: 'General Info',
    description: 'General Info',
    id: 'components.UserDetail.Profile.generalInfo',
  },
  location: {
    defaultMessage: 'Location',
    description: 'Location',
    id: 'components.UserDetail.Profile.location',
  },
  profession: {
    defaultMessage: 'Profession',
    description: 'Profession',
    id: 'components.UserDetail.Profile.profession',
  },
  other: {
    defaultMessage: 'Other',
    description: 'Other',
    id: 'components.UserDetail.Profile.other',
  },
  acquisitionSource: {
    defaultMessage: 'Acquisition Source',
    description: 'Acquisition Source',
    id: 'components.UserDetail.Profile.acquisitionSource',
  },
});
