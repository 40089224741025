import { FormikErrors } from 'formik';
import { EmploymentStatus, LevelOfTrainingPhysician, UserRole } from '~Data';
import { FormValues } from './types';

const hasMissingFields = (section: { [key: string]: any }, errors: any) => {
  let fieldsMissing = false;
  Object.keys(section).forEach((key) => {
    const value = section[key];

    if (!value || value.length === 0 || errors[key]) {
      fieldsMissing = true;
    }
  });

  return fieldsMissing;
};

export const getMissingFields = (
  {
    firstName,
    lastName,
    phone,
    stateId,
    cityId,
    dateOfBirth,
    bio,
    degreeObtained,
    graduatingInstitution,
    availabilityMaxNumber,
    availabilityShifts,
    availabilityStartAt,
    jobTypeSeeking,
    EHRCompatibility,
    employmentHistory,
    employmentStatus,
    levelOfTraining,
    npi,
    profession,
    professionOther,
    providerType,
    specialty,
    yearLastPracticed,
  }: FormValues,
  userRole: UserRole,
  errors: FormikErrors<FormValues>,
) => {
  const requiredBasicValues = {
    firstName,
    lastName,
    phone,
    stateId,
    cityId,
    dateOfBirth,
    bio,
  };
  const requiredJobPreferenceValues = {
    availabilityMaxNumber,
    availabilityShifts,
    availabilityStartAt,
    jobTypeSeeking,
  };

  const requiredEducationValues = {
    graduatingInstitution,
    degreeObtained,
  };
  let requiredProfessionalValues: any = {
    employmentStatus,
  };

  if (userRole === UserRole.Other) {
    requiredProfessionalValues = {
      ...requiredProfessionalValues,
      profession,
    };

    if (profession === 'Other') {
      requiredProfessionalValues = {
        ...requiredProfessionalValues,
        professionOther,
      };
    }
  }

  if (userRole === UserRole.Physician) {
    requiredProfessionalValues = {
      ...requiredProfessionalValues,
      providerType,
      specialty,
    };
  }

  if ([UserRole.App, UserRole.Nurse].includes(userRole)) {
    requiredProfessionalValues = {
      ...requiredProfessionalValues,
      specialty,
    };
  }

  if (userRole !== UserRole.Other) {
    requiredProfessionalValues = {
      ...requiredProfessionalValues,
      levelOfTraining,
    };
  }

  if ([UserRole.App, UserRole.Nurse, UserRole.Physician].includes(userRole)) {
    requiredProfessionalValues = {
      ...requiredProfessionalValues,
      EHRCompatibility,
    };
    if (levelOfTraining && levelOfTraining !== LevelOfTrainingPhysician.MedStudent) {
      requiredProfessionalValues = {
        ...requiredProfessionalValues,
        npi,
      };
    }
  }

  if (employmentStatus && employmentStatus === EmploymentStatus.Retired) {
    requiredProfessionalValues = {
      ...requiredProfessionalValues,
      yearLastPracticed,
    };
  }

  if (employmentStatus && employmentStatus === EmploymentStatus.NotCurrentlyEmployed) {
    requiredProfessionalValues = {
      ...requiredProfessionalValues,
      employmentHistory,
    };
  }

  return {
    basicFieldsMissing: hasMissingFields(requiredBasicValues, errors),
    educationFieldsMissing: hasMissingFields(requiredEducationValues, errors),
    jobPreferenceFieldsMissing: hasMissingFields(requiredJobPreferenceValues, errors),
    professionalFieldsMissing: hasMissingFields(requiredProfessionalValues, errors),
  };
};
