import { MutationUpdaterFn } from '@apollo/client';
import {
  ApplyToJobMutation,
  ClinicDocument,
  ClinicQuery,
  ClinicQueryVariables,
  CreateClinicContactMutation,
  CurrentUserDocument,
  JobDocument,
  JobQuery,
  JobQueryVariables,
  SetPrimaryContactMutation,
  SignUpAndApplyMutation,
} from '~Data';
import { CurrentUserQuery, UploadCvMutation } from './generated';

const cacheUpdates = {
  applyToJob(jobId: string): MutationUpdaterFn<ApplyToJobMutation | SignUpAndApplyMutation> {
    return async (cache, { data }) => {
      try {
        if (data && ('applyToJob' in data || 'signUpAndApply' in data)) {
          const cacheData = cache.readQuery<JobQuery, JobQueryVariables>({
            query: JobDocument,
            variables: {
              id: jobId,
            },
          });

          if (cacheData?.job) {
            const { job } = cacheData;

            cache.writeQuery<JobQuery, JobQueryVariables>({
              data: {
                job: {
                  ...job,
                  applied: true,
                  applicationDate: new Date(),
                },
              },
              query: JobDocument,
              variables: {
                id: jobId,
              },
            });
          }
        }
      } catch (e) {
        console.info('Not updating store - cannot apply to job');
      }
    };
  },
  createClinicContact(clinicId: string): MutationUpdaterFn<CreateClinicContactMutation> {
    return async (cache, { data }) => {
      try {
        if (data?.createClinicContact) {
          const { createClinicContact: newContact } = data;
          const cacheData = cache.readQuery<ClinicQuery, ClinicQueryVariables>({
            query: ClinicDocument,
            variables: { id: clinicId },
          });
          if (cacheData?.clinic) {
            const {
              clinic: { contacts },
              clinic,
            } = cacheData;
            const newContacts = contacts ? [...contacts, newContact] : [newContact];
            const newClinicData = {
              ...clinic,
              contacts: newContacts,
            };
            cache.writeQuery<ClinicQuery, ClinicQueryVariables>({
              data: { clinic: newClinicData },
              query: ClinicDocument,
              variables: { id: clinicId },
            });
          }
        }
      } catch (e) {
        console.info('Not updating store - cannot insert clinic contact');
      }
    };
  },
  setPrimaryContact(
    clinicId: string,
    contactId: string,
  ): MutationUpdaterFn<SetPrimaryContactMutation> {
    return async (cache, { data }) => {
      try {
        if (data?.setPrimaryContact) {
          const { setPrimaryContact: successfullyUpdatedContact } = data;
          const cacheData = cache.readQuery<ClinicQuery, ClinicQueryVariables>({
            query: ClinicDocument,
            variables: { id: clinicId },
          });

          if (successfullyUpdatedContact && cacheData?.clinic) {
            const {
              clinic: { contacts },
              clinic,
            } = cacheData;
            const newContacts = contacts?.map((contact) => {
              return { ...contact, isPrimary: contact.id === contactId };
            });
            const newClinic = {
              ...clinic,
              contacts: newContacts,
            };
            cache.writeQuery<ClinicQuery, ClinicQueryVariables>({
              data: { clinic: newClinic },
              query: ClinicDocument,
              variables: { id: clinicId },
            });
          }
        }
      } catch (e) {
        console.info('Not updating store - cannot set isPrimary for clinic contact');
      }
    };
  },
  uploadCV(): MutationUpdaterFn<UploadCvMutation> {
    return async (cache, { data }) => {
      try {
        if (data?.uploadCV) {
          const { uploadCV } = data;

          if (uploadCV && uploadCV.profile.cv) {
            const {
              profile: { cv },
            } = uploadCV;

            const cacheData = cache.readQuery<CurrentUserQuery>({
              query: CurrentUserDocument,
            });

            if (cv && cacheData?.currentUser) {
              const { currentUser } = cacheData;

              cache.writeQuery<CurrentUserQuery>({
                data: {
                  currentUser: {
                    ...currentUser,
                    profile: {
                      ...currentUser.profile,
                      cv,
                    },
                  },
                },
                query: CurrentUserDocument,
              });
            }
          }
        }
      } catch (e) {
        console.info('Not updating store - cannot set cv for current user');
      }
    };
  },
};

export default cacheUpdates;
