import React, { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { Table, Select, Row, Col, Input } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import type { ColumnProps } from 'antd/lib/table';
import type { FilterDropdownProps } from 'antd/lib/table/interface';
import { defineMessages, useIntl } from 'react-intl';
import Heading from '@openloop/limbic/Heading';
import toast from '@openloop/limbic/Toast';

import messages from '~Constants/messages';
import { clinicRoutes } from '~Constants/routes';
import Link from '~Core/Link';
import SEO from '~Core/SEO';
import { useAdminAdminUsersLazyQuery, useSetClinicAccountManagerMutation } from '~Data/generated';
import { formatPhone } from '~Helpers/phoneNumber';

import { useClinicsQuery } from './ClinicList.generated';

import styles from './ClinicList.module.scss';

interface DataSourceItem {
  accountManagerId?: string | null;
  accountManager?: string | null;
  createdAt: string;
  key: string;
  name: string;
  numJobsActive: number;
  phone: string | null;
  primaryContactEmail: string | null;
  primaryContactName: string | null;
  primaryContactPhone: string | null;
}

const intlMessages = defineMessages({
  headingAccountManager: {
    defaultMessage: 'Account Manager',
    description: 'Column heading',
    id: 'Modules.admin.ClinicList.headingAccountManager',
  },
  headingActiveJobs: {
    defaultMessage: 'Active Jobs',
    description: 'Column heading',
    id: 'Modules.admin.ClinicList.headingActiveJobs',
  },
  headingCreated: {
    defaultMessage: 'Created',
    description: 'Column heading',
    id: 'Modules.admin.ClinicList.headingCreated',
  },
  headingName: {
    defaultMessage: 'Name',
    description: 'Column heading',
    id: 'Modules.admin.ClinicList.headingName',
  },
  headingPhone: {
    defaultMessage: 'Phone',
    description: 'Column heading',
    id: 'Modules.admin.ClinicList.headingPhone',
  },
  headingPrimaryContact: {
    defaultMessage: 'Primary Contact',
    description: 'Column heading',
    id: 'Modules.admin.ClinicList.headingPrimaryContact',
  },
  headingPrimaryContactEmail: {
    defaultMessage: 'Primary Contact Email',
    description: 'Column heading',
    id: 'Modules.admin.ClinicList.headingPrimaryContactEmail',
  },
  headingPrimaryContactPhone: {
    defaultMessage: 'Primary Contact Phone',
    description: 'Column heading',
    id: 'Modules.admin.ClinicList.headingPrimaryContactPhone',
  },
  linkCreateClinic: {
    defaultMessage: 'New Clinic',
    description: 'Link to create a new clinic',
    id: 'Modules.admin.ClinicList.linkCreateClinic',
  },
  pageSubTitle: {
    defaultMessage: `
        {itemCount, plural,
          =0 {# Clinics}
          one {# Clinic}
          other {# Clinics}
        }`,
    description: 'Total number of clinics',
    id: 'Modules.admin.ClinicList.pageSubTitle',
  },
  pageTitle: {
    defaultMessage: 'Clinics',
    description: 'Page title',
    id: 'Modules.admin.ClinicList.pageTitle',
  },
  pageTitleSeo: {
    defaultMessage: 'Clinics (Admin)',
    description: 'Browser page title',
    id: 'Modules.admin.ClinicList.pageTitleSeo',
  },
  placeholderAccountManagerSelect: {
    defaultMessage: 'Set Account Manager',
    description: 'Placeholder for account manager select',
    id: 'Modules.admin.ClinicList.placeholderAccountManagerSelect',
  },
  resetButtonLabel: {
    defaultMessage: 'Reset',
    description: 'Reset button label',
    id: 'Modules.admin.ClinicList.resetButtonLabel',
  },
  searchButtonLabel: {
    defaultMessage: 'Search',
    description: 'Search button label',
    id: 'Modules.admin.ClinicList.searchButtonLabel',
  },
});

const ClinicList = () => {
  const { formatDate, formatMessage } = useIntl();
  const { data, loading } = useClinicsQuery();
  const [fetchAdmins, { data: accountManagers, loading: accountManagersLoading }] =
    useAdminAdminUsersLazyQuery();

  const [setClinicAccountManager] = useSetClinicAccountManagerMutation({
    onCompleted: () => {
      toast({ message: formatMessage(messages.accountManagerUpdateSuccess), variant: 'success' });
    },
    onError: () => {
      toast({ message: formatMessage(messages.accountManagerUpdateError), variant: 'danger' });
    },
  });

  const accountManagersList = useMemo(() => {
    if (!accountManagers) {
      return [];
    }
    return accountManagers.adminAdminUsers.map(({ profile: { displayName, userId } }) => ({
      label: displayName,
      value: userId,
    }));
  }, [accountManagers]);

  const SearchFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    searchTerm,
  }: FilterDropdownProps & { searchTerm: string }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search by ${searchTerm}`}
        value={selectedKeys[0]}
        onChange={(e) => {
          if (setSelectedKeys) {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }
        }}
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <div className="ant-table-filter-dropdown-btns">
        <button
          type="button"
          disabled={selectedKeys.length === 0}
          onClick={clearFilters}
          className="ant-btn ant-btn-link ant-btn-sm"
        >
          {formatMessage(intlMessages.resetButtonLabel)}
        </button>
        <button
          type="button"
          onClick={() => confirm()}
          className="ant-btn ant-btn-primary ant-btn-sm"
        >
          {formatMessage(intlMessages.searchButtonLabel)}
        </button>
      </div>
    </div>
  );

  const columns = useMemo<ColumnProps<DataSourceItem>[]>(
    () => [
      {
        dataIndex: 'name',
        key: 'name',
        render: (name, { key: clinicId }) => (
          <Link to={generatePath(clinicRoutes.clinicDetail, { clinicId })} target="_blank">
            {name}
          </Link>
        ),
        sorter: ({ name: nameRawA }, { name: nameRawB }) => {
          const nameA = nameRawA.toLowerCase();
          const nameB = nameRawB.toLowerCase();
          if (nameA === nameB) {
            return 0;
          }
          return nameA < nameB ? -1 : 1;
        },
        title: formatMessage(intlMessages.headingName),
        onFilter: (value, rowData) => {
          const val = value.toString().toLowerCase();
          const rowName = rowData?.name?.toLowerCase();
          return rowName.includes(val);
        },
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <SearchFilterDropdown searchTerm="name" {...filterProps} />
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#ed0256' : undefined }} />
        ),
      },
      {
        dataIndex: 'accountManager',
        key: 'accountManager',
        title: formatMessage(intlMessages.headingAccountManager),
        filters:
          accountManagers?.adminAdminUsers.map(({ profile: { displayName, userId } }) => {
            return {
              text: displayName,
              value: userId,
            };
          }) || [],
        filterMultiple: false,
        onFilter: (value, record) => value === record.accountManagerId,
        onFilterDropdownVisibleChange: (visible) => {
          if (visible && !accountManagersLoading) {
            fetchAdmins();
          }
        },
        render: (_, clinic) => {
          return (
            <Select
              onClick={() => fetchAdmins()}
              onChange={(accountManagerId) => {
                setClinicAccountManager({
                  variables: {
                    input: {
                      clinicId: clinic.key,
                      accountManagerId,
                    },
                  },
                });
              }}
              defaultValue={clinic.accountManager || undefined}
              loading={accountManagersLoading}
              options={accountManagersList}
              placeholder={formatMessage(intlMessages.placeholderAccountManagerSelect)}
            />
          );
        },
      },
      {
        dataIndex: 'numJobsActive',
        key: 'numJobsActive',
        title: formatMessage(intlMessages.headingActiveJobs),
        sorter: ({ numJobsActive: jobsActiveA }, { numJobsActive: jobsActiveB }) => {
          if (jobsActiveA === jobsActiveB) {
            return 0;
          }
          return jobsActiveA < jobsActiveB ? -1 : 1;
        },
      },
      {
        dataIndex: 'phone',
        key: 'phone',
        title: formatMessage(intlMessages.headingPhone),
        onFilter: (value, rowData) => {
          const val = value.toString().toLowerCase();
          return rowData?.phone?.includes(val) || false;
        },
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <SearchFilterDropdown searchTerm="phone" {...filterProps} />
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#ed0256' : undefined }} />
        ),
      },
      {
        dataIndex: 'primaryContactName',
        key: 'primaryContactName',
        title: formatMessage(intlMessages.headingPrimaryContact),
        sorter: ({ primaryContactName: pContactA }, { primaryContactName: pContactB }) => {
          const nameA = pContactA?.toLowerCase() || '';
          const nameB = pContactB?.toLowerCase() || '';
          if (nameA === nameB) {
            return 0;
          }
          return nameA < nameB ? -1 : 1;
        },
        onFilter: (value, rowData) => {
          const val = value.toString().toLowerCase();
          const pContactName = rowData?.primaryContactName?.toLowerCase() || '';
          return pContactName.includes(val);
        },
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <SearchFilterDropdown searchTerm="contact" {...filterProps} />
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#ed0256' : undefined }} />
        ),
      },
      {
        dataIndex: 'primaryContactEmail',
        key: 'primaryContactEmail',
        title: formatMessage(intlMessages.headingPrimaryContactEmail),
        onFilter: (value, rowData) => {
          const val = value.toString().toLowerCase();
          const pContactEmail = rowData?.primaryContactEmail?.toLowerCase() || '';
          return pContactEmail.includes(val);
        },
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <SearchFilterDropdown searchTerm="email " {...filterProps} />
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#ed0256' : undefined }} />
        ),
      },
      {
        dataIndex: 'primaryContactPhone',
        key: 'primaryContactPhone',
        title: formatMessage(intlMessages.headingPrimaryContactPhone),
        onFilter: (value, rowData) => {
          const val = value.toString().toLowerCase();
          return rowData?.primaryContactPhone?.includes(val) || false;
        },
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <SearchFilterDropdown searchTerm="phone" {...filterProps} />
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#ed0256' : undefined }} />
        ),
      },
      {
        dataIndex: 'createdAt',
        key: 'createdAt',
        defaultSortOrder: 'descend',
        render: (createdAt) => formatDate(createdAt),
        sorter: ({ createdAt: createdAtA }, { createdAt: createdAtB }) =>
          new Date(createdAtA).getTime() - new Date(createdAtB).getTime(),
        title: formatMessage(intlMessages.headingCreated),
      },
    ],
    // Added next slint-disable line to not modify logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      accountManagers?.adminAdminUsers,
      accountManagersList,
      accountManagersLoading,
      fetchAdmins,
      formatDate,
      formatMessage,
      setClinicAccountManager,
    ],
  );

  const dataSource = useMemo(() => {
    if (!data) {
      return [];
    }
    const { clinics } = data;

    return clinics.map(
      ({ createdAt, id, name, phone, numJobsActive, accountManager, contacts }) => {
        const primaryContact = contacts ? contacts.find(({ isPrimary }) => !!isPrimary) : undefined;

        return {
          accountManager: accountManager ? accountManager.profile.displayName : undefined,
          accountManagerId: accountManager ? accountManager.profile.userId : undefined,
          createdAt,
          key: id,
          name,
          numJobsActive: numJobsActive || 0,
          phone: phone ? formatPhone(phone) : null,
          primaryContactName: primaryContact?.name || null,
          primaryContactEmail: primaryContact?.email || null,
          primaryContactPhone: primaryContact?.phone ? formatPhone(primaryContact.phone) : null,
        };
      },
    );
  }, [data]);

  return (
    <>
      <SEO title={formatMessage(intlMessages.pageTitleSeo)} />
      <Row justify="space-between" className={styles.headingRow}>
        <Col>
          <Heading level="h3" className={styles.pageTitle}>
            {formatMessage(intlMessages.pageTitle)}
          </Heading>
          <Heading level="h2" size="h4" variant="secondary" className={styles.counterText}>
            {data ? (
              formatMessage(intlMessages.pageSubTitle, { itemCount: data.clinics.length })
            ) : (
              <>&nbsp;</>
            )}
          </Heading>
        </Col>
        <Col>
          <Link buttonVariant="primary" icon={<PlusOutlined />} to={clinicRoutes.clinicCreate}>
            {formatMessage(intlMessages.linkCreateClinic)}
          </Link>
        </Col>
      </Row>
      <Table<DataSourceItem>
        className={styles.clinicListTable}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={{
          pageSizeOptions: ['10', '25', '50', '100', '200', '500', '1000'],
          position: ['bottomRight', 'topRight'],
        }}
      />
    </>
  );
};

export default ClinicList;
