import React, { useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { Formik } from 'formik';
import Button from '@openloop/limbic/Button';
import Grid from '@openloop/limbic/Grid';
import Heading from '@openloop/limbic/Heading';
import Icon from '@openloop/limbic/Icon';
import Modal from '@openloop/limbic/Modal';
import toast from '@openloop/limbic/Toast';
import Tooltip from '@openloop/limbic/Tooltip';
import MDEditor from '@uiw/react-md-editor';
import { useId } from '@reach/auto-id';

import { sharedRoutes } from '~Constants/routes';
import RichEditor from '~Core/RichEditor';
import useCurrentOrigin from '~Hooks/useCurrentOrigin';
import { usePrevious } from '~Hooks/usePrevious';

import {
  useCreateJobApplicationSharableProfileTokenMutation,
  useEditJobApplicationSharableProfileTokenMutation,
} from './SharingTokenModal.generated';

import styles from './SharingTokenModal.module.scss';

const { Row, Col } = Grid;

const intlMessages = defineMessages({
  modalAriaLabel: {
    defaultMessage: 'Sharing token modal',
    description: 'Sharing token modal aria label text',
    id: 'Modules.admin.UserDetail.SharingTokenModal.modalAriaLabel',
  },
  modalOkButtonCreate: {
    defaultMessage: 'Create Profile Link',
    description: 'Modal ok button text for creating a new sharing token',
    id: 'Modules.admin.UserDetail.SharingTokenModal.modalOkButtonCreate',
  },
  modalOkButtonUpdate: {
    defaultMessage: 'Update Profile Link',
    description: 'Modal ok button text for updating a sharing token',
    id: 'Modules.admin.UserDetail.SharingTokenModal.modalOkButtonUpdate',
  },
  modalHeading: {
    defaultMessage: `Top Reasons We Picked This Clinician for {clinicName}`,
    description: 'Modal heading text',
    id: 'Modules.admin.UserDetail.SharingTokenModal.modalHeading',
  },
  modalEditText: {
    defaultMessage: 'Edit info',
    description: 'Modal edit info text',
    id: 'Modules.admin.UserDetail.SharingTokenModal.modalEditText',
  },
  useSharingTokensText: {
    defaultMessage: 'Use sharing tokens to present candidates to hiring managers.',
    description: 'Sharing token use case text',
    id: 'Modules.admin.UserDetail.SharingTokenModal.useSharingTokensText',
  },
  copyText: {
    defaultMessage: 'Copy',
    description: 'text when able to copy link',
    id: 'Modules.admin.UserDetail.SharingTokenModal.coptText',
  },
  copiedText: {
    defaultMessage: 'Copied!',
    description: 'text when link has been copied',
    id: 'Modules.admin.UserDetail.SharingTokenModal.copiedText',
  },
  copyLinkText: {
    defaultMessage: 'copy link',
    description: 'button text to copy link',
    id: 'Modules.admin.UserDetail.SharingTokenModal.copyLinkText',
  },
  createdBy: {
    defaultMessage: 'Created: {createdAt} by {createdBy}',
    description: 'by who and when the token was created',
    id: 'Modules.admin.UserDetail.SharingTokenModal',
  },
  expires: {
    defaultMessage: 'Expires: {expiresAt}',
    description: 'Expiration data of token',
    id: 'Modules.admin.UserDetail.SharingTokenModal',
  },
});

interface FormValues {
  clientDescription: string;
}

interface SharingTokenModalProps {
  visible: boolean;
  clinicName: string;
  jobApplicationId: string;
  sharableProfileToken:
    | {
        clientDescription?: string | null;
        createdAt: Date;
        createdBy: string;
        expiresAt: Date;
        token: string;
      }
    | undefined;
  onCancel: () => void;
  onOkCallback?: () => void;
}

const SharingTokenModal = ({
  jobApplicationId,
  visible,
  clinicName,
  sharableProfileToken,
  onOkCallback,
  onCancel,
}: SharingTokenModalProps) => {
  const { clientDescription, createdAt, createdBy, expiresAt, token } = sharableProfileToken || {};
  const [isEditing, setIsEditing] = useState<boolean>(!sharableProfileToken?.token);

  const { formatDate, formatMessage } = useIntl();
  const richEditorId = useId()?.toString() || '';
  const currentOrigin = useCurrentOrigin();
  const [isCopied, setIsCopied] = useState(false);

  const prevIsCopied = usePrevious(isCopied);

  const [editJobApplicationSharableProfileToken, { loading: editLoading }] =
    useEditJobApplicationSharableProfileTokenMutation({
      onCompleted: () => {
        toast({ message: 'Sharing token updated successfully', variant: 'success' });
        if (onOkCallback) {
          onOkCallback();
        }
      },
      onError: () => {
        toast({ message: 'There was an error updating the sharing token', variant: 'danger' });
      },
    });

  const [createJobApplicationSharableProfileToken, { loading: createLoading }] =
    useCreateJobApplicationSharableProfileTokenMutation({
      onCompleted: () => {
        toast({ message: 'Sharing token created successfully', variant: 'success' });
        if (onOkCallback) {
          onOkCallback();
        }
      },
      onError: () => {
        toast({ message: 'There was an error creating the sharing token', variant: 'danger' });
      },
    });

  const reviewClinicianUrl = useMemo<string | undefined>(() => {
    if (!token) {
      return undefined;
    }
    const reviewClinicianPath = generatePath(sharedRoutes.reviewClinician, { tokenId: token });
    return `${currentOrigin}${reviewClinicianPath}`;
  }, [currentOrigin, token]);

  useEffect(() => {
    /**
     * Keep edit state in sync if the token is deleted after editing, then re-created
     */
    setIsEditing(!sharableProfileToken?.token);
  }, [sharableProfileToken?.token]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let timeout: number | undefined;
      if (isCopied && !prevIsCopied) {
        timeout = window.setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      }

      return () => window.clearTimeout(timeout);
    }
    return () => {};
  }, [isCopied, prevIsCopied]);

  const handleLinkCopy = () => {
    if (reviewClinicianUrl) {
      navigator.clipboard.writeText(reviewClinicianUrl);
      setIsCopied(true);
    }
  };

  const handleSubmit = (values: FormValues) => {
    if (sharableProfileToken?.token) {
      editJobApplicationSharableProfileToken({
        variables: {
          input: {
            jobApplicationId,
            clientDescription: values.clientDescription,
          },
        },
      });
    } else {
      createJobApplicationSharableProfileToken({
        variables: { input: { jobApplicationId, clientDescription: values.clientDescription } },
      });
    }
  };

  const loading = editLoading || createLoading;

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{ clientDescription: clientDescription || '' }}
      onSubmit={handleSubmit}
    >
      {({ resetForm, submitForm, values }) => (
        <Modal
          width="large"
          isVisible={visible}
          ariaLabel={formatMessage(intlMessages.modalAriaLabel)}
          showCancelButton={false}
          showFooter={isEditing}
          okButtonText={formatMessage(
            sharableProfileToken?.token
              ? intlMessages.modalOkButtonUpdate
              : intlMessages.modalOkButtonCreate,
          )}
          okButtonProps={{
            loading,
            variant: 'secondary',
          }}
          contentClassName={`${styles.modalWidth}`}
          onCancel={() => {
            onCancel();
            resetForm();
            setIsCopied(false);
            setIsEditing(false);
          }}
          onOk={() => {
            submitForm();
            setIsEditing(false);
          }}
        >
          <>
            <Heading level="h5" size="h5">
              {formatMessage(intlMessages.modalHeading, { clinicName })}
            </Heading>

            {isEditing ? (
              <div className={styles.modalbody}>
                <RichEditor name="clientDescription" id={richEditorId} />
              </div>
            ) : (
              <div className={styles.modalbody}>
                <div className={styles.viewModeBody}>
                  <MDEditor.Markdown data-color-mode="light" source={values.clientDescription} />
                </div>
                <div className={styles.buttonContainer}>
                  <Button
                    icon={<Icon name="Pencil" className={styles.editIcon} />}
                    className={styles.editText}
                    onClick={() => setIsEditing(true)}
                    variant="secondary-borderless"
                  >
                    {formatMessage(intlMessages.modalEditText)}
                  </Button>
                </div>
                <div className={styles.horizontalDivider} />
                <span className={styles.belowDividerContainer}>
                  <span className={styles.useSharingTokensText}>
                    {formatMessage(intlMessages.useSharingTokensText)}
                  </span>
                  {reviewClinicianUrl && (
                    <Row>
                      <Col className={styles.leftColumnContainer}>
                        <Row align="middle">
                          <span className={styles.link}>{reviewClinicianUrl}</span>
                          <Tooltip
                            tooltipPosition="top"
                            tooltipDescription={
                              isCopied
                                ? formatMessage(intlMessages.copiedText)
                                : formatMessage(intlMessages.copyText)
                            }
                          >
                            <Button
                              type="button"
                              variant="secondary-borderless"
                              className={`${styles.copyLinkButton} ${styles.removePadding}`}
                              onClick={handleLinkCopy}
                            >
                              {formatMessage(intlMessages.copyLinkText)}
                            </Button>
                          </Tooltip>
                        </Row>
                        {formatMessage(intlMessages.createdBy, {
                          createdAt: formatDate(createdAt, {
                            month: '2-digit',
                            day: 'numeric',
                            year: 'numeric',
                            minute: '2-digit',
                            hour: 'numeric',
                          }),
                          createdBy,
                        })}
                      </Col>
                      <Col>
                        <Row justify="end">
                          {formatMessage(intlMessages.expires, {
                            expiresAt: formatDate(expiresAt, {
                              month: '2-digit',
                              day: 'numeric',
                              year: 'numeric',
                            }),
                          })}
                        </Row>
                      </Col>
                    </Row>
                  )}
                </span>
              </div>
            )}
          </>
        </Modal>
      )}
    </Formik>
  );
};

export default SharingTokenModal;
