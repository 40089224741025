import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { adminRoutes, clinicRoutes, guestRoutes, providerRoutes } from '~Constants/routes';
import { useAuthContext } from '~Context/AuthContext';
import { providerRoles, UserRole } from '~Data';

interface Props {
  children: ReactNode;
  userTypesAllowed: Array<UserRole>;
}

const AuthGuard = ({ children, userTypesAllowed }: Props) => {
  const { currentUser, isAuthenticated } = useAuthContext();
  const location = useLocation();

  if (!isAuthenticated && userTypesAllowed.length > 0) {
    return <Navigate to={guestRoutes.login} state={{ from: location }} replace />;
  }
  if (currentUser && !userTypesAllowed.includes(currentUser.role)) {
    if (providerRoles.includes(currentUser.role)) {
      return <Navigate to={providerRoutes.dashboard} replace />;
    }
    if (currentUser.role === UserRole.Clinic) {
      return <Navigate to={clinicRoutes.dashboard} replace />;
    }
    if (currentUser.role === UserRole.Admin) {
      return <Navigate to={adminRoutes.dashboard} replace />;
    }
  }

  return <>{children}</>;
};

export default AuthGuard;
