import React, { ReactNode, useEffect } from 'react';
import * as FullStory from '@fullstory/browser';

import { useAuthContext } from '~Context/AuthContext';

interface Props {
  children: ReactNode;
}

const UserInsights = ({ children }: Props) => {
  const { currentUser } = useAuthContext();

  useEffect(() => {
    if (currentUser && import.meta.env.VITE_FULLSTORY_ORG_ID) {
      const {
        id,
        email,
        profile: { completion, displayName },
        role,
      } = currentUser;
      const commonAttributes = {
        displayName,
        email,
      };
      FullStory.identify(id, commonAttributes);
      FullStory.setUserVars({
        ...commonAttributes,
        profileCompletionPercentage: completion,
        role,
      });
    }
  }, [currentUser]);

  return <>{children}</>;
};

export default UserInsights;
