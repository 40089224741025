import React, { ReactNode } from 'react';
import Badge from '@openloop/limbic/Badge';
import Card from '@openloop/limbic/Card';
import Heading from '@openloop/limbic/Heading';
import Icon, { IconName } from '@openloop/limbic/Icon';

import styles from './SectionCard.module.scss';

interface Props {
  iconName?: IconName;
  children: ReactNode;
  title?: string;
  badgeCount?: number;
}

const SectionCard = ({ badgeCount, children, iconName, title }: Props) => {
  const titleContent = (() => {
    const baseContent = (title || iconName) && (
      <Heading level="h4">
        <span className={styles.headingContents}>
          {iconName && (
            <span>
              <Icon name={iconName} aria-hidden />
            </span>
          )}
          {title && <span>{title}</span>}
        </span>
      </Heading>
    );
    if (badgeCount) {
      return <Badge value={badgeCount}>{baseContent}</Badge>;
    }
    return baseContent;
  })();

  return (
    <Card className={styles.main} title={titleContent}>
      {children}
    </Card>
  );
};

export default SectionCard;
