import React, { useEffect } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import { ExclamationCircleOutlined, PoweroffOutlined } from '@ant-design/icons';
import Button from '@openloop/limbic/Button';
import { Modal, Typography } from 'antd';
import { useIntl } from 'react-intl';

import { clinicRoutes } from '~Constants/routes';
import { useDisableClinicDrChronoIntegrationMutation } from '~Data';
import { intlMessagesManagement } from './intlMessages';

const { Paragraph } = Typography;

interface Props {
  clinicId: string;
}

const DrChronoManagement = ({ clinicId }: Props) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [disableDrChronoIntegration, { data: disableData, loading: disableLoading }] =
    useDisableClinicDrChronoIntegrationMutation({ variables: { clinicId, input: { clinicId } } });

  useEffect(() => {
    if (disableData) {
      navigate(generatePath(clinicRoutes.clinicIntegrations, { clinicId }));
    }
  }, [clinicId, disableData, navigate]);

  const onDeleteClick = () => {
    const modalInstance = Modal.confirm({
      okButtonProps: {
        onClick: () => {
          disableDrChronoIntegration({ variables: { clinicId, input: { clinicId } } });
          modalInstance.destroy();
        },
        icon: <PoweroffOutlined aria-hidden />,
        loading: disableLoading,
      },
      okText: 'Disable DrChrono',
      autoFocusButton: 'cancel',
      icon: <ExclamationCircleOutlined aria-hidden />,
      content: (
        <>
          <Paragraph>{formatMessage(intlMessagesManagement.modalQuestion)}</Paragraph>
          <Paragraph>{formatMessage(intlMessagesManagement.modalDescription)}</Paragraph>
        </>
      ),
      title: 'Disable DrChrono Integration',
    });
  };

  return (
    <>
      <Paragraph>{formatMessage(intlMessagesManagement.paragraph)}</Paragraph>
      <Button onClick={onDeleteClick} variant="danger">
        {formatMessage(intlMessagesManagement.button)}
      </Button>
    </>
  );
};

export default DrChronoManagement;
