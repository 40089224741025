import React, { useCallback, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { Input, Table } from 'antd';
import type { ColumnProps } from 'antd/lib/table';
import Heading from '@openloop/limbic/Heading';
import { defineMessages, useIntl } from 'react-intl';
import type { FilterDropdownProps } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';

import { Button, Icon, Tooltip } from '@openloop/limbic';
import common, { yesNoStatusChoices } from '~Constants/common';
import { jobStatusChoices } from '~Constants/jobStatus';
import { clinicRoutes, adminRoutes, sharedRoutes } from '~Constants/routes';
import { userTypeChoices } from '~Constants/userTypes';
import Link from '~Core/Link';
import SEO from '~Core/SEO';
import { JobStatus, ProviderUserRole, UserRole } from '~Data';

import styles from './JobsList.module.scss';
import { useJobsQuery } from './JobsList.generated';

const intlMessages = defineMessages({
  headingClinic: {
    defaultMessage: 'Clinic',
    description: 'Column heading',
    id: 'Modules.admin.JobsList.headingClinic',
  },
  headingClinicianType: {
    defaultMessage: 'Clinician Type',
    description: 'Column heading',
    id: 'Modules.admin.JobsList.headingClinicianType',
  },
  headingStatus: {
    defaultMessage: 'Status',
    description: 'Column heading',
    id: 'Modules.admin.JobsList.headingStatus',
  },
  headingTitle: {
    defaultMessage: 'Title',
    description: 'Column heading',
    id: 'Modules.admin.JobsList.headingTitle',
  },
  pageSubTitle: {
    defaultMessage: `
        {itemCount, plural,
          =0 {# Jobs}
          one {# Job}
          other {# Jobs}
        }`,
    description: 'Total number of jobs',
    id: 'Modules.admin.JobsList.pageSubTitle',
  },
  pageTitle: {
    defaultMessage: 'Jobs',
    description: 'Page title',
    id: 'Modules.admin.JobsList.pageTitle',
  },
  pageTitleSeo: {
    defaultMessage: 'Jobs (Admin)',
    description: 'Browser page title',
    id: 'Modules.admin.JobsList.pageTitleSeo',
  },
  headingCandidates: {
    defaultMessage: 'Candidates',
    description: 'Column heading',
    id: 'Modules.admin.JobsList.headingCandidates',
  },
  headingIsInternal: {
    defaultMessage: 'Shared to Indeed',
    description: 'Column heading',
    id: 'Modules.admin.JobsList.headingIsInternal',
  },
  tooltipEditJob: {
    defaultMessage: 'Edit Job',
    description: 'Tooltip',
    id: 'Modules.admin.JobsList.tooltipEditJob',
  },
  resetButtonLabel: {
    defaultMessage: 'Reset',
    description: 'Reset button label',
    id: 'Modules.admin.JobsList.resetButtonLabel',
  },
  searchButtonLabel: {
    defaultMessage: 'Search',
    description: 'Search button label',
    id: 'Modules.admin.JobsList.searchButtonLabel',
  },
  viewCandidatesLinkLabel: {
    defaultMessage: 'View Candidates',
    description: 'View Candidates button label',
    id: 'Modules.admin.JobsList.viewCandidatesLinkLabel',
  },
});

interface DataSourceItem {
  clinicId: string;
  clinicianType: UserRole | null | undefined;
  clinicName: string;
  key: string;
  status: JobStatus;
  title: string;
  candidates: string;
  isInternal: boolean;
}

const JobsList = () => {
  const { formatMessage } = useIntl();
  const { data, loading } = useJobsQuery();
  const [jobsCounta, setJobsCounta] = useState(data?.jobs.length || 0);
  const [buttonLabel, setButtonLabel] = useState('Copy URL');
  const [copiedIndex, setCopiedIndex] = useState<Array<number>>([]);

  const SearchFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    searchTerm,
  }: FilterDropdownProps & { searchTerm: string }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search by ${searchTerm}`}
        value={selectedKeys[0]}
        onChange={(e) => {
          if (setSelectedKeys) {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }
        }}
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <div className="ant-table-filter-dropdown-btns">
        <button
          type="button"
          disabled={selectedKeys.length === 0}
          onClick={clearFilters}
          className="ant-btn ant-btn-link ant-btn-sm"
        >
          {formatMessage(intlMessages.resetButtonLabel)}
        </button>
        <button
          type="button"
          onClick={() => confirm()}
          className="ant-btn ant-btn-primary ant-btn-sm"
        >
          {formatMessage(intlMessages.searchButtonLabel)}
        </button>
      </div>
    </div>
  );

  const currentOrigin = useMemo(() => {
    if (window) {
      return window.location.origin;
    }
    return common.appBaseUrl;
  }, []);

  const copyJobLink = useCallback(
    (jobId: string, index: number) => {
      navigator.clipboard.writeText(`${currentOrigin}/jobs/${jobId}`);
      setCopiedIndex([index]);
      setButtonLabel('Copied!');
      setTimeout(() => {
        setButtonLabel('Copy URL');
      }, 5000);
    },
    [currentOrigin],
  );

  const columns = useMemo<ColumnProps<DataSourceItem>[]>(
    () => [
      {
        dataIndex: 'title',
        key: 'title',
        render: (text, { key: jobId, clinicId }, index) => {
          return (
            <span className={styles.titleCell}>
              <Tooltip tooltipDescription={!copiedIndex.includes(index) ? 'Copy URL' : buttonLabel}>
                <Button
                  className={styles.copyButton}
                  variant="secondary-borderless"
                  icon={<Icon name="Copy" />}
                  onClick={() => copyJobLink(jobId, index)}
                />
              </Tooltip>
              <Tooltip tooltipDescription={formatMessage(intlMessages.tooltipEditJob)}>
                <Link
                  data-test="jobs-list-edit-job-link"
                  to={generatePath(clinicRoutes.clinicJobEdit, { clinicId, jobId })}
                >
                  <Button
                    className={styles.copyButton}
                    variant="secondary-borderless"
                    icon={<Icon name="Edit" />}
                  />
                </Link>
              </Tooltip>
              <Link to={generatePath(sharedRoutes.jobDescription, { jobId })} target="_blank">
                {text}
              </Link>
            </span>
          );
        },
        title: formatMessage(intlMessages.headingTitle),
        sorter: ({ title: titleRawA }, { title: titleRawB }) => {
          const nameA = titleRawA.toLowerCase();
          const nameB = titleRawB.toLowerCase();
          if (nameA === nameB) {
            return 0;
          }
          return nameA < nameB ? -1 : 1;
        },
        onFilter: (value, rowData) => {
          const val = value.toString().toLowerCase();
          const clinicNameVal = rowData?.title?.toLowerCase();
          return clinicNameVal.includes(val);
        },
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <SearchFilterDropdown searchTerm="title" {...filterProps} />
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#ed0256' : undefined }} />
        ),
      },
      {
        dataIndex: 'clinicianType',
        key: 'clinicianType',
        render: (text: ProviderUserRole | null | undefined) => (text ? userTypeChoices[text] : ''),
        title: formatMessage(intlMessages.headingClinicianType),
        filterMultiple: true,
        onFilter: (value, record) => record.clinicianType?.includes(value.toString()) || false,
        filters: Object.entries(UserRole)
          ?.filter((item) => item[1] !== UserRole.Admin && item[1] !== UserRole.Clinic)
          .map(([value, label]) => {
            return {
              text: label,
              value,
            };
          }),
      },
      {
        dataIndex: 'status',
        key: 'status',
        render: (text: JobStatus) => jobStatusChoices[text],
        title: formatMessage(intlMessages.headingStatus),
        filterMultiple: true,
        onFilter: (value, record) => record.status.includes(value.toString()),
        filters: Object.entries(jobStatusChoices).map(([value, label]) => ({
          text: label,
          value,
        })),
      },
      {
        dataIndex: 'isInternal',
        key: 'isInternal',
        render: (value: boolean) => yesNoStatusChoices[value ? 'Yes' : 'No'],
        title: formatMessage(intlMessages.headingIsInternal),
        filterMultiple: true,
        sorter: ({ isInternal: isInternalA }, { isInternal: isInternalB }) => {
          if (isInternalA === isInternalB) {
            return 1;
          }
          return -1;
        },
        onFilter: (value, record) => record.isInternal === (value === yesNoStatusChoices.Yes),
        filters: Object.entries(yesNoStatusChoices).map(([value, label]) => ({
          text: label,
          value,
        })),
      },
      {
        dataIndex: 'clinicName',
        key: 'clinicName',
        render: (text, { clinicId }) => (
          <Link to={generatePath(clinicRoutes.clinicDetail, { clinicId })}>{text}</Link>
        ),
        title: formatMessage(intlMessages.headingClinic),
        sorter: ({ clinicName: clinicNameA }, { clinicName: clinicNameB }) => {
          const nameA = clinicNameA.toLowerCase();
          const nameB = clinicNameB.toLowerCase();
          if (nameA === nameB) {
            return 0;
          }
          return nameA < nameB ? -1 : 1;
        },
        onFilter: (value, rowData) => {
          const val = value.toString().toLowerCase();
          const clinicNameVal = rowData?.clinicName?.toLowerCase();
          return clinicNameVal.includes(val);
        },
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <SearchFilterDropdown searchTerm="clinic" {...filterProps} />
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? '#ed0256' : undefined }} />
        ),
      },
      {
        dataIndex: 'candidates',
        key: 'candidates',
        render: (_, { key: jobId }) => (
          <Link to={`${adminRoutes.users}?userAppliedToJobIds=${jobId}`}>
            {formatMessage(intlMessages.viewCandidatesLinkLabel)}
          </Link>
        ),
        title: formatMessage(intlMessages.headingCandidates),
      },
    ],
    // Added next slint-disable line to not modify logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [copiedIndex, copyJobLink, buttonLabel, formatMessage],
  );

  const dataSource = useMemo<DataSourceItem[]>(() => {
    if (!data) {
      return [];
    }
    return data.jobs.map(
      ({
        clinicianType,
        clinic: { id: clinicId, name: clinicName },
        id: key,
        status,
        title,
        isInternal,
      }) => {
        setJobsCounta(data.jobs.length);
        return {
          clinicId,
          clinicianType,
          clinicName,
          key,
          status,
          title,
          candidates: key,
          isInternal,
        };
      },
    );
  }, [data]);

  return (
    <>
      <SEO title={formatMessage(intlMessages.pageTitleSeo)} />
      <div>
        <div className={styles.headingRow}>
          <Heading level="h3" className={styles.pageTitle}>
            {formatMessage(intlMessages.pageTitle)}
          </Heading>
          <Heading level="h2" size="h4" variant="secondary" className={styles.counterText}>
            {data ? (
              formatMessage(intlMessages.pageSubTitle, { itemCount: jobsCounta })
            ) : (
              <>&nbsp;</>
            )}
          </Heading>
        </div>
        <Table
          onChange={(...args) => {
            setJobsCounta(args[3].currentDataSource.length);
          }}
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          className={styles.jobsListTable}
          pagination={{
            pageSizeOptions: ['10', '25', '50', '100', '200', '500', '1000'],
            position: ['bottomRight', 'topRight'],
          }}
        />
      </div>
    </>
  );
};

export default JobsList;
