import React, { KeyboardEvent } from 'react';
import classNames from 'classnames/bind';
import { NavLink, useMatch } from 'react-router-dom';

import Button from '@openloop/limbic/Button';
import Icon from '@openloop/limbic/Icon/Icon';
import { MenuItemType } from '~Components/Navigation';

import styles from './MenuLi.module.scss';

const cx = classNames.bind(styles);

interface MenuLiProps extends MenuItemType {
  navLinkOnClick?: () => void;
  handleKeydown?: (event: KeyboardEvent<any>, menuItem: MenuItemType) => void;
  liClassName?: string;
  buttonClassName?: string;
  anchorClassName?: string;
}

export const MenuLi = ({
  title,
  icon,
  route,
  onClick,
  navLinkOnClick,
  handleKeydown,
  liClassName,
  buttonClassName,
  anchorClassName,
}: MenuLiProps) => {
  const activePath = useMatch({ path: `${route}`, end: true });

  return (
    <li className={cx(styles.menuItem, activePath && styles.active, liClassName)} role="menuitem">
      {route ? (
        <NavLink
          to={route}
          onClick={navLinkOnClick ? () => navLinkOnClick() : undefined}
          onKeyDown={handleKeydown ? () => handleKeydown : undefined}
          className={cx(styles.navLi, anchorClassName)}
        >
          {icon && <Icon className={styles.menuItemIcon} name={icon} ariaHidden />}
          {title}
        </NavLink>
      ) : (
        <Button
          variant="primary-borderless"
          icon={icon && <Icon name={icon} ariaHidden />}
          onClick={onClick}
          onKeyDown={handleKeydown ? () => handleKeydown : undefined}
          className={cx(styles.buttonLi, buttonClassName)}
        >
          {title}
        </Button>
      )}
    </li>
  );
};
