import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  ctaCreateAccountClinician: {
    defaultMessage: 'Need an Account?',
    description: 'Call-to-action text for creating a clinician account',
    id: 'Modules.guest.Login.ctaCreateAccountClinician',
  },
  formInputLabelEmail: {
    defaultMessage: 'Email',
    description: 'Label & placeholder for email input',
    id: 'Modules.guest.Login.formInputLabelEmail',
  },
  formInputLabelPassword: {
    defaultMessage: 'Password',
    description: 'Label & placeholder for password input',
    id: 'Modules.guest.Login.formInputLabelPassword',
  },
  linkForgotPassword: {
    defaultMessage: 'Forgot Password?',
    description: 'Forgot password link',
    id: 'Modules.guest.Login.linkForgotPassword',
  },
  linkRegisterClinician: {
    defaultMessage: 'Register as a Clinician',
    description: 'Clinician registration link',
    id: 'Modules.guest.Login.linkRegisterClinician',
  },
  metaTitle: {
    defaultMessage: 'OpenLoop Clinician Login',
    description: 'Page title used in `head` metadata (used for SEO)',
    id: 'Modules.guest.Login.metaTitle',
  },
  metaDescription: {
    defaultMessage: 'Login to your OpenLoop clinician account.',
    description: 'Page description used in `head` metadata (used for SEO)',
    id: 'Modules.guest.Login.metaDescription',
  },
  pageHeading: {
    defaultMessage: 'Log In',
    description: 'Page heading',
    id: 'Modules.guest.Login.pageHeading',
  },
  healing: {
    defaultMessage: 'Healing',
    description: 'Healing',
    id: 'components.guest.Login.healing',
  },
  anywhere: {
    defaultMessage: 'Anywhere',
    description: 'Anywhere',
    id: 'components.guest.Login.anywhere',
  },
});
