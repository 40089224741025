import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { IntlProvider } from 'react-intl';
import { HelmetProvider } from 'react-helmet-async';
import LimbicProvider from '@openloop/limbic/LimbicContext';

import BackToTopButton from '~Components/BackToTopButton';
import AuthProvider from '~Context/AuthContext';
import ViewportProvider from '~Context/ViewportContext';
import apolloClient from '~Data/apolloClient';
import enMessages from '~I18n/locales/en';

import ScrollToTop from './ScrollToTop';
import AdminBar from './AdminBar';
import Analytics from './Analytics';
import AppRouter from './AppRouter';
import UserInsights from './UserInsights';

const App = () => (
  <IntlProvider locale="en" defaultLocale="en" messages={enMessages}>
    <BrowserRouter>
      <Analytics>
        <ApolloProvider client={apolloClient}>
          <LimbicProvider>
            <AuthProvider>
              <ViewportProvider>
                <UserInsights>
                  <HelmetProvider>
                    <ScrollToTop />
                    <AdminBar />
                    <AppRouter />
                  </HelmetProvider>
                </UserInsights>
              </ViewportProvider>
            </AuthProvider>
          </LimbicProvider>
        </ApolloProvider>
      </Analytics>
    </BrowserRouter>
    <BackToTopButton />
  </IntlProvider>
);

export default App;
