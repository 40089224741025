import React, { ComponentProps } from 'react';
import { Checkbox, Row, Col } from 'antd';
import { useIntl } from 'react-intl';

import Paragraph from '~Core/Paragraph';
import { AvailabilityShifts as AvailabilityShiftsType, Shift } from '~Data';
import { capitalise } from '~Helpers/strings';

import styles from './AvailabilityShits.module.less';
import { intlMessages } from './intlMessages';

interface Props {
  availabilityShifts: AvailabilityShiftsType;
}

const dayColumnSpan: ComponentProps<typeof Col>['span'] = 4;

const shiftColumnSpan: ComponentProps<typeof Col>['span'] = 4;

const AvailabilityShifts = ({ availabilityShifts }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.main}>
      <Paragraph>{formatMessage(intlMessages.willToWork)}:</Paragraph>
      <Row>
        <Col offset={dayColumnSpan} span={24 - dayColumnSpan}>
          <Row>
            <Col span={shiftColumnSpan}>{formatMessage(intlMessages.day)}</Col>
            <Col span={shiftColumnSpan}>{formatMessage(intlMessages.evening)}</Col>
            <Col span={shiftColumnSpan}>{formatMessage(intlMessages.night)}</Col>
            <Col span={shiftColumnSpan}>24hr</Col>
          </Row>
        </Col>
      </Row>
      {availabilityShifts
        ? Object.entries(availabilityShifts)
            .filter(([key]) => key !== '__typename')
            .map(([key, value], index) => {
              return (
                <Row key={`checkbox__${index}`}>
                  <Col span={dayColumnSpan}>
                    <Paragraph>{capitalise(key)}</Paragraph>
                  </Col>
                  <Col span={24 - dayColumnSpan}>
                    <Row>
                      <Col span={shiftColumnSpan}>
                        <Checkbox checked={(value || []).includes(Shift.Day)} disabled />
                      </Col>
                      <Col span={shiftColumnSpan}>
                        <Checkbox checked={(value || []).includes(Shift.Evening)} disabled />
                      </Col>
                      <Col span={shiftColumnSpan}>
                        <Checkbox checked={(value || []).includes(Shift.Night)} disabled />
                      </Col>
                      <Col span={shiftColumnSpan}>
                        <Checkbox checked={(value || []).includes(Shift.Full)} disabled />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })
        : null}
    </div>
  );
};

export default AvailabilityShifts;
