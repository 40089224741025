import React from 'react';
import { Link as ReactRouterLink, LinkProps, useNavigate } from 'react-router-dom';
import Button, { ButtonProps } from '@openloop/limbic/Button';

import styles from './Link.module.less';

interface ButtonStyleProps extends Omit<ButtonProps, 'variant' | 'type'> {
  buttonVariant?: ButtonProps['variant'];
}

interface LinkStyleProps extends Partial<Record<keyof ButtonStyleProps, never>> {
  type?: 'cta';
}

type BaseProps = (ButtonStyleProps | LinkStyleProps) & {
  to: string;
};

type Props = Omit<LinkProps, 'component'> & BaseProps;

const Link = ({ buttonVariant, children, className: classNameProp, type, ...rest }: Props) => {
  const navigate = useNavigate();

  const onLinkClick = () => {
    navigate(rest.to);
  };

  const LinkElement = buttonVariant
    ? (props: ButtonProps) => (
        <Button onClick={onLinkClick} variant={buttonVariant} {...props} {...rest}>
          {children}
        </Button>
      )
    : ReactRouterLink;

  return (
    // @TODO via OPE-245
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <LinkElement className={`${styles.type} ${classNameProp}`} {...rest}>
      {children}
    </LinkElement>
  );
};

export default Link;
