import React from 'react';

import Select, { SelectProps } from '@openloop/limbic/Form/Select';

import { userTypeChoices } from '~Constants/userTypes';

interface Props extends Omit<SelectProps, 'options'> {}

const clinicianTypeOptions = Object.entries(userTypeChoices).map(([value, label]) => ({
  value,
  label,
}));

const ClinicianTypeSelect = ({ placeholder = 'Choose clinician type', ...rest }: Props) => (
  <Select placeholder={placeholder} options={clinicianTypeOptions} {...rest} />
);

export default ClinicianTypeSelect;
