import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  patientId: {
    defaultMessage: 'Patient ID',
    description: 'Patient ID label',
    id: 'components.provider.Patients.PatientIdModal.patientId',
  },
  close: {
    defaultMessage: 'Close',
    description: 'Close label',
    id: 'components.provider.Patients.PatientIdModal.closeId',
  },
});
