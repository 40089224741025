import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Icon, Table, TableProps, TableTypes } from '@openloop/limbic';
import camelcase from 'camelcase';

import Empty from '~Components/Empty';
import IntlDate from '~Components/IntlDate';
import StatusBadge from '~Components/StatusBadge';
import { useAuthContext } from '~Context/AuthContext';
import Link from '~Core/Link';
import { Job } from '~Data';

import SectionCard from '../SectionCard';
import { getJobStatus } from './utils';
import { ApplicationStatus } from './types';
import { intlMessages } from './intlMessages';

import styles from './JobApplications.module.scss';

type Data = {
  job: {
    id: Job['id'];
    title: Job['title'];
  };
  status: ApplicationStatus | 'Suggested';
  applied: Date | null;
  update: Date;
};

type Columns = TableProps<Data>['columns'];

const JobsApplications = () => {
  const { currentUser } = useAuthContext();

  const { formatMessage } = useIntl();

  const columns = useMemo<Columns>(
    () => [
      {
        Header: formatMessage(intlMessages.jobTitleTableHeader),
        accessor: 'job',
        Cell: ({
          cell: {
            value: { id: jobId, title: jobTitle },
          },
        }: TableTypes.CellProps<Data, Data['job']>) => (
          <span className={styles.link}>
            <Link
              // need inline style b/c of zillion a {} rules in app
              style={{ color: '#2469FF' }}
              to={`/jobs/${jobId}`}
              target="_blank"
              rel="noreferrer"
            >
              <Icon className={styles.linkIcon} name="ExternalLink" size="smallest" />
              {jobTitle}
            </Link>
          </span>
        ),
      },
      {
        Header: formatMessage(intlMessages.jobStatusTableHeader),
        accessor: 'status',
        Cell: ({ cell: { value: status } }: TableTypes.CellProps<Data, Data['status']>) => {
          return status !== 'Suggested' ? (
            <StatusBadge
              iconType={styles[camelcase(status)]}
              text={<span className={styles.statusText}>{status}</span>}
            />
          ) : (
            <StatusBadge
              iconType={styles.suggested}
              text={
                <span className={styles.statusText}>{formatMessage(intlMessages.suggested)}</span>
              }
            />
          );
        },
      },
      {
        Header: formatMessage(intlMessages.jobUpdateTableHeader),
        accessor: 'update',
        Cell: ({ cell: { value: update } }: TableTypes.CellProps<Data, Data['update']>) => (
          <IntlDate date={update} />
        ),
      },
      {
        Header: formatMessage(intlMessages.jobAppliedTableHeader),
        accessor: 'applied',
        Cell: ({ cell: { value: applied } }: TableTypes.CellProps<Data, Data['applied']>) => {
          return applied ? <IntlDate date={applied} /> : <></>;
        },
      },
    ],
    [formatMessage],
  );

  if (!currentUser) {
    return null;
  }

  const {
    jobSuggestions,
    jobApplications,
    profile: { networkStatus },
  } = currentUser;
  const jobAppsIds: string[] = [];
  const jobApps: Data[] = jobApplications
    ? jobApplications.map(
        ({
          jobId,
          createdAt: applicationDate,
          job: { title: jobTitle, status: jobStatus },
          latestRuling: latestRulingEvent,
        }) => {
          jobAppsIds.push(jobId);
          return {
            job: {
              id: jobId,
              title: jobTitle,
            },
            applied: new Date(applicationDate),
            jobTitle,
            status: getJobStatus({
              latestRulingDecision: latestRulingEvent?.decision || undefined,
              jobStatus,
              networkStatus,
            }),
            update: new Date(latestRulingEvent?.createdAt || applicationDate),
          };
        },
      )
    : [];
  const jobSuggest: Data[] = jobSuggestions
    ? jobSuggestions
        .filter((jobSuggestion) => !jobAppsIds.includes(jobSuggestion.jobId))
        .map(({ createdAt, jobId, job: { title: jobTitle } }) => ({
          job: {
            id: jobId,
            title: jobTitle,
          },
          status: 'Suggested',
          update: createdAt,
          applied: null,
        }))
    : [];
  const data: Data[] = [...jobApps, ...jobSuggest];
  return (
    <SectionCard title={formatMessage(intlMessages.title)}>
      {data.length > 0 ? (
        <Table<Data> columns={columns} data={data} />
      ) : (
        <Empty
          description={formatMessage(intlMessages.bodyNoJobApplications)}
          title={formatMessage(intlMessages.titleNoJobApplications)}
        />
      )}
    </SectionCard>
  );
};

export default JobsApplications;
