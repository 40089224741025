import React from 'react';
import { List, Typography } from 'antd';

import { externalRoutes } from '~Constants/routes';
import { useClinicIntegrationsDrChronoOfficesQuery } from '~Data';

const { Text } = Typography;

interface Props {
  clinicId: string;
}

const getBookingUrl = (clinicId: string, officeId: string): string =>
  `${externalRoutes.integrationsDomain}/${clinicId}/drChrono/${officeId}`;

const DrChronoDetails = ({ clinicId }: Props) => {
  const { data: officesData, loading: officesLoading } = useClinicIntegrationsDrChronoOfficesQuery({
    variables: { clinicId },
  });

  const offices = officesData?.clinic.integrations?.drChrono?.offices || [];

  return (
    <>
      <List
        dataSource={offices}
        loading={officesLoading}
        renderItem={({ id: officeId, name }) => {
          const bookingUrl = getBookingUrl(clinicId, officeId);
          return (
            <List.Item>
              <List.Item.Meta
                description={
                  <>
                    Booking Link:{' '}
                    <Text copyable={{ text: bookingUrl }}>
                      <a href={bookingUrl} rel="noopener noreferrer" target="_blank">
                        {bookingUrl}
                      </a>
                    </Text>
                  </>
                }
                title={<Text strong>{name}</Text>}
              />
            </List.Item>
          );
        }}
      />
    </>
  );
};

export default DrChronoDetails;
