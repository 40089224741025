import React from 'react';
import { Button, Card, Heading } from '@openloop/limbic';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import { adminRoutes } from '~Constants/routes';
import { InviteClinicianValues, SuggestJobsValues } from '../types';

import styles from '../InviteAndSuggest.module.scss';
import pageStyles from './ConfirmationScreens.module.scss';
import LoadingScreen from '~Modules/shared/LoadingScreen';
import { intlSuccessMessages } from './intlMessages';

const cx = classNames.bind(styles);

interface Props {
  flowType: 'invite' | 'inviteAndSuggest' | 'suggest';
  isLoading?: boolean;
}

export const SuccessScreen = ({ flowType, isLoading }: Props) => {
  const { formatMessage } = useIntl();
  type FormValuesType = Props['flowType'] extends 'suggest'
    ? SuggestJobsValues
    : InviteClinicianValues;
  const navigate = useNavigate();
  const {
    values: { firstName, lastName },
  } = useFormikContext<FormValuesType>();

  return (
    <div className={styles.main}>
      <Card className={styles.card}>
        <div className={styles.cardInnerContainer}>
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <>
              <Heading level="h2" size="h3" className={styles.heading}>
                {formatMessage(intlSuccessMessages.sentTitle)}!
              </Heading>
              <div>
                <p className={cx(pageStyles.sentText, styles.bold)}>
                  {formatMessage(intlSuccessMessages.sentDescription)} {`${firstName} ${lastName}`}.{' '}
                </p>
                {flowType === 'invite' && (
                  <p className={pageStyles.sentText}>
                    {formatMessage(intlSuccessMessages.inviteItem1)}
                  </p>
                )}
                {flowType === 'inviteAndSuggest' && (
                  <>
                    <p className={pageStyles.sentText}>
                      {formatMessage(intlSuccessMessages.inviteAndSuggestItem1)}
                    </p>
                    <p className={pageStyles.sentText}>
                      {formatMessage(intlSuccessMessages.inviteAndSuggestItem2)}{' '}
                    </p>
                  </>
                )}
                {flowType === 'suggest' && (
                  <>
                    <p className={pageStyles.sentText}>
                      {formatMessage(intlSuccessMessages.suggestItem1)}
                    </p>
                    <p className={pageStyles.sentText}>
                      {formatMessage(intlSuccessMessages.suggestItem2)}{' '}
                    </p>
                  </>
                )}
              </div>
              <Button
                className={cx(styles.button, styles.singleButton)}
                onClick={() => navigate(adminRoutes.inviteAndSuggest)}
              >
                {formatMessage(intlSuccessMessages.backButton)}
              </Button>
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

export default SuccessScreen;
