import React, { ReactNode } from 'react';
import { useId } from '@reach/auto-id';

import Icon, { Icons } from '~Core/Icon';
import useClassNames from '~Hooks/useClassNames';

import RadioType from './RadioType';
import { DirectionVariant, MarginVariant } from '../Types';

import styles from './RadioItem.module.less';

interface Props {
  children: ReactNode;
  direction?: DirectionVariant;
  value: string;
  icon?: ReactNode;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  currentValue?: string;
  name?: string;
  type?: RadioType;
  labelDirection?: DirectionVariant;
  margin?: MarginVariant;
  disabled?: boolean;
  className?: string;
}

const RadioItem = ({
  children,
  value,
  icon,
  onChange,
  currentValue,
  direction,
  name,
  type = 'default',
  labelDirection = 'horizontal',
  margin = 'default',
  disabled,
  className,
}: Props) => {
  const isChecked = currentValue === value;
  const itemId = useId()?.toString();

  const classNames = useClassNames(styles, className, {
    direction,
    isChecked,
    type,
    margin,
    disabled,
  });

  const radioLabel = icon ? (
    <div
      className={`${styles.radioLabel} ${
        labelDirection === 'vertical' && styles.radioLabelVertical
      }`}
    >
      {isChecked ? (
        <Icon aria-hidden className={styles.checkIcon} name={Icons.CheckCircleOutlined} />
      ) : (
        icon
      )}
      <span>{children}</span>
    </div>
  ) : (
    <span className={styles.radioLabel}>{children}</span>
  );

  return (
    <label className={classNames} htmlFor={itemId}>
      <input
        id={itemId}
        type="radio"
        name={name}
        checked={isChecked}
        value={value}
        disabled={disabled}
        onChange={onChange}
        className={styles.radioInput}
      />
      {radioLabel}
    </label>
  );
};

export default RadioItem;
