import React from 'react';
import { useParams, Outlet, useLocation, generatePath } from 'react-router-dom';
import { Menu, Spin, Row, Col } from 'antd';
import { useIntl } from 'react-intl';
import Heading from '@openloop/limbic/Heading';

import ClinicianAdvocatesSelect from '~Components/ClinicianAdvocateSelect';
import NetworkStatusSelect from '~Components/NetworkStatusSelect';
import { adminRoutes } from '~Constants/routes';
import NavLink from '~Core/NavLink';
import SEO from '~Core/SEO';
import { useAdminUserQuery } from '~Data';
import { FeatureFlag, useFeatureFlag } from '~Hooks/useFeatureFlag';
import { intlMessages } from './intlMessages';

import styles from './UserDetail.module.scss';

const UserDetail = () => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const { userId } = useParams<{ userId: string }>();

  const isSharingTokenV2Enabled = useFeatureFlag(FeatureFlag.SharingTokensV2);

  const { data, error, loading } = useAdminUserQuery({ variables: { userId } });

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <h1>{formatMessage(intlMessages.errorMessage)}</h1>;
  }

  if (!data) {
    return null;
  }

  const {
    adminUser: {
      clinicianAdvocateId,
      profile: { networkStatus, completion, displayName },
    },
  } = data;

  return (
    <>
      <SEO title={`${displayName} (Admin)`} />
      <Row>
        <Col span={18}>
          <Heading level="h1">{displayName}</Heading>
        </Col>
        <Col span={6}>
          {formatMessage(intlMessages.updateAdvocateBelow)}:{/* TODO */}
          {/* @ceolson01 Don't love how clinicianAdvocateId is passed */}
          <ClinicianAdvocatesSelect
            userId={userId}
            clinicianAdvocateId={clinicianAdvocateId || ''}
          />
        </Col>
      </Row>
      <Menu className={styles.subNav} selectedKeys={[pathname]} mode="horizontal">
        <Menu.Item key={generatePath(adminRoutes.userJobs, { userId })}>
          <NavLink to={generatePath(adminRoutes.userJobs, { userId })}>
            {formatMessage(intlMessages.applicationsTabMenuItem)}
          </NavLink>
        </Menu.Item>
        <Menu.Item key={generatePath(adminRoutes.userDetail, { userId })}>
          <NavLink to={generatePath(adminRoutes.userDetail, { userId })}>
            {formatMessage(intlMessages.profileTabMenuItem)}
          </NavLink>
        </Menu.Item>
        <Menu.Item key={generatePath(adminRoutes.userLicenses, { userId })}>
          <NavLink to={generatePath(adminRoutes.userLicenses, { userId })}>
            {formatMessage(intlMessages.licensesTabMenuItem)}
          </NavLink>
        </Menu.Item>
        <Menu.Item key={generatePath(adminRoutes.userNotes, { userId })}>
          <NavLink to={generatePath(adminRoutes.userNotes, { userId })}>
            {formatMessage(intlMessages.notesTabMenuItem)}
          </NavLink>
        </Menu.Item>
        {!isSharingTokenV2Enabled && (
          <Menu.Item key={generatePath(adminRoutes.userSharingTokens, { userId })}>
            <NavLink to={generatePath(adminRoutes.userSharingTokens, { userId })}>
              {formatMessage(intlMessages.sharingTokensTabMenuItem)}
            </NavLink>
          </Menu.Item>
        )}
      </Menu>
      <Row>
        <Col span={6} offset={18}>
          {formatMessage(intlMessages.updateNetworkBelow)}:
          <NetworkStatusSelect
            userId={userId}
            networkStatus={networkStatus}
            completion={completion || 0}
            displayName={displayName}
          />
        </Col>
      </Row>
      <Outlet />
    </>
  );
};

export default UserDetail;
