import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  questionSpan: {
    defaultMessage: 'Already a member?',
    description: 'Question Span',
    id: 'components.guest.Header.questionSpan',
  },
  logIn: {
    defaultMessage: 'Log in',
    description: 'Log in link',
    id: 'components.guest.Header.logIn',
  },
});
