import { ApolloError } from '@apollo/client';
import { useEffect } from 'react';

import { displayGraphQLErrors } from '~Data';

export const useErrorDisplay = (error: ApolloError | undefined): void => {
  useEffect(() => {
    displayGraphQLErrors(error);
  }, [error]);
};
