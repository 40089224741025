import { useCallback } from 'react';

import {
  displayGraphQLErrors,
  EditUserProfileInput,
  SignUpInput,
  useServerSignUpMutation,
} from '~Data';
import { useAccessToken } from '~Hooks/useAccessToken';

type SignUpFn = (input: SignUpInput, userProfileInput?: EditUserProfileInput) => void;

export const useSignUpMutation = (): [SignUpFn, ReturnType<typeof useServerSignUpMutation>[1]] => {
  const [, setAccessToken] = useAccessToken();

  const [mutation, result] = useServerSignUpMutation({
    onCompleted: ({ signUp }) => {
      if (signUp?.token) {
        setAccessToken(signUp.token);
      }
    },
    onError: displayGraphQLErrors,
  });

  const signup = useCallback<SignUpFn>(
    (input, userProfileInput) => {
      mutation({
        variables: { input, userProfileInput },
      });
    },
    [mutation],
  );

  return [signup, result];
};
