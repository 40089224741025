import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@openloop/limbic/Button';
import { message } from 'antd';
import { defineMessages, useIntl } from 'react-intl';

import Paragraph from '~Core/Paragraph';
import { providerRoutes } from '~Constants/routes';
import { CurrentUserDocument, useApplyToJobMutation } from '~Data';
import cacheUpdates from '~Data/cacheUpdates';
import { getUtmParams } from '~Helpers/utmParams';
import useQuerystringParams from '~Hooks/useQuerystringParams';

import styles from './JobApplicationFormContainer.module.scss';

const intlMessages = defineMessages({
  pageContent: {
    defaultMessage:
      'Interested in this job? We have your information compiled and ready to submit! Click here to be considered.',
    description: 'Page content',
    id: 'Components.jobApplicationFormContainer.ClickToApply.pageContent',
  },
  submitButtonLabel: {
    defaultMessage: 'SUBMIT APPLICATION',
    description: 'Submit button label',
    id: 'Components.JobApplicationFormContainer.ClickToApply.submitButtonLabel',
  },
});

const ClickToApply = () => {
  const { formatMessage } = useIntl();
  const { jobId } = useParams<{ jobId: string }>();
  const navigate = useNavigate();
  const queryParams = useQuerystringParams();
  const utm = getUtmParams(queryParams);

  const [applyToJob, { loading: applyToJobLoading }] = useApplyToJobMutation({
    onCompleted: () => {
      message.success('Applied!');
      navigate(providerRoutes.dashboard);
    },
    update: cacheUpdates.applyToJob(jobId),
    // @TODO - do this via cache updater fn
    refetchQueries: [{ query: CurrentUserDocument }],
  });

  const handleClick = () => {
    applyToJob({
      variables: {
        input: {
          jobId,
          ...(utm && { utm }),
        },
      },
    });
  };

  return (
    <>
      <Paragraph>{formatMessage(intlMessages.pageContent)}</Paragraph>
      <div>
        <Button
          type="submit"
          className={styles.submitBtn}
          loading={applyToJobLoading}
          onClick={handleClick}
        >
          {formatMessage(intlMessages.submitButtonLabel)}
        </Button>
      </div>
    </>
  );
};

export default ClickToApply;
