export const getUtmParams = (urlSearchParams: URLSearchParams): Record<string, string> | null => {
  const utmParams: Record<string, string> = {};
  Array.from(urlSearchParams.entries()).forEach((param) => {
    const [key, value] = param;
    if (key.includes('utm_')) {
      const utmKey = key.replace('utm_', '');
      utmParams[utmKey] = value;
    }
  });
  return Object.keys(utmParams).length === 0 ? null : utmParams;
};

export const convertUtmParamsToAString = (utmParams: Record<string, string>): string => {
  const utmParamsChain = new URLSearchParams(utmParams).toString();
  return utmParamsChain;
};

export const convertUtmParamsSearchedToObject = (
  utmParamsToSearch: string[],
): Record<string, string> | undefined => {
  const utmParamsObject: Record<string, string> = {};
  const [key, value] = utmParamsToSearch;
  const keyWithoutWhitespaces = key?.replace(/\s/g, '');
  const valueWithoutWhitespaces = value?.replace(/\s/g, '');
  if (keyWithoutWhitespaces && valueWithoutWhitespaces) {
    utmParamsObject[keyWithoutWhitespaces] = valueWithoutWhitespaces;
  }
  return Object.keys(utmParamsObject).length > 0 ? utmParamsObject : undefined;
};
