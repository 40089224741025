import React from 'react';
import { NavLink, To } from 'react-router-dom';

import useViewport from '~Hooks/useViewport';
import {
  DesktopNav,
  MenuItemProps,
  MobileNav,
  MobileNavProps,
  Navbar,
} from '~Components/Navigation';
import images from '~Constants/images';

import styles from './Navigation.module.scss';

interface NavigationProps extends Partial<MobileNavProps> {
  logoRoute: To;
  leftMenuItems?: MenuItemProps[];
  rightMenuItems?: MenuItemProps[];
  mobileMenuItems?: MenuItemProps[];
  variant?: 'dark' | 'light';
  mobileScreenWidth?: number;
}

export const Navigation = ({
  logoRoute,
  leftMenuItems,
  rightMenuItems,
  mobileMenuItems,
  variant,
  mobileScreenWidth = 768,
  ...rest
}: NavigationProps) => {
  const { width } = useViewport();
  const showMobile = width < mobileScreenWidth;

  return (
    <Navbar variant={variant}>
      <div className={styles.navigation}>
        <NavLink to={logoRoute} className={styles.logoLink}>
          <img
            alt="OpenLoop"
            className={styles.logo}
            src={variant === 'light' ? images.logo : images.pinkWhiteOpenLoopLogo}
          />
        </NavLink>
        {!showMobile && leftMenuItems ? (
          <DesktopNav leftMenuItems={leftMenuItems} rightMenuItems={rightMenuItems} />
        ) : (
          mobileMenuItems && <MobileNav mobileMenuItems={mobileMenuItems} {...rest} />
        )}
      </div>
    </Navbar>
  );
};
