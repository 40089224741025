import React from 'react';
import classNames from 'classnames';
import Icon from '@openloop/limbic/Icon';

import Link from '~Core/Link';

import styles from './TaskCard.module.scss';

export interface TaskCardProps {
  description: string;
  linkText: string;
  route: string;
  stacked?: boolean;
  title: string;
}

const TaskCard = ({ description, linkText, route, stacked = false, title }: TaskCardProps) => {
  const cardClassName = classNames(styles.card, { [styles.pinkBorder]: !stacked });
  const titleClassName = classNames(styles.flexContainer, { [styles.flexColumnReverse]: stacked });
  const descriptionClassName = classNames(styles.flexContainer, { [styles.flexColumn]: stacked });

  return (
    <div className={cardClassName}>
      <div className={titleClassName}>
        <p className={styles.title}>{title}</p>
      </div>
      <div className={descriptionClassName}>
        <p className={styles.description}>{description}</p>
        <Link
          buttonVariant="primary"
          className={styles.link}
          icon={<Icon name="ChevronRight" />}
          iconPlacement="after"
          size="sm"
          to={route}
        >
          {linkText}
        </Link>
      </div>
    </div>
  );
};

export default TaskCard;
