import React, { useEffect } from 'react';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { message } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import Heading from '@openloop/limbic/Heading';
import Grid from '@openloop/limbic/Grid';
import { useIntl } from 'react-intl';

import common from '~Constants/common';
import messages from '~Constants/messages';
import { clinicRoutes } from '~Constants/routes';
import WizardFooter from '~Components/WizardFooter/WizardFooter';
import SEO from '~Core/SEO';
import {
  EditJobInput,
  transformNullableValues,
  useClinicAdminJobQuery,
  useClinicQuery,
  useEditJobMutation,
} from '~Data';
import { getMaybeMomentDateString } from '~Helpers/dates';
import { useInitialValuesJob } from '~Hooks/useInitialValuesJob';
import LoadingScreen from '~Modules/shared/LoadingScreen';
import FullScreen from '~Templates/FullScreen';

import { JobFields, JobFormValues, jobValidationSchema } from '../Forms/JobFields';
import { intlMessages } from './intlMessages';

import styles from './EditJob.module.scss';

const { Col, Row } = Grid;

const EditJob = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { clinicId, jobId } = useParams<{ clinicId: string; jobId: string }>();
  const { data: jobData, loading: jobLoading } = useClinicAdminJobQuery({
    variables: { id: jobId },
  });
  const { data: clinicData, loading: clinicLoading } = useClinicQuery({
    variables: { id: clinicId },
  });
  const [editJob, { data: editJobData, error: editJobError, loading: editJobLoading }] =
    useEditJobMutation();

  const initialValues = useInitialValuesJob(jobData ? jobData.clinicAdminJob : null);

  useEffect(() => {
    if (editJobData && editJobData.editJob) {
      message.success(messages.jobUpdateSuccessful);
      // fixme this causes memory leak
      navigate(generatePath(clinicRoutes.clinicDetail, { clinicId }));
    }
  }, [clinicId, editJobData, navigate]);

  useEffect(() => {
    if (editJobError && !editJobLoading && !editJobData) {
      message.error(messages.jobUpdateError);
    }
  }, [editJobError, editJobLoading, editJobData]);

  const performJobEdit = (snapshot: JobFormValues) => {
    const { startAt, ...initialStep } = snapshot;
    const formValues = {
      ...initialStep,
      startAt: getMaybeMomentDateString(startAt, common.requestDateFormat),
      id: jobId,
    };
    const input = transformNullableValues<EditJobInput>(formValues);
    editJob({
      variables: { input },
    });
  };

  if (clinicLoading || jobLoading) {
    return <LoadingScreen />;
  }

  if (!clinicData || !jobData) {
    return null;
  }

  return (
    <FullScreen withHeader={false}>
      <SEO title="Edit Job" />
      <div className={styles.main}>
        <Row justify="center">
          <Col>
            <Heading level="h1" size="h3" className={styles.heading}>
              {formatMessage(intlMessages.editJob)}
            </Heading>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <Formik
              initialValues={initialValues}
              onSubmit={performJobEdit}
              validationSchema={jobValidationSchema}
            >
              {({ dirty, isValid }) => (
                <Form>
                  <JobFields />
                  <WizardFooter
                    cancelRoute={generatePath(clinicRoutes.clinicDetail, { clinicId })}
                    nextButtonText="Update"
                    nextButtonProps={{
                      disabled: !(dirty && isValid),
                      loading: editJobLoading,
                      type: 'submit',
                      variant: 'primary',
                    }}
                  />
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    </FullScreen>
  );
};

export default EditJob;
