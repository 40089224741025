import React, { ReactNode, ReactNodeArray, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    gtag?: (key: string, trackingId: string, config: { page_path: string }) => void;
  }
}

interface Props {
  children: ReactNode | ReactNodeArray;
}

const Analytics = ({ children }: Props) => {
  const { pathname } = useLocation();

  const googleAnalyticsId = import.meta.env.VITE_GOOGLE_ANALYTICS;
  const nodeEnv = import.meta.env.MODE;
  const enableTracking = import.meta.env.PROD;

  useEffect(() => {
    if (!enableTracking) {
      console.info(`Analytics not enabled for current environment: ${nodeEnv}.`);
    }
    if (enableTracking && !googleAnalyticsId) {
      console.info(
        'Analytics not enabled. To enable, provide the required environment variable: `VITE_GOOGLE_ANALYTICS`.',
      );
    }
  }, [nodeEnv, googleAnalyticsId, enableTracking]);

  useEffect(() => {
    if (!enableTracking || !googleAnalyticsId || !window.gtag) {
      return;
    }
    window.gtag('config', googleAnalyticsId, { page_path: pathname });
  }, [enableTracking, pathname, googleAnalyticsId, nodeEnv]);

  return <>{children}</>;
};

export default Analytics;
