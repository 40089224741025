import React, { ComponentProps } from 'react';
import Select from '@openloop/limbic/Form/Select';

import { useAdminAdminUsersQuery } from '~Data';

interface Props {
  inputId: string;
  name: string;
  onChange?: ComponentProps<typeof Select>['onChange'];
  isLoading?: boolean;
}

const ClinicAccountManagerSelect = ({
  inputId,
  isLoading: isLoadingProp,
  name,
  onChange,
}: Props) => {
  const { data, loading: adminUsersLoading } = useAdminAdminUsersQuery();
  return (
    <Select
      id={inputId}
      isLoading={adminUsersLoading || isLoadingProp}
      name={name}
      onChange={onChange}
      options={data?.adminAdminUsers.map(({ id, profile: { displayName } }) => ({
        label: displayName,
        value: id,
      }))}
    />
  );
};

export default ClinicAccountManagerSelect;
