import { JobApplicationRulingDecision, JobStatus, UserNetworkStatus } from '~Data';
import { ApplicationStatus } from './types';

type GetJobStatus = (args: {
  latestRulingDecision?: JobApplicationRulingDecision;
  jobStatus: JobStatus;
  networkStatus: UserNetworkStatus;
}) => ApplicationStatus;

export const getJobStatus: GetJobStatus = ({ latestRulingDecision, jobStatus, networkStatus }) => {
  // we don't worry about no/not now network statuses here b/c this component shouldn't render if so
  // TODO - are there any other job statuses that we want to affect what the user sees - for example, if a job is on hold we should probably notify the user

  // job no longer available
  if (jobStatus === JobStatus.Cancelled || jobStatus === JobStatus.Inactive) {
    return ApplicationStatus.Closed;
  }

  // user's profile is incomplete
  if (networkStatus === UserNetworkStatus.Incomplete) {
    return ApplicationStatus.Incomplete;
  }

  // user is no longer being considered
  if (latestRulingDecision === JobApplicationRulingDecision.No) {
    return ApplicationStatus.Declined;
  }

  /* 
    > User network status is pending review OR
    > User has been accepted into the network AND:
      > their application has not been reviewed by a CA OR
      > their application has been approved by a CA but not yet approved for submission by sales OR
      > they have been added to Zoho
  */
  if (
    networkStatus === UserNetworkStatus.PendingReview ||
    (networkStatus === UserNetworkStatus.YesNetwork &&
      (!latestRulingDecision ||
        latestRulingDecision === JobApplicationRulingDecision.AwaitingAccountManager ||
        latestRulingDecision === JobApplicationRulingDecision.MoveToZoho))
  ) {
    return ApplicationStatus.InReview;
  }

  // User has an offer from the client
  if (latestRulingDecision === JobApplicationRulingDecision.Yes) {
    return ApplicationStatus.Offer;
  }

  // escape hatch
  return ApplicationStatus.None;
};
