import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  editClinic: {
    defaultMessage: 'Edit Clinic',
    description: 'Edit Clinic heading',
    id: 'components.clinic.EditClinic.editClinic',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    description: 'Cancel button',
    id: 'components.clinic.EditClinic.cancelButton',
  },
  saveButton: {
    defaultMessage: 'Save',
    description: 'Save button',
    id: 'components.clinic.EditClinic.saveButton',
  },
});
