import { getIn, FormikProps } from 'formik';

export const getAdjacentFieldErrors = (
  fieldNameA: string,
  fieldNameB: string,
  errors: FormikProps<any>['errors'],
  touched: FormikProps<any>['touched'],
): boolean => {
  const isOneTouched =
    getIn(touched, fieldNameA, undefined) || getIn(touched, fieldNameB, undefined);
  const isOneInvalid = getIn(errors, fieldNameA, undefined) || getIn(errors, fieldNameB, undefined);
  return isOneTouched && isOneInvalid;
};
