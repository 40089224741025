import React, { ComponentProps, useMemo } from 'react';

import { adminRoutes } from '~Constants/routes';
import { AdminStatsQuery } from '~Data';
import { getFullnameForDisplay } from '~Helpers/users';

import SimpleList from './SimpleList';

interface Props {
  users: AdminStatsQuery['adminStats']['users'];
}

const UserList = ({ users }: Props) => {
  const listData = useMemo<ComponentProps<typeof SimpleList>['listData']>(
    () =>
      users.map(({ createdAt, id, profile: { firstName, lastName } }) => ({
        createdAt,
        href: `${adminRoutes.users}/${id}`,
        key: id,
        title: getFullnameForDisplay(firstName, lastName) || 'No Name',
      })),
    [users],
  );

  return <SimpleList listData={listData} />;
};

export default UserList;
