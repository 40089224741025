import React from 'react';
import { RouteObject } from 'react-router-dom';

import { adminRoutes } from '~Constants/routes';
import { UserRole } from '~Data';
import AuthGuard from '~Auth';

import ClinicList from '~Modules/admin/ClinicList';
import Dashboard from '~Modules/admin/Dashboard';
import InviteAndSuggest from '~Modules/admin/InviteAndSuggest';
import Jobs from '~Modules/admin/UserDetail/Jobs';
import JobsList from '~Modules/admin/JobsList';
import Licenses from '~Modules/admin/UserDetail/Licenses';
import Notes from '~Modules/admin/UserDetail/Notes';
import Profile from '~Modules/admin/UserDetail/Profile';
import SharingTokens from '~Modules/admin/UserDetail/SharingTokens';
import UserDetail from '~Modules/admin/UserDetail';
import UserList from '~Modules/admin/UserList';
import Wrapper from '~Modules/admin/Wrapper';
import { ClinicianInviteWizard, SuggestJobsWizard } from '~Modules/admin/InviteAndSuggest/Wizards';

export const adminRoutesObject: RouteObject[] = [
  {
    path: '/admin',
    element: (
      <AuthGuard userTypesAllowed={[UserRole.Admin]}>
        <Wrapper />
      </AuthGuard>
    ),
    children: [
      { index: true, element: <Dashboard /> },
      { path: adminRoutes.clinics, element: <ClinicList /> },
      { path: adminRoutes.jobs, element: <JobsList /> },
      { path: adminRoutes.users, element: <UserList /> },
      {
        path: adminRoutes.inviteAndSuggest,
        children: [
          { index: true, element: <InviteAndSuggest /> },
          { path: adminRoutes.inviteClinician, element: <ClinicianInviteWizard /> },
          { path: adminRoutes.suggestJobs, element: <SuggestJobsWizard /> },
        ],
      },
      {
        path: adminRoutes.userDetail,
        element: <UserDetail />,
        children: [
          { index: true, element: <Profile /> },
          { path: adminRoutes.userJobs, element: <Jobs /> },
          { path: adminRoutes.userLicenses, element: <Licenses /> },
          { path: adminRoutes.userNotes, element: <Notes /> },
          { path: adminRoutes.userSharingTokens, element: <SharingTokens /> },
        ],
      },
    ],
  },
];
