import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Heading from '@openloop/limbic/Heading';
import styles from './WelcomeText.module.scss';

const intlMessages = defineMessages({
  heading: {
    defaultMessage: 'Welcome to the OpenLoop Network!',
    description: 'Accepted network status heading text',
    id: 'Modules.provider.Home.Welcome.WelcomeText.Accepted.heading',
  },
  firstParagraph: {
    defaultMessage:
      "Thanks again for taking the time to complete your profile. After review, we're excited to welcome you into our network! Consider it your all-access pass to multi-state licensure expansion, priority shift placement, flexible scheduling, and great pay.",
    description: 'Accepted network status first paragraph',
    id: 'Modules.provider.Home.Welcome.WelcomeText.Accepted.firstParagraph',
  },
  secondParagraph: {
    defaultMessage:
      "Next up, we'll be pairing you with your own Clinician Advocate to better understand your areas of expertise and passions, so we can match you with companies who value the same.",
    description: 'Accepted network status second paragraph',
    id: 'Modules.provider.Home.Welcome.WelcomeText.Accepted.secondParagraph',
  },
});

const Accepted = () => {
  const { formatMessage } = useIntl();
  const heading = formatMessage(intlMessages.heading);
  const firstParagraph = formatMessage(intlMessages.firstParagraph);
  const secondParagraph = formatMessage(intlMessages.secondParagraph);

  return (
    <div>
      <Heading className={styles.welcomeTextHeading} level="h2">
        {heading}
      </Heading>
      <p>{firstParagraph}</p>
      <p>{secondParagraph}</p>
    </div>
  );
};

export default Accepted;
