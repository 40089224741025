import React, { Fragment, useEffect, useState } from 'react';
import { Empty, List, message } from 'antd';
import LimbicIcon from '@openloop/limbic/Icon';

import messages from '~Constants/messages';
import Icon, { Icons } from '~Core/Icon';
import { ClinicContactFieldsFragment, ClinicContacts, useSetPrimaryContactMutation } from '~Data';
import cacheUpdates from '~Data/cacheUpdates';
import { formatPhone } from '~Helpers/phoneNumber';

import EditClinicContactModal from '~Components/EditClinicContactModal';

import styles from './ClinicContactsList.module.scss';

interface Props {
  clinicContacts?: ClinicContacts;
  clinicId: string;
}

const ClinicContact = ({
  className,
  clinicId,
  id: contactId,
  email,
  isPrimary,
  name,
  phone: maybePhone,
  title,
}: ClinicContactFieldsFragment & { className?: string; clinicId: string }) => {
  const formattedPhone = maybePhone ? formatPhone(maybePhone) : undefined;

  const [setPrimaryContact, { data, error }] = useSetPrimaryContactMutation({
    update: cacheUpdates.setPrimaryContact(clinicId, contactId),
  });

  const [editContactModalVisible, setEditContactModalVisible] = useState(false);

  const success = !!data;

  useEffect(() => {
    if (error) {
      message.error(messages.primaryContactUpdateError);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      message.success(messages.primaryContactUpdateSuccess);
    }
  }, [success]);

  const contentDisplay = [name, email, formattedPhone, title]
    .filter((x) => !!x)
    .map((part) => <Fragment key={part}>{part}</Fragment>);

  const user = name || email || formattedPhone || 'this user';

  return (
    <List.Item className={className} key={contactId}>
      <button
        aria-label={`Edit ${user}'s contact information'`}
        type="button"
        className={styles.primaryContactIcon}
        onClick={() => setEditContactModalVisible(true)}
      >
        <LimbicIcon name="Edit" variant="dark" />
      </button>

      <button
        aria-label={`Set ${user} as the Primary Contact`}
        type="button"
        className={`${styles.primaryContactIcon} ${isPrimary ? styles.isPrimary : ''}`}
        {...(!isPrimary && { onClick: () => setPrimaryContact({ variables: { contactId } }) })}
      >
        <Icon name={Icons.Star} />
      </button>
      <span className={styles.contactInformation}>
        {contentDisplay.length > 0
          ? contentDisplay.map((item, idx) => <div key={idx}>{item}</div>)
          : 'Unnamed Contact'}
      </span>
      {/* TODO rendering one of these per contact this isn't super efficient, but it works 🤷 */}
      <EditClinicContactModal
        contactId={contactId}
        contactName={name}
        contactEmail={email}
        contactPhone={maybePhone}
        contactTitle={title}
        isVisible={editContactModalVisible}
        setIsOpen={setEditContactModalVisible}
      />
    </List.Item>
  );
};

const ClinicContactsList = ({ clinicContacts, clinicId }: Props) => (
  <>
    {clinicContacts && clinicContacts.length > 0 ? (
      <List>
        <ul className={styles.list}>
          {clinicContacts.map((clinicContact) => (
            <ClinicContact
              key={`clinicContact_${clinicContact.id}`}
              {...clinicContact}
              clinicId={clinicId}
              className={styles.clinicContact}
            />
          ))}
        </ul>
      </List>
    ) : (
      <Empty description="No Clinic Contacts" />
    )}
  </>
);

export default ClinicContactsList;
