import React from 'react';
import { Typography } from 'antd';
import { defineMessages, useIntl } from 'react-intl';

import Empty from '~Components/Empty';

import SectionCard from '../SectionCard';
import { TaskCardProps } from '../TaskCard/TaskCard';
import TaskCard from '../TaskCard';

import styles from './Tasks.module.scss';

const { Paragraph } = Typography;

interface Props {
  isStacked?: boolean;
  taskData: Omit<TaskCardProps, 'stacked'>[];
}

const intlMessages = defineMessages({
  emptyDescription: {
    defaultMessage: 'Thanks for getting your tasks completed.',
    description: 'All tasks complete description',
    id: 'Modules.provider.Home.Tasks.emptyDescription',
  },
  emptyTitle: {
    defaultMessage: `You're all set!`,
    description: 'All tasks complete title',
    id: 'Modules.provider.Home.Tasks.emptyTitle',
  },
  taskPrompt: {
    defaultMessage: 'Please complete the following item(s):',
    description: 'Task Prompt Message',
    id: 'Modules.provider.Home.Tasks.taskPrompt',
  },
  title: {
    defaultMessage: 'Tasks',
    description: 'Task Container Title',
    id: 'Modules.provider.Home.Tasks.title',
  },
});

const Tasks = ({ isStacked = false, taskData }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <SectionCard
      badgeCount={taskData.length}
      title={formatMessage(intlMessages.title)}
      iconName="List"
    >
      <div className={styles.container}>
        {taskData.length > 0 ? (
          <>
            <Paragraph>{formatMessage(intlMessages.taskPrompt)}</Paragraph>
            <div className={styles.cardContainer}>
              {taskData.map(({ ...props }, idx) => (
                // TODO: update key to task id
                <TaskCard key={idx} stacked={isStacked} {...props} />
              ))}
            </div>
          </>
        ) : (
          <Empty
            description={formatMessage(intlMessages.emptyDescription)}
            title={formatMessage(intlMessages.emptyTitle)}
          />
        )}
      </div>
    </SectionCard>
  );
};

export default Tasks;
