import React, { createContext, ReactNode, ReactNodeArray, useEffect, useState } from 'react';

export interface ViewportInfo {
  width: number;
  height: number;
}

export const ViewportContext = createContext<ViewportInfo>({
  width: window.innerWidth,
  height: window.innerHeight,
});

interface Props {
  children: ReactNode | ReactNodeArray;
}

const ViewportProvider = ({ children }: Props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <ViewportContext.Provider value={{ width, height }}>{children}</ViewportContext.Provider>;
};

export default ViewportProvider;
