import { defineMessages } from 'react-intl';

export const intlMessagesClientInfo = defineMessages({
  title: {
    defaultMessage: 'Setup Instructions',
    description: 'Form title',
    id: 'components.DrChronoClientInfo.title',
  },
  description: {
    defaultMessage:
      'To obtain your DrChrono Client ID and Client Secret, follow the instructions below.',
    description: 'Form description',
    id: 'components.DrChronoClientInfo.description',
  },
  visit: {
    defaultMessage: 'Visit ',
    description: 'Visit',
    id: 'components.DrChronoClientInfo.visit',
  },
  item1: {
    defaultMessage:
      'If not yet configured, give the application a name (i.e. "OpenLoop Patient Scheduling Portal")',
    description: 'Description on list',
    id: 'components.DrChronoClientInfo.item1',
  },
  item2: {
    defaultMessage:
      'In the "Redirect URIs" field, enter the following URI (or add as a new URI if any already exist).',
    description: 'Description on list',
    id: 'components.DrChronoClientInfo.item2',
  },
  item3: {
    defaultMessage:
      'Save your changes, then enter the "Client ID" and "Client Secret" from DrChrono into the fields below.',
    description: 'Description on list',
    id: 'components.DrChronoClientInfo.item3',
  },
  clientId: {
    defaultMessage: 'Client ID ',
    description: 'Client ID',
    id: 'components.DrChronoClientInfo.clientId',
  },
  clientSecret: {
    defaultMessage: 'Client Secret ',
    description: 'Client Secret',
    id: 'components.DrChronoClientInfo.clientSecret',
  },
  next: {
    defaultMessage: 'Next ',
    description: 'Next',
    id: 'components.DrChronoClientInfo.next',
  },
});

export const intlMessagesCustomization = defineMessages({
  formLabel: {
    defaultMessage: 'Primary Button Background Color',
    description: 'Form label',
    id: 'components.DrChronoCustomization.formLabel',
  },
  save: {
    defaultMessage: 'Save',
    description: 'Save button',
    id: 'components.DrChronoCustomization.save',
  },
});

export const intlMessagesLogin = defineMessages({
  paragraph: {
    defaultMessage:
      'Almost done! To complete integration setup, authorize OpenLoop to connect to your DrChrono account by clicking the link below.',
    description: 'Paragraph',
    id: 'components.DrChronoLogin.paragraph',
  },
});

export const intlMessagesManagement = defineMessages({
  modalQuestion: {
    defaultMessage: 'Are you sure you want to disable this integration?',
    description: 'Modal question',
    id: 'components.DrChronoManagement.modalQuestion',
  },
  modalDescription: {
    defaultMessage:
      'This will disable your scheduling widget and will require re-configuration to re-enable.',
    description: 'Modal description',
    id: 'components.DrChronoManagement.modalDescription',
  },
  paragraph: {
    defaultMessage: 'Disable the integration and unauthenticate from DrChrono.',
    description: 'Management paragraph',
    id: 'components.DrChronoManagement.paragraph',
  },
  button: {
    defaultMessage: 'Disable DrChrono',
    description: 'Management button',
    id: 'components.DrChronoManagement.button',
  },
});
