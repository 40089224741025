import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Divider } from 'antd';
import Icon from '@openloop/limbic/Icon';
import { useNavigate } from 'react-router-dom';

import { providerRoutes } from '~Constants/routes';
import Link from '~Core/Link';
import SEO from '~Core/SEO';
import { FeatureFlag, useFeatureFlag } from '~Hooks/useFeatureFlag';

import styles from './Prescribe.module.scss';

const intlMessages = defineMessages({
  breadcrumbsAriaLabel: {
    id: 'Modules.provider.Prescribe.breadcrumbsAriaLabel',
    defaultMessage: 'breadcrumbs',
    description: 'ARIA label for breadcrumbs navigation',
  },
  breadCrumbToManagePrescription: {
    id: 'Modules.provider.Prescribe.breadCrumbToManagePrescription',
    defaultMessage: 'Manage Prescription',
    description: 'Breadcrumb text that indicates navigation to the manage prescription page',
  },
  breadCrumbToPatientRequest: {
    id: 'Modules.provider.Prescribe.breadCrumbToPatientRequest',
    defaultMessage: 'Patient Request',
    description: 'Breadcrumb text that indicates navigation to the patient request page',
  },
  currentLocationAriaLabel: {
    id: 'Modules.provider.Prescribe.currentLocationAriaLabel',
    defaultMessage: 'location',
    description: 'ARIA attribute that indicates which breadcrumb links to the current page',
  },
  linkBackToPatientRequest: {
    id: 'Modules.provider.Prescribe.linkBackToPatientRequest',
    defaultMessage: 'back to patient request',
    description: 'Link text that indicates navigation back to the patient request page',
  },
  pageTitle: {
    id: 'Modules.provider.Prescribe.pageTitle',
    defaultMessage: 'Prescribe',
    description: 'Page title',
  },
});

const Prescribe = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const isAsyncQueueEnabled = useFeatureFlag(FeatureFlag.AsyncQueue);

  useEffect(() => {
    if (!isAsyncQueueEnabled) {
      navigate(providerRoutes.dashboard);
    }
  }, [isAsyncQueueEnabled, navigate]);

  const breadcrumbs = [
    {
      key: 0,

      to: providerRoutes.patients,
      text: formatMessage(intlMessages.breadCrumbToPatientRequest),
    },
    {
      key: 1,
      to: providerRoutes.prescribe,
      text: formatMessage(intlMessages.breadCrumbToManagePrescription),
    },
  ];

  return (
    <>
      <SEO title={formatMessage(intlMessages.pageTitle)} />
      <div className={styles.navContainer}>
        <nav aria-label={formatMessage(intlMessages.breadcrumbsAriaLabel)}>
          <ol className={styles.breadcrumbsOl}>
            {breadcrumbs.map(({ key, to, text }) => {
              const linkProps: any = {
                to,
              };
              const lastCrumb = key === breadcrumbs.length - 1;
              if (lastCrumb) {
                linkProps['aria-current'] = formatMessage(intlMessages.currentLocationAriaLabel);
              }
              return (
                <li className={styles.breadCrumbsLi} key={key}>
                  <span>
                    <Link className={styles.breadCrumbsLink} {...linkProps}>
                      {text}
                    </Link>
                    {!lastCrumb && (
                      <span aria-hidden className={styles.colorGray}>
                        {' > '}
                      </span>
                    )}
                  </span>
                </li>
              );
            })}
          </ol>
        </nav>
        <Divider />
        <Link className={styles.backLink} to={providerRoutes.patients}>
          <Icon name="ChevronLeft" />
          &nbsp;{formatMessage(intlMessages.linkBackToPatientRequest)}
        </Link>
      </div>
      <div>
        <iframe title="TODO - Dosespot Integration" />
      </div>
    </>
  );
};

export default Prescribe;
