import React from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@openloop/limbic/Grid';
import Heading from '@openloop/limbic/Heading';
import { useIntl } from 'react-intl';

import ClinicIntegrationsList from '~Components/ClinicIntegrationsList';
import { intlMessages } from './intlMessages';

import styles from './Integrations.module.scss';

const { Col, Row } = Grid;

const Integrations = () => {
  const { formatMessage } = useIntl();
  const { clinicId } = useParams<{
    clinicId: string;
  }>();

  return (
    <div className={styles.main}>
      <Row justify="center">
        <Col xs={22} md={18} lg={16}>
          <Heading level="h1">{formatMessage(intlMessages.integrations)}</Heading>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={22} md={18} lg={16}>
          <ClinicIntegrationsList clinicId={clinicId} />
        </Col>
      </Row>
    </div>
  );
};

export default Integrations;
