import { JobApplicationRulingDecision } from '~Data';

export const jobRulingChoices: Record<
  JobApplicationRulingDecision | 'NotYetRuled' | 'Suggested',
  string
> = {
  [JobApplicationRulingDecision.AwaitingAccountManager]: 'Awaiting AM',
  [JobApplicationRulingDecision.MoveToZoho]: 'Move to Zoho',
  [JobApplicationRulingDecision.No]: 'Rejected',
  NotYetRuled: 'Not Yet Ruled',
  [JobApplicationRulingDecision.Yes]: 'Hired',
  Suggested: 'Suggested',
};
