import React, { ComponentProps } from 'react';
import Form from '@openloop/limbic/Form';

import { employmentTypeChoices } from '~Constants/employmentTypes';

const { Checkbox } = Form;

const EmploymentTypeCheckboxes = ({
  name,
  ...rest
}: Omit<ComponentProps<typeof Checkbox>, 'label' | 'value'>) => {
  return (
    <fieldset>
      {Object.entries(employmentTypeChoices).map(([value, label]) => (
        <Checkbox key={value} name={name} value={value} label={label} {...rest} />
      ))}
    </fieldset>
  );
};

export default EmploymentTypeCheckboxes;
