import * as Types from '../../../Data/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VerifyUserInvitationTokenQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type VerifyUserInvitationTokenQuery = { verifyUserInvitationToken: { id: string, firstName: string, clinicianAdvocate: { profile: { userId: string, firstName?: string | null, lastName?: string | null, image?: { location: string } | null } } } | { isExpired: boolean, message: string, userInvitation: { id: string } } | { isNotFound: boolean, message: string } };

export type SignUpByInvitationMutationVariables = Types.Exact<{
  input: Types.SignUpByInvitationInput;
}>;


export type SignUpByInvitationMutation = { signUpByInvitation?: { token: string } | null };


export const VerifyUserInvitationTokenDocument = gql`
    query VerifyUserInvitationToken($token: String!) {
  verifyUserInvitationToken(token: $token) {
    ... on UserInvitation {
      clinicianAdvocate {
        profile {
          userId
          firstName
          lastName
          image {
            location
          }
        }
      }
      id
      firstName
    }
    ... on UserInvitationExpired {
      isExpired
      message
      userInvitation {
        id
      }
    }
    ... on UserInvitationNotFound {
      isNotFound
      message
    }
  }
}
    `;

/**
 * __useVerifyUserInvitationTokenQuery__
 *
 * To run a query within a React component, call `useVerifyUserInvitationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserInvitationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyUserInvitationTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyUserInvitationTokenQuery(baseOptions: Apollo.QueryHookOptions<VerifyUserInvitationTokenQuery, VerifyUserInvitationTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyUserInvitationTokenQuery, VerifyUserInvitationTokenQueryVariables>(VerifyUserInvitationTokenDocument, options);
      }
export function useVerifyUserInvitationTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyUserInvitationTokenQuery, VerifyUserInvitationTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyUserInvitationTokenQuery, VerifyUserInvitationTokenQueryVariables>(VerifyUserInvitationTokenDocument, options);
        }
export type VerifyUserInvitationTokenQueryHookResult = ReturnType<typeof useVerifyUserInvitationTokenQuery>;
export type VerifyUserInvitationTokenLazyQueryHookResult = ReturnType<typeof useVerifyUserInvitationTokenLazyQuery>;
export type VerifyUserInvitationTokenQueryResult = Apollo.QueryResult<VerifyUserInvitationTokenQuery, VerifyUserInvitationTokenQueryVariables>;
export const SignUpByInvitationDocument = gql`
    mutation SignUpByInvitation($input: SignUpByInvitationInput!) {
  signUpByInvitation(input: $input) {
    token
  }
}
    `;
export type SignUpByInvitationMutationFn = Apollo.MutationFunction<SignUpByInvitationMutation, SignUpByInvitationMutationVariables>;

/**
 * __useSignUpByInvitationMutation__
 *
 * To run a mutation, you first call `useSignUpByInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpByInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpByInvitationMutation, { data, loading, error }] = useSignUpByInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpByInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SignUpByInvitationMutation, SignUpByInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpByInvitationMutation, SignUpByInvitationMutationVariables>(SignUpByInvitationDocument, options);
      }
export type SignUpByInvitationMutationHookResult = ReturnType<typeof useSignUpByInvitationMutation>;
export type SignUpByInvitationMutationResult = Apollo.MutationResult<SignUpByInvitationMutation>;
export type SignUpByInvitationMutationOptions = Apollo.BaseMutationOptions<SignUpByInvitationMutation, SignUpByInvitationMutationVariables>;