/*
  Type checking for CSS Modules
  eslint-disable-next-line on line 6 is necessary because we must ensure Value does not include a '-'
  as of Typescript v4.1, that is only possible with `infer`.
  Therefore, PropertyTwo and ValueTwo must be ignored and eslint will warn you that their 
  values are not used. 
*/
type CSSClassName<T extends string> = T extends `${infer Property}-${infer Value}`
  ? // eslint-disable-next-line
    Value extends '' | `${infer PropertyTwo}-${infer ValueTwo}`
    ? never
    : Property extends ''
    ? never
    : T extends Lowercase<T>
    ? T
    : never
  : never;

type ObjectOfCSSClassNameKeys<T extends Record<string, string>> = T extends { main: string }
  ? keyof T extends string
    ? { [P in CSSClassName<keyof T> | 'main']: string }
    : never
  : never;

type ExtractValuesOfP<T, P extends string, I extends string> = Extract<
  T,
  `${P}-${I}`
> extends `${P}-${infer ValueTwo}`
  ? ValueTwo
  : never;

export type ClassNameKeysObjToKeyValuePairs<T extends Record<string, string>> =
  T extends ObjectOfCSSClassNameKeys<T>
    ? keyof Omit<
        Pick<T, keyof ObjectOfCSSClassNameKeys<T>>,
        'main'
      > extends `${infer Property}-${infer Value}`
      ? { [P in Property]?: ExtractValuesOfP<keyof T, P, Value> }
      : never
    : never;

const useClassNames = <Styles extends Record<string, string>>(
  styles: Styles,
  customClassName: string | undefined,
  stylesList: Record<string, string | number | boolean | undefined> = {},
): string => {
  const baseClassNames = [styles.main];
  if (customClassName) baseClassNames.push(customClassName);

  const classNames = Object.entries(stylesList).reduce((acc: string[], [key, value]) => {
    const keyValueCSSClass = `${key}-${value}`;
    if (styles[keyValueCSSClass]) return [...acc, styles[keyValueCSSClass]];
    return acc;
  }, baseClassNames);

  return classNames.join(' ');
};

export default useClassNames;
