import { EhrCompatibility } from '~Data';

export const ehrChoices: { [key in EhrCompatibility]: string } = {
  [EhrCompatibility.AllscriptsCps]: 'Allscripts CPS',
  [EhrCompatibility.AthenaHealth]: 'AthenaHealth',
  [EhrCompatibility.CareCloud]: 'CareCloud',
  [EhrCompatibility.Cerner]: 'Cerner',
  [EhrCompatibility.EClinicalWorks]: 'eClinicalWorks',
  [EhrCompatibility.Epic]: 'Epic',
  [EhrCompatibility.Essentris]: 'Essentris',
  [EhrCompatibility.GeCentricity]: 'GE Centricity',
  [EhrCompatibility.Meditech]: 'Meditech',
  [EhrCompatibility.NextGen]: 'NextGen',
  [EhrCompatibility.Other]: 'Other',
};
