import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import { useAuthContext } from '~Context/AuthContext';
import { UserRole } from '~Data';

import UnderConstruction from '~Modules/shared/UnderConstruction';
import Footer from '../Footer';
import Header from '../Header';

import styles from './Wrapper.module.less';

const { Content, Footer: AntFooter, Header: AntHeader } = Layout;

const Wrapper = () => {
  const { currentUser } = useAuthContext();

  if (currentUser && currentUser.role === UserRole.Clinic) {
    return <UnderConstruction />;
  }

  const layoutClassNames = [styles.layout];
  if (currentUser && currentUser.role === UserRole.Admin) {
    layoutClassNames.push(styles.isAdmin);
  }

  return (
    <Layout className={layoutClassNames.join(' ')}>
      <AntHeader className={styles.header}>
        <Header />
      </AntHeader>
      <Content className={styles.content}>
        <Outlet />
      </Content>
      <AntFooter>
        <Footer />
      </AntFooter>
    </Layout>
  );
};

export default Wrapper;
