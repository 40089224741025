import React, { useMemo } from 'react';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Card, Divider, Row, Col, Typography, Popconfirm, Spin } from 'antd';
import moment from 'moment';
import Button from '@openloop/limbic/Button';
import toast from '@openloop/limbic/Toast';
import { useIntl } from 'react-intl';

import { useParams } from 'react-router-dom';
import Paragraph from '~Core/Paragraph';
import common from '~Constants/common';
import {
  AdminUserDocument,
  AdminUserQueryVariables,
  useAdminUserQuery,
  useCreateSharingTokenMutation,
  useDeleteSharingTokenMutation,
} from '~Data';
import { intlMessages } from './intlMessages';

import styles from './SharingTokens.module.less';

const { Text } = Typography;

const SharingTokens = () => {
  const { formatMessage } = useIntl();
  const { userId } = useParams<{ userId: string }>();
  const { data, error, loading } = useAdminUserQuery({ variables: { userId } });

  const currentOrigin = useMemo(() => {
    if (window) {
      return window.location.origin;
    }
    return common.appBaseUrl;
  }, []);

  const [createSharingTokenMutation, { loading: createSharingTokenLoading }] =
    useCreateSharingTokenMutation({
      onCompleted: () => {
        toast({ message: 'Sharing token created successfully', variant: 'success' });
      },
      onError: () => {
        toast({ message: 'There was an error creating the sharing token', variant: 'danger' });
      },
      refetchQueries: [
        { query: AdminUserDocument, variables: { userId } as AdminUserQueryVariables },
      ],
      variables: { input: { userId } },
    });

  const [deleteSharingTokenMutation] = useDeleteSharingTokenMutation({
    onCompleted: () => {
      toast({ message: 'Sharing token deleted successfully', variant: 'success' });
    },
    onError: () => {
      toast({ message: 'There was an error deleting the sharing token', variant: 'danger' });
    },
    refetchQueries: [
      { query: AdminUserDocument, variables: { userId } as AdminUserQueryVariables },
    ],
  });

  const handleCreateSharingToken = () => {
    createSharingTokenMutation();
  };

  const handleDeleteSharingToken = (tokenId: string) => {
    deleteSharingTokenMutation({ variables: { input: { tokenId } } });
  };

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <h1>{formatMessage(intlMessages.error)}</h1>;
  }

  if (!data) {
    return null;
  }

  const {
    adminUser: { sharingTokens },
  } = data;

  if (!sharingTokens) {
    return null;
  }

  return (
    <Card
      extra={
        <Button
          icon={<PlusOutlined />}
          loading={createSharingTokenLoading}
          onClick={handleCreateSharingToken}
          title="New Sharing Token"
          variant="primary"
        >
          {formatMessage(intlMessages.newToken)}
        </Button>
      }
      title="Sharing Tokens"
    >
      <Card.Meta
        title={<Paragraph type="secondary">{formatMessage(intlMessages.paragraph)}</Paragraph>}
      />
      {sharingTokens &&
        sharingTokens.map(({ id, createdAt, createdBy: { email: creatorEmail }, expires }, idx) => {
          const reviewLink = `${currentOrigin}/review/${id}`;
          return (
            <div key={id}>
              {idx > 0 && <Divider />}
              <Row gutter={24} justify="space-between">
                <Col xs={24} md={18}>
                  <Paragraph>
                    <Text copyable>{reviewLink}</Text>
                    <br />
                    {formatMessage(intlMessages.created)}{' '}
                    <Text strong>{moment(createdAt).format(common.dateTimeFormat)}</Text>
                    <br />
                    {formatMessage(intlMessages.by)} <Text strong>{creatorEmail}</Text>
                  </Paragraph>
                </Col>
                <Col xs={24} md={4} className={styles.alignRight}>
                  <Paragraph>
                    {formatMessage(intlMessages.expires)}:{' '}
                    <Text strong>{moment(expires).format(common.dateFormat)}</Text>
                  </Paragraph>
                </Col>
                <Col xs={24} md={2} className={styles.alignRight}>
                  <Popconfirm
                    onConfirm={() => handleDeleteSharingToken(id)}
                    title="Are you sure you want to delete this token?"
                  >
                    <Button ariaLabel="Delete" icon={<DeleteOutlined />} size="sm" />
                  </Popconfirm>
                </Col>
              </Row>
            </div>
          );
        })}
    </Card>
  );
};

export default SharingTokens;
