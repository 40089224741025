export enum Keys {
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  Backspace = 'Backspace',
  Enter = 'Enter',
  Escape = 'Escape',
  Space = 'Space',
  Tab = 'Tab',
}
