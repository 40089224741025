import React from 'react';
import Select, { SelectProps } from '@openloop/limbic/Form/Select/Select';

import otherProfessions from '~Constants/otherProfessions';
import { PartialBy } from '~Types/utils';

interface Props extends PartialBy<Omit<SelectProps, 'options'>, 'placeholder'> {}

const otherProfessionsOptions = otherProfessions.map((profession) => ({
  value: profession,
  label: profession,
}));

const OtherProfessionSelect = ({ id, name, placeholder = 'Type of Profession' }: Props) => (
  <Select placeholder={placeholder} id={id} name={name} options={otherProfessionsOptions} />
);

export default OtherProfessionSelect;
