import React, { Children, forwardRef, HTMLAttributes, ReactNode, useMemo, useState } from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@reach/disclosure';
import classnames from 'classnames/bind';

import styles from './ShowMore.module.scss';

const cx = classnames.bind(styles);

interface Props {
  className?: string;
  children?: ReactNode | Array<ReactNode>;
  minItems?: number;
  showLessAriaLabel: string;
  showLessText?: ReactNode;
  showMoreAriaLabel: string | ((numMore: number) => string);
  showMoreText?: ReactNode;
}

interface ShowMoreButtonProps extends HTMLAttributes<HTMLButtonElement> {
  className?: string;
}

const ShowMoreButton = forwardRef<HTMLButtonElement, ShowMoreButtonProps>(
  ({ className, ...rest }, ref) => (
    <button className={cx('showMoreButton', className)} ref={ref} type="button" {...rest} />
  ),
);

const ShowMore = ({
  className,
  children: childrenProp,
  minItems,
  showLessText = 'Show less',
  showLessAriaLabel,
  showMoreText: showMoreTextProp,
  showMoreAriaLabel: showMoreAriaLabelProp,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [minChildren, disclosureChildren] = useMemo<[Array<ReactNode>, Array<ReactNode>?]>(() => {
    const childrenArray = Children.toArray(childrenProp);
    if (minItems && childrenArray.length > minItems) {
      return [childrenArray.slice(0, minItems), childrenArray.slice(minItems)];
    }
    return [childrenArray];
  }, [childrenProp, minItems]);

  const showMoreText = showMoreTextProp || `Show ${disclosureChildren?.length} more`;
  const showMoreAriaLabel: string =
    typeof showMoreAriaLabelProp === 'string'
      ? showMoreAriaLabelProp
      : showMoreAriaLabelProp(disclosureChildren?.length || 0);

  return (
    <>
      {minChildren}
      {disclosureChildren && disclosureChildren.length > 0 && (
        <Disclosure open={isOpen} onChange={() => setIsOpen(!isOpen)}>
          <DisclosurePanel className={className}>{disclosureChildren}</DisclosurePanel>
          <DisclosureButton
            aria-label={isOpen ? showLessAriaLabel : showMoreAriaLabel}
            as={ShowMoreButton}
          >
            {isOpen ? showLessText : showMoreText}
          </DisclosureButton>
        </Disclosure>
      )}
    </>
  );
};

export default ShowMore;
