import { ApolloError } from '@apollo/client';
import { message } from 'antd';

import {
  AppProfessionalContextFieldsFragment,
  ClinicAdminJobQuery,
  ClinicQuery,
  NurseProfessionalContext,
  OtherProfessionalContext,
  PhysicianProfessionalContext,
  TherapistProfessionalContext,
  UserAddress,
  UserAddressFieldsFragment,
  UserRole,
} from './generated';

export { default as cache } from './cache';
export * from './generated';
export * from './hooks';
export { default as typeDefs } from './graphql/typeDefs';

export type ProviderProfessionalContext =
  // @TODO create a SINGLE type to act as the source of truth for these. don't depend upon fragments
  | AppProfessionalContextFieldsFragment
  | NurseProfessionalContext
  | OtherProfessionalContext
  | PhysicianProfessionalContext
  | TherapistProfessionalContext;

export type ProviderUserRole =
  | UserRole.App
  | UserRole.Nurse
  | UserRole.Other
  | UserRole.Physician
  | UserRole.Therapist;

export const providerRoles = [
  UserRole.App,
  UserRole.Nurse,
  UserRole.Other,
  UserRole.Physician,
  UserRole.Therapist,
];

export const providerUserRoles: ProviderUserRole[] = [
  UserRole.App,
  UserRole.Nurse,
  UserRole.Other,
  UserRole.Physician,
  UserRole.Therapist,
];

export type OneAddress = UserAddress | UserAddressFieldsFragment;

export type OneJob = ClinicAdminJobQuery['clinicAdminJob'] | ClinicQuery['clinic']['jobs'][number];

export type ClinicContacts = ClinicQuery['clinic']['contacts'];

export const displayGraphQLErrors = (error: ApolloError | undefined): void => {
  if (error) {
    const { graphQLErrors, networkError } = error;
    graphQLErrors.forEach((graphqlError) => {
      message.error(graphqlError.message);
    });
    if (networkError) {
      message.error(networkError.message);
    }
  }
};

export const transformNullableValues = <
  ReturnType,
  InputType extends Record<string, any> = Record<string, unknown>,
>(
  obj: InputType,
): ReturnType =>
  Object.entries(obj).reduce(
    (accum, [key, val]) => ({
      ...accum,
      [key]: typeof val === 'string' && val.trim() === '' ? null : val,
    }),
    {} as ReturnType,
  );
