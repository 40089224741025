import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  activeJobApplications: {
    id: 'ClinicJobsList.activeJobApplications',
    defaultMessage: `{numActiveApplications, plural,
      =1 {# Applicant}
      other {# Applicants}
    }`,
    description: 'Number of active job applications towards a particular job',
  },
  editJob: {
    defaultMessage: 'Edit Job',
    description: 'Edit Job',
    id: 'components.ClinicJobsListItem.editJob',
  },
  viewListing: {
    defaultMessage: 'View Listing',
    description: 'View Listing',
    id: 'components.ClinicJobsListItem.viewListing',
  },
  viewCandidates: {
    defaultMessage: 'View Candidates',
    description: 'View Candidates',
    id: 'components.ClinicJobsListItem.viewCandidates',
  },
});
