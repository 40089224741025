import React, { useEffect } from 'react';
import { getIn, useFormikContext } from 'formik';
import { Form } from 'antd';
import { defineMessages, useIntl } from 'react-intl';

import Select from '@openloop/limbic/Form/Select/Select';

import { useStatesQuery, useCitiesLazyQuery } from '~Data';
import { getAdjacentFieldErrors } from '~Helpers/forms';

import styles from './CityStateSelect.module.less';

const intlMessages = defineMessages({
  formLabel: {
    defaultMessage: 'Location',
    description: 'Location label',
    id: 'Core.CityStateSelect.formLabel',
  },
});

interface Props {
  required?: boolean;
  cityName?: string;
  stateName?: string;
}

const CityStateSelect = ({ cityName = 'cityId', required, stateName = 'stateId' }: Props) => {
  const { formatMessage } = useIntl();

  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext();

  const cityValue = getIn(values, cityName);
  const stateValue = getIn(values, stateName);

  const { data: statesData, loading: statesLoading } = useStatesQuery();

  const [fetchCities, { data: citiesData, loading: citiesLoading }] = useCitiesLazyQuery();

  useEffect(() => {
    if (stateValue) {
      fetchCities({ variables: { stateId: stateValue } });
    }
  }, [cityValue, fetchCities, stateValue]);

  const handleStateChange = () => {
    if (cityValue) {
      setFieldValue(cityName, '');
    }
    setFieldTouched(cityName, false);
  };

  const statesSelectOptions =
    statesData &&
    statesData.states.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const citiesSelectOptions =
    citiesData && citiesData.cities.map(({ id, name }) => ({ value: id, label: name }));

  let hasErrors = false;
  const cityTouched = !!getIn(touched, 'cityId');

  if (cityTouched) {
    hasErrors = getAdjacentFieldErrors('stateId', 'cityId', errors, touched);
  }

  return (
    <Form.Item
      label={formatMessage(intlMessages.formLabel)}
      required={required}
      colon={false}
      validateStatus={hasErrors ? 'error' : 'success'}
      help={hasErrors}
    >
      <div className={styles.selectGroup}>
        <div className={styles.select}>
          <Select
            id="stateSelectId"
            isClearable
            isLoading={statesLoading}
            name={stateName}
            onChange={handleStateChange}
            placeholder="Select a State"
            options={statesSelectOptions || []}
          />
        </div>
        <div className={styles.select}>
          <Select
            id="citySelectId"
            isClearable
            isDisabled={!stateValue || citiesLoading}
            isLoading={citiesLoading}
            name={cityName}
            placeholder="Select a City"
            options={citiesSelectOptions || []}
          />
        </div>
      </div>
    </Form.Item>
  );
};

export default CityStateSelect;
