import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { Empty, notification, Spin } from 'antd';
import Card from '@openloop/limbic/Card';
import Grid from '@openloop/limbic/Grid';
import Heading from '@openloop/limbic/Heading';
import Tag from '@openloop/limbic/Tag';
import MDEditor from '@uiw/react-md-editor';

import ViewCVModal from '~Components/ViewCVButton';
import { employmentTypeChoices } from '~Constants/employmentTypes';
import images from '~Constants/images';
import messages from '~Constants/messages';
import { clinicRoutes, guestRoutes } from '~Constants/routes';
import { trainingLevelsByType } from '~Constants/trainingLevels';
import { useAuthContext } from '~Context/AuthContext';
import SEO from '~Core/SEO';
import { EmploymentType, LicenseFieldsFragment } from '~Data';
import { getProviderLevelOfTraining } from '~Helpers/users';
import { FeatureFlag, useFeatureFlag } from '~Hooks/useFeatureFlag';
import LicensesCard from '~Modules/shared/ReviewClinician/LicensesCard';

import { useUserBySharingTokenQuery } from './ReviewClinician.generated';

import styles from './ReviewClinician.module.scss';

const { Row, Col } = Grid;

const NOT_PROVIDED_TEXT = 'Not Provided';

const intlMessages = defineMessages({
  availability: {
    defaultMessage: 'Availability:',
    description: 'Availability label',
    id: 'shared.ReviewClinician.availability',
  },
  topReasons: {
    defaultMessage: 'Top Reasons We Picked This Clinician For {clinicName}',
    description: 'Top Reasons text',
    id: 'shared.ReviewClinician.topReasons',
  },
});

const CLIENT_SUPPORT_EMAIL = 'clientsupport@openloophealth.com';

const ReviewClinician = () => {
  const isSharingTokensV2Enabled = useFeatureFlag(FeatureFlag.SharingTokensV2);
  const { currentUser } = useAuthContext();
  const [, notificationContextHolder] = notification.useNotification();

  const { tokenId } = useParams<{ tokenId: string }>();

  const { data: candidateData, loading: candidateLoading } = useUserBySharingTokenQuery({
    onError: () => {
      notification.error({
        description: (
          <>
            Either the clinician doesn't exist, or the link has expired. If you feel this is an
            error, please contact us at:{' '}
            <a href={`mailto:${CLIENT_SUPPORT_EMAIL}`}>{CLIENT_SUPPORT_EMAIL}</a>.
          </>
        ),
        duration: 0,
        message: messages.clinicianFetchError,
      });
    },
    variables: { tokenId },
  });

  const { formatMessage } = useIntl();

  if (candidateLoading) {
    return (
      <Row align="middle" className={styles.fullScreen} justify="center">
        <Col>
          <Spin size="large" tip="Loading Clinician..." />
        </Col>
      </Row>
    );
  }

  if (!candidateData) {
    return (
      <Row align="middle" className={styles.fullScreen} justify="center">
        <Col>
          <Empty description="There was an error loading this clinician's profile." />
        </Col>
      </Row>
    );
  }

  const { userBySharingToken } = candidateData;

  if ('isExpired' in userBySharingToken || 'isNotFound' in userBySharingToken) {
    return (
      <Row align="middle" className={styles.fullScreen} justify="center">
        <Col>
          <Empty
            description={
              <>
                Either the clinician doesn't exist, or the link has expired. If you feel this is an
                error, please contact us at:{' '}
                <a href={`mailto:${CLIENT_SUPPORT_EMAIL}`}>{CLIENT_SUPPORT_EMAIL}</a>.
              </>
            }
          />
        </Col>
      </Row>
    );
  }

  const {
    clientDescription,
    user: {
      id,
      profile: { cv, displayName, image, professionalContext, licenses: selfReportedLicenses },
      verifiedActiveLicenses,
    },
    jobApplication,
  } = userBySharingToken;

  const licenses = [
    ...selfReportedLicenses.reduce((acc, selfReportedLicense) => {
      const { stateId } = selfReportedLicense;
      const verifiedLicense = verifiedActiveLicenses.find(
        ({ stateId: verifiedStateId }) => verifiedStateId === stateId,
      );
      if (!verifiedLicense) {
        acc.push(selfReportedLicense);
      }
      return acc;
    }, [] as LicenseFieldsFragment[]),
    ...verifiedActiveLicenses,
  ].sort((a, b) => {
    if (a.state.shortName < b.state.shortName) {
      return -1;
    }
    if (a.state.shortName > b.state.shortName) {
      return 1;
    }
    return 0;
  });

  const { type: userType } = professionalContext;

  let jobTypeSeeking;
  if ('jobTypeSeeking' in professionalContext) {
    jobTypeSeeking = professionalContext.jobTypeSeeking;
  }

  let specialty;
  if ('specialty' in professionalContext) {
    specialty = professionalContext.specialty;
  }

  const levelOfTraining = getProviderLevelOfTraining(professionalContext);
  const trainingLevels = trainingLevelsByType[userType];
  const levelOfTrainingText =
    levelOfTraining && trainingLevels ? trainingLevels[levelOfTraining] : undefined;

  const secondaryHeadingText =
    specialty && levelOfTraining
      ? `${specialty} - ${levelOfTrainingText}`
      : specialty || levelOfTrainingText;

  return (
    <main className={styles.main}>
      <SEO title={displayName} />
      {notificationContextHolder}
      <Row justify="center">
        <Col xs={22} md={20} lg={18}>
          <Link to={currentUser ? clinicRoutes.dashboard : guestRoutes.login}>
            <img alt="OpenLoop Logo" className={styles.logo} src={images.logo} />
          </Link>
        </Col>
        <Card className={styles.infoCard}>
          <div className={styles.basicInfoRow}>
            <div className={styles.colPhoto}>
              <img
                alt={displayName}
                className={styles.clinicianPhoto}
                src={image ? image.location : images.placeholderDoctor}
              />
            </div>
            <div className={styles.colInfo}>
              <div>
                <Heading level="h1" size="h4">
                  {displayName}
                </Heading>
                {secondaryHeadingText && (
                  <Heading level="h2" size="h5" className={styles.specialty}>
                    {secondaryHeadingText}
                  </Heading>
                )}
              </div>
              <div className={styles.extraInfoRow}>
                <div className={styles.availabilityItems}>
                  <span>{formatMessage(intlMessages.availability)}</span>
                  {jobTypeSeeking && jobTypeSeeking.length > 0
                    ? jobTypeSeeking.map((jobType) => (
                        <Tag key={jobType} className={styles.jobTypeTag}>
                          {employmentTypeChoices[jobType as EmploymentType]}
                        </Tag>
                      ))
                    : NOT_PROVIDED_TEXT}
                </div>
                <div>{cv && <ViewCVModal userId={id} />}</div>
              </div>
            </div>
          </div>
        </Card>
      </Row>
      <Row justify="center">
        <div className={styles.infoCard}>
          <LicensesCard licenses={licenses} />
        </div>
      </Row>
      {isSharingTokensV2Enabled && clientDescription && jobApplication && (
        <Row justify="center">
          <Card className={styles.infoCard}>
            <Heading level="h3" className={styles.topReasons}>
              {formatMessage(intlMessages.topReasons, {
                clinicName: jobApplication.job.clinic.name,
              })}
            </Heading>
            <div>
              <MDEditor.Markdown data-color-mode="light" source={clientDescription} />
            </div>
          </Card>
        </Row>
      )}
    </main>
  );
};

export default ReviewClinician;
