import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  dashboard: {
    defaultMessage: 'Dashboard',
    description: 'Dashboard NavLink',
    id: 'components.clinic.Header.dashboard',
  },
  integrations: {
    defaultMessage: 'Integrations',
    description: 'Integrations NavLink',
    id: 'components.clinic.Header.integrations',
  },
  logOut: {
    defaultMessage: 'Log Out',
    description: 'Log Out NavLink',
    id: 'components.clinic.Header.logOut',
  },
});
