import React from 'react';
import { FormattedDate } from 'react-intl';

interface IntlDateProps {
  date: Date;
}

const IntlDate = ({ date }: IntlDateProps) => (
  <FormattedDate value={date} year="numeric" month="numeric" day="numeric" />
);

export default IntlDate;
