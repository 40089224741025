import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  adminLoggedIn: {
    defaultMessage: "You're currently logged in as an admin. ",
    description: 'Message logged in as an admin',
    id: 'AdminBar.adminLoggedIn',
  },
  visitDashboard: {
    defaultMessage: 'Visit Dashboard',
    description: 'Visit Dashboard Message',
    id: 'AdminBar.visitDashboard',
  },
});
