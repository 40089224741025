import React from 'react';
import PatientCardListItem, { PatientCardListItemProps } from '~Components/PatientCardListItem';

import styles from './PatientCardList.module.scss';

interface PatientCardListProps {
  patients: Array<PatientCardListItemProps>;
  onSelectedPatient: (id: PatientCardListItemProps['id']) => void;
  className?: string;
  patientCardClassName?: string;
}

const PatientCardList = ({
  patients,
  onSelectedPatient,
  className = '',
  patientCardClassName = '',
}: PatientCardListProps) => {
  return (
    <div className={`${styles.patientCardListContainer} ${className}`}>
      {patients &&
        patients.map(({ id, isBeingViewed, ...rest }) => (
          <button
            type="button"
            key={id}
            disabled={isBeingViewed}
            onClick={() => onSelectedPatient(id)}
            onKeyDown={(e) => e.key === 'Enter' && onSelectedPatient(id)}
          >
            <PatientCardListItem
              id={id}
              isBeingViewed={isBeingViewed}
              className={patientCardClassName}
              {...rest}
            />
          </button>
        ))}
    </div>
  );
};

export default PatientCardList;
