import React, { useState } from 'react';

import { defineMessages, useIntl } from 'react-intl';
import { Button, Card, Grid, Heading, Icon, toast } from '@openloop/limbic';

import { useResetUserInvitationTokenMutation } from './ExpiredTokenScreen.generated';
import LoadingScreen from '~Modules/shared/LoadingScreen';
import Paragraph from '~Core/Paragraph';
import { Navigation } from '~Components/Navigation';

import styles from '../InviteRegister.module.scss';

const intlMessages = defineMessages({
  success: {
    defaultMessage: 'Success',
    description: 'Success message title',
    id: 'Modules.provider.Invite.successTitle',
  },
  successDescription: {
    defaultMessage:
      "We've created another sign-up link and sent it to you. Please check your email and try using it again",
    description: 'Description for Success after correctly resending user invitation link',
    id: 'Modules.provider.Invite.successDescription',
  },
  warning: {
    defaultMessage: 'Oops!{br}Your sign-up link has expired',
    description: 'Title for expired Invitation Link warning',
    id: 'Modules.provider.Invite.warning',
  },
  warningDescription: {
    defaultMessage: "Don't worry. We'll send you a new one.",
    description: 'Description for expired Invitation Link warning',
    id: 'Modules.provider.Invite.warningDescription',
  },
  resendLink: {
    defaultMessage: 'Resend invitation link',
    description: 'Label for Resend Invitation Link button',
    id: 'Modules.provider.Invite.resendLink',
  },
});
const { Row } = Grid;

interface Props {
  userInvitationId: string;
}

const ExpiredTokenScreen = ({ userInvitationId }: Props) => {
  const [resetUserInvitationToken, { loading }] = useResetUserInvitationTokenMutation();
  const [isInvitationReset, setIsInvitationReset] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <>
      <Navigation logoRoute="/" />
      <div className={styles.content}>
        <Row justify="center">
          <Card className={styles.expirationContainer}>
            {loading && <LoadingScreen />}
            {isInvitationReset && (
              <>
                <Icon
                  name="CheckmarkCircle"
                  size="largest"
                  variant="success"
                  className={styles.successIcon}
                />
                <Heading level="h1" size="h2" className={styles.textCentered}>
                  {formatMessage(intlMessages.success)}
                </Heading>
                <Paragraph style={{ fontSize: '18px', fontWeight: 500 }}>
                  {formatMessage(intlMessages.successDescription)}
                </Paragraph>
              </>
            )}
            {!isInvitationReset && !loading && (
              <>
                <Icon name="AlertCircle" size="largest" variant="info" />
                <Heading level="h1" size="h3" className={styles.textCentered}>
                  {formatMessage(intlMessages.warning, { br: <br /> })}
                </Heading>
                <Paragraph>{formatMessage(intlMessages.warningDescription)}</Paragraph>
                <Button
                  block
                  size="lg"
                  onClick={() =>
                    resetUserInvitationToken({
                      variables: { userInvitationId },
                      onCompleted: () => setIsInvitationReset(true),
                      onError: (e) => toast({ variant: 'danger', message: e.message }),
                    })
                  }
                >
                  {formatMessage(intlMessages.resendLink)}
                </Button>
              </>
            )}
          </Card>
        </Row>
      </div>
    </>
  );
};

export default ExpiredTokenScreen;
