const otherProfessions = [
  'Certified Nurse Assistant',
  'Dietician',
  'EMT-B',
  'EMT-I',
  'EMT-P',
  'Medical Assistant',
  'Medical Lab Tech',
  'Nurse Assistant',
  'Occupational Therapy (OT)',
  'Pharmacist',
  'Phlebotomist',
  'Physical Therapy (PT)',
  'Radiology Tech',
  'Respiratory Therapy (RT)',
  'Speech Path/Therapy',
  'Ultrasound Tech',
  'Dental Student',
  'Nursing Student',
  'Medical Student',
  'Physician Assistant Student',
  'Radiology Technician Student',
  'Physician Therapy Student',
  'Chiropractor Student',
  'Surgical Technician',
  'Janitorial',
  'Housekeeping',
  'Other',
] as const;

export default otherProfessions;
