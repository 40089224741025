import React, { ComponentProps, useMemo } from 'react';
import { generatePath } from 'react-router-dom';

import { clinicRoutes } from '~Constants/routes';
import { AdminStatsQuery } from '~Data';

import SimpleList from './SimpleList';

interface Props {
  jobs: AdminStatsQuery['adminStats']['jobs'];
}

const JobList = ({ jobs }: Props) => {
  const listData = useMemo<ComponentProps<typeof SimpleList>['listData']>(
    () =>
      jobs.map(({ createdAt, clinicId, id: jobId, title }) => ({
        createdAt,
        href: generatePath(clinicRoutes.clinicJobEdit, {
          clinicId,
          jobId,
        }),
        key: jobId,
        title,
      })),
    [jobs],
  );

  return <SimpleList listData={listData} />;
};

export default JobList;
