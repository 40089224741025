import React from 'react';
import { Col, Row, Typography } from 'antd';
import Button from '@openloop/limbic/Button';
import Form from '@openloop/limbic/Form';
import Heading from '@openloop/limbic/Heading';
import * as yup from 'yup';
import { useIntl } from 'react-intl';

import FormikForm from '~Core/Form/Form';
import FormItem from '~Core/Form/FormItem';
import { useConfigureClinicDrChronoIntegrationMutation } from '~Data';
import { intlMessagesClientInfo } from './intlMessages';

const { Input } = Form;
const { Paragraph, Text } = Typography;

interface FormValues {
  clientId: string;
  clientSecret: string;
}

interface Props {
  clinicId: string;
  redirectUri: string;
}

const validationSchema: yup.SchemaOf<FormValues> = yup.object().shape({
  clientId: yup.string().required('Client ID is required'),
  clientSecret: yup.string().required('Client secret is required'),
});

const DrChronoClientInfo = ({ clinicId, redirectUri }: Props) => {
  const { formatMessage } = useIntl();
  const [configureClinicDrChronoIntegration, { loading }] =
    useConfigureClinicDrChronoIntegrationMutation();

  const handleSubmit = (values: FormValues) => {
    configureClinicDrChronoIntegration({
      variables: { clinicId, input: { clinicId, redirectUri, ...values } },
    });
  };

  return (
    <FormikForm<FormValues>
      initialValues={{
        clientId: '',
        clientSecret: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, isValid }) => (
        <>
          <Heading level="h1">{formatMessage(intlMessagesClientInfo.title)}</Heading>
          <Paragraph>{formatMessage(intlMessagesClientInfo.description)}</Paragraph>
          <Paragraph>
            <ol>
              <li>
                {formatMessage(intlMessagesClientInfo.visit)}
                <a
                  href="https://drchrono.com/api-management/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* eslint-disable @calm/react-intl/missing-formatted-message */}
                  https://drchrono.com/api-management/
                </a>
              </li>
              <li>{formatMessage(intlMessagesClientInfo.item1)}</li>
              <li>
                {formatMessage(intlMessagesClientInfo.item2)}
                <pre>
                  <Text copyable>{redirectUri}</Text>
                </pre>
              </li>
              <li>{formatMessage(intlMessagesClientInfo.item3)}</li>
            </ol>
          </Paragraph>
          <FormItem label={formatMessage(intlMessagesClientInfo.clientId)} name="clientId">
            <Input name="clientId" placeholder={formatMessage(intlMessagesClientInfo.clientId)} />
          </FormItem>
          <FormItem label={formatMessage(intlMessagesClientInfo.clientSecret)} name="clientSecret">
            <Input
              name="clientSecret"
              placeholder={formatMessage(intlMessagesClientInfo.clientSecret)}
              type="password"
            />
          </FormItem>
          <Row justify="end">
            <Col>
              <Button disabled={!isValid || !dirty} loading={loading} type="submit">
                {formatMessage(intlMessagesClientInfo.next)}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </FormikForm>
  );
};

export default DrChronoClientInfo;
