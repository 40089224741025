import { useCallback } from 'react';

import { EditUserProfileInput, SignUpInput, UserRole, useServerSignUpMutation } from '~Data';
import { useSignUpMutation } from './useSignUpMutation';

interface ProviderSignUpInput extends Omit<SignUpInput, 'role'> {
  role: Omit<UserRole, UserRole.Clinic | UserRole.Admin>;
}

export const useProviderSignUpMutation = (): [
  (input: ProviderSignUpInput, userProfileInput: EditUserProfileInput) => void,
  ReturnType<typeof useServerSignUpMutation>[1],
] => {
  const [signup, result] = useSignUpMutation();
  const performSignup = useCallback(
    (input: ProviderSignUpInput, userProfileInput: EditUserProfileInput) => {
      signup(input as SignUpInput, userProfileInput);
    },
    [signup],
  );
  return [performSignup, result];
};
