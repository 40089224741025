import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Badge from '@openloop/limbic/Badge';
import Icon from '@openloop/limbic/Icon';
import Tab from '@openloop/limbic/Tab';
import { SingleTabProp } from '@openloop/limbic/Tab/TabComponents/Tab';
import { TabPanelProps } from '@openloop/limbic/Tab/TabComponents/TabPanel';
import Tooltip from '@openloop/limbic/Tooltip';
import { defineMessages, useIntl } from 'react-intl';

import Heading from '@openloop/limbic/Heading';
import PatientCardList from '~Components/PatientCardList/PatientCardList';
import { PatientCardListItemProps } from '~Components/PatientCardListItem';
import images from '~Constants/images';
import { providerRoutes } from '~Constants/routes';
import { TagStatus } from '~Constants/TagStatus';
import Link from '~Core/Link';
import SEO from '~Core/SEO';
import { FeatureFlag, useFeatureFlag } from '~Hooks/useFeatureFlag';
import PatientInfoBox from './PatientInfoBox';

import styles from './Patients.module.scss';
import { PatientProfile } from './PatientInfoBox/PatientInfoBox';

const tabItems: SingleTabProp[] = [
  { label: 'new', unreadCount: 2, children: 'new' },
  { label: 'pending', unreadCount: 5, children: 'pending' },
  { label: 'completed', children: 'completed' },
];

export type NewAndAvailablePatients = PatientCardListItemProps & {
  isAvailable?: boolean;
} & Partial<{ profile: PatientProfile }>;

const patientsList: Array<NewAndAvailablePatients> = [
  {
    id: '000',
    name: 'Mr. Garvey',
    companyName: 'Substitue Teacher',
    companyLogo: images.boldHealth,
    dateTime: '1 Day Ago',
    tags: [TagStatus.NEW_PATIENT],
    voicemailCount: 1,
    profile: {
      state: 'Iowa',
      dob: '1/17/1982',
      gender: 'F',
      medications: [
        'Allegra, 30 Tablets, 2x Daily,  Refill 3,  02/19/20',
        'Prinivil, 5 capsules, 1 at Bedtime, No Refills, 02/19/20',
        'Metformin HCL Tab, 1000MG, 1 Tablet by Mouth Daily, Qty Filled 8 of 10, 02/19/21',
      ],
      allergies: {
        Medicine: 'Amoxicillin, Naproxen',
        Other: 'Animal dander, Strawberries, Pollen, Latex, Mold',
      },
      patientID: images.laptopLamp,
    },
  },
  {
    id: '001',
    name: 'Meegan Peele',
    companyName: 'Key and Peele',
    companyLogo: images.minuteMd,
    dateTime: '10/10/2021, 5:30 PM',
    tags: [TagStatus.CALL_BACK, TagStatus.LEFT_VOICEMAIL],
    callBackCount: 1,
  },
  {
    id: '002',
    name: 'Frank Ocean',
    companyName: 'Blonde Healthcare',
    companyLogo: images.minuteMd,
    dateTime: '10/10/2021, 5:30 PM',
    tags: [TagStatus.NEED_ASSISTANCE, TagStatus.LEFT_VOICEMAIL],
    callBackCount: 1,
  },
  {
    id: '003',
    name: 'Andre Key',
    companyName: 'Key and Peele',
    companyLogo: images.minuteMd,
    dateTime: '10/10/2021, 5:30 PM',
    tags: [TagStatus.RETURNING_PATIENT],
    callBackCount: 2,
    isBeingViewed: true,
  },
  {
    id: '004',
    name: 'Ozamataz Buckshank',
    companyName: 'Key and Peele',
    companyLogo: images.minuteMd,
    dateTime: '10/10/2021, 5:30 PM',
    tags: [TagStatus.CALL_BACK, TagStatus.LEFT_VOICEMAIL, TagStatus.NO_RESPONSE],
    callBackCount: 1,
    voicemailCount: 2,
    noResponseCount: 3,
  },
  {
    id: '005',
    name: 'Todd Jacobson',
    companyName: 'Chappelle Trading Spouses',
    companyLogo: images.boldHealth,
    dateTime: '1 Day Ago',
    tags: [TagStatus.RETURNING_PATIENT],
    profile: {
      state: 'Florida',
      dob: '3/22/2005',
      gender: 'M',
      medications: [
        'Allegra, 30 Tablets, 2x Daily,  Refill 3,  02/19/20',
        'Prinivil, 5 capsules, 1 at Bedtime, No Refills, 02/19/20',
        'Metformin HCL Tab, 1000MG, 1 Tablet by Mouth Daily, Qty Filled 8 of 10, 02/19/21',
      ],
      allergies: {
        Medacine: 'Amoxicillin, Naproxen',
        Other: 'Animal dander, Strawberries, Pollen, Latex, Mold',
      },
      patientID: images.laptopLamp,
    },
  },
  {
    id: '006',
    name: 'Ralph Henderson',
    companyName: 'PopCopy',
    companyLogo: images.minuteMd,
    dateTime: '10/10/2021, 5:30 PM',
    tags: [TagStatus.NEW_PATIENT],
    isAvailable: true,
  },
  {
    id: '007',
    name: 'Bo Jackson',
    companyName: 'Key and Peele',
    companyLogo: images.minuteMd,
    dateTime: '10/10/2021, 5:30 PM',
    tags: [TagStatus.APPROVED],
  },
  {
    id: '008',
    name: 'Rick James',
    companyName: 'Chappelle Show',
    companyLogo: images.minuteMd,
    dateTime: '10/10/2021, 5:30 PM',
    tags: [TagStatus.NEW_PATIENT],
    isAvailable: true,
  },
  {
    id: '009',
    name: 'Mordecai Lune',
    companyName: 'Regular Show',
    companyLogo: images.minuteMd,
    dateTime: '12/1/2021, 10:30 PM',
    tags: [TagStatus.DENIED],
  },
];

const intlMessages = defineMessages({
  availablePatientsHeading: {
    defaultMessage: 'Available Patients',
    description: 'Available patients heading text',
    id: 'Modules.provider.Patients.availablePatientsHeading',
  },
  availablePatientsTooltipDescription: {
    defaultMessage: 'Pool of patients you can snag for extra pay.',
    description: 'Available patients tooltip description',
    id: 'Modules.provider.Patients.availablePatientsTooltipDescription',
  },
  myPatientsHeading: {
    defaultMessage: 'My Patients',
    description: 'My patient heading text',
    id: 'Modules.provider.Patients.myPatientsHeading',
  },
  myPatientsTooltipDescription: {
    defaultMessage: 'Patients assigned directly to you to complete.',
    description: 'My patients tooltip description',
    id: 'Modules.provider.Patients.myPatientsTooltipDescription',
  },
  selectPatientInfoText: {
    defaultMessage: 'Please select a patient to view their information.',
    description: 'Select patient text',
    id: 'Modules.provider.Patients.selectPatientInfoText',
  },
  patientProfileIncompleteText: {
    defaultMessage: "This patient's profile has NOT been completed.",
    description: 'Incomplete patient profile text',
    id: 'Modules.provider.Patients.patientProfileIncompleteText',
  },
  eRxNotification: {
    defaultMessage: 'eRx Notification',
    description: 'ePrescription Notifications',
    id: 'Modules.provider.Patients.eRxNotification',
  },
  patientQueueHeading: {
    defaultMessage: 'Patient Queue',
    description: 'Patient queue heading text',
    id: 'Modules.provider.Patients.patientQueueHeading',
  },
});

const Patients = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const isAsyncQueueEnabled = useFeatureFlag(FeatureFlag.AsyncQueue);
  const [patients, setPatients] = useState<Array<NewAndAvailablePatients>>(patientsList);
  const [availablePatients, setAvailablePatients] = useState<Array<NewAndAvailablePatients>>();
  const [selectedPatient, setSelectedPatient] = useState<NewAndAvailablePatients>();

  useEffect(() => {
    const updateAvailablePatients = patients?.filter((patient) => patient.isAvailable);
    if (!selectedPatient) {
      setSelectedPatient(patients.find((patient) => patient.isSelected));
    }
    setAvailablePatients(updateAvailablePatients);
  }, [patients, selectedPatient]);

  useEffect(() => {
    if (!isAsyncQueueEnabled) {
      navigate(providerRoutes.dashboard);
    }
  }, [isAsyncQueueEnabled, navigate]);

  const updateSelectedPatient = (id: PatientCardListItemProps['id']) => {
    const updatedPatients = patients.map((patient) => {
      const tempPatient = patient;

      // TODO: refactor if possible to make it easier to read
      if (id !== patient.id && patient.isSelected) {
        tempPatient.isSelected = !tempPatient.isSelected;
        // signal to set: patient.isBeingViewed = false
      } else if (id === patient.id && !patient.isBeingViewed) {
        tempPatient.isSelected = !tempPatient.isSelected;
        if (selectedPatient?.id === patient.id) {
          setSelectedPatient(undefined);
          // signal to set: patient.isBeingViewed = false
        } else {
          setSelectedPatient(patient);
        }

        // signal to set: patient.isBeingViewed = true
      }
      return tempPatient;
    });
    setPatients(updatedPatients);
  };

  const tabPanelItems: TabPanelProps[] = [
    {
      label: 'new',
      panelDisplayTitle: (
        <>
          {/* TODO this should be a heading element */}
          <span className={styles.titleSpan}>{formatMessage(intlMessages.myPatientsHeading)}</span>
          <Tooltip
            tooltipDescription={formatMessage(intlMessages.myPatientsTooltipDescription)}
            tooltipPosition="right"
          >
            <Icon name="InfoCircle" variant="info" />
          </Tooltip>
        </>
      ),
      children: (
        <div className={styles.sectionsParentContainer}>
          <section className={styles.tabPanel}>
            <PatientCardList
              patients={patients.filter(
                ({ isAvailable, tags }) =>
                  (!isAvailable && tags.includes(TagStatus.NEW_PATIENT)) ||
                  tags.includes(TagStatus.RETURNING_PATIENT),
              )}
              onSelectedPatient={(id) => updateSelectedPatient(id)}
            />
          </section>
          {availablePatients && availablePatients.length > 0 && (
            <section className={styles.availablePatientsSection}>
              <div className={styles.subSectionTitle}>
                <Heading uppercase level="h6" size="h6" className={styles.titleSpan}>
                  {formatMessage(intlMessages.availablePatientsHeading)}
                </Heading>
                <Tooltip
                  tooltipDescription={formatMessage(
                    intlMessages.availablePatientsTooltipDescription,
                  )}
                  tooltipPosition="right"
                >
                  <Icon name="InfoCircle" variant="info" />
                </Tooltip>
              </div>
              <PatientCardList
                patients={availablePatients}
                onSelectedPatient={(id) => updateSelectedPatient(id)}
              />
            </section>
          )}
        </div>
      ),
    },
    {
      label: 'pending',
      panelDisplayTitle: (
        <>
          <span className={styles.titleSpan}>{formatMessage(intlMessages.myPatientsHeading)}</span>
          <Tooltip
            tooltipDescription={formatMessage(intlMessages.myPatientsTooltipDescription)}
            tooltipPosition="right"
          >
            <Icon name="InfoCircle" variant="info" />
          </Tooltip>
        </>
      ),
      children: (
        <section className={styles.tabPanel}>
          <PatientCardList
            patients={patients.filter(
              ({ isAvailable, tags }) =>
                (!isAvailable && tags.includes(TagStatus.LEFT_VOICEMAIL)) ||
                tags.includes(TagStatus.VOICEMAIL_FULL) ||
                tags.includes(TagStatus.NO_RESPONSE) ||
                tags.includes(TagStatus.CALL_BACK) ||
                tags.includes(TagStatus.NEED_ASSISTANCE),
            )}
            onSelectedPatient={(id) => updateSelectedPatient(id)}
          />
        </section>
      ),
    },
    {
      label: 'completed',
      panelDisplayTitle: (
        <>
          <span className={styles.titleSpan}>{formatMessage(intlMessages.myPatientsHeading)}</span>
          <Tooltip
            tooltipDescription={formatMessage(intlMessages.myPatientsTooltipDescription)}
            tooltipPosition="right"
          >
            <Icon name="InfoCircle" variant="info" />
          </Tooltip>
        </>
      ),
      children: (
        <section className={styles.tabPanel}>
          <PatientCardList
            patients={patients.filter(
              ({ tags }) => tags.includes(TagStatus.APPROVED) || tags.includes(TagStatus.DENIED),
            )}
            onSelectedPatient={(id) => updateSelectedPatient(id)}
          />
        </section>
      ),
    },
  ];

  return (
    <>
      <SEO title="View Patients" />
      <div className={styles.patientsContainer}>
        <div>
          <div className={styles.doseSpotNotificationBar}>
            <Link to={providerRoutes.prescribe} className={styles.doseSpotLink}>
              <img alt="pill" src={images.pill} className={styles.doseSpotImage} />
              <Badge className={styles.doseSpotLinkTitle} value={10}>
                <span>{formatMessage(intlMessages.eRxNotification)}</span>
              </Badge>
              <Icon name="ChevronRight" size="small" />
            </Link>
          </div>
          <section className={styles.patientQueueContainer}>
            <div>
              <div className={styles.sectionTitle}>
                {formatMessage(intlMessages.patientQueueHeading)}
              </div>
              <Tab
                tabItems={tabItems}
                panels={tabPanelItems}
                justifyTitles="center"
                className={styles.tabsSection}
              />
            </div>
            {/* Search hidden for now */}
            {/* <div className={styles.searchSection}>
            <input name="search" placeholder="Search Patient or Client" type="text" />
          </div> */}
          </section>
        </div>
        <section className={styles.patientInformationContainer}>
          {!selectedPatient && (
            <Heading className={styles.selectPatientHeading} level="h4">
              {formatMessage(intlMessages.selectPatientInfoText)}
            </Heading>
          )}
          {selectedPatient && !selectedPatient?.profile && (
            <Heading className={styles.selectPatientHeading} level="h4">
              {formatMessage(intlMessages.patientProfileIncompleteText)}
            </Heading>
          )}
          <PatientInfoBox patient={selectedPatient} />
        </section>
      </div>
    </>
  );
};

export default Patients;
