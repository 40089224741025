import React from 'react';
import { Row, Col } from 'antd';
import { useIntl } from 'react-intl';

import { useAuthContext } from '~Context/AuthContext';
import SEO from '~Core/SEO';
import BasicUserInfo from '~Modules/provider/Profile/BasicUserInfo';
import { intlMessages } from './intlMessages';

import styles from './Profile.module.less';

const Profile = () => {
  const { formatMessage } = useIntl();
  const { currentUser } = useAuthContext();

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <SEO title={formatMessage(intlMessages.myProfile)} />
      <div className={styles.main}>
        <Row justify="center">
          <Col xs={20} xl={16}>
            <BasicUserInfo user={currentUser} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Profile;
