import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  name: {
    defaultMessage: 'Name',
    description: 'Name label',
    id: 'components.clinic.Forms.ClinicFields.name',
  },
  phoneNumber: {
    defaultMessage: 'Phone number',
    description: 'Phone number label',
    id: 'components.clinic.Forms.ClinicFields.phoneNumber',
  },
  website: {
    defaultMessage: 'Website',
    description: 'Website label',
    id: 'components.clinic.Forms.ClinicFields.website',
  },
  zohoUrl: {
    defaultMessage: 'Zoho URL',
    description: 'Zoho URL label',
    id: 'components.clinic.Forms.ClinicFields.zohoUrl',
  },
});
