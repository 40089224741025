import React, { useEffect } from 'react';
import { Button, Card, Form, Heading } from '@openloop/limbic';
import { useWizard } from 'react-use-wizard';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

import FormItem from '~Core/Form/FormItem';
import { useClinicLazyQuery, useClinicsQuery } from '~Data';
import { SuggestJobsValues } from '../types';
import ExitWizardButton from '../ExitWizardButton';
import { intlMessages } from './intlMessages';

import styles from '../InviteAndSuggest.module.scss';

const { Combobox, Select } = Form;

const JobSearchAndSelectScreen = () => {
  const { formatMessage } = useIntl();
  const { handleStep, previousStep, nextStep, isFirstStep } = useWizard();

  const { data, loading } = useClinicsQuery();

  const { dirty, isValid, setStatus, status, values } = useFormikContext<SuggestJobsValues>();
  const { firstName, lastName, clinicId, jobsToSuggestIds } = values;
  const [getClinicById, { data: clinicData, loading: clinicLoading }] = useClinicLazyQuery({
    variables: { id: clinicId },
  });
  useEffect(() => {
    if (clinicId) getClinicById({ variables: { id: clinicId } });
  }, [clinicId, getClinicById]);

  const clinicList = data?.clinics.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const activeClinicJobs = clinicData?.clinic.jobs.filter((job) => job.status === 'Active');

  const clinicJobsList = activeClinicJobs?.map(({ id, title }) => ({
    label: title,
    value: id,
  }));
  const jobTitles: { [key: string]: string } | undefined = activeClinicJobs?.reduce(
    (prevJobs, { id, title }) => {
      return { ...prevJobs, [id]: title };
    },
    {},
  );

  const selectedJobs = jobTitles
    ? jobsToSuggestIds?.map((jobId: string) => ({
        id: jobId,
        title: jobTitles[jobId],
      }))
    : undefined;

  handleStep(() => {
    setStatus({
      ...status,
      jobs: selectedJobs,
    });
  });

  return (
    <div className={styles.main}>
      <Card className={styles.card}>
        <div className={styles.cardInnerContainer}>
          <Heading level="h2" size="h3" className={styles.heading}>
            {formatMessage(intlMessages.findAndSelect)} {`${firstName} ${lastName}`}
          </Heading>
          <FormItem
            name="clinicId"
            label={formatMessage(intlMessages.selectClinic)}
            className={styles.formItem}
          >
            {({ inputId }) => (
              <Select
                name="clinicId"
                id={inputId}
                options={clinicList}
                placeholder="Select"
                isLoading={loading}
              />
            )}
          </FormItem>
          <FormItem
            label={formatMessage(intlMessages.selectJob)}
            name="jobsToSuggestIds"
            className={styles.formItem}
          >
            {({ inputId }) => (
              <Combobox
                id={inputId}
                isClearable
                name="jobsToSuggestIds"
                options={clinicJobsList}
                placeholder="Select"
                isDisabled={clinicId === ''}
                isLoading={clinicLoading}
              />
            )}
          </FormItem>
          <footer>
            <div className={styles.buttonRow}>
              {isFirstStep ? null : (
                <Button variant="secondary" className={styles.button} onClick={previousStep}>
                  {formatMessage(intlMessages.previousButton)}
                </Button>
              )}
              <Button
                className={styles.button}
                onClick={() => nextStep()}
                disabled={!(dirty && isValid)}
              >
                {formatMessage(intlMessages.nextButton)}
              </Button>
            </div>
            <ExitWizardButton />
          </footer>
        </div>
      </Card>
    </div>
  );
};

export default JobSearchAndSelectScreen;
