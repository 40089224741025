import React, { ComponentProps, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import Button from '@openloop/limbic/Button';
import Icon from '@openloop/limbic/Icon';
import Tooltip from '@openloop/limbic/Tooltip';

import { sharedRoutes } from '~Constants/routes';
import useCurrentOrigin from '~Hooks/useCurrentOrigin';
import { usePrevious } from '~Hooks/usePrevious';

import SharingTokenModal from '../../SharingTokenModal';
import DeleteSharingTokenModal from '../DeleteSharingTokenModal';
import { intlMessages } from './intlMessages';

import styles from './SharableProfileTokenCell.module.scss';

interface SharableProfileTokenCellProps {
  clinicName: string;
  jobApplicationId: string;
  sharableProfileToken:
    | ComponentProps<typeof SharingTokenModal>['sharableProfileToken']
    | undefined;
}

const SharableProfileTokenCell = ({
  clinicName,
  jobApplicationId,
  sharableProfileToken,
}: SharableProfileTokenCellProps) => {
  const { formatMessage } = useIntl();
  const currentOrigin = useCurrentOrigin();
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isSharingTokenModalOpen, setIsSharingTokenModalOpen] = useState<boolean>(false);
  const [isDeleteSharingTokenModalOpen, setIsDeleteSharingTokenModalOpen] =
    useState<boolean>(false);

  const prevIsCopied = usePrevious(isCopied);

  const copySharingToken = useCallback(() => {
    if (sharableProfileToken) {
      const sharableProfilePath = generatePath(sharedRoutes.reviewClinician, {
        tokenId: sharableProfileToken.token,
      });
      const sharableProfileUrl = `${currentOrigin}${sharableProfilePath}`;
      navigator.clipboard.writeText(sharableProfileUrl);
      setIsCopied(true);
    }
  }, [currentOrigin, sharableProfileToken]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let timeout: number | undefined;
      if (isCopied && !prevIsCopied) {
        timeout = window.setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      }

      return () => window.clearTimeout(timeout);
    }
    return () => {};
  }, [isCopied, prevIsCopied]);

  const hasValidToken =
    !!sharableProfileToken && new Date(sharableProfileToken.expiresAt) > new Date();

  return (
    <span className={styles.main}>
      {hasValidToken ? (
        <>
          <Tooltip
            tooltipPosition="top"
            tooltipDescription={
              isCopied
                ? formatMessage(intlMessages.copiedText)
                : formatMessage(intlMessages.copyText)
            }
          >
            <Button
              className={styles.copyButton}
              icon={<Icon name="Copy" />}
              onClick={copySharingToken}
              variant="secondary-borderless"
              size="sm"
            >
              {formatMessage(intlMessages.copyLink)}
            </Button>
          </Tooltip>
          <Button
            aria-label={formatMessage(intlMessages.editSharingToken)}
            className={styles.editButton}
            icon={<Icon name="Pencil" />}
            onClick={() => setIsSharingTokenModalOpen(true)}
            size="lg"
            variant="secondary-borderless"
          />
          <Button
            aria-label={formatMessage(intlMessages.deleteSharingToken)}
            className={styles.deleteButton}
            icon={<Icon name="Trash" />}
            onClick={() => setIsDeleteSharingTokenModalOpen(true)}
            size="lg"
            variant="danger-borderless"
          />
        </>
      ) : (
        <Button
          className={styles.createButton}
          icon={<Icon name="Plus" />}
          iconPlacement="before"
          onClick={() => setIsSharingTokenModalOpen(true)}
          size="sm"
          variant="primary-borderless"
        >
          {formatMessage(intlMessages.createLink)}
        </Button>
      )}
      <DeleteSharingTokenModal
        handleModalClose={() => setIsDeleteSharingTokenModalOpen(false)}
        isVisible={isDeleteSharingTokenModalOpen}
        jobApplicationId={jobApplicationId}
      />
      <SharingTokenModal
        clinicName={clinicName}
        jobApplicationId={jobApplicationId}
        onCancel={() => setIsSharingTokenModalOpen(false)}
        sharableProfileToken={sharableProfileToken}
        visible={isSharingTokenModalOpen}
      />
    </span>
  );
};

export default SharableProfileTokenCell;
