import React, { useState, useCallback, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Row, Popover, notification, Result, Col } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem } from 'formik-antd';
import * as Yup from 'yup';
import Button from '@openloop/limbic/Button';
import Input from '@openloop/limbic/Form/Input';
import Heading from '@openloop/limbic/Heading';
import { useIntl } from 'react-intl';

import images from '~Constants/images';
import { guestRoutes } from '~Constants/routes';
import Paragraph from '~Core/Paragraph';
import { useResetPasswordMutation, useVerifyPasswordResetTokenQuery } from '~Data';
import { intlMessages } from './intlMessages';

import styles from './ResetPassword.module.less';

interface FormValues {
  confirmPassword: string;
  password: string;
}

const ResetPassword = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string | null>(null);
  const { resetToken } = useParams<{ resetToken: string }>();

  const { data: verifiedTokenData, error: verifiedTokenError } = useVerifyPasswordResetTokenQuery({
    variables: { resetToken },
  });

  const [
    resetPasswordMutation,
    { data: resetPasswordData, error: resetPasswordError, loading: resetPasswordLoading },
  ] = useResetPasswordMutation();

  const onSubmit = useCallback(
    ({ confirmPassword, password }: FormValues) => {
      if (email) {
        resetPasswordMutation({
          variables: { input: { confirmPassword, email, password, resetToken } },
        });
      }
    },
    [email, resetPasswordMutation, resetToken],
  );

  useEffect(() => {
    if (verifiedTokenData) {
      const {
        verifyPasswordResetToken: { email: userEmail },
      } = verifiedTokenData;
      setEmail(userEmail);
    }
  }, [verifiedTokenData]);

  useEffect(() => {
    if (verifiedTokenError || resetPasswordError) {
      notification.error({
        message: 'Reset token is either invalid or expired. Please reset it again.',
        duration: 5,
      });
      navigate(guestRoutes.forgotPassword);
    }
  }, [navigate, resetPasswordError, verifiedTokenError]);

  const content = (
    <ul>
      <li>{formatMessage(intlMessages.listItem1)}</li>
      <li>{formatMessage(intlMessages.listItem2)}</li>
      <li>{formatMessage(intlMessages.listItem3)}</li>
    </ul>
  );

  return (
    <div className={styles.main}>
      <Row justify="center">
        <Col xs={20} sm={16} md={10} lg={8}>
          <img src={images.logo} className={styles.logo} alt="OpenLoop" />
          {(!resetPasswordData || !resetPasswordData.resetPassword) && (
            <>
              <div className={styles.introContent}>
                <Heading level="h1">{formatMessage(intlMessages.setNewPassword)}</Heading>
                <Paragraph>{formatMessage(intlMessages.setNewPasswordDesc)}</Paragraph>
                {email && (
                  <Paragraph strong>
                    {formatMessage(intlMessages.email)}: {email}
                  </Paragraph>
                )}
              </div>
              <Formik<FormValues>
                initialValues={{ confirmPassword: '', password: '' }}
                validationSchema={Yup.object().shape({
                  password: Yup.string()
                    .min(8, 'Password must longer than 8 characters')
                    .matches(/[A-Z]/, 'At least one uppercase character')
                    .matches(/[0-9]/, 'At least one numerical')
                    .required('Password is required'),
                  confirmPassword: Yup.string()
                    .oneOf([Yup.ref('password'), undefined], 'Password does not match')
                    .required('Confirm Password is required'),
                })}
                onSubmit={onSubmit}
              >
                {({ dirty, isValid }) => (
                  <Form>
                    <FormItem name="password">
                      <Popover content={content} title="" placement="bottom" trigger="focus">
                        <Input type="password" name="password" placeholder="New password" />
                      </Popover>
                    </FormItem>
                    <FormItem name="confirmPassword">
                      <Input
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm new password"
                      />
                    </FormItem>
                    <div>
                      <Button
                        block
                        disabled={!isValid || !dirty || !email}
                        variant="primary"
                        type="submit"
                        loading={resetPasswordLoading}
                      >
                        {formatMessage(intlMessages.changePassword)}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
          {resetPasswordData && resetPasswordData.resetPassword && (
            <div className={styles.success}>
              <Result
                status="success"
                subTitle="You can now sign in using your new password."
                title="Password Reset Successfully"
              />
              <Link to={guestRoutes.login}>
                <Button>{formatMessage(intlMessages.goBack)}</Button>
              </Link>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ResetPassword;
