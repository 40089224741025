import React from 'react';
import { Helmet } from 'react-helmet-async';

interface Props {
  description?: string;
  title: string;
}

const SEO = ({ description, title }: Props) => {
  return (
    <Helmet>
      <title>{`${title} | OpenLoop`}</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
};

export default SEO;
