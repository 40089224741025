import React, { ReactNode, ReactNodeArray } from 'react';
import classnames from 'classnames/bind';

import styles from './StatusBadge.module.scss';

const cx = classnames.bind(styles);

export interface StatusBadgeProps {
  iconType: string;
  text: ReactNode | ReactNodeArray;
}

const StatusBadge = ({ iconType, text }: StatusBadgeProps) => (
  <>
    <span className={cx(styles.statusIcon, iconType)} />
    <span className={styles.statusText}>{text}</span>
  </>
);

export default StatusBadge;
