import { encode } from 'querystring';

import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import deepEqual from 'fast-deep-equal';

import { Complete } from '~Types/utils';
import useQuerystringParams from './useQuerystringParams';

type SetFieldValueFn<Values> = (paramKey: keyof Values, value: Values[typeof paramKey]) => void;
type ResetFieldFn<Values> = (paramKey: keyof Values) => void;
type SetValuesFn<Values> = (values: Values) => void;

interface RouteStateConfig<Values> {
  defaultValues: Required<Values>;
}

type RouteState<Values> = [
  { values: Complete<Values> },
  {
    setFieldValue: SetFieldValueFn<Values>;
    setValues: SetValuesFn<Values>;
    resetField: ResetFieldFn<Values>;
  },
];

const toObjectWithValues = (
  accum: Record<string, any>,
  [k, v]: [string, any],
): Record<string, any> => {
  const newAccum = accum;
  if (v) {
    if (Array.isArray(v) && v.length === 0) {
      return newAccum;
    }
    newAccum[k] = v;
  }
  return newAccum;
};

export const useRouteState = <Values extends Record<string, any> = Record<string, never>>({
  defaultValues,
}: RouteStateConfig<Values>): RouteState<Values> => {
  const navigate = useNavigate();
  const location = useLocation();
  const querystringParams = useQuerystringParams();

  const [currentValues, setCurrentValues] = useState<Complete<Values>>(defaultValues);

  const getValuesFromQs = useCallback<() => Complete<Values>>(
    () =>
      Object.entries(defaultValues).reduce<Complete<Values>>((accum, [key, value]) => {
        let newVal: string | string[] | number | boolean | undefined = Array.isArray(value)
          ? querystringParams.getAll(key) || []
          : querystringParams.get(key) || undefined;
        if (typeof value === 'boolean' && (newVal === 'true' || newVal === 'false')) {
          newVal = newVal === 'true';
        }
        if (typeof value === 'number' && newVal !== undefined) {
          newVal = Number(newVal);
        }
        return {
          ...accum,
          [key]: newVal,
        };
      }, {} as Complete<Values>),
    [defaultValues, querystringParams],
  );

  const updateQsValues = useCallback<(newSearchParmas: Partial<Values>) => void>(
    (newSearchParmas) => {
      const potentialSearchParams = {
        ...currentValues,
        ...newSearchParmas,
      };
      const truthySearchParams: Record<string, any> = Object.entries(potentialSearchParams).reduce(
        toObjectWithValues,
        {},
      );
      const encodedParams = encode(truthySearchParams);
      const newRoute = `${location.pathname}?${encodedParams}`;
      navigate(newRoute, { replace: true });
    },
    [currentValues, navigate, location],
  );

  const setFieldValue = useCallback<SetFieldValueFn<Values>>(
    (paramKey, value) => {
      updateQsValues({ [paramKey]: value } as Values);
    },
    [updateQsValues],
  );

  const setValues = useCallback<SetValuesFn<Values>>(
    (values) => {
      updateQsValues(values);
    },
    [updateQsValues],
  );

  const resetField = useCallback<ResetFieldFn<Values>>(
    (paramKey) => {
      updateQsValues({
        [paramKey]: Array.isArray(defaultValues[paramKey] ? [] : undefined),
      } as Values);
    },
    [defaultValues, updateQsValues],
  );

  useEffect(() => {
    const qsValues = getValuesFromQs();
    if (!deepEqual(qsValues, currentValues)) {
      setCurrentValues(qsValues);
    }
  }, [currentValues, getValuesFromQs]);

  return [
    {
      values: currentValues,
    },
    {
      setFieldValue,
      setValues,
      resetField,
    },
  ];
};
