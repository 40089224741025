import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  openLoopAdmin: {
    defaultMessage: 'OpenLoop Admin',
    description: 'OpenLoop Admin',
    id: 'components.admin.LegacyNavigation.openLoopAdmin',
  },
  users: {
    defaultMessage: 'Users',
    description: 'Users',
    id: 'components.admin.LegacyNavigation.users',
  },
  clinics: {
    defaultMessage: 'Clinics',
    description: 'Clinics',
    id: 'components.admin.LegacyNavigation.clinics',
  },
  jobs: {
    defaultMessage: 'Jobs',
    description: 'Jobs',
    id: 'components.admin.LegacyNavigation.jobs',
  },
  inviteSuggest: {
    defaultMessage: 'Invite & Suggest',
    description: 'Invite & Suggest',
    id: 'components.admin.LegacyNavigation.inviteSuggest',
  },
});
