import {
  LevelOfTrainingApp,
  LevelOfTrainingNurse,
  LevelOfTrainingPhysician,
  UserRole,
} from '~Data';

export const trainingLevelsApp: Record<LevelOfTrainingApp, string> = {
  [LevelOfTrainingApp.Pa]: 'Physician Assistant (PA)',
  [LevelOfTrainingApp.Arnp]: 'Nurse Practicioner (ARNP)',
  [LevelOfTrainingApp.Crna]: 'Nurse Anesthetist (CRNA)',
};

export const trainingLevelsNurse: Record<LevelOfTrainingNurse, string> = {
  [LevelOfTrainingNurse.Dnp]: 'Doctor of Nursing Practice (DNP)',
  [LevelOfTrainingNurse.Cns]: 'Clinical Nurse Specialist (CNS)',
  [LevelOfTrainingNurse.Cnm]: 'Certified Nurse-Midwife (CNM)',
  [LevelOfTrainingNurse.Nurse]: 'Registered Nurse (RN)',
};

export const TrainingLevelsPhysician: Record<LevelOfTrainingPhysician, string> = {
  [LevelOfTrainingPhysician.Attending]: 'Attending',
  [LevelOfTrainingPhysician.Fellow1]: 'Fellow (1st year)',
  [LevelOfTrainingPhysician.Fellow2]: 'Fellow (2nd year)',
  [LevelOfTrainingPhysician.Fellow3]: 'Fellow (3rd year)',
  [LevelOfTrainingPhysician.Resident1]: 'Resident (1st year)',
  [LevelOfTrainingPhysician.Resident2]: 'Resident (2nd year)',
  [LevelOfTrainingPhysician.Resident3]: 'Resident (3rd year)',
  [LevelOfTrainingPhysician.Resident4]: 'Resident (4th year)',
  [LevelOfTrainingPhysician.Resident5]: 'Resident (5th year)',
  [LevelOfTrainingPhysician.Resident6]: 'Resident (6th year)',
  [LevelOfTrainingPhysician.MedStudent]: 'Med Student',
};

export const trainingLevelsTherapist = {
  'Addiction Therapist': 'Addiction Therapist',
  'Behavioral Therapist': 'Behavioral Therapist',
  'Child Therapist': 'Child Therapist',
  'Clinical Therapist': 'Clinical Therapist',
  'Cognitive Therapist': 'Cognitive Therapist',
  'Cognitive Behavioral Therapist ': 'Cognitive Behavioral Therapist ',
  'Divorce Therapist': 'Divorce Therapist',
  'Eating Disorder Therapist': 'Eating Disorder Therapist',
  'Exercise Therapist': 'Exercise Therapist',
  'High School Therapist': 'High School Therapist',
  'Marriage and Family Therapist': 'Marriage and Family Therapist',
  'Nutritional Therapist': 'Nutritional Therapist',
  'School Therapist': 'School Therapist',
  'Social Therapist': 'Social Therapist',
  'Social Work Therapist': 'Social Work Therapist',
  'Trauma Work Therapist': 'Trauma Work Therapist',
  'Youth Therapist': 'Youth Therapist',
};

export const trainingLevelsByType: Record<UserRole, Record<string, string> | null> = {
  [UserRole.Admin]: null,
  [UserRole.App]: trainingLevelsApp,
  [UserRole.Clinic]: null,
  [UserRole.Nurse]: trainingLevelsNurse,
  [UserRole.Other]: null,
  [UserRole.Physician]: TrainingLevelsPhysician,
  [UserRole.Therapist]: trainingLevelsTherapist,
};
