import React from 'react';
import { useField } from 'formik';
import MDEditor from '@uiw/react-md-editor';

interface Props {
  id: string;
  name: string;
}

const RichEditor = ({ name, id }: Props) => {
  const [{ value }, , { setValue }] = useField(name);

  return (
    <MDEditor
      // Currently hiding bold and italic filters as they are not rendering properly
      commandsFilter={(cmd) => {
        if (!cmd.name) return cmd;
        if (/(bold|italic)/.test(cmd.name)) {
          return false;
        }
        return cmd;
      }}
      data-color-mode="light"
      defaultTabEnable
      highlightEnable={false}
      onChange={(mdvalue = '') => {
        setValue(mdvalue);
      }}
      previewOptions={{
        warpperElement: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          'data-color-mode': 'light',
        },
      }}
      textareaProps={{ id, name }}
      value={value}
    />
  );
};

export default RichEditor;
