export enum TagStatus {
  NEW_PATIENT = 'New Patient',
  RETURNING_PATIENT = 'Returning Patient',
  LEFT_VOICEMAIL = 'Left Voicemail',
  VOICEMAIL_FULL = 'Voicemail Full',
  NO_RESPONSE = 'No Response',
  CALL_BACK = 'Call Back',
  NEED_ASSISTANCE = 'Need Assistance',
  DENIED = 'Denied',
  APPROVED = 'Approved',
  FULL_TIME = 'Full-Time',
  REMOTE = 'Remote',
}
