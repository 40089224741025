import React from 'react';

import { defineMessages, useIntl } from 'react-intl';

import Icon, { Icons } from '~Core/Icon';

import styles from './Footer.module.scss';

const intlMessage = defineMessages({
  footerMainMessage: {
    defaultMessage: 'Shifts & services',
    description: 'Footer main message',
    id: 'Modules.provider.InviteRegister.Footer.footerMainMessage',
  },
  footerMainContrastMessage: {
    defaultMessage: ' Designed with you in mind.',
    description: 'Footer main contrast message',
    id: 'Modules.provider.InviteRegister.Footer.footerMainContrastMessage',
  },
  footerCredentialingMessage: {
    defaultMessage: 'Credentialing {br} as a Service',
    description: 'Footer credentialing message',
    id: 'Modules.provider.InviteRegister.Footer.footerCredentialingMessage',
  },
  footerLicensingMessage: {
    defaultMessage: 'Licensing {br} as a Service',
    description: 'Footer licensing message',
    id: 'Modules.provider.InviteRegister.Footer.footerLicensingMessage',
  },
  footerProviderNetworkMessage: {
    defaultMessage: 'Provider {br} Network',
    description: 'Footer provider network message',
    id: 'Modules.provider.InviteRegister.Footer.footerProviderNetworkMessage',
  },
  footerJobOpportunitiesMessage: {
    defaultMessage: 'Job {br} Opportunities',
    description: 'Footer job opportunities message',
    id: 'Modules.provider.InviteRegister.Footer.footerJobOpportunitiesMessage',
  },
});

const Footer = () => {
  const { formatMessage } = useIntl();

  return (
    <footer className={styles.footer}>
      <div className={styles.main}>
        <span className={styles.primaryMessage}>
          {formatMessage(intlMessage.footerMainMessage)}
        </span>
        <span className={styles.constrastMessage}>
          {formatMessage(intlMessage.footerMainContrastMessage)}
        </span>
      </div>

      <div className={styles.mainDivider} />

      <div className={styles.servicesContainer}>
        <div className={styles.service}>
          <Icon name={Icons.Credentialing} />
          <p>{formatMessage(intlMessage.footerCredentialingMessage, { br: <br /> })}</p>
        </div>

        <div className={styles.divider} />

        <div className={styles.service}>
          <Icon name={Icons.Licensing} />
          <p>{formatMessage(intlMessage.footerLicensingMessage, { br: <br /> })}</p>
        </div>

        <div className={styles.divider} />

        <div className={styles.service}>
          <Icon name={Icons.ProviderNetwork} />
          <p>{formatMessage(intlMessage.footerProviderNetworkMessage, { br: <br /> })}</p>
        </div>

        <div className={styles.divider} />

        <div className={styles.service}>
          <Icon name={Icons.JobOpportunities} />
          <p>{formatMessage(intlMessage.footerJobOpportunitiesMessage, { br: <br /> })}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
