import React from 'react';
import { MenuItemType, MenuLi, MenuLiWithSub } from '~Components/Navigation';

interface MenuLiSubProps {
  subClassName?: string;
  subLiClassName?: string;
  subButtonClassName?: string;
  subAnchorClassName?: string;
}
interface MenuItemComponentProps extends MenuItemType {
  subItems?: MenuItemType[];
  isRightMenu?: boolean;
  isMobile?: boolean;
  navLinkOnClick?: () => void;
  liClassName?: string;
  buttonClassName?: string;
  anchorClassName?: string;
}
export const MenuItem = ({
  title,
  icon,
  route,
  onClick,
  subItems,
  isRightMenu,
  navLinkOnClick,
  liClassName,
  buttonClassName,
  anchorClassName,
  ...rest
}: MenuItemComponentProps & MenuLiSubProps) => {
  if (!subItems)
    return (
      <MenuLi
        title={title}
        icon={icon}
        route={route}
        onClick={onClick}
        navLinkOnClick={navLinkOnClick}
        liClassName={liClassName}
        buttonClassName={buttonClassName}
        anchorClassName={anchorClassName}
      />
    );

  return (
    <MenuLiWithSub
      title={title}
      icon={icon}
      route={route}
      onClick={onClick}
      subItems={subItems}
      isRightMenu={isRightMenu}
      navLinkOnClick={navLinkOnClick}
      liClassName={liClassName}
      buttonClassName={buttonClassName}
      anchorClassName={anchorClassName}
      {...rest}
    />
  );
};
