import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  graduatingInstitution: {
    defaultMessage: 'Graduating Institution',
    description: 'Graduating Institution',
    id: 'components.provider.Forms.EducationFields.graduatingInstitution',
  },
  degreeObtained: {
    defaultMessage: 'Degree Obtained',
    description: 'Degree Obtained',
    id: 'components.provider.Forms.EducationFields.degreeObtained',
  },
});
