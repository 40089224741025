import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';

import styles from './Navbar.module.scss';

const cx = classNames.bind(styles);

interface NavbarProps {
  children: ReactNode;
  variant?: 'light' | 'dark';
}

export const Navbar = ({ children, variant = 'dark' }: NavbarProps) => {
  return <div className={cx(styles.navbar, `${variant}Variant`)}>{children}</div>;
};
