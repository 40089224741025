import React, { useCallback, useEffect } from 'react';
import { Col, Row, Typography, message, Space, Affix } from 'antd';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form } from 'formik-antd';
import Alert from '@openloop/limbic/Alert';
import Button from '@openloop/limbic/Button';
import Heading from '@openloop/limbic/Heading';
import toast from '@openloop/limbic/Toast';
import moment from 'moment';
import { useIntl } from 'react-intl';

import common from '~Constants/common';
import messages from '~Constants/messages';
import { providerRoutes } from '~Constants/routes';
import Link from '~Core/Link';
import SEO from '~Core/SEO';
import {
  AppProfessionalContext,
  EmploymentType,
  LicenseStatus,
  OtherProfessionalContext,
  PhysicianProfessionalContext,
  ProviderProfessionalContext,
  useEditUserProfileMutation,
} from '~Data';
import { getMaybeMomentDateString } from '~Helpers/dates';
import { getProviderLevelOfTraining } from '~Helpers/users';
import { useAuthContext } from '~Context/AuthContext';

import FormSection from './FormSection';
import { FormValues } from './types';
import BasicInfoFields, {
  validationSchema as basicInfoValidationSchema,
} from '../Forms/BasicInfoFields';
import EducationFields, {
  validationSchema as educationValidationSchema,
} from '../Forms/EducationFields';
import JobPreferenceFields, {
  defaultShifts,
  validationSchema as jobPreferenceValidationSchema,
} from '../Forms/JobPreferenceFields';
import ProfessionalFields, {
  validationSchema as professionalValidationSchema,
} from '../Forms/ProfessionalFields';

import { getMissingFields } from './utils';
import { intlMessages } from './intlMessages';

import styles from './EditProfile.module.scss';

const { Text } = Typography;

const validationSchema = yup
  .object()
  .concat(basicInfoValidationSchema)
  .concat(educationValidationSchema)
  .concat(professionalValidationSchema)
  .concat(jobPreferenceValidationSchema);

const EditProfile = () => {
  const { formatMessage } = useIntl();
  const { currentUser } = useAuthContext();
  const [
    editProfile,
    { data: editProfileData, error: editProfileError, loading: editProfileLoading },
  ] = useEditUserProfileMutation();

  const handleSubmit = useCallback(
    ({
      availabilityMaxNumber,
      dateOfBirth: dateOfBirthVal,
      employmentStatus,
      jobTypeSeeking,
      levelOfTraining,
      yearLastPracticed,
      ...values
    }: FormValues) => {
      const dateOfBirth = dateOfBirthVal
        ? getMaybeMomentDateString(dateOfBirthVal, common.requestDateFormat)
        : null;
      const input = {
        ...values,
        availabilityMaxNumber:
          typeof availabilityMaxNumber === 'string'
            ? Number(availabilityMaxNumber)
            : availabilityMaxNumber,
        dateOfBirth: dateOfBirth || null,
        employmentStatus: employmentStatus || null,
        jobTypeSeeking: jobTypeSeeking as EmploymentType[],
        levelOfTraining: levelOfTraining || null,
      };
      editProfile({
        variables: {
          input: {
            ...input,
            yearLastPracticed: yearLastPracticed ? Number(yearLastPracticed) : null,
          },
        },
      });
    },
    [editProfile],
  );

  useEffect(() => {
    if (editProfileData && editProfileData.editUserProfile && !editProfileLoading) {
      toast({ variant: 'success', message: 'Information has been saved.' });
    }
  }, [editProfileData, editProfileLoading]);

  useEffect(() => {
    if (editProfileError && !editProfileLoading && !editProfileData) {
      message.error(messages.profileUpdateError);
    }
  }, [editProfileData, editProfileError, editProfileLoading]);

  if (!currentUser) {
    return null;
  }

  const {
    profile: {
      address,
      bio,
      displayName,
      firstName,
      lastName,
      preferredName,
      phone,
      licenses,
      DEALicenses,
      professionalContext,
    },
  } = currentUser;

  const currentActiveLicenses = licenses.flatMap((license) =>
    license.status === LicenseStatus.SelfReportedActive ? license.stateId : [],
  );

  const currentPendingLicenses = licenses.flatMap((license) =>
    license.status === LicenseStatus.SelfReportedPending ? license.stateId : [],
  );
  const currentActiveDEALicenses = DEALicenses?.flatMap((license) =>
    license.status === LicenseStatus.SelfReportedActive ? license.stateId : [],
  );

  const currentPendingDEALicenses = DEALicenses?.flatMap((license) =>
    license.status === LicenseStatus.SelfReportedPending ? license.stateId : [],
  );

  const {
    availabilityMaxNumber,
    availabilityShifts: availabilityShiftsValue,
    availabilityStartAt,
    dateOfBirth,
    degreeObtained,
    employmentHistory,
    employmentStatus,
    graduatingInstitution,
    jobTypeSeeking,
    type: userRole,
    yearLastPracticed,
  } = professionalContext as ProviderProfessionalContext;

  const { EHRCompatibility, npi, specialty } = professionalContext as
    | AppProfessionalContext
    | PhysicianProfessionalContext;
  const { providerType } = professionalContext as PhysicianProfessionalContext;
  const { profession, professionOther } = professionalContext as OtherProfessionalContext;
  const levelOfTraining = getProviderLevelOfTraining(
    professionalContext as ProviderProfessionalContext,
  );

  const availabilityShifts = {
    ...availabilityShiftsValue,
  };
  if ('__typename' in availabilityShifts) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    delete availabilityShifts.__typename;
  }

  return (
    <div className={styles.main}>
      <SEO title="Edit Profile" />
      <Formik<FormValues>
        initialValues={{
          availabilityMaxNumber:
            typeof availabilityMaxNumber === 'number' ? availabilityMaxNumber.toString() : '',
          availabilityShifts:
            Object.keys(availabilityShifts).length > 0 ? availabilityShifts : defaultShifts,
          availabilityStartAt: availabilityStartAt || undefined,
          bio: bio || '',
          EHRCompatibility: EHRCompatibility || [],
          employmentHistory: employmentHistory || '',
          employmentStatus: employmentStatus || '',
          jobTypeSeeking: jobTypeSeeking || [],
          levelOfTraining: levelOfTraining || '',
          npi: npi || '',
          profession: profession || '',
          professionOther: professionOther || '',
          providerType: providerType || '',
          specialty: specialty || '',
          yearLastPracticed: yearLastPracticed || '',
          currentActiveLicenses: currentActiveLicenses || [],
          currentPendingLicenses: currentPendingLicenses || [],
          currentActiveDEALicenses: currentActiveDEALicenses || [],
          currentPendingDEALicenses: currentPendingDEALicenses || [],
          cityId: (address && address.city.id) || '',
          dateOfBirth: dateOfBirth ? moment(dateOfBirth).utc().format(common.dateFormat) : '',
          degreeObtained: degreeObtained || '',
          firstName: firstName || '',
          graduatingInstitution: graduatingInstitution || '',
          lastName: lastName || '',
          preferredName: preferredName || '',
          phone: phone || '',
          stateId: (address && address.state.id) || '',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ dirty, values, errors }) => {
          const {
            basicFieldsMissing,
            educationFieldsMissing,
            jobPreferenceFieldsMissing,
            professionalFieldsMissing,
          } = getMissingFields(values, userRole, errors);
          const fieldsMissing =
            basicFieldsMissing ||
            educationFieldsMissing ||
            jobPreferenceFieldsMissing ||
            professionalFieldsMissing;
          return (
            <Form layout="vertical">
              <Row className={styles.actionRow} justify="center">
                <Col xs={22} lg={20} xl={18}>
                  <Affix>
                    <div className={styles.actions}>
                      <Row align="middle" justify="space-between">
                        <Col>
                          <Heading level="h4" style={{ margin: 'none' }}>
                            <Space size="large">
                              {displayName && (
                                <>
                                  <Text>{displayName}</Text>
                                  <Text type="secondary">/</Text>
                                </>
                              )}
                              <Text type="secondary">
                                {formatMessage(intlMessages.editProfile)}
                              </Text>
                            </Space>
                          </Heading>
                        </Col>
                        <Col>
                          <Space>
                            <Link
                              buttonVariant="primary-borderless"
                              size="lg"
                              to={providerRoutes.myProfile}
                            >
                              {formatMessage(intlMessages.cancelButton)}
                            </Link>
                            <Button
                              disabled={!dirty}
                              type="submit"
                              loading={editProfileLoading}
                              size="lg"
                              variant="primary"
                            >
                              {formatMessage(intlMessages.saveButton)}
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </div>
                  </Affix>
                </Col>
              </Row>
              {fieldsMissing && (
                <Row justify="center" gutter={[0, 24]}>
                  <Col xs={22} lg={20} xl={18}>
                    <Alert className={styles.alert} border="rounded">
                      <span>{formatMessage(intlMessages.alertMsg1)}</span>
                      <span className={styles.srOnly}>
                        {formatMessage(intlMessages.alertMsg2)}:{' '}
                        <ul>
                          {basicFieldsMissing && <li>{formatMessage(intlMessages.basics)}</li>}
                          {educationFieldsMissing && (
                            <li>{formatMessage(intlMessages.education)}</li>
                          )}
                          {professionalFieldsMissing && (
                            <li>{formatMessage(intlMessages.professional)}</li>
                          )}
                          {jobPreferenceFieldsMissing && (
                            <li>{formatMessage(intlMessages.jobPreferences)}</li>
                          )}
                        </ul>
                      </span>
                    </Alert>
                  </Col>
                </Row>
              )}
              <Row justify="center" gutter={[0, 24]}>
                <Col xs={22} lg={20} xl={18}>
                  <Row gutter={[24, 24]}>
                    <Col className={styles.heightMatchingColumn} xs={24} sm={24} md={12}>
                      <FormSection hasMissingFields={basicFieldsMissing} title="Basics">
                        <BasicInfoFields />
                      </FormSection>
                      <FormSection hasMissingFields={educationFieldsMissing} title="Education">
                        <EducationFields />
                      </FormSection>
                    </Col>
                    <Col className={styles.heightMatchingColumn} xs={24} sm={24} md={12}>
                      <FormSection
                        hasMissingFields={professionalFieldsMissing}
                        title="Professional"
                      >
                        <ProfessionalFields userRole={userRole} />
                      </FormSection>
                    </Col>
                  </Row>
                </Col>
                <Col xs={22} lg={20} xl={18}>
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <FormSection
                        hasMissingFields={jobPreferenceFieldsMissing}
                        title="Job Preferences"
                      >
                        <JobPreferenceFields />
                      </FormSection>
                    </Col>
                    <Col span={24}>
                      <Button
                        disabled={!dirty}
                        type="submit"
                        loading={editProfileLoading}
                        size="lg"
                        variant="primary"
                      >
                        {formatMessage(intlMessages.saveButton)}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditProfile;
