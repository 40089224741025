import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  licenses: {
    defaultMessage: 'Licenses: <b>{licenseCount}</b>',
    description: 'Licenses count',
    id: 'shared.ReviewClinician.LicensesCard.licenses',
  },
  pendingSpan: {
    defaultMessage: 'Self-Reported Pending',
    description: 'Pending Span',
    id: 'shared.ReviewClinician.LicensesCard.licenses',
  },
  activeSpan: {
    defaultMessage: 'Self-Reported Active',
    description: 'Active Span',
    id: 'shared.ReviewClinician.LicensesCard.activeSpan',
  },
  verifiedSpan: {
    defaultMessage: 'Verified Active',
    description: 'Verified Span',
    id: 'shared.ReviewClinician.LicensesCard.verifiedSpan',
  },
});
