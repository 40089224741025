import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Button, Card, Heading, Form, toast } from '@openloop/limbic';
import { useWizard } from 'react-use-wizard';
import { useIntl } from 'react-intl';

import FormItem from '~Core/Form/FormItem/FormItem';
import { useAdminAdminUsersQuery, User, UserProfile } from '~Data';
import { userTypeChoices } from '~Constants/userTypes';
import { InviteClinicianValues } from '../types';
import ExitWizardButton from '../ExitWizardButton';

import styles from '../InviteAndSuggest.module.scss';
import {
  useUserInvitationByEmailLazyQuery,
  useCliniciansSearchLazyQuery,
} from './ClinicianInfo.generated';
import { intlMessages } from './intlMessages';

const { Input, Select } = Form;

const ClinicianInfo = () => {
  const { formatMessage } = useIntl();
  const {
    dirty,
    isValid,
    setFieldValue,
    setStatus,
    initialValues: { clinicId: initialClinic, jobsToSuggestIds: initialJobs },
    values,
    status,
  } = useFormikContext<InviteClinicianValues>();
  const { jobsToSuggestIds, clinicianAdvocateId, email } = values;
  const { data: adminsData, loading: adminUsersLoading } = useAdminAdminUsersQuery();
  const { handleStep, nextStep } = useWizard();
  const [getUserInvitationByEmail] = useUserInvitationByEmailLazyQuery();
  const [getUserByEmail] = useCliniciansSearchLazyQuery();

  useEffect(() => {
    setFieldValue('addJobs', false);
  }, [setFieldValue]);

  const verifyUserInvitation = async () => {
    try {
      const { data: userInvitationData } = await getUserInvitationByEmail({
        variables: {
          email,
        },
      });
      const { data: usersData } = await getUserByEmail({
        variables: {
          filter: {
            email,
          },
          paginate: {
            skip: 0,
          },
        },
      });
      if (userInvitationData?.userInvitationByEmail)
        throw Error(`User invitation for email ${email} already exists`);
      if (usersData?.cliniciansSearch?.users?.length)
        throw Error(`User with email ${email} is already registered`);
      return false;
    } catch (e: any) {
      toast({ variant: 'danger', message: e.message });
      return true;
    }
  };

  const continueInviteOnly = async () => {
    const emailIsTaken = await verifyUserInvitation();
    if (emailIsTaken) return;
    if (jobsToSuggestIds?.length) {
      setFieldValue('clinicId', initialClinic);
      setFieldValue('jobsToSuggestIds', initialJobs);
    }
    nextStep();
  };

  const continueSuggestJobs = async () => {
    const emailIsTaken = await verifyUserInvitation();
    if (emailIsTaken) return;
    setFieldValue('addJobs', true);
    nextStep();
  };

  let clinicianAdvocates: { [key: User['id']]: UserProfile['displayName'] };
  adminsData?.adminAdminUsers.forEach(({ id, profile: { displayName } }) => {
    clinicianAdvocates = {
      ...clinicianAdvocates,
      [id]: displayName,
    };
  });

  handleStep(() => {
    setStatus({
      ...status,
      clinicianAdvocate: clinicianAdvocates[clinicianAdvocateId],
      jobs: initialJobs,
    });
  });

  return (
    <div className={styles.main}>
      <Card className={styles.card}>
        <div className={styles.cardInnerContainer}>
          <Heading level="h2" size="h3" className={styles.heading}>
            {formatMessage(intlMessages.clinicianInformation)}
          </Heading>
          <div className={styles.twoColumnForm}>
            <FormItem name="firstName" label={formatMessage(intlMessages.firstName)}>
              <Input name="firstName" />
            </FormItem>
            <FormItem name="lastName" label={formatMessage(intlMessages.lastName)}>
              <Input name="lastName" />
            </FormItem>
            <FormItem name="preferredName" label={formatMessage(intlMessages.howRefer)}>
              <Input name="preferredName" placeholder="e.g. Dr. Smith, Mrs. Elsworth, John" />
            </FormItem>
            <FormItem name="email" label={formatMessage(intlMessages.emailAddress)}>
              <Input name="email" placeholder="joe@username.com" />
            </FormItem>
            <FormItem name="clinicianType" label={formatMessage(intlMessages.clinicianType)}>
              <Select
                id="clinicianType"
                name="clinicianType"
                placeholder=""
                options={Object.keys(userTypeChoices).map((role) => ({
                  label: role,
                  value: role,
                }))}
              />
            </FormItem>
            <FormItem name="clinicianAdvocateId" label={formatMessage(intlMessages.assignCA)}>
              <Select
                id="clinicianAdvocateId"
                isLoading={adminUsersLoading}
                name="clinicianAdvocateId"
                placeholder=""
                options={adminsData?.adminAdminUsers.map(({ id, profile: { displayName } }) => ({
                  label: displayName,
                  value: id,
                }))}
              />
            </FormItem>
          </div>
          <footer>
            <div className={styles.buttonRow}>
              <Button
                disabled={!(dirty && isValid)}
                className={styles.button}
                onClick={continueInviteOnly}
              >
                {formatMessage(intlMessages.inviteOnly)}
              </Button>
              <Button
                disabled={!(dirty && isValid)}
                variant="secondary"
                className={styles.button}
                onClick={continueSuggestJobs}
              >
                {formatMessage(intlMessages.suggestJob)}
              </Button>
            </div>
            <ExitWizardButton />
          </footer>
        </div>
      </Card>
    </div>
  );
};

export default ClinicianInfo;
