import React from 'react';
import { RouteObject } from 'react-router-dom';

import { sharedRoutes } from '~Constants/routes';

import ReviewClinician from '~Modules/shared/ReviewClinician';
import JobDescription from '~Modules/shared/JobDescription/JobDescription';

export const sharedRoutesObject: RouteObject[] = [
  { path: sharedRoutes.reviewClinician, element: <ReviewClinician /> },
  { path: sharedRoutes.jobDescription, element: <JobDescription /> },
];
