import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import Input from '@openloop/limbic/Form/Input';
import FormItem from '~Core/Form/FormItem';

const intlMessages = defineMessages({
  formLabel: {
    defaultMessage: 'Email',
    description: 'Email form label',
    id: 'Components.JobApplicationFormContainer.EmailFormItem.formLabel',
  },
});
interface Props {
  disabled?: boolean;
}

const EmailFormItem = ({ disabled = false }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <FormItem label={formatMessage(intlMessages.formLabel)} name="email" required>
      {({ inputId }) => <Input id={inputId} name="email" disabled={disabled} />}
    </FormItem>
  );
};

export default EmailFormItem;
