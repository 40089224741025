import React from 'react';
import { defineMessage, useIntl } from 'react-intl';

import images from '~Constants/images';
import Icon, { Icons } from '~Core/Icon';

import styles from './CAAvatar.module.scss';

interface AvatarProps {
  caAvatarUrl?: string;
  caName: string;
  className?: string;
}

const caAvatarDescription = defineMessage({
  defaultMessage: 'Your Clinician Advocate!',
  description: 'Clinician advocate avatar description text',
  id: 'Modules.provider.Invite.caAvatarDescription',
});

const CAAvatar = ({ caAvatarUrl, caName, className }: AvatarProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className={className}>
      <div className={styles.container}>
        <img
          src={caAvatarUrl || images.placeholderContact}
          alt={`${formatMessage(caAvatarDescription)} ${caName}`}
          className={caAvatarUrl ? styles.avatar : styles.avatarPlaceholder}
        />
        <div className={styles.descriptionBox}>
          <span className={styles.title}>{caName}</span>
          <span>{formatMessage(caAvatarDescription)}</span>
          <Icon name={Icons.NoteArrow} className={styles.arrow} />
        </div>
      </div>
    </div>
  );
};

export default CAAvatar;
