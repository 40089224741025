import { OneAddress } from '~Data';

export const getAddressForDisplay = (
  address: OneAddress,
  stateFormat: 'short' | 'long' = 'long',
): string => {
  const {
    city: { name: cityName },
    state: { name: stateName, shortName },
  } = address;
  const statePart = stateFormat === 'short' ? shortName : stateName;
  return `${cityName}, ${statePart}`;
};
