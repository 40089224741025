import { defineMessages } from 'react-intl';
import { common } from './common';

const createdSuccessfully = (text: string) => `${text} was successfully created`;
const createError = (text: string) => `There was an error creating the ${text}`;
const fetchError = (text: string) => `There was an error fetching ${text}`;
const isRequired = (text: string) => `${text} is required`;
const updatedSuccessfully = (text: string) => `${text} successfully updated`;
const updateError = (text: string) => `There was an error updating the ${text}`;
const uploadError = (text: string) => `There was an error uploading the ${text}`;
const uploadedSuccessfully = (text: string) => `${text} successfully uploaded`;

const intlMessages = defineMessages({
  accountManagerUpdateSuccess: {
    defaultMessage: updatedSuccessfully('Account Manager'),
    description: "Message when a Clinic's account manager is successfully updated",
    id: 'Messages.accountManager.update.success',
  },
  accountManagerUpdateError: {
    defaultMessage: updateError('Account Manager'),
    description: "Message when a Clinic's account manager fails to update",
    id: 'Messages.accountManager.update.error',
  },
});

const messages = {
  accountManagerUpdateSuccess: intlMessages.accountManagerUpdateSuccess,
  accountManagerUpdateError: intlMessages.accountManagerUpdateError,
  clinicContactCreateSuccess: createdSuccessfully('Clinic contact'),
  clinicContactCreateError: createError('Clinic contact'),
  clinicContactEditSuccess: updatedSuccessfully('Clinic contact'),
  clinicContactEditError: updateError('Clinic contact'),
  clinicianFetchError: fetchError('this clinician'),
  clinicUpdateError: updateError('Clinic'),
  clinicUpdateSuccessful: updatedSuccessfully('Clinic'),
  cvFileSizeTooLarge: `File too large. Must be less than ${common.limitCvSizeInMb} MB.`,
  cvUploadError: uploadError('CV'),
  cvUploadSuccessful: uploadedSuccessfully('CV'),
  emailInvalid: 'Must be a valid email address',
  emailRequired: isRequired('Email'),
  expressJobInterestError: 'There was an error expressing interest in this job.',
  expressJobInterestSuccessful: 'Thanks for expressing interest in this job!',
  failedClinicCreation: 'There was an error creating your clinic. Please try again later.',
  imageUpdateError: updateError('image'),
  imageUpdateSuccessful: updatedSuccessfully('Image'),
  jobCreateError: createError('job'),
  jobCreateSuccessful: createdSuccessfully('Job'),
  jobsFetchError: fetchError('jobs'),
  jobUpdateError: updateError('job'),
  jobUpdateSuccessful: updatedSuccessfully('Job'),
  noteCreateError: createError('note'),
  noteCreateSuccessful: createdSuccessfully('Your note'),
  passwordRequired: isRequired('Password'),
  primaryContactUpdateError: createError('Primary Contact'),
  primaryContactUpdateSuccess: updatedSuccessfully('Primary Contact'),
  profileUpdateError: updateError('profile'),
  profileUpdateSuccessful: updatedSuccessfully('User profile'),
};

export default messages;
