import React from 'react';
import { Navigate, generatePath } from 'react-router-dom';
import { Divider, Empty, Typography, Spin } from 'antd';
import Heading from '@openloop/limbic/Heading';
import { useIntl } from 'react-intl';

import { clinicRoutes } from '~Constants/routes';
import { useClinicsQuery } from '~Data';
import Link from '~Core/Link';
import { intlMessages } from './intlMessages';

import styles from './Dashboard.module.less';

const { Paragraph } = Typography;

const NoClinics = () => {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.noClinics}>
      <Heading className={styles.title} level="h1">
        {formatMessage(intlMessages.clinicPortal)}
      </Heading>
      <Empty className={styles.empty} description="No Clinics" image={Empty.PRESENTED_IMAGE_SIMPLE}>
        <Link to={clinicRoutes.clinicCreate} buttonVariant="primary">
          {formatMessage(intlMessages.createLink)}
        </Link>
        <Divider>{formatMessage(intlMessages.or)}</Divider>
        <Paragraph>{formatMessage(intlMessages.question)}</Paragraph>
        <Paragraph>{formatMessage(intlMessages.paragraph)}</Paragraph>
      </Empty>
    </div>
  );
};

const Dashboard = () => {
  const { data, loading } = useClinicsQuery();
  if (!data || loading) return <Spin />;
  if (data.clinics.length === 0) return <NoClinics />;
  return (
    <Navigate to={generatePath(clinicRoutes.clinicDetail, { clinicId: data.clinics[0].id })} />
  );
};

export default Dashboard;
