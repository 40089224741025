import * as yup from 'yup';
import { InviteClinicianValues, SuggestJobsValues } from './types';
import { UserRole } from '~Data';
import messages from '~Constants/messages';

export const inviteClinicianValidationSchema = yup.object({
  addJobs: yup.boolean(),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  preferredName: yup.string().required('Preferred name is required'),
  email: yup.string().required(messages.emailRequired).email(messages.emailInvalid),
  clinicianType: yup.string().required('Clinician type is required'),
  clinicianAdvocateId: yup.string().required('Clinician Advocate is required'),
  clinicId: yup.string().when('addJobs', {
    is: true,
    then: (schema) => schema.required('Clinic is required'),
  }),
  jobsToSuggestIds: yup.array().when('addJobs', {
    is: true,
    then: (schema) => schema.min(1, 'At least 1 job is required').required(),
  }),
});

export const suggestJobsValidationSchema = yup.object({
  id: yup.string(),
  firstName: yup.string(),
  lastName: yup.string(),
  preferredName: yup.string(),
  email: yup.string(),
  clinicianType: yup.string(),
  clinicianAdvocateId: yup.string(),
  clinicId: yup.string().required('Clinic is required'),
  jobsToSuggestIds: yup.array().min(1, 'At least 1 job is required').required(),
});

const clinicianDataValues = {
  firstName: '',
  lastName: '',
  preferredName: '',
  email: '',
  clinicianType: UserRole.Physician,
  clinicianAdvocateId: '',
  clinicId: '',
  jobsToSuggestIds: [],
};

export const inviteClinicianInitialValues: InviteClinicianValues = {
  ...clinicianDataValues,
  addJobs: false,
};

export const suggestJobsInitialValues: SuggestJobsValues = {
  id: '',
  ...clinicianDataValues,
};
