import React from 'react';
import { Menu } from 'antd';
import Icon, {
  FileDoneOutlined,
  HomeOutlined,
  LogoutOutlined,
  MedicineBoxOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { UsersIcon } from '~Assets/icons';

import { FeatureFlag, useFeatureFlag } from '~Hooks/useFeatureFlag';
import { adminRoutes } from '~Constants/routes';
import { useAuthContext } from '~Context/AuthContext';
import NavLink from '~Core/NavLink';
import { intlMessages } from './intlMessages';

import styles from './LegacyNavigation.module.scss';

const Navigation = () => {
  const { formatMessage } = useIntl();
  const { logout } = useAuthContext();
  const isInviteAndSuggestEnabled = useFeatureFlag(FeatureFlag.InviteAndSuggest);

  return (
    <Menu mode="horizontal">
      <Menu.Item key="admin">
        <NavLink to={adminRoutes.dashboard}>
          <HomeOutlined />
          <span>{formatMessage(intlMessages.openLoopAdmin)}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="users">
        <NavLink to={adminRoutes.users}>
          <TeamOutlined />
          <span>{formatMessage(intlMessages.users)}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="clinics">
        <NavLink to={adminRoutes.clinics}>
          <MedicineBoxOutlined />
          <span>{formatMessage(intlMessages.clinics)}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="jobs">
        <NavLink to={adminRoutes.jobs}>
          <FileDoneOutlined />
          <span>{formatMessage(intlMessages.jobs)}</span>
        </NavLink>
      </Menu.Item>
      {isInviteAndSuggestEnabled && (
        <Menu.Item key="inviteAndSuggest">
          <NavLink to={adminRoutes.inviteAndSuggest}>
            <Icon component={UsersIcon} style={{ fontSize: '1rem' }} />
            <span>{formatMessage(intlMessages.inviteSuggest)}</span>
          </NavLink>
        </Menu.Item>
      )}
      <Menu.Item
        className={styles.logoutItem}
        onClick={logout}
        key="logout"
        data-testid="logout-button"
      >
        <LogoutOutlined />
      </Menu.Item>
    </Menu>
  );
};

export default Navigation;
