import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { FeatureFlag, useFeatureFlag } from '~Hooks/useFeatureFlag';

import HomeScreen from './HomeScreen';

const InviteAndSuggest = () => {
  const navigate = useNavigate();
  const isInviteAndSuggestEnabled = useFeatureFlag(FeatureFlag.InviteAndSuggest);

  useEffect(() => {
    if (!isInviteAndSuggestEnabled) {
      navigate('/', { replace: true });
    }
  }, [isInviteAndSuggestEnabled, navigate]);

  return <HomeScreen />;
};

export default InviteAndSuggest;
