import React, { ReactNode } from 'react';
import { generatePath } from 'react-router-dom';
import { Card, Tag } from 'antd';
import { ApiOutlined, SettingOutlined } from '@ant-design/icons';
import Grid from '@openloop/limbic/Grid';
import { useIntl } from 'react-intl';

import { clinicIntegrationsMetadata } from '~Constants/clinicIntegrations';
import { clinicRoutes } from '~Constants/routes';
import Link from '~Core/Link';
import { ClinicIntegrations, useClinicIntegrationsQuery } from '~Data';
import { intlMessages } from './intlMessages';

import styles from './ClinicIntegrationsList.module.scss';

const { Col, Row } = Grid;

interface Integration {
  description: ReactNode;
  isActive: boolean;
  link: string;
  logoUrl: string;
  title: string;
}

interface Props {
  clinicId: string;
}

const ClinicIntegrationsList = ({ clinicId }: Props) => {
  const { formatMessage } = useIntl();
  const { data } = useClinicIntegrationsQuery({ variables: { clinicId } });

  const allIntegrations: Array<Integration> = Object.entries(clinicIntegrationsMetadata).map(
    ([integrationKey, integrationMetadata]) => {
      const isActive = !!(
        data?.clinic.integrations &&
        data.clinic.integrations[integrationKey as keyof ClinicIntegrations]?.isActive
      );
      return {
        ...integrationMetadata,
        isActive,
        link: generatePath(clinicRoutes.clinicIntegrationDetail, {
          clinicId,
          integrationSlug: integrationKey,
        }),
      };
    },
  );

  return (
    <Row gutter={16}>
      {allIntegrations.map(({ description, isActive, link, logoUrl, title }) => (
        <Col key={link} xs={24} sm={12} lg={8} xl={6}>
          <Card
            actions={[
              <Link to={link}>
                {isActive ? (
                  <>
                    <SettingOutlined /> Settings
                  </>
                ) : (
                  <>
                    <ApiOutlined /> Connect to {title}
                  </>
                )}
              </Link>,
            ]}
            extra={isActive && <Tag color="green">{formatMessage(intlMessages.active)}</Tag>}
            title={title}
          >
            <div className={styles.itemSection}>
              <img alt={`${title} Logo`} className={styles.logo} src={logoUrl} />
            </div>
            <div className={styles.itemSection}>{description}</div>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default ClinicIntegrationsList;
