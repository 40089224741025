import React from 'react';

// import React, { useState, ReactNode, useRef } from 'react';
// import { useIntl } from 'react-intl';
// import { DeleteFilled, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
// import Icon from '@openloop/limbic/Icon';
// import Button from '@openloop/limbic/Button';
// import Card from '@openloop/limbic/Card';
// import Heading from '@openloop/limbic/Heading';
// import Input from '@openloop/limbic/Form/Input';
// import Spinner from '@openloop/limbic/Spinner';
// import { Badge, Col, Divider, Input as AntInput, message, Modal, Row, Typography } from 'antd';
// import { Formik, FormikHelpers } from 'formik';
// import { FormItem, DatePicker, Form } from 'formik-antd';
// import moment from 'moment';
// import * as yup from 'yup';

// import common from '~Constants/common';
// import Paragraph from '~Core/Paragraph';
// import StateSelect from '~Components/StateSelect';
// import {
//   AddLicenseInput,
//   CurrentUserDocument,
//   CurrentUserTasksDocument,
//   LicenseStatus,
//   useAddLicenseMutation,
//   useCurrentUserQuery,
//   useRemoveLicenseMutation,
//   useEditLicenseMutation,
//   useStatesQuery,
//   EditLicenseInput,
// } from '~Data';

// import styles from './Licenses.module.scss';

// const { Group: InputGroup } = AntInput;
// const { Text } = Typography;

// interface AddLicenseFormValues {
//   expirationDate?: string;
//   issueDate?: string;
//   licenseNumber: string;
//   stateId: string;
//   status: LicenseStatus;
// }

// const addLicensevalidationSchema = yup.object({
//   expirationDate: yup.string().nullable(),
//   issueDate: yup.string().nullable(),
//   licenseNumber: yup.string().required('License number required'),
//   stateId: yup.string().required('State is required'),
//   status: yup.string().required('Status is required'),
// });

// interface EditLicenseFormValues extends Omit<AddLicenseFormValues, 'stateId'> {}

// const editLicenseValidationSchema = yup.object({
//   expirationDate: yup.string().required('Expiration date is required'),
//   issueDate: yup.string().required('Issue date is required'),
//   licenseNumber: yup.string().required('License number required'),
//   status: yup.string().required('Status is required'),
// });

// const LicenseInfoItem = ({ title, value }: { title: ReactNode; value: ReactNode }) => (
//   <Paragraph>
//     <Text strong>{title}:</Text> {value}
//   </Paragraph>
// );

const Licenses = () => {
  // const { formatDate } = useIntl();
  // const [currentLicense, setCurrentLicense] = useState<string>('');
  // const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  // const scrollToLicensesRef = useRef<{ [key: string]: HTMLElement | null }>({});
  // const { data: currentUserData, loading: currentUserLoading } = useCurrentUserQuery({
  //   onError: () => {
  //     message.error('There was an error loading user data');
  //   },
  // });
  // const { data: statesData, loading: statesLoading } = useStatesQuery({
  //   onError: () => {
  //     message.error('There was an error loading states data');
  //   },
  // });

  // const refetchQueries = [{ query: CurrentUserDocument }, { query: CurrentUserTasksDocument }];

  // const [addLicense, { loading: addLicenseLoading }] = useAddLicenseMutation({
  //   onCompleted: ({ addLicense: addLicenseData }) => {
  //     if (addLicenseData) {
  //       const {
  //         state: { name: stateName },
  //       } = addLicenseData;
  //       setIsAddModalVisible(false);
  //       message.success(`${stateName} license successfully added`);
  //     }
  //   },
  //   onError: () => {
  //     message.error('There was an error adding your license');
  //   },
  //   refetchQueries,
  // });

  // const [removeLicense] = useRemoveLicenseMutation({
  //   onCompleted: ({ removeLicense: removeLicenseData }) => {
  //     if (removeLicenseData) {
  //       const {
  //         state: { name: stateName },
  //       } = removeLicenseData;
  //       message.success(`${stateName} license successfully removed`);
  //     }
  //   },
  //   onError: () => {
  //     message.error('There was an error deleting your license');
  //   },
  //   refetchQueries,
  // });

  // const [editLicense] = useEditLicenseMutation({
  //   onCompleted: ({ editLicense: editLicenseData }) => {
  //     if (editLicenseData) {
  //       const {
  //         state: { name: stateName },
  //       } = editLicenseData;
  //       message.success(`${stateName} license successfully edited`);
  //     }
  //   },
  //   onError: () => {
  //     message.error('There was an error updating your license');
  //   },
  //   refetchQueries,
  // });

  // if (currentUserLoading || statesLoading) {
  //   return <Spinner />;
  // }

  // if (!currentUserData || !statesData) {
  //   return null;
  // }

  // const {
  //   currentUser: {
  //     id: userId,
  //     profile: { licenses },
  //   },
  // } = currentUserData;

  // const scrollToLicense = (licenseId: string) => {
  //   setCurrentLicense(licenseId);
  //   scrollToLicensesRef.current[licenseId]?.scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'center',
  //     inline: 'nearest',
  //   });
  // };

  // const handleRemoveLicenseClick = (id: string) => {
  //   const modalInstance = Modal.confirm({
  //     okButtonProps: {
  //       onClick: () => {
  //         removeLicense({ variables: { userId, licenseId: id } });
  //         modalInstance.destroy();
  //       },
  //       icon: <DeleteFilled aria-hidden />,
  //     },
  //     okText: 'Delete',
  //     autoFocusButton: 'cancel',
  //     icon: <ExclamationCircleOutlined className={styles.brandColor} />,
  //     content: 'Are you sure you want to delete this license?',
  //     title: 'Delete License',
  //   });
  // };

  // const handleAddLicense = (
  //   { expirationDate, issueDate, ...values }: AddLicenseFormValues,
  //   { resetForm }: FormikHelpers<AddLicenseFormValues>,
  // ) => {
  //   const input: AddLicenseInput = {
  //     ...values,
  //   };
  //   if (expirationDate) {
  //     input.expirationDate = moment(expirationDate).format(common.requestDateFormat);
  //   }
  //   if (issueDate) {
  //     input.issueDate = moment(issueDate).format(common.requestDateFormat);
  //   }
  //   // addLicense({ variables: { userId, input } });
  //   resetForm();
  // };

  // const handleUpdateLicense = (
  //   { expirationDate, issueDate, ...values }: EditLicenseFormValues,
  //   { resetForm }: FormikHelpers<EditLicenseFormValues>,
  // ) => {
  //   const input: EditLicenseInput = {
  //     ...values,
  //   };
  //   if (expirationDate) {
  //     input.expirationDate = moment(expirationDate).format(common.requestDateFormat);
  //   }
  //   if (issueDate) {
  //     input.issueDate = moment(issueDate).format(common.requestDateFormat);
  //   }
  //   editLicense({ variables: { licenseId: currentLicense, input } });
  //   setCurrentLicense('');
  //   resetForm();
  // };

  // const StateTags = () => (
  //   <div className={styles.stateLicenseContainer}>
  //     {licenses.map(({ id, state: { shortName }, ...rest }) => {
  //       const hasMissingData = Object.values(rest).some((val) => val === '' || val === null);
  //       return (
  //         <div className={styles.stateTagContainer} key={id}>
  //           <Button
  //             onClick={() => scrollToLicense(id)}
  //             size="sm"
  //             variant="secondary-borderless"
  //             className={`${styles.stateButton} ${
  //               id === currentLicense ? styles.selectedStateButton : ''
  //             }`}
  //           >
  //             {shortName}
  //           </Button>
  //           {hasMissingData && (
  //             <Badge size="small" count="!" className={styles.stateLicenseBadge} />
  //           )}
  //         </div>
  //       );
  //     })}
  //   </div>
  // );

  // eslint-disable-next-line @calm/react-intl/missing-formatted-message
  return <div>Licenses page is currently not in use.</div>;

  //   return (
  //     <div className={styles.main}>
  //       <Row justify="center">
  //         <Col xs={20} md={16}>
  //           <Row>
  //             <Col flex={1}>
  //               <Heading level="h1">Licenses</Heading>
  //             </Col>
  //             <Col>
  //               <Button icon={<PlusOutlined />} onClick={() => setIsAddModalVisible(true)}>
  //                 Add License
  //               </Button>
  //             </Col>
  //           </Row>
  //           <Paragraph>
  //             We use your licenses to help find you great opportunities in your licensed states.
  //           </Paragraph>
  //           <Divider />
  //           {licenses?.length > 0 && <StateTags />}
  //           {licenses?.length > 0 ? (
  //             licenses.map(
  //               ({
  //                 expirationDate,
  //                 id: licenseId,
  //                 issueDate,
  //                 licenseNumber,
  //                 state: { name: licenseState },
  //                 status,
  //               }) => (
  //                 <Card
  //                   className={`${styles.licenseCard} ${
  //                     currentLicense === licenseId ? styles.selected : ''
  //                   }`}
  //                   key={licenseId}
  //                 >
  //                   <span
  //                     ref={(el) => {
  //                       scrollToLicensesRef.current[licenseId] = el;
  //                     }}
  //                     className={styles.buttonGroup}
  //                   >
  //                     <Button
  //                       aria-label={currentLicense === licenseId ? 'Cancel' : 'Edit License'}
  //                       className={`${currentLicense !== licenseId ? styles.highlighted : ''}`}
  //                       disabled={currentLicense.length > 0 && currentLicense !== licenseId}
  //                       icon={currentLicense !== licenseId && <Icon name="Edit" />}
  //                       onClick={() =>
  //                         currentLicense === licenseId
  //                           ? setCurrentLicense('')
  //                           : setCurrentLicense(licenseId)
  //                       }
  //                       variant="secondary-borderless"
  //                     >
  //                       {currentLicense === licenseId ? 'Cancel' : 'Edit'}
  //                     </Button>
  //                     <Button
  //                       icon={<Icon name="Trash" />}
  //                       onClick={() => handleRemoveLicenseClick(licenseId)}
  //                       aria-label="Delete License"
  //                       variant="secondary-borderless"
  //                     >
  //                       Delete
  //                     </Button>
  //                   </span>
  //                   {currentLicense === licenseId ? (
  //                     <Formik<EditLicenseFormValues>
  //                       initialValues={{
  //                         expirationDate,
  //                         issueDate,
  //                         licenseNumber: licenseNumber || '',
  //                         status: LicenseStatus.SelfReportedActive,
  //                       }}
  //                       onSubmit={handleUpdateLicense}
  //                       validationSchema={editLicenseValidationSchema}
  //                     >
  //                       {({ dirty, isValid, setFieldValue, submitForm, values }) => (
  //                         <Form layout="horizontal">
  //                           <Row>
  //                             <Col span={12}>
  //                               <Paragraph>
  //                                 <Text>License Held:</Text> {licenseState}
  //                               </Paragraph>
  //                               {/* <FormItem label="Status" name="status">
  //                                   <LicenseStatusSelect id="licenseStatus" name="status" />
  //                                 </FormItem> */}
  //                               <FormItem label="License Number" name="licenseNumber">
  //                                 <Input name="licenseNumber" />
  //                               </FormItem>
  //                               <FormItem label="License Issue Date" name="issueDate">
  //                                 <DatePicker
  //                                   className={styles.datePicker}
  //                                   disabledDate={(currentDate) =>
  //                                     values.expirationDate
  //                                       ? currentDate > moment(values.expirationDate)
  //                                       : currentDate > moment().endOf('day')
  //                                   }
  //                                   format={common.dateFormat}
  //                                   name="issueDate"
  //                                   onChange={(_, dateString) => {
  //                                     setFieldValue('issueDate', dateString);
  //                                   }}
  //                                   placeholder="Issue Date"
  //                                 />
  //                               </FormItem>
  //                               <FormItem label="License Expiry Date" name="expirationDate">
  //                                 <DatePicker
  //                                   className={styles.datePicker}
  //                                   disabledDate={(currentDate) =>
  //                                     currentDate < moment(values.issueDate)
  //                                   }
  //                                   format={common.dateFormat}
  //                                   name="expirationDate"
  //                                   onChange={(_, dateString) => {
  //                                     setFieldValue('expirationDate', dateString);
  //                                   }}
  //                                   placeholder="Expiration Date"
  //                                 />
  //                               </FormItem>
  //                             </Col>
  //                             <Col span={24}>
  //                               <Button
  //                                 className={styles.saveButton}
  //                                 disabled={!isValid || !dirty}
  //                                 onClick={submitForm}
  //                                 variant="secondary"
  //                               >
  //                                 Save
  //                               </Button>
  //                             </Col>
  //                           </Row>
  //                         </Form>
  //                       )}
  //                     </Formik>
  //                   ) : (
  //                     <>
  //                       <LicenseInfoItem title="License Held" value={licenseState} />
  //                       <LicenseInfoItem title="License Status" value={status} />
  //                       <LicenseInfoItem title="License Number" value={licenseNumber} />
  //                       {/* TODO required on backend */}
  //                       <LicenseInfoItem
  //                         title="License Issue Date"
  //                         value={issueDate ? formatDate(issueDate, { timeZone: 'UTC' }) : 'N/A'}
  //                       />
  //                       {/* TODO required on backend */}
  //                       <LicenseInfoItem
  //                         title="License Expiration Date"
  //                         value={
  //                           expirationDate ? formatDate(expirationDate, { timeZone: 'UTC' }) : 'N/A'
  //                         }
  //                       />
  //                     </>
  //                   )}
  //                 </Card>
  //               ),
  //             )
  //           ) : (
  //             <Paragraph className={styles.noLicensesText}>
  //               There aren't currenly any licenses or certifications on file.
  //             </Paragraph>
  //           )}
  //         </Col>
  //       </Row>
  //       <Formik<AddLicenseFormValues>
  //         initialValues={{
  //           expirationDate: '',
  //           issueDate: '',
  //           licenseNumber: '',
  //           stateId: '',
  //           status: LicenseStatus.SelfReportedActive,
  //         }}
  //         onSubmit={handleAddLicense}
  //         validationSchema={addLicensevalidationSchema}
  //       >
  //         {({ dirty, isValid, resetForm, setFieldValue, submitForm, values }) => (
  //           <Modal
  //             title="Add License"
  //             cancelButtonProps={{ disabled: addLicenseLoading }}
  //             okButtonProps={{
  //               disabled: !isValid || !dirty,
  //               onClick: submitForm,
  //               loading: addLicenseLoading,
  //             }}
  //             okText="Submit"
  //             onCancel={() => {
  //               setIsAddModalVisible(false);
  //               resetForm();
  //             }}
  //             visible={isAddModalVisible}
  //           >
  //             <Form layout="vertical">
  //               <div className={styles.modalMain}>
  //                 <FormItem label="License Number" name="licenseNumber" required>
  //                   <Input name="licenseNumber" />
  //                 </FormItem>
  //                 <FormItem label="License Held" name="stateId" required>
  //                   <StateSelect name="stateId" id="licenseState" />
  //                 </FormItem>
  //                 <InputGroup>
  //                   <Row gutter={24}>
  //                     <Col span={12}>
  //                       <FormItem label="Issued" name="issueDate">
  //                         <DatePicker
  //                           className={styles.datePicker}
  //                           disabledDate={(currentDate) =>
  //                             values.expirationDate
  //                               ? currentDate > moment(values.expirationDate)
  //                               : currentDate > moment().endOf('day')
  //                           }
  //                           format={common.dateFormat}
  //                           name="issueDate"
  //                           onChange={(_, dateString) => {
  //                             setFieldValue('issueDate', dateString);
  //                           }}
  //                           placeholder="Issue Date"
  //                         />
  //                       </FormItem>
  //                     </Col>
  //                     <Col span={12}>
  //                       <FormItem label="Expires" name="expirationDate">
  //                         <DatePicker
  //                           className={styles.datePicker}
  //                           disabledDate={(currentDate) => currentDate < moment(values.issueDate)}
  //                           format={common.dateFormat}
  //                           name="expirationDate"
  //                           onChange={(_, dateString) => {
  //                             setFieldValue('expirationDate', dateString);
  //                           }}
  //                           placeholder="Expiration Date"
  //                         />
  //                       </FormItem>
  //                     </Col>
  //                   </Row>
  //                 </InputGroup>
  //                 {/* <FormItem label="Status" name="status" required>
  //                   <LicenseStatusSelect id="licenseStatus" name="status" />
  //                 </FormItem> */}
  //               </div>
  //             </Form>
  //           </Modal>
  //         )}
  //       </Formik>
  //     </div>
  //   );
};

export default Licenses;
