export const common = {
  dateFormat: 'MM/DD/YYYY',
  dateTimeFormat: 'MM/DD/YYYY h:mm a',
  requestDateFormat: 'YYYY-MM-DD',
  limitCvSizeInMb: 5,
  limitImageSizeInMb: 2,
  supportedImageFormats: ['image/jpeg', 'image/png', 'image/jpg'],
  acceptDocumentExtensions: '.doc, .docx, .pdf',
  acceptImageExtensions: '.png, .jpg, .jpeg',
  checkDevEnv: import.meta.env.VITE_ENVIRONMENT === 'DEVELOP',
  appBaseUrl:
    import.meta.env.VITE_ENVIRONMENT === 'production'
      ? 'https://app.openloophealth.com'
      : 'https://staging.openloophealth.com',
};

export const yesNoStatusChoices = {
  Yes: 'Yes',
  No: 'No',
};

export default common;
