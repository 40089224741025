import React, { ReactNode, Children, isValidElement, cloneElement } from 'react';
import { useField } from 'formik';

import useClassNames from '~Hooks/useClassNames';

import RadioItem from './RadioItem';
import { DirectionVariant, LabelType } from '../Types';
import RadioType from './RadioType';

import styles from './Radio.module.less';

interface Props {
  name: string;
  arialabelledBy: LabelType;
  type?: RadioType;
  labelDirection?: DirectionVariant;
  direction?: DirectionVariant;
  className?: string;
  children: ReactNode | ReactNode[];
}

const Radio = ({
  name: nameProp,
  arialabelledBy,
  labelDirection = 'horizontal',
  direction = 'vertical',
  type = 'default',
  className,
  children,
}: Props) => {
  const [{ value: currentValue, name, onChange }] = useField(nameProp);
  const classNames = useClassNames(styles, className, {
    direction,
  });

  const mappedChildren = Children.map(children, (child: ReactNode) =>
    isValidElement(child)
      ? cloneElement(child, {
          onChange,
          name,
          currentValue,
          type,
          direction,
          labelDirection,
        })
      : child,
  );

  // @TODO: Improve with Typescript
  const radiogroupLabel = arialabelledBy.toLowerCase().replace(/\s+/g, '');

  return (
    <div role="radiogroup" aria-labelledby={radiogroupLabel} className={classNames}>
      {mappedChildren}
    </div>
  );
};

Radio.Item = RadioItem;

export default Radio;
