import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  firstHeading: {
    defaultMessage: 'For Internal Use',
    description: 'For Internal Use',
    id: 'components.clinic.JobFields.heading',
  },
  link: {
    defaultMessage: 'Link',
    description: 'Link',
    id: 'components.clinic.JobFields.link',
  },
  clinicianType: {
    defaultMessage: 'Clinician Type',
    description: 'Clinician Type',
    id: 'components.clinic.JobFields.clinicianType',
  },
  jobOpeningStatus: {
    defaultMessage: 'Job Opening Status',
    description: 'Job Opening Status',
    id: 'components.clinic.JobFields.jobOpeningStatus',
  },
  shareIndeed: {
    defaultMessage: 'Share to Indeed?',
    description: 'Share to Indeed Question',
    id: 'components.clinic.JobFields.shareIndeed',
  },
  yes: {
    defaultMessage: 'Yes',
    description: 'Yes',
    id: 'components.clinic.JobFields.yes',
  },
  secondHeading: {
    defaultMessage: 'For The Public Job Description',
    description: 'For The Public Job Description',
    id: 'components.clinic.JobFields.secondHeading',
  },
  jobTitle: {
    defaultMessage: 'Job Title',
    description: 'Job Title',
    id: 'components.clinic.JobFields.jobTitle',
  },
  jobType: {
    defaultMessage: 'Job Type',
    description: 'Job Type',
    id: 'components.clinic.JobFields.jobType',
  },
  briefSummary: {
    defaultMessage: 'Brief summary with catchy CTA',
    description: 'Brief summary',
    id: 'components.clinic.JobFields.briefSummary',
  },
  aboutPosition: {
    defaultMessage: 'About the Position',
    description: 'About the Position',
    id: 'components.clinic.JobFields.aboutPosition',
  },
  responsibilities: {
    defaultMessage: 'Responsibilities',
    description: 'Responsibilities',
    id: 'components.clinic.JobFields.responsibilities',
  },
  requirements: {
    defaultMessage: 'Requirements',
    description: 'Requirements',
    id: 'components.clinic.JobFields.requirements',
  },
  payPerks: {
    defaultMessage: 'Pay & Perks',
    description: 'Pay & Perks',
    id: 'components.clinic.JobFields.payPerks',
  },
  otherInfo: {
    defaultMessage: 'Other Info',
    description: 'Other Info',
    id: 'components.clinic.JobFields.otherInfo',
  },
  education: {
    defaultMessage: 'Education',
    description: 'Education',
    id: 'components.clinic.JobFields.education',
  },
  salary: {
    defaultMessage: 'Salary',
    description: 'Salary',
    id: 'components.clinic.JobFields.salary',
  },
  state: {
    defaultMessage: 'State',
    description: 'Job State',
    id: 'components.clinic.JobFields.state',
  },
  jobLocation: {
    defaultMessage: 'Remote or Statewide',
    description: 'Job Location',
    id: 'components.clinic.JobFields.jobLocation',
  },
});
