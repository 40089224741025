import { TypePolicy } from '@apollo/client';

import { UserProfile as UserProfileType } from '~Data';

export const DISPLAY_NAME_FALLBACK = 'No Name';

export const UserProfile: TypePolicy = {
  fields: {
    displayName: {
      read: (_, { readField }): string => {
        const firstName = readField<UserProfileType['firstName']>({ fieldName: 'firstName' });
        const lastName = readField<UserProfileType['lastName']>({ fieldName: 'lastName' });
        if (!firstName && !lastName) {
          return DISPLAY_NAME_FALLBACK;
        }
        let displayName = DISPLAY_NAME_FALLBACK;
        if (firstName) {
          displayName = firstName;
        }
        if (lastName) {
          displayName = displayName ? `${displayName} ${lastName}` : lastName;
        }
        const professionalContext = readField<UserProfileType['professionalContext']>({
          fieldName: 'professionalContext',
        });
        if (
          professionalContext &&
          'providerType' in professionalContext &&
          professionalContext.providerType
        ) {
          displayName = `${displayName}, ${professionalContext.providerType}`;
        }
        return displayName;
      },
    },
  },
  keyFields: ['userId'],
};
