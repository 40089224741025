import React from 'react';
import { Row, Col, Menu, Avatar } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { LogoutOutlined, DashboardOutlined } from '@ant-design/icons';
import { useLocation, useParams, generatePath } from 'react-router-dom';
import { useIntl } from 'react-intl';

import images from '~Constants/images';
import { clinicRoutes } from '~Constants/routes';
import { useAuthContext } from '~Context/AuthContext';
import Link from '~Core/Link';
import NavLink from '~Core/NavLink';
import { intlMessages } from './intlMessages';

import styles from './Header.module.less';

const { SubMenu } = Menu;

const Header = () => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const { currentUser, logout } = useAuthContext();
  const { md } = useBreakpoint();
  const { clinicId } = useParams<{ clinicId?: string }>();

  const showDesktopMenu = md;
  const showMobileMenu = !showDesktopMenu;

  const rootLink = clinicId
    ? generatePath(clinicRoutes.clinicDetail, { clinicId })
    : clinicRoutes.dashboard;

  return (
    <Row align="middle" gutter={24}>
      <Col>
        <Link to={rootLink}>
          <img alt="OpenLoop" className={styles.logo} src={images.logo} />
        </Link>
      </Col>
      <Col flex="1 0 auto">
        <Row justify="space-between">
          <Col>
            {showDesktopMenu && (
              <Menu className={styles.menu} mode="horizontal" selectedKeys={[pathname]}>
                <Menu.Item key={rootLink}>
                  <NavLink to={rootLink}>{formatMessage(intlMessages.dashboard)}</NavLink>
                </Menu.Item>
                {clinicId && (
                  <>
                    <Menu.Item key={generatePath(clinicRoutes.clinicIntegrations, { clinicId })}>
                      <NavLink to={generatePath(clinicRoutes.clinicIntegrations, { clinicId })}>
                        {formatMessage(intlMessages.integrations)}
                      </NavLink>
                    </Menu.Item>
                  </>
                )}
              </Menu>
            )}
          </Col>
          <Col>
            <Menu className={styles.menu} mode="horizontal">
              <SubMenu
                className={styles.userMenu}
                title={
                  <Avatar
                    size={{ xs: 24, sm: 32, md: 40, lg: 48, xl: 48, xxl: 48 }}
                    src={
                      currentUser && currentUser.profile.image
                        ? currentUser.profile.image.location
                        : images.placeholderDoctor
                    }
                  />
                }
              >
                {showMobileMenu && (
                  <Menu.Item icon={<DashboardOutlined />} key="2">
                    <NavLink to={rootLink}>{formatMessage(intlMessages.dashboard)}</NavLink>
                  </Menu.Item>
                )}
                <Menu.Divider />
                <Menu.Item icon={<LogoutOutlined />} onClick={logout}>
                  {formatMessage(intlMessages.logOut)}
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Header;
