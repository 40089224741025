import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  preferredLabel: {
    defaultMessage: 'Preferred Employment Type',
    description: 'Preferred Label',
    id: 'components.provider.Forms.JobPreferenceFields.preferredLabel',
  },
  potentialLabel: {
    defaultMessage: 'Potential Start Date',
    description: 'Potential Label',
    id: 'components.provider.Forms.JobPreferenceFields.potentialLabel',
  },
  maximumLabel: {
    defaultMessage: 'Maximum number of hours per month',
    description: 'Maximum Label',
    id: 'components.provider.Forms.JobPreferenceFields.maximumLabel',
  },
  availabilityLabel: {
    defaultMessage: 'Availability',
    description: 'Availability Label',
    id: 'components.provider.Forms.JobPreferenceFields.availabilityLabel',
  },
});
