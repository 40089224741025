import React from 'react';

import { DialogContent, DialogOverlay } from '@reach/dialog';
import '@reach/dialog/styles.css';
import Button from '@openloop/limbic/Button';
import Icon from '@openloop/limbic/Icon';
import { useIntl } from 'react-intl';

import { intlMessages } from './intlMessages';

import styles from './PatientIdModal.module.scss';

interface Props {
  close: () => void;
  idImgSrc: string;
  isOpen: boolean;
}

const PatientIdModal = ({ close, idImgSrc, isOpen = false }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <DialogOverlay style={{ background: 'none' }} isOpen={isOpen} onDismiss={close}>
      <DialogContent
        className={styles.patientIdDialogContent}
        aria-label={formatMessage(intlMessages.patientId)}
      >
        <div className={styles.closeButtonContainer}>
          <div>
            <Button
              aria-label={formatMessage(intlMessages.close)}
              icon={<Icon name="Close" size="small" />}
              onClick={close}
              size="sm"
              variant="secondary-borderless"
            />
          </div>
        </div>
        <div className={styles.patientIdContainer}>
          <img alt="Enlarged Patient ID" className={styles.patientId} src={idImgSrc} />
        </div>
      </DialogContent>
    </DialogOverlay>
  );
};

export default PatientIdModal;
