import React from 'react';
import { useField } from 'formik';
import { defineMessages, useIntl } from 'react-intl';

import Input from '@openloop/limbic/Form/Input';
import FormItem from '~Core/Form/FormItem';

import sharedStyles from './shared.module.scss';
import { usePassword } from './hooks';

const intlMessages = defineMessages({
  formLabel: {
    defaultMessage: 'Password',
    description: 'Password form label',
    id: 'Components.jobApplicationFormContainer.PasswordFormItem.formLabel',
  },
  passwordRequirements: {
    defaultMessage:
      'Password must be minimum of 8 characters and include one uppercase letter and one number',
    description: 'Password requirements',
    id: 'Components.jobApplicationFormContainer.PasswordFormItem.passwordRequirements',
  },
});
interface Props {
  showHint?: boolean;
}

const PasswordFormItem = ({ showHint = false }: Props) => {
  const { formatMessage } = useIntl();
  const { label, onBlur, onFocus, onLabelClick, showRequirements } = usePassword();
  const [, { error, touched }] = useField('password');

  const isInvalid = error !== undefined && touched;

  return (
    <FormItem label={formatMessage(intlMessages.formLabel)} name="password" required>
      {({ inputId }) => (
        <div style={{ position: 'relative' }}>
          <Input
            type={label === 'Show password' ? 'password' : 'text'}
            id={inputId}
            name="password"
            autoComplete="new-password"
            onFocus={onFocus}
            onBlur={onBlur}
          />

          {showHint && showRequirements && (
            <p className={sharedStyles.fieldDescription}>
              {formatMessage(intlMessages.passwordRequirements)}
            </p>
          )}
          <button
            type="button"
            role="switch"
            aria-checked={label === 'Hide password'}
            className={`${sharedStyles.showPasswordButton} ${
              isInvalid ? sharedStyles.moveLeft : ''
            }`}
            onClick={onLabelClick}
          >
            {label}
          </button>
        </div>
      )}
    </FormItem>
  );
};

export default PasswordFormItem;
