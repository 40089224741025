import React from 'react';
import Input, { InputProps } from '@openloop/limbic/Form/Input/Input';
import { Form as AntForm, Col, Row } from 'antd';
import { useFormikContext } from 'formik';

import { getAdjacentFieldErrors } from '~Helpers/forms';

const FormItem = AntForm.Item;

interface Props {
  firstNameProps: InputProps;
  label: string;
  lastNameProps: InputProps;
  required?: boolean;
}

const NameInputGroup = ({ firstNameProps, label, lastNameProps, required }: Props) => {
  const { errors, touched } = useFormikContext();
  const adjacentFieldErrors = getAdjacentFieldErrors(
    firstNameProps.name,
    lastNameProps.name,
    errors,
    touched,
  );
  return (
    <FormItem
      help={adjacentFieldErrors}
      label={label}
      required={required}
      validateStatus={adjacentFieldErrors ? 'error' : 'success'}
    >
      <Row>
        <Col span={12}>
          <Input placeholder="First Name" {...firstNameProps} />
        </Col>
        <Col span={12}>
          <Input placeholder="Last Name" {...lastNameProps} />
        </Col>
      </Row>
    </FormItem>
  );
};

export default NameInputGroup;
