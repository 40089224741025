import React, { ReactNode } from 'react';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Heading from '@openloop/limbic/Heading';
import { useIntl } from 'react-intl';
import { Wizard } from 'react-use-wizard';

import SEO from '~Core/SEO';
import Paragraph from '~Core/Paragraph';
import Icon, { Icons } from '~Core/Icon';
import images from '~Constants/images';
import FullScreen from '~Templates/FullScreen';

import StepControl from './StepControl';
import AnimatedStep from './AnimatedStep';
import StepUserType from './StepUserType';
import StepMoreInfo from './StepMoreInfo';
import StepUsername from './StepUsername';
import { intlMessagesRegister } from './intlMessages';

import styles from './Register.module.less';

interface RegistrationIncentiveProps {
  icon: ReactNode;
  text: string;
}

const RegistrationIncentive = ({ icon, text }: RegistrationIncentiveProps) => (
  <div className={styles.registrationContainer}>
    <div className={styles.icon}>{icon}</div>
    <Paragraph type="inverted" className={styles.registrationParagraph}>
      {text}
    </Paragraph>
  </div>
);

const registrationIncentives: RegistrationIncentiveProps[] = [
  {
    icon: <Icon name={Icons.HospitalOutlined} />,
    text: 'Work with leading digital health companies.',
  },
  {
    icon: <Icon name={Icons.PaymentOutlined} />,
    text: 'See job details and payment information in advance.',
  },
  {
    icon: <Icon name={Icons.ScheduleOutlined} />,
    text: 'Find long term part-time shifts that fit any practice schedule.',
  },
  {
    icon: <Icon name={Icons.ChatTypingOutlined} />,
    text: 'Learn about how much others are earning in telemedicine.',
  },
];

const steps = [StepUserType, StepMoreInfo, StepUsername];

const Register = () => {
  const previousStep = React.useRef<number>(0);

  const { formatMessage } = useIntl();
  const { md } = useBreakpoint();

  const desktopOnly = md;
  const mobileOnly = !desktopOnly;

  return (
    <FullScreen withHeader={mobileOnly}>
      <SEO
        title={formatMessage(intlMessagesRegister.metaTitle)}
        description={formatMessage(intlMessagesRegister.metaDescription)}
      />
      <main className={styles.main}>
        {desktopOnly && (
          <div className={styles.leftColumn}>
            <div className={styles.leftColumnContainer}>
              <img src={images.transparentLogo} className={styles.logo} alt="OpenLoop logo" />
              <Heading level="h2" className={styles.title} size="h3">
                {formatMessage(intlMessagesRegister.whyQuestion)}
              </Heading>
              {registrationIncentives.map(({ icon, text }) => (
                <RegistrationIncentive key={text} icon={icon} text={text} />
              ))}
            </div>
          </div>
        )}
        <div className={styles.rightColumn}>
          <div className={styles.stepContent}>
            <Wizard wrapper={<StepControl />}>
              {steps.map((Step, i) => (
                <AnimatedStep key={i} previousStep={previousStep}>
                  <Step />
                </AnimatedStep>
              ))}
            </Wizard>
          </div>
        </div>
      </main>
    </FullScreen>
  );
};

export default Register;
