import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Heading from '@openloop/limbic/Heading';
import styles from './WelcomeText.module.scss';

const intlMessages = defineMessages({
  heading: {
    defaultMessage: 'Thank you for your interest.',
    description: 'Not Now network status heading text',
    id: 'Modules.provider.Home.Welcome.WelcomeText.NotNow.heading',
  },
  firstParagraph: {
    defaultMessage:
      'We do not currently have an open spot aligned to your areas of expertise, but may in the future. We encourage you to keep your profile with us as current and competitive as possible.',
    description: 'Not Now network status first paragraph',
    id: 'Modules.provider.Home.Welcome.WelcomeText.NotNow.firstParagraph',
  },
  secondParagraph: {
    defaultMessage:
      "We’re adding new telehealth partners and a wider array of shifts every month. If we have any positions come up that you'd be a prime candidate for, we'll be sure to reach out.",
    description: 'Not Now network status second paragraph',
    id: 'Modules.provider.Home.Welcome.WelcomeText.NotNow.secondParagraph',
  },
});

const NotNow = () => {
  const { formatMessage } = useIntl();
  const heading = formatMessage(intlMessages.heading);
  const firstParagraph = formatMessage(intlMessages.firstParagraph);
  const secondParagraph = formatMessage(intlMessages.secondParagraph);

  return (
    <div>
      <Heading className={styles.welcomeTextHeading} level="h2">
        {heading}
      </Heading>
      <p>{firstParagraph}</p>
      <p>{secondParagraph}</p>
    </div>
  );
};

export default NotNow;
