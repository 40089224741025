import React from 'react';
import { Comment as AntComment } from 'antd';
import moment from 'moment';

import Paragraph from '~Core/Paragraph';
import common from '~Constants/common';

interface Props {
  author: string;
  createdAt: Date;
  message: string;
}

const Comment = ({ author, createdAt, message }: Props) => (
  <AntComment
    author={author}
    content={<Paragraph>{message}</Paragraph>}
    datetime={moment(createdAt).format(common.dateFormat)}
  />
);

export default Comment;
