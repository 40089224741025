import React, { CSSProperties, useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import type { UploadFile } from 'antd/lib/upload/interface';
import Button, { ButtonProps } from '@openloop/limbic/Button';

import { useUploadCvMutation } from '~Data';
import cacheUpdates from '~Data/cacheUpdates';
import { common } from '~Constants/common';
import messages from '~Constants/messages';

interface Props {
  className?: string;
  hasCv: boolean;
  userId: string;
  buttonProps?: Omit<ButtonProps, 'children'>;
  style?: CSSProperties;
}

const CvUploadButton = ({ buttonProps = {}, className, hasCv, style, userId }: Props) => {
  const [uploadCV, { data: uploadCVData, error: uploadCVError, loading: uploadCVLoading }] =
    useUploadCvMutation({
      update: cacheUpdates.uploadCV(),
    });

  useEffect(() => {
    if (uploadCVData && uploadCVData.uploadCV) {
      message.success(messages.cvUploadSuccessful);
    }
  }, [uploadCVData]);

  useEffect(() => {
    if (uploadCVError) {
      message.error(messages.cvUploadError);
    }
  }, [uploadCVError]);

  const handleCVUpload = (file: UploadFile) => {
    const isLtSize =
      typeof file.size === 'number' && file.size / 1024 / 1024 < common.limitCvSizeInMb;
    if (isLtSize) {
      uploadCV({ variables: { input: { file, userId } } });
    } else {
      message.error(messages.cvFileSizeTooLarge);
    }
    return false;
  };

  return (
    <Upload
      accept={common.acceptDocumentExtensions}
      beforeUpload={handleCVUpload}
      className={className}
      multiple={false}
      showUploadList={false}
      style={style}
    >
      <Button icon={<UploadOutlined />} loading={uploadCVLoading} {...buttonProps}>
        {hasCv ? 'Replace CV' : 'Upload CV'}
      </Button>
    </Upload>
  );
};

export default CvUploadButton;
