import { useCallback } from 'react';

/**
 * Performs programmatic file download.
 * For cases where standard `<a download="" />` won't suffice, such as pre-signed URLs
 */
export const useFileDownload = () => {
  const downloadFile = useCallback<(fileUri: string) => void>((fileUri) => {
    const link = document.createElement('a');
    link.href = fileUri;
    link.download = 'true';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  return downloadFile;
};
