import React, { useMemo } from 'react';
import { useId } from '@reach/auto-id';

import Input from '@openloop/limbic/Form/Input';
import Select, { SelectProps } from '@openloop/limbic/Form/Select/Select';

import { trainingLevelsByType } from '~Constants/trainingLevels';
import Form from '~Core/Form';

import { UserRole } from '~Data';

interface Props extends Omit<SelectProps, 'options' | 'id'> {
  placeholder?: string;
  required?: boolean;
  userRole: UserRole;
}

const TrainingLevelSelect = ({ name, placeholder, required, userRole, ...rest }: Props) => {
  const levelOfTrainingId = useId()?.toString() || '';
  const trainingLevels = trainingLevelsByType[userRole];

  const levelOfTainingLabel = useMemo(() => {
    switch (userRole) {
      case UserRole.App:
        return 'APP Type';
      case UserRole.Nurse:
        return 'Nurse Type';
      case UserRole.Therapist:
        return 'Therapist Type';
      default:
        return 'Level of Training';
    }
  }, [userRole]);

  if (!trainingLevels) {
    return null;
  }

  const trainingLevelOptions = Object.entries(trainingLevels).map(([value, label]) => ({
    value,
    label,
  }));

  return (
    <Form.Item
      inputId={levelOfTrainingId}
      label={levelOfTainingLabel}
      name={name}
      required={required}
    >
      {trainingLevelOptions.length > 0 ? (
        <Select
          id={levelOfTrainingId}
          name={name}
          placeholder={placeholder || `Choose ${levelOfTainingLabel}`}
          options={trainingLevelOptions}
          {...rest}
        />
      ) : (
        <Input
          id={levelOfTrainingId}
          name={name}
          placeholder={placeholder || `Enter ${levelOfTainingLabel}`}
        />
      )}
    </Form.Item>
  );
};

export default TrainingLevelSelect;
