import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  inviteTitle: {
    defaultMessage: 'Invite a New Clinician',
    description: 'Invite title',
    id: 'components.InviteAndSuggest.HomeScreen.inviteTitle',
  },
  youWouldNeed: {
    defaultMessage: "What you'll need",
    description: 'You Would Need',
    id: 'components.InviteAndSuggest.HomeScreen.youWouldNeed',
  },
  needInviteItem1: {
    defaultMessage: 'Their name & how they like to be addressed',
    description: 'What you need item 1 for invite',
    id: 'components.InviteAndSuggest.HomeScreen.needInviteItem1',
  },
  needInviteItem2: {
    defaultMessage: 'Their email address',
    description: 'What you need item 2 for invite',
    id: 'components.InviteAndSuggest.HomeScreen.needInviteItem2',
  },
  needInviteItem3: {
    defaultMessage: 'Their Clinician type',
    description: 'What you need item 3 for invite',
    id: 'components.InviteAndSuggest.HomeScreen.needInviteItem3',
  },
  ableTo: {
    defaultMessage: 'You will be able to',
    description: 'You will be able to',
    id: 'components.InviteAndSuggest.HomeScreen.ableTo',
  },
  ableToInviteItem1: {
    defaultMessage: 'Invite them to join the network',
    description: 'You will be able to item 1 for invite',
    id: 'components.InviteAndSuggest.HomeScreen.ableToInviteItem1',
  },
  ableToInviteItem2: {
    defaultMessage: 'Optionally: suggest jobs to them',
    description: 'You will be able to item 2 for invite',
    id: 'components.InviteAndSuggest.HomeScreen.ableToInviteItem2',
  },
  getStartedButton: {
    defaultMessage: 'Get Started',
    description: 'Get Started button',
    id: 'components.InviteAndSuggest.HomeScreen.getStartedButton',
  },
  suggestJobs: {
    defaultMessage: 'Suggest Jobs to a Clinician',
    description: 'Suggest Jobs',
    id: 'components.InviteAndSuggest.HomeScreen.suggestJobs',
  },
  needSuggestItem1: {
    defaultMessage: 'The name and/or email address of the clinician',
    description: 'What you need item 1 for suggest',
    id: 'components.InviteAndSuggest.HomeScreen.needSuggestItem1',
  },
  needSuggestItem2: {
    defaultMessage: 'The job title',
    description: 'What you need item 2 for suggest',
    id: 'components.InviteAndSuggest.HomeScreen.needSuggestItem2',
  },
  ableToSuggestItem1: {
    defaultMessage: 'Suggest jobs to clinicians and request they apply',
    description: 'You will be able to item 1 for suggest',
    id: 'components.InviteAndSuggest.HomeScreen.ableToSuggestItem1',
  },
  ableToSuggestItem2: {
    defaultMessage: 'See who has been suggested in the Candidates Screen',
    description: 'You will be able to item 2 for suggest',
    id: 'components.InviteAndSuggest.HomeScreen.ableToSuggestItem2',
  },
});
