import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  clinicianInformation: {
    defaultMessage: 'Clinician Information',
    description: 'Head title',
    id: 'components.InviteAndSuggest.ClinicianInfo.clinicianInformation',
  },
  firstName: {
    defaultMessage: 'First Name',
    description: 'First Name label',
    id: 'components.InviteAndSuggest.ClinicianInfo.firstName',
  },
  lastName: {
    defaultMessage: 'Last Name',
    description: 'Last Name label',
    id: 'components.InviteAndSuggest.ClinicianInfo.lastName',
  },
  howRefer: {
    defaultMessage: 'How should we refer to them?',
    description: 'How refer question',
    id: 'components.InviteAndSuggest.ClinicianInfo.howRefer',
  },
  emailAddress: {
    defaultMessage: 'Email Address',
    description: 'Email Address label',
    id: 'components.InviteAndSuggest.ClinicianInfo.emailAddress',
  },
  clinicianType: {
    defaultMessage: 'Clinician Type',
    description: 'Clinician Type label',
    id: 'components.InviteAndSuggest.ClinicianInfo.clinicianType',
  },
  assignCA: {
    defaultMessage: 'Assign a CA',
    description: 'Assign a CA label',
    id: 'components.InviteAndSuggest.ClinicianInfo.assignCA',
  },
  inviteOnly: {
    defaultMessage: 'Invite Only',
    description: 'Invite Only button',
    id: 'components.InviteAndSuggest.ClinicianInfo.inviteOnly',
  },
  suggestJob: {
    defaultMessage: 'Suggest a job',
    description: 'Suggest a job button',
    id: 'components.InviteAndSuggest.ClinicianInfo.suggestJob',
  },
});
