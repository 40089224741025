import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message, Typography } from 'antd';
import { ApiOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import { ClinicDrChronoIntegration } from '~Data';
import useQuerystringParams from '~Hooks/useQuerystringParams';
import { intlMessagesLogin } from './intlMessages';

const { Paragraph } = Typography;

interface Props {
  drChronoIntegration: ClinicDrChronoIntegration | null | undefined;
  redirectUri: string;
}

const SCHEDULING_SCOPES = [
  'calendar:read',
  'calendar:write',
  'clinical:read', // Do we need this one? 🤔
  'clinical:write',
  'patients:summary:write',
  'patients:read',
  'patients:write',
  'settings:read',
  'settings:write',
  'user:read',
];

const LOGIN_LINK_COPY = 'Log In to DrChrono';

const DrChronoLogin = ({ drChronoIntegration, redirectUri }: Props) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const queryParams = useQuerystringParams();
  const drChronoError = queryParams.get('error');

  useEffect(() => {
    if (drChronoError) {
      message.error('Unable to authenticate with DrChrono. Please refresh the page and try again.');
      console.error(drChronoError);
      queryParams.delete('error');
      navigate({ search: queryParams.toString() }, { replace: true });
    }
  }, [drChronoError, navigate, queryParams]);

  const clientIdEncoded = encodeURI(drChronoIntegration?.config?.clientId || '');
  const scopesEncoded = encodeURI(SCHEDULING_SCOPES.join(' '));
  const drChronoAuthUri =
    clientIdEncoded && redirectUri && scopesEncoded
      ? `https://drchrono.com/o/authorize/?redirect_uri=${redirectUri}&response_type=code&client_id=${clientIdEncoded}&scope=${scopesEncoded}`
      : undefined;

  return (
    <>
      <Paragraph>{formatMessage(intlMessagesLogin.paragraph)}</Paragraph>
      <a href={drChronoAuthUri}>
        {LOGIN_LINK_COPY} <ApiOutlined />
      </a>
    </>
  );
};

export default DrChronoLogin;
