import React, { ReactNode } from 'react';
import { Card } from 'antd';
import Icon from '@openloop/limbic/Icon';
import { useIntl } from 'react-intl';

import { intlMessagesFormSection } from './intlMessages';

import styles from './FormSection.module.scss';

const MissingFieldsError = () => {
  const { formatMessage } = useIntl();
  return (
    <span className={styles.missingFieldsError}>
      <span className={styles.missingFieldsErrorMsg}>
        {formatMessage(intlMessagesFormSection.missingFields)}
      </span>
      <Icon className={styles.alertCircle} name="AlertCircle" />
    </span>
  );
};

interface Props {
  children: ReactNode;
  hasMissingFields: boolean;
  title: ReactNode;
}

const FormSection = ({ children, hasMissingFields, title }: Props) => {
  return (
    <Card
      className={styles.formSection}
      title={
        <>
          {title} {hasMissingFields && <MissingFieldsError />}
        </>
      }
    >
      {children}
    </Card>
  );
};

export default FormSection;
