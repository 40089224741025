import React from 'react';
import { List } from 'antd';

import ClinicJobsListItem from '~Components/ClinicJobsListItem';
import { ClinicQuery } from '~Data';

interface Props {
  jobs: ClinicQuery['clinic']['jobs'];
}

const ClinicJobsList = ({ jobs }: Props) => (
  <List>
    {jobs.map((job) => (
      <ClinicJobsListItem key={job.id} job={job} />
    ))}
  </List>
);

export default ClinicJobsList;
