import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  heading: {
    defaultMessage: 'Suggest a Job to a Clinician',
    description: 'Heading',
    id: 'components.admin.InviteAndSuggest.ClinicianSearchPlaceholder.heading',
  },
  paragraph: {
    defaultMessage:
      'We’re currently working to improve this feature. In the meantime, please navigate to the {applicationsTab} of the {clinicianProfile} of the Clinician for whom you’d like to Suggest a Job.',
    description: 'Paragraph',
    id: 'components.admin.InviteAndSuggest.ClinicianSearchPlaceholder.paragraph',
  },
  applicationsTab: {
    defaultMessage: 'Applications tab',
    description: 'Applications tab',
    id: 'components.admin.InviteAndSuggest.ClinicianSearchPlaceholder.applicationsTab',
  },
  clinicianProfile: {
    defaultMessage: 'Clinician Profile',
    description: 'Heading',
    id: 'components.admin.InviteAndSuggest.ClinicianSearchPlaceholder.clinicianProfile',
  },
  previous: {
    defaultMessage: 'Previous',
    description: 'Previous',
    id: 'components.admin.InviteAndSuggest.ClinicianSearchPlaceholder.previous',
  },
  goToUsers: {
    defaultMessage: 'go to users',
    description: 'go to users',
    id: 'components.admin.InviteAndSuggest.ClinicianSearchPlaceholder.goToUsers',
  },
});
