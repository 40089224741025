import * as Types from '../../../../Data/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateJobApplicationSharableProfileTokenMutationVariables = Types.Exact<{
  input: Types.CreateJobApplicationSharableProfileTokenInput;
}>;


export type CreateJobApplicationSharableProfileTokenMutation = { createJobApplicationSharableProfileToken: { id: string, sharableProfileToken?: { clientDescription?: string | null, createdAt: any, createdById: string, expiresAt: any, token: string, createdBy: { id: string, email: string, profile: { userId: string, firstName?: string | null, lastName?: string | null, displayName: string } } } | null } };

export type EditJobApplicationSharableProfileTokenMutationVariables = Types.Exact<{
  input: Types.EditJobApplicationSharableProfileTokenInput;
}>;


export type EditJobApplicationSharableProfileTokenMutation = { editJobApplicationSharableProfileToken: { id: string, sharableProfileToken?: { clientDescription?: string | null, createdAt: any, createdById: string, expiresAt: any, token: string, createdBy: { id: string, email: string, profile: { userId: string, firstName?: string | null, lastName?: string | null, displayName: string } } } | null } };


export const CreateJobApplicationSharableProfileTokenDocument = gql`
    mutation CreateJobApplicationSharableProfileToken($input: CreateJobApplicationSharableProfileTokenInput!) {
  createJobApplicationSharableProfileToken(input: $input) {
    id
    sharableProfileToken {
      clientDescription
      createdAt
      createdById
      createdBy {
        id
        email
        profile {
          userId
          firstName
          lastName
          displayName @client
        }
      }
      expiresAt
      token
    }
  }
}
    `;
export type CreateJobApplicationSharableProfileTokenMutationFn = Apollo.MutationFunction<CreateJobApplicationSharableProfileTokenMutation, CreateJobApplicationSharableProfileTokenMutationVariables>;

/**
 * __useCreateJobApplicationSharableProfileTokenMutation__
 *
 * To run a mutation, you first call `useCreateJobApplicationSharableProfileTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobApplicationSharableProfileTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobApplicationSharableProfileTokenMutation, { data, loading, error }] = useCreateJobApplicationSharableProfileTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJobApplicationSharableProfileTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobApplicationSharableProfileTokenMutation, CreateJobApplicationSharableProfileTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJobApplicationSharableProfileTokenMutation, CreateJobApplicationSharableProfileTokenMutationVariables>(CreateJobApplicationSharableProfileTokenDocument, options);
      }
export type CreateJobApplicationSharableProfileTokenMutationHookResult = ReturnType<typeof useCreateJobApplicationSharableProfileTokenMutation>;
export type CreateJobApplicationSharableProfileTokenMutationResult = Apollo.MutationResult<CreateJobApplicationSharableProfileTokenMutation>;
export type CreateJobApplicationSharableProfileTokenMutationOptions = Apollo.BaseMutationOptions<CreateJobApplicationSharableProfileTokenMutation, CreateJobApplicationSharableProfileTokenMutationVariables>;
export const EditJobApplicationSharableProfileTokenDocument = gql`
    mutation EditJobApplicationSharableProfileToken($input: EditJobApplicationSharableProfileTokenInput!) {
  editJobApplicationSharableProfileToken(input: $input) {
    id
    sharableProfileToken {
      clientDescription
      createdAt
      createdById
      createdBy {
        id
        email
        profile {
          userId
          firstName
          lastName
          displayName @client
        }
      }
      expiresAt
      token
    }
  }
}
    `;
export type EditJobApplicationSharableProfileTokenMutationFn = Apollo.MutationFunction<EditJobApplicationSharableProfileTokenMutation, EditJobApplicationSharableProfileTokenMutationVariables>;

/**
 * __useEditJobApplicationSharableProfileTokenMutation__
 *
 * To run a mutation, you first call `useEditJobApplicationSharableProfileTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditJobApplicationSharableProfileTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editJobApplicationSharableProfileTokenMutation, { data, loading, error }] = useEditJobApplicationSharableProfileTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditJobApplicationSharableProfileTokenMutation(baseOptions?: Apollo.MutationHookOptions<EditJobApplicationSharableProfileTokenMutation, EditJobApplicationSharableProfileTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditJobApplicationSharableProfileTokenMutation, EditJobApplicationSharableProfileTokenMutationVariables>(EditJobApplicationSharableProfileTokenDocument, options);
      }
export type EditJobApplicationSharableProfileTokenMutationHookResult = ReturnType<typeof useEditJobApplicationSharableProfileTokenMutation>;
export type EditJobApplicationSharableProfileTokenMutationResult = Apollo.MutationResult<EditJobApplicationSharableProfileTokenMutation>;
export type EditJobApplicationSharableProfileTokenMutationOptions = Apollo.BaseMutationOptions<EditJobApplicationSharableProfileTokenMutation, EditJobApplicationSharableProfileTokenMutationVariables>;