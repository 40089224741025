import React, { useEffect } from 'react';
import { useId } from '@reach/auto-id';
import toast from '@openloop/limbic/Toast';

import Select from '@openloop/limbic/Form/Select';
import { useAdminAdminUsersQuery, useAdminSetClinicianAdvocateIdMutation } from '~Data';
import Form from '~Core/Form';
import { DISPLAY_NAME_FALLBACK } from '~Data/typePolicies';

interface FormValues {
  clinicianAdvocateId: string;
}

interface Props {
  clinicianAdvocateId: string;
  userId: string;
}

const ClinicianAdvocateSelect = ({ clinicianAdvocateId = '', userId }: Props) => {
  const id = useId()?.toString() || '';
  const { data } = useAdminAdminUsersQuery();
  const [
    setClinicianAdvocateId,
    { data: setAdvocateData, loading: setAdvocateLoading, error: setAdvocateError },
  ] = useAdminSetClinicianAdvocateIdMutation();

  useEffect(() => {
    if (!setAdvocateData && setAdvocateError) {
      toast({ variant: 'danger', message: 'Advocate update failed' });
    }
  }, [setAdvocateData, setAdvocateError]);

  useEffect(() => {
    if (setAdvocateData && !setAdvocateLoading) {
      toast({ variant: 'success', message: 'Advocate successfully updated' });
    }
  }, [setAdvocateData, setAdvocateLoading]);

  const options = data?.adminAdminUsers.map((user) => ({
    value: user.id,
    label:
      user.profile.displayName === DISPLAY_NAME_FALLBACK ? user.email : user.profile.displayName,
  }));

  const onSubmit = (values: FormValues) => {
    if (values.clinicianAdvocateId) {
      setClinicianAdvocateId({ variables: { input: { ...values, userId } } });
    }
  };

  return (
    <Form<FormValues>
      initialValues={{ clinicianAdvocateId }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <Select
          id={id}
          options={options}
          name="clinicianAdvocateId"
          onChange={() => handleSubmit()}
        />
      )}
    </Form>
  );
};

export default ClinicianAdvocateSelect;
