import React from 'react';

import Select, { SelectProps } from '@openloop/limbic/Form/Select/Select';

import { salutationChoices } from '~Constants/salutation';
import { PartialBy } from '~Types/utils';

interface Props extends PartialBy<Omit<SelectProps, 'options'>, 'placeholder'> {}

const salutationOptions = Object.entries(salutationChoices).map(([value, label]) => ({
  value,
  label,
}));

const SalutationSelect = ({ id, name, placeholder = 'Choose a title' }: Props) => (
  <Select placeholder={placeholder} id={id} name={name} options={salutationOptions} />
);

export default SalutationSelect;
