import React from 'react';
import classnames from 'classnames/bind';
import Card from '@openloop/limbic/Card';
import Icon from '@openloop/limbic/Icon';
import { defineMessages, useIntl } from 'react-intl';

import PatientCardTag from '~Components/PatientCardTag';
import { TagStatus } from '~Constants/TagStatus';

import styles from './PatientCardListItem.module.scss';

const cx = classnames.bind(styles);

const intlMessages = defineMessages({
  beingViewedText: {
    defaultMessage: 'Other Clinician Viewing',
    description: 'Text for patient being viewed by other clinician',
    id: 'Components.PatientCardListItem.beingViewedText',
  },
});

interface PatientCardListItemProps {
  id: string;
  name: string;
  companyName: string;
  companyLogo: string;
  dateTime: string;
  tags: Partial<[TagStatus, TagStatus, TagStatus]>;
  isSelected?: boolean;
  isBeingViewed?: boolean;
  voicemailCount?: number;
  callBackCount?: number;
  noResponseCount?: number;
  className?: string;
}

const PatientCardListItem = ({
  id,
  name,
  companyName,
  companyLogo,
  dateTime,
  tags,
  voicemailCount,
  callBackCount,
  noResponseCount,
  isSelected = false,
  isBeingViewed = false,
  className = '',
}: PatientCardListItemProps) => {
  const { formatMessage } = useIntl();
  const cardClassName = cx(styles.patientCard, {
    selected: isSelected,
    isBeingViewed,
    className,
  });
  const companyNameClassName = cx(styles.infoTimeColor, {
    isBeingViewed,
  });
  const dateTimeClassName = cx(styles.infoTimeColor, styles.dateTime, {
    isBeingViewed,
  });
  const patientNameClassName = cx(styles.patientName, {
    isBeingViewed,
  });
  const tagClassName = cx(styles.tag, {
    tagBeingViewed: isBeingViewed,
  });

  return (
    <Card key={id} className={cardClassName}>
      <div className={styles.patientCardContents}>
        <div className={styles.companyInfoAndTime}>
          <div className={styles.companyLogoAndName}>
            <img className={styles.companyLogo} alt={`${companyName} logo`} src={companyLogo} />
            <span className={companyNameClassName}>{companyName}</span>
          </div>
          <div className={dateTimeClassName}>{dateTime}</div>
        </div>
        <div className={patientNameClassName}>{name}</div>
        <div className={styles.tagContainer}>
          {tags.map((status, idx) => (
            <PatientCardTag
              key={`${id}-${idx}`}
              status={status as TagStatus}
              className={tagClassName}
            >
              {voicemailCount !== 0 &&
                voicemailCount &&
                voicemailCount > 1 &&
                status === TagStatus.LEFT_VOICEMAIL && (
                  <span className={styles.tagNotification}>({voicemailCount})</span>
                )}
              {callBackCount !== 0 &&
                callBackCount &&
                callBackCount > 1 &&
                status === TagStatus.CALL_BACK && (
                  <span className={styles.tagNotification}>({callBackCount})</span>
                )}
              {noResponseCount !== 0 &&
                noResponseCount &&
                noResponseCount > 1 &&
                status === TagStatus.NO_RESPONSE && (
                  <span className={styles.tagNotification}>({noResponseCount})</span>
                )}
            </PatientCardTag>
          ))}
        </div>
      </div>
      {isBeingViewed && (
        <div className={styles.clinicianViewingSpace}>
          <Icon className={styles.lockIcon} name="Login" />
          <div className={styles.beingViewedText}>
            <Icon className={styles.eyeIcon} name="Eye" />
            {formatMessage(intlMessages.beingViewedText)}
          </div>
        </div>
      )}
    </Card>
  );
};
export type { PatientCardListItemProps };
export default PatientCardListItem;
