import { ApolloClient, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import toast from '@openloop/limbic/Toast';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';

import { guestRoutes } from '~Constants/routes';
import { cache, typeDefs } from '~Data';
import {
  getPersistedAccessToken,
  refreshAuthToken,
  shouldRefresh,
  unsetPersistedAccessToken,
} from '~LocalStorage/accessToken';

const authLink = setContext(async (_, { headers }) => {
  let accessToken = getPersistedAccessToken();
  const needsRefresh = shouldRefresh(accessToken);

  if (!accessToken) return { headers };

  if (needsRefresh) {
    accessToken = await refreshAuthToken();
  }

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (
    graphQLErrors &&
    graphQLErrors.length &&
    graphQLErrors.find(({ extensions }) => {
      return extensions.code === 'FORBIDDEN';
    }) &&
    window.location.pathname !== guestRoutes.login
  ) {
    if (getPersistedAccessToken()) {
      unsetPersistedAccessToken();
    }
    /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
    client.cache.reset();
    toast({
      variant: 'info',
      message: 'Your session has ended. Please log in to continue.',
      options: { delay: 7, toastId: 'logOutToast' },
    });
  }
});

const httpLink = createUploadLink({
  uri: `${import.meta.env.VITE_SERVER_ENDPOINT}/graphql`,
  credentials: 'include',
});

const sentryLink = new SentryLink();

const link = from([authLink, errorLink, sentryLink, httpLink]);

const client = new ApolloClient({
  link,
  cache,
  typeDefs,
});

export default client;
