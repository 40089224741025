import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  title: {
    defaultMessage: 'Resources',
    description: 'Resources Container Title',
    id: 'Modules.provider.Home.Resources.title',
  },
  telehealthChecklist: {
    defaultMessage: `Looking to practice telehealth but unsure where to begin? You’re in luck! We know it can
    be hard transitioning into a new healthcare setting you aren’t familiar with. So, we put
    together`,
    description: 'Clinician Resource',
    id: 'Modules.provider.Home.Resources.telehealthChecklist',
  },
  resumeTips: {
    defaultMessage: `Read our tips and tricks for creating a killer resume that will stand out and land you the
    telehealth jobs you want.`,
    description: 'Clinician Resource',
    id: 'Modules.provider.Home.Resources.resumeTips',
  },
  locumTenensWhitepaper: {
    defaultMessage: `This whitepaper will walk you through all things locum tenens, including its history,
    expert trend predictions, and resources for finding the best shifts around.`,
    description: 'Clinician Resource',
    id: 'Modules.provider.Home.Resources.locumTenensWhitepaper',
  },
  readTime: {
    defaultMessage: '{time} min read',
    description: 'Length of time to read an article',
    id: 'Modules.provider.Home.Resources.readTime',
  },
  readMore: {
    defaultMessage: 'Read More',
    description: 'Read More',
    id: 'Modules.provider.Home.Resources.readMore',
  },
});
