import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Modal from '@openloop/limbic/Modal';
import { defineMessages, useIntl } from 'react-intl';
import Button from '@openloop/limbic/Button';
import classNames from 'classnames/bind';
import DocViewer, { PDFRenderer, MSDocRenderer } from 'react-doc-viewer';

import { useRequestCvDownloadMutation } from '~Data';
import { useFileDownload } from '~Hooks/useFileDownload';

import styles from './ViewCVButton.module.scss';

interface ViewCVButtonProps {
  userId: string;
  className?: string;
}

const cx = classNames.bind(styles);

const intlMessages = defineMessages({
  ariaLabelText: {
    defaultMessage: 'View & Download CV Modal',
    description: 'Aria label text',
    id: 'Components.ViewCVModal.araLabelText',
  },
  cvAriaLabelText: {
    defaultMessage: 'CV document',
    description: 'CV object tag aria label',
    id: 'Components.ViewCVModal.downloadButtonText',
  },
  downloadButtonText: {
    defaultMessage: 'Download CV',
    description: 'Download button text',
    id: 'Components.ViewCVModal.downloadButtonText',
  },
  closeText: {
    defaultMessage: 'Close',
    description: 'Close button text',
    id: 'Components.ViewCVModal.closeButtonText',
  },
  paginationText: {
    defaultMessage: `{currentPage} of {numPages}`,
    description: 'Pagination nav text',
    id: 'Components.ViewCVModal.paginationText',
  },
  buttonLabelViewCv: {
    defaultMessage: 'View CV',
    description: 'View CV button label',
    id: 'Components.ViewCVModal.buttonLabelViewCv',
  },
});

const ViewCVButton = ({ userId, className }: ViewCVButtonProps) => {
  const { formatMessage } = useIntl();
  const downloadFile = useFileDownload();
  const [isCVModalOpen, setIsCVModalOpen] = useState(false);
  const [requestCVDownload, { data: cvData, loading: cvLoading }] = useRequestCvDownloadMutation();
  const [ext, setExt] = useState('');

  const modalContentClassNames = cx(styles.modalContainer, styles.removeMargins);
  const docViewerClassNames = cx(styles.reactDocViewerContainer, styles.hideScrollbar);

  const getCV = useCallback(() => {
    requestCVDownload({ variables: { input: { userId } } });
  }, [requestCVDownload, userId]);

  useEffect(() => {
    if (cvData && cvData.requestCVDownload) {
      const foo = cvData.requestCVDownload.split('.');

      // last foo item is a string that shows the CV extension before the first "?"
      if (foo[foo.length - 1]) {
        const end = foo[foo.length - 1];
        setExt(end.split('?')[0] || 'pdf');
      }
    } else {
      getCV();
    }
  }, [cvData, getCV]);

  const onOk = () => {
    if (cvData && cvData.requestCVDownload) {
      downloadFile(cvData.requestCVDownload);
    }
    setIsCVModalOpen(false);
  };

  const handleClick = () => {
    getCV();
    setIsCVModalOpen(!isCVModalOpen);
  };

  return (
    <Fragment key={userId}>
      <Button className={className} variant="secondary" onClick={handleClick}>
        {formatMessage(intlMessages.buttonLabelViewCv)}
      </Button>
      {isCVModalOpen && (
        <Modal
          width="60vw"
          isVisible={isCVModalOpen}
          contentClassName={modalContentClassNames}
          ariaLabel={formatMessage(intlMessages.ariaLabelText)}
          okButtonText={formatMessage(intlMessages.downloadButtonText)}
          okButtonProps={{
            variant: 'secondary',
          }}
          cancelButtonText={formatMessage(intlMessages.closeText)}
          onCancel={() => setIsCVModalOpen(false)}
          onOk={onOk}
        >
          <div className={styles.modalBodyContainer}>
            {!cvLoading && cvData?.requestCVDownload && (
              <>
                <DocViewer
                  documents={[
                    {
                      uri: cvData.requestCVDownload,
                      fileType: ext,
                    },
                  ]}
                  className={ext === 'pdf' ? styles.reactDocViewerContainer : docViewerClassNames}
                  config={{
                    header: {
                      disableFileName: true,
                      disableHeader: true,
                    },
                  }}
                  pluginRenderers={[PDFRenderer, MSDocRenderer]}
                />
              </>
            )}
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

export default ViewCVButton;
