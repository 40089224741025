import React, { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { MailOutlined, PhoneOutlined, LinkOutlined } from '@ant-design/icons';
import { PageHeader, Tag, Descriptions, Space, Row, Col, Divider, Typography, Spin } from 'antd';
import { useIntl } from 'react-intl';

import AvailabilityShifts from '~Core/AvailabilityShifts';
import CvUploadButton from '~Core/CvUploadButton';
import {
  AppProfessionalContextFieldsFragment,
  NurseProfessionalContextFieldsFragment,
  OtherProfessionalContextFieldsFragment,
  PhysicianProfessionalContextFieldsFragment,
  ProviderProfessionalContext,
  TherapistProfessionalContextFieldsFragment,
  useAdminUserQuery,
} from '~Data';
import { getAddressForDisplay } from '~Helpers/address';
import { getPhoneURI, formatPhone } from '~Helpers/phoneNumber';
import { getNpiLink } from '~Helpers/users';
import ViewCVButton from '~Components/ViewCVButton';
import { intlMessages } from './intlMessages';

import styles from './Profile.module.scss';

const { Text } = Typography;

const DESCRIPTION_NUM_COLS = 3;

const camelCaseToTitleCase = (text: string) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const Profile = () => {
  const { formatMessage } = useIntl();
  const { userId } = useParams<{ userId: string }>();
  const { data, error, loading } = useAdminUserQuery({ variables: { userId } });

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <h1>{formatMessage(intlMessages.errorMessage)}</h1>;
  }

  if (!data) {
    return null;
  }

  const {
    adminUser: {
      email,
      profile: {
        address,
        bio,
        cv,
        displayName,
        missingFields,
        phone: rawPhone,
        professionalContext,
        utm,
      },
      role,
      zohoRecruitUserId,
    },
  } = data;

  const hasMissingFields = missingFields.length > 0;

  const {
    availabilityMaxNumber,
    availabilityShifts,
    availabilityStartAt,
    dateOfBirth,
    degreeObtained,
    employmentHistory,
    employmentStatus,
    isVerified,
    graduatingInstitution,
    jobTypeSeeking,
    yearLastPracticed,
  } = professionalContext as ProviderProfessionalContext;

  const { EHRCompatibility, specialty } = professionalContext as
    | AppProfessionalContextFieldsFragment
    | NurseProfessionalContextFieldsFragment
    | PhysicianProfessionalContextFieldsFragment
    | TherapistProfessionalContextFieldsFragment;

  const { npi } = professionalContext as
    | AppProfessionalContextFieldsFragment
    | NurseProfessionalContextFieldsFragment
    | PhysicianProfessionalContextFieldsFragment;

  const { profession, professionOther } =
    professionalContext as OtherProfessionalContextFieldsFragment;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { __typename } = professionalContext;
  let levelOfTraining = null;

  if (__typename === 'APPProfessionalContext') {
    const { appLevelOfTraining } = professionalContext as AppProfessionalContextFieldsFragment;
    levelOfTraining = appLevelOfTraining;
  } else if (__typename === 'NurseProfessionalContext') {
    const { nurseLevelOfTraining } = professionalContext as NurseProfessionalContextFieldsFragment;
    levelOfTraining = nurseLevelOfTraining;
  } else if (__typename === 'PhysicianProfessionalContext') {
    const { physicianLevelOfTraining } =
      professionalContext as PhysicianProfessionalContextFieldsFragment;
    levelOfTraining = physicianLevelOfTraining;
  } else if (__typename === 'TherapistProfessionalContext') {
    const { therapistLevelOfTraining } =
      professionalContext as TherapistProfessionalContextFieldsFragment;
    levelOfTraining = therapistLevelOfTraining;
  }

  const phoneUri = rawPhone ? getPhoneURI(rawPhone) : undefined;
  const phone = rawPhone ? formatPhone(rawPhone) : undefined;
  const zohoRecruitUrl = zohoRecruitUserId
    ? `https://recruit.zoho.com/recruit/org740000821/EntityInfo.do?module=Candidates&id=${zohoRecruitUserId}&submodule=Candidates`
    : undefined;

  const customLabels: Record<string, string> = {
    availabilityMaxNumber: formatMessage(intlMessages.availabilityMaxNumber),
    availabilityStartAt: formatMessage(intlMessages.availabilityStartAt),
  };

  const items: { label: ReactNode; value?: ReactNode; span?: number }[] = [
    { label: formatMessage(intlMessages.specialty), value: specialty },
    { label: formatMessage(intlMessages.levelOfTraining), value: levelOfTraining },
    {
      label: 'NPI',
      value: npi ? (
        <a href={getNpiLink(npi)} rel="noopener noreferrer" target="_blank">
          {npi} <LinkOutlined />
        </a>
      ) : null,
    },
    {
      label: formatMessage(intlMessages.compatibility),
      value: EHRCompatibility ? (
        <div>
          {EHRCompatibility.map((val) => (
            <Tag color="blue" key={val}>
              {val}
            </Tag>
          ))}
        </div>
      ) : null,
    },
    { label: formatMessage(intlMessages.dateOfBirth), value: dateOfBirth },
    { label: formatMessage(intlMessages.employmentStatus), value: employmentStatus },
    {
      label: formatMessage(intlMessages.yearLastPracticed),
      value: Number(yearLastPracticed) || null,
    },
    { label: formatMessage(intlMessages.employmentHistory), value: employmentHistory },
    { label: <strong>{formatMessage(intlMessages.education)}</strong>, span: DESCRIPTION_NUM_COLS },
    { label: formatMessage(intlMessages.degreeObtained), value: degreeObtained },
    {
      label: formatMessage(intlMessages.graduatingInstitution),
      value: graduatingInstitution,
      span: 2,
    },
    {
      label: <strong>{formatMessage(intlMessages.jobPreferences)}</strong>,
      span: DESCRIPTION_NUM_COLS,
    },
    { label: formatMessage(intlMessages.seeking), value: jobTypeSeeking },
    { label: customLabels.availabilityMaxNumber, value: availabilityMaxNumber },
    {
      label: customLabels.availabilityStartAt,
      span: DESCRIPTION_NUM_COLS,
      value: availabilityStartAt,
    },
    {
      label: '',
      span: DESCRIPTION_NUM_COLS,
      value: <AvailabilityShifts availabilityShifts={availabilityShifts || {}} />,
    },
    {
      label: formatMessage(intlMessages.bio),
      span: 2,
      value: <p style={{ whiteSpace: 'pre-line' }}>{bio}</p>,
    },
  ];

  return (
    <>
      {hasMissingFields && (
        <section className={styles.missingFieldsContainer}>
          <h3 className={styles.missingFieldsHeader}>
            {formatMessage(intlMessages.missingFields)}
          </h3>
          <ul>
            {missingFields.map((missingFieldName) => (
              <li key={missingFieldName}>
                {customLabels[missingFieldName] || camelCaseToTitleCase(missingFieldName)}
              </li>
            ))}
          </ul>
        </section>
      )}
      <PageHeader
        subTitle={role}
        tags={
          isVerified ? (
            <Tag color="green">{formatMessage(intlMessages.verified)}</Tag>
          ) : (
            <Tag color="red">{formatMessage(intlMessages.notVerified)}</Tag>
          )
        }
        title={displayName}
      >
        <Row className={styles.contactInfo}>
          <Col flex={1}>
            <Space size="large">
              <a href={`mailto:${email}`}>
                <MailOutlined /> {email}
              </a>
              {phoneUri && phone && (
                <a href={phoneUri}>
                  <PhoneOutlined /> {phone}
                </a>
              )}
              <span>
                {formatMessage(intlMessages.zohoCandidateId)}:{' '}
                {zohoRecruitUrl ? (
                  <Text copyable={{ text: zohoRecruitUrl }}>
                    <a href={zohoRecruitUrl} target="_blank" rel="noreferrer">
                      {zohoRecruitUserId}
                    </a>
                  </Text>
                ) : (
                  'N/A'
                )}
              </span>
            </Space>
          </Col>
          <Col>
            <Space>
              <CvUploadButton hasCv={!!cv} userId={userId} />
              {cv && <ViewCVButton userId={userId} />}
            </Space>
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
            <Space>
              <div>
                {formatMessage(intlMessages.acquisitionSource)}: {utm?.source || 'Unknown'}
              </div>
            </Space>
          </Col>
        </Row>
        <Divider />
        <Descriptions column={DESCRIPTION_NUM_COLS}>
          <Descriptions.Item
            label={<strong>{formatMessage(intlMessages.generalInfo)}</strong>}
            span={DESCRIPTION_NUM_COLS}
          >
            {null}
          </Descriptions.Item>
          <Descriptions.Item label={formatMessage(intlMessages.location)}>
            {address && <address>{getAddressForDisplay(address)}</address>}
          </Descriptions.Item>
          {__typename === 'OtherProfessionalContext' && (
            <>
              <Descriptions.Item label={formatMessage(intlMessages.profession)}>
                {profession}
              </Descriptions.Item>
              <Descriptions.Item
                label={`${formatMessage(intlMessages.profession)} - ${formatMessage(
                  intlMessages.other,
                )}`}
              >
                {professionOther}
              </Descriptions.Item>
            </>
          )}
          {items.map(({ label, span, value }, idx) => (
            <Descriptions.Item key={idx} label={label} span={span}>
              {value || null}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </PageHeader>
    </>
  );
};

export default Profile;
