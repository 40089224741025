import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const formatPhone = (phone: string): string | null => {
  const parsedPhone = parsePhoneNumberFromString(phone, 'US');
  return parsedPhone ? parsedPhone.formatNational() : null;
};

export const getPhoneURI = (phone: string): string | null => {
  const parsedPhone = parsePhoneNumberFromString(phone, 'US');
  return parsedPhone ? parsedPhone.getURI() : null;
};
