import * as Types from '../../../../../Data/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateJobSuggestionsMutationVariables = Types.Exact<{
  input: Types.CreateJobSuggestionsInput;
}>;


export type CreateJobSuggestionsMutation = { createJobSuggestions: Array<{ id: string }> };


export const CreateJobSuggestionsDocument = gql`
    mutation CreateJobSuggestions($input: CreateJobSuggestionsInput!) {
  createJobSuggestions(input: $input) {
    id
  }
}
    `;
export type CreateJobSuggestionsMutationFn = Apollo.MutationFunction<CreateJobSuggestionsMutation, CreateJobSuggestionsMutationVariables>;

/**
 * __useCreateJobSuggestionsMutation__
 *
 * To run a mutation, you first call `useCreateJobSuggestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobSuggestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobSuggestionsMutation, { data, loading, error }] = useCreateJobSuggestionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJobSuggestionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobSuggestionsMutation, CreateJobSuggestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJobSuggestionsMutation, CreateJobSuggestionsMutationVariables>(CreateJobSuggestionsDocument, options);
      }
export type CreateJobSuggestionsMutationHookResult = ReturnType<typeof useCreateJobSuggestionsMutation>;
export type CreateJobSuggestionsMutationResult = Apollo.MutationResult<CreateJobSuggestionsMutation>;
export type CreateJobSuggestionsMutationOptions = Apollo.BaseMutationOptions<CreateJobSuggestionsMutation, CreateJobSuggestionsMutationVariables>;