import React, { MouseEventHandler, KeyboardEvent, useState, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import FocusTrap from 'focus-trap-react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import classNames from 'classnames/bind';
import { defineMessages, useIntl } from 'react-intl';

import Button from '@openloop/limbic/Button';
import Icon from '@openloop/limbic/Icon';
import { MenuItem, MenuItemProps, MenuItemType } from '~Components/Navigation';

import styles from './MobileNav.module.scss';

const cx = classNames.bind(styles);

const intlMessages = defineMessages({
  menuDescription: {
    defaultMessage: 'Main menu',
    description: 'Main menu description',
    id: 'Components.Navigation.MobileNav.menuDescription',
  },
  mobileNavButtonAriaLabel: {
    defaultMessage: 'Mobile Navigation Button',
    description: 'Mobile Navigation Button aria label',
    id: 'Components.Navigation.MobileNav.mobileNavButtonAriaLabel',
  },
  navAriaLabel: {
    defaultMessage: 'Mobile navigation',
    description: 'Mobile navigation aria label',
    id: 'Components.Navigation.MobileNav.navAriaLabel',
  },
  closeButtonLabel: {
    defaultMessage: 'Close',
    description: 'Close button label',
    id: 'Components.Navigation.MobileNav.closeButtonLabel',
  },
  closeButtonAriaLabel: {
    defaultMessage: 'Close mobile navigation',
    description: 'Close button aria label',
    id: 'Components.Navigation.MobileNav.closeButtonAriaLabel',
  },
});

export interface MobileNavProps {
  mobileMenuItems: MenuItemProps[];
  mobileClassName?: string;
  showLogoutButton?: boolean;
  mobileMenuButtonLabel?: string;
  mobileMenuButtonAction?: () => void;
}

const motionVariants = {
  initial: {
    opacity: 0,
    y: 0,
  },
  animate: {
    opacity: 1,
    y: 76,
    transition: { type: 'tween' },
  },
  exit: {
    opacity: 0,
    y: 0,
    transition: { duration: 0.4 },
  },
};

export const MobileNav = ({
  mobileMenuItems,
  showLogoutButton = true,
  mobileMenuButtonLabel = 'log out',
  mobileMenuButtonAction,
  mobileClassName,
}: MobileNavProps) => {
  const { formatMessage } = useIntl();
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false);
  const [openKey, setOpenKey] = useState<MenuItemType['title']>('');
  const mobileRef = useRef<HTMLElement>(null);

  const toggleSubMenu = (key: MenuItemType['title']) => {
    setOpenKey(openKey !== key ? key : '');
  };

  const toggleMobileNav = () => {
    setShowMobileNav(!showMobileNav);
    toggleSubMenu('');
  };

  const closeMenu: MouseEventHandler<HTMLDivElement> = () => {
    setShowMobileNav(false);
    toggleSubMenu('');
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Escape') {
      setShowMobileNav(false);
      toggleSubMenu('');
    }
  };

  useEffect(() => {
    if (showMobileNav && mobileRef.current) {
      disableBodyScroll(mobileRef.current);
    } else if (!showMobileNav && mobileRef.current) {
      enableBodyScroll(mobileRef.current);
      clearAllBodyScrollLocks();
    }
  }, [showMobileNav]);

  return (
    <>
      <span className={styles.srOnly}>{formatMessage(intlMessages.menuDescription)}</span>
      <button
        type="button"
        className={styles.mobileNavButton}
        onClick={() => toggleMobileNav()}
        onKeyDown={handleKeyDown}
        aria-controls="mainNavigation"
        aria-label={formatMessage(intlMessages.mobileNavButtonAriaLabel)}
        aria-expanded={showMobileNav}
      >
        <div
          aria-hidden
          className={cx(styles.mobileNavButtonLine, showMobileNav && styles.lineOne)}
        />
        <div
          aria-hidden
          className={cx(styles.mobileNavButtonLine, showMobileNav && styles.lineTwo)}
        />
        <div
          aria-hidden
          className={cx(styles.mobileNavButtonLine, showMobileNav && styles.lineThree)}
        />
      </button>
      <AnimatePresence>
        {showMobileNav && (
          <FocusTrap
            active={showMobileNav}
            focusTrapOptions={{
              clickOutsideDeactivates: true,
            }}
          >
            <motion.nav
              ref={mobileRef}
              className={cx(styles.mobileMenu, showMobileNav && styles.open, mobileClassName)}
              id="mainNavigation"
              aria-label={formatMessage(intlMessages.navAriaLabel)}
              role="navigation"
              onKeyDown={handleKeyDown}
              variants={motionVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <ul className={styles.menu} role="menu">
                {showLogoutButton && (
                  <>
                    <Button
                      variant="primary-borderless"
                      onClick={mobileMenuButtonAction}
                      className={styles.logoutButton}
                    >
                      {mobileMenuButtonLabel}
                    </Button>
                    <div className={styles.border} />
                  </>
                )}
                {mobileMenuItems?.map(({ title, route, onClick, icon, subMenu }) => (
                  <MenuItem
                    isMobile
                    key={title}
                    navLinkOnClick={() => setShowMobileNav(false)}
                    title={title}
                    route={route}
                    onClick={onClick}
                    icon={icon}
                    subItems={subMenu}
                    liClassName={styles.menuItem}
                    anchorClassName={styles.menuItemAnchor}
                    buttonClassName={styles.menuItemButton}
                    subClassName={styles.mobileSubMenu}
                    subLiClassName={styles.subMenuItem}
                    subAnchorClassName={styles.subMenuAnchor}
                    subButtonClassName={styles.subMenuItemButton}
                  />
                ))}
                <Button
                  variant="primary-borderless"
                  icon={<Icon name="Close" size="medium" />}
                  onClick={() => setShowMobileNav(false)}
                  className={styles.closeButton}
                  aria-label={formatMessage(intlMessages.closeButtonAriaLabel)}
                />
                <span className={styles.srOnly}>
                  {formatMessage(intlMessages.closeButtonLabel)}
                </span>
              </ul>
            </motion.nav>
          </FocusTrap>
        )}
      </AnimatePresence>
      <div
        className={cx(styles.backdrop, showMobileNav && styles.showBackdrop)}
        role="presentation"
        tabIndex={-1}
        onClick={closeMenu}
      />
    </>
  );
};
