import * as Yup from 'yup';

import messages from '~Constants/messages';

export default {
  email: Yup.string().required(messages.emailRequired).email('Please enter a valid email address'),
  password: Yup.string()
    .min(8, 'Minimum 8 characters required')
    .matches(/[A-Z]/, 'At least one uppercase letter required')
    .matches(/[0-9]/, 'At least one number required')
    .required('Password is required'),
};
