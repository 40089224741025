import React, { createContext, useContext, ReactNode, ReactNodeArray } from 'react';

import { ApolloError } from '@apollo/client';
import { JobQuery, useJobQuery } from '~Data';

interface JobContextValue {
  jobData: JobQuery['job'] | undefined;
  jobError: ApolloError | undefined;
  jobLoading: boolean;
}
const JobContext = createContext<JobContextValue>({
  jobData: undefined,
  jobError: undefined,
  jobLoading: false,
});

export const useJobContext = () => {
  const jobContext = useContext(JobContext);
  if (!jobContext) {
    throw new Error('JobContext must be used within JobProvider.');
  }
  return jobContext;
};

export const JobProvider = ({
  jobId,
  children,
}: {
  jobId: string;
  children: ReactNode | ReactNodeArray;
}) => {
  const {
    data: jobData,
    error: jobError,
    loading: jobLoading,
  } = useJobQuery({
    variables: { id: jobId },
  });
  return (
    <JobContext.Provider value={{ jobData: jobData?.job, jobError, jobLoading }}>
      {children}
    </JobContext.Provider>
  );
};
