import * as Types from '../../../../Data/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserInvitationByEmailQueryVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type UserInvitationByEmailQuery = { userInvitationByEmail?: { id: string } | null };

export type CliniciansSearchQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.UsersFilterInput>;
  paginate?: Types.InputMaybe<Types.OffsetPaginationInput>;
}>;


export type CliniciansSearchQuery = { cliniciansSearch: { users: Array<{ id: string }> } };


export const UserInvitationByEmailDocument = gql`
    query UserInvitationByEmail($email: String!) {
  userInvitationByEmail(email: $email) {
    id
  }
}
    `;

/**
 * __useUserInvitationByEmailQuery__
 *
 * To run a query within a React component, call `useUserInvitationByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInvitationByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInvitationByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserInvitationByEmailQuery(baseOptions: Apollo.QueryHookOptions<UserInvitationByEmailQuery, UserInvitationByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserInvitationByEmailQuery, UserInvitationByEmailQueryVariables>(UserInvitationByEmailDocument, options);
      }
export function useUserInvitationByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInvitationByEmailQuery, UserInvitationByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserInvitationByEmailQuery, UserInvitationByEmailQueryVariables>(UserInvitationByEmailDocument, options);
        }
export type UserInvitationByEmailQueryHookResult = ReturnType<typeof useUserInvitationByEmailQuery>;
export type UserInvitationByEmailLazyQueryHookResult = ReturnType<typeof useUserInvitationByEmailLazyQuery>;
export type UserInvitationByEmailQueryResult = Apollo.QueryResult<UserInvitationByEmailQuery, UserInvitationByEmailQueryVariables>;
export const CliniciansSearchDocument = gql`
    query CliniciansSearch($filter: UsersFilterInput, $paginate: OffsetPaginationInput) {
  cliniciansSearch(filter: $filter, paginate: $paginate) {
    users {
      id
    }
  }
}
    `;

/**
 * __useCliniciansSearchQuery__
 *
 * To run a query within a React component, call `useCliniciansSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCliniciansSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCliniciansSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paginate: // value for 'paginate'
 *   },
 * });
 */
export function useCliniciansSearchQuery(baseOptions?: Apollo.QueryHookOptions<CliniciansSearchQuery, CliniciansSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CliniciansSearchQuery, CliniciansSearchQueryVariables>(CliniciansSearchDocument, options);
      }
export function useCliniciansSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CliniciansSearchQuery, CliniciansSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CliniciansSearchQuery, CliniciansSearchQueryVariables>(CliniciansSearchDocument, options);
        }
export type CliniciansSearchQueryHookResult = ReturnType<typeof useCliniciansSearchQuery>;
export type CliniciansSearchLazyQueryHookResult = ReturnType<typeof useCliniciansSearchLazyQuery>;
export type CliniciansSearchQueryResult = Apollo.QueryResult<CliniciansSearchQuery, CliniciansSearchQueryVariables>;