import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  bodyNoJobApplications: {
    defaultMessage:
      'To see if we have upcoming positions aligned to your expertise, reach out to your Clinician Advocate.',
    description: 'Body to show when clinician has no open job applications',
    id: 'Modules.provider.Home.JobApplications.bodyNoJobApplications',
  },
  jobTitleTableHeader: {
    defaultMessage: 'Job Title',
    description: 'Column heading',
    id: 'Modules.provider.Home.JobApplications.jobTitleTableHeader',
  },
  jobStatusTableHeader: {
    defaultMessage: 'Status',
    description: 'Column heading',
    id: 'Modules.provider.Home.JobApplications.jobStatusTableHeader',
  },
  jobUpdateTableHeader: {
    defaultMessage: 'Update',
    description: 'Column heading',
    id: 'Modules.provider.Home.JobApplications.jobUpdateTableHeader',
  },
  jobAppliedTableHeader: {
    defaultMessage: 'Applied',
    description: 'Column heading',
    id: 'Modules.provider.Home.JobApplications.jobAppliedTableHeader',
  },
  title: {
    defaultMessage: 'Job Applications Tracker',
    description: 'Section title',
    id: 'Modules.provider.Home.JobApplications.title',
  },
  titleNoJobApplications: {
    defaultMessage: `You don't have any job applications to track.`,
    description: 'Title to show when clinician has no open job applications',
    id: 'Modules.provider.Home.JobApplications.titleNoJobApplications',
  },
  suggested: {
    defaultMessage: 'Suggested',
    description: 'Suggested',
    id: 'Modules.provider.Home.JobApplications.suggested',
  },
});
