import * as Types from '../../../Data/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CliniciansSearchQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.UsersFilterInput>;
  paginate?: Types.InputMaybe<Types.OffsetPaginationInput>;
  sort?: Types.InputMaybe<Types.UsersSortInput>;
}>;


export type CliniciansSearchQuery = { cliniciansSearch: { hasNext: boolean, hasPrev: boolean, skip: number, take: number, totalRecords: number, users: Array<{ createdAt: any, id: string, email: string, lastActive?: any | null, role: Types.UserRole, clinicianAdvocateId?: string | null, profile: { completion?: number | null, displayName: string, firstName?: string | null, lastName?: string | null, phone?: string | null, userId: string, networkStatus: Types.UserNetworkStatus, utm?: any | null, licenses: Array<{ stateId: string, status: Types.LicenseStatus }>, DEALicenses: Array<{ stateId: string, status: Types.LicenseStatus }>, professionalContext: { specialty?: string | null, type: Types.UserRole } | { type: Types.UserRole } | { specialty?: string | null, type: Types.UserRole } | { type: Types.UserRole } | { specialty?: string | null, type: Types.UserRole } | { specialty?: string | null, type: Types.UserRole } }, clinicianAdvocate?: { id: string } | null, jobApplications?: Array<{ id: string, createdAt: any, jobId: string, latestRuling?: { id: string, decision?: Types.JobApplicationRulingDecision | null } | null, job: { id: string, title: string, status: Types.JobStatus, clinic: { id: string, name: string } } }> | null, verifiedActiveLicenses: Array<{ stateId: string, status: Types.LicenseStatus }>, jobSuggestions?: Array<{ clinicianAdvocateId: string, createdAt: any, id: string, jobId: string, job: { id: string, title: string, clinic: { id: string, name: string } } }> | null }> } };

export type JobsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type JobsQuery = { jobs: Array<{ id: string, title: string }> };


export const CliniciansSearchDocument = gql`
    query CliniciansSearch($filter: UsersFilterInput, $paginate: OffsetPaginationInput, $sort: UsersSortInput) {
  cliniciansSearch(filter: $filter, paginate: $paginate, sort: $sort) {
    hasNext
    hasPrev
    skip
    take
    totalRecords
    users {
      createdAt
      id
      email
      lastActive
      profile {
        completion
        displayName @client
        firstName
        lastName
        licenses {
          stateId
          status
        }
        DEALicenses {
          stateId
          status
        }
        phone
        professionalContext {
          ... on APPProfessionalContext {
            specialty
            type
          }
          ... on ClinicProfessionalContext {
            type
          }
          ... on NurseProfessionalContext {
            specialty
            type
          }
          ... on OtherProfessionalContext {
            type
          }
          ... on PhysicianProfessionalContext {
            specialty
            type
          }
          ... on TherapistProfessionalContext {
            specialty
            type
          }
        }
        userId
        networkStatus
        utm
      }
      role
      clinicianAdvocateId
      clinicianAdvocate {
        id
      }
      jobApplications {
        id
        createdAt
        jobId
        latestRuling {
          id
          decision
        }
        job {
          id
          title
          status
          clinic {
            id
            name
          }
        }
      }
      verifiedActiveLicenses {
        stateId
        status
      }
      jobSuggestions {
        clinicianAdvocateId
        createdAt
        id
        jobId
        job {
          id
          title
          clinic {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCliniciansSearchQuery__
 *
 * To run a query within a React component, call `useCliniciansSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCliniciansSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCliniciansSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paginate: // value for 'paginate'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCliniciansSearchQuery(baseOptions?: Apollo.QueryHookOptions<CliniciansSearchQuery, CliniciansSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CliniciansSearchQuery, CliniciansSearchQueryVariables>(CliniciansSearchDocument, options);
      }
export function useCliniciansSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CliniciansSearchQuery, CliniciansSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CliniciansSearchQuery, CliniciansSearchQueryVariables>(CliniciansSearchDocument, options);
        }
export type CliniciansSearchQueryHookResult = ReturnType<typeof useCliniciansSearchQuery>;
export type CliniciansSearchLazyQueryHookResult = ReturnType<typeof useCliniciansSearchLazyQuery>;
export type CliniciansSearchQueryResult = Apollo.QueryResult<CliniciansSearchQuery, CliniciansSearchQueryVariables>;
export const JobsDocument = gql`
    query Jobs {
  jobs {
    id
    title
  }
}
    `;

/**
 * __useJobsQuery__
 *
 * To run a query within a React component, call `useJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobsQuery(baseOptions?: Apollo.QueryHookOptions<JobsQuery, JobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
      }
export function useJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobsQuery, JobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
        }
export type JobsQueryHookResult = ReturnType<typeof useJobsQuery>;
export type JobsLazyQueryHookResult = ReturnType<typeof useJobsLazyQuery>;
export type JobsQueryResult = Apollo.QueryResult<JobsQuery, JobsQueryVariables>;