import { useState } from 'react';
import { PasswordLabel } from './types';

export const usePassword = () => {
  const [showRequirements, setShowRequirements] = useState<boolean>(false);
  const [label, setLabel] = useState<PasswordLabel>('Show password');

  const toggleLabel = (pwdLabel: PasswordLabel) =>
    pwdLabel === 'Show password' ? 'Hide password' : 'Show password';

  return {
    label,
    onBlur: () => {
      setLabel('Show password');
      setShowRequirements(false);
    },
    onFocus: () => setShowRequirements(true),
    onLabelClick: () => setLabel(toggleLabel),
    showRequirements,
  };
};
