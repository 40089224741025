import React from 'react';
import Select, { SelectProps } from '@openloop/limbic/Form/Select/Select';

import physicianTypes from '~Constants/physicianTypes';
import { PartialBy } from '~Types/utils';

interface Props extends PartialBy<Omit<SelectProps, 'options'>, 'placeholder'> {}

const physicianTypeOptions = physicianTypes.map((physicianType) => ({
  value: physicianType,
  label: physicianType,
}));

const PhysicianTypeSelect = ({ id, name, placeholder = 'Type' }: Props) => (
  <Select placeholder={placeholder} id={id} name={name} options={physicianTypeOptions} />
);

export default PhysicianTypeSelect;
