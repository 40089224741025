import React, { ComponentType } from 'react';
import { useParams, generatePath } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Grid from '@openloop/limbic/Grid';
import { Tag } from 'antd';
import { useIntl } from 'react-intl';

import ClinicIntegrationDrChrono from '~Components/ClinicIntegrationDrChrono';
import { clinicIntegrationsMetadata } from '~Constants/clinicIntegrations';
import { clinicRoutes } from '~Constants/routes';
import Link from '~Core/Link';
import ErrorBoundary from '~Core/ErrorBoundary';
import { ClinicIntegrations, useClinicIntegrationsQuery } from '~Data';
import LoadingScreen from '~Modules/shared/LoadingScreen';
import { intlMessages } from './intlMessages';

import styles from './IntegrationDetails.module.scss';

const { Col, Row } = Grid;

interface ConfigComponentProps {
  clinicId: string;
}

const integrationDetailComponents: Record<
  keyof ClinicIntegrations,
  ComponentType<ConfigComponentProps>
> = {
  drChrono: ClinicIntegrationDrChrono,
};

const IntegrationDetails = () => {
  const { formatMessage } = useIntl();
  const { clinicId, integrationSlug } = useParams<{
    clinicId: string;
    integrationSlug: keyof ClinicIntegrations;
  }>();
  const { data, loading } = useClinicIntegrationsQuery({ variables: { clinicId } });

  if (loading) {
    return <LoadingScreen />;
  }

  const IntegrationDetailComponent = integrationDetailComponents[integrationSlug];

  const { description, logoUrl, title } = clinicIntegrationsMetadata[integrationSlug];

  const isActive = data?.clinic.integrations && data.clinic.integrations[integrationSlug]?.isActive;

  return (
    <div className={styles.main}>
      <Row justify="center">
        <Col xs={22} md={18} lg={16}>
          <Link to={generatePath(clinicRoutes.clinicIntegrations, { clinicId })}>
            <ArrowLeftOutlined /> {formatMessage(intlMessages.integrations)}
          </Link>
          <hr />
          <ErrorBoundary>
            <div className={styles.titleRow}>
              <img alt={title} className={styles.logoImg} src={logoUrl} />
              {isActive && <Tag color="green">{formatMessage(intlMessages.active)}</Tag>}
            </div>
            <p>{description}</p>
            <IntegrationDetailComponent clinicId={clinicId} />
          </ErrorBoundary>
        </Col>
      </Row>
    </div>
  );
};

export default IntegrationDetails;
