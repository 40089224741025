import { useMemo } from 'react';
import common from '~Constants/common';

const useCurrentOrigin = () => {
  const currentOrigin = useMemo(() => {
    if (window) {
      return window.location.origin;
    }
    return common.appBaseUrl;
  }, []);
  return currentOrigin;
};

export default useCurrentOrigin;
