import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  willToWork: {
    defaultMessage: 'Willing to work on',
    description: 'Availability paragraph',
    id: 'components.AvailabilityShifts.willToWork',
  },
  day: {
    defaultMessage: 'Day',
    description: 'Day',
    id: 'components.AvailabilityShifts.day',
  },
  evening: {
    defaultMessage: 'Evening',
    description: 'Evening',
    id: 'components.AvailabilityShifts.evening',
  },
  night: {
    defaultMessage: 'Night',
    description: 'Night',
    id: 'components.AvailabilityShifts.night',
  },
});
