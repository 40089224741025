import { ProviderUserRole, UserRole } from '~Data';

export const physicianSpecialties = [
  'Anesthesiology',
  'Cardiology',
  'Cardiothoracic Surgery',
  'Dermatology',
  'Emergency Medicine',
  'Endocrinologist',
  'Family Medicine',
  'Gastroenterology',
  'General surgery',
  'Hematology and Oncology',
  'Intensive Care',
  'Internal medicine',
  'Nephrology',
  'Neurology',
  'Neurosurgery',
  'Obstetrics and Gynecology',
  'Ophthalmology',
  'Orthopedic surgery',
  'Otolaryngology',
  'Pediatrics',
  'Physical Medicine and Rehabilitation',
  'Plastic Surgery',
  'Psychiatry',
  'Pulmonology ',
  'Radiology',
  'Rheumatologist',
  'Urology',
  'Other',
] as const;

export const appSpecialties = [
  'Acute Care',
  'Adult Health',
  'Community Health',
  'Critical Care Medicine',
  'Critical Care Neonatal',
  'Critical Care Pediatrics',
  'Family',
  'Gerontology',
  'Neonatal',
  'Obstetricians & Gynecology',
  'Occupational Health',
  'Pediatrics',
  'Perinatal',
  'Primary Care',
  'Psych/Mental Health',
  'School',
  `Women's Health`,
  'Other',
] as const;

export const nurseSpecialties = [
  'Addiction',
  'Administrator',
  'Adult Psych/Mental Health',
  'Ambulatory Care',
  `Ambulatory Women's Health Care`,
  'Antepartum',
  'Cardiac Rehabilitation',
  'Cardiovascular Intensive Care Unit',
  'Cardiovascular Operating Room',
  'Case Management',
  'Child & Adolescent Psych/Mental Health',
  'College Health',
  'Community Health',
  'Continence Care Registered Nurse',
  'Continuing Education/Staff Development',
  'Critical Care Medicine',
  'Diabetes Educator',
  'Dialysis',
  'Dialysis/Peritoneal',
  'Director of Nursing',
  'Emergency Room',
  'Enterostomal Therapy',
  'Flight Nurse',
  'Gastroenterology',
  'General Practice',
  'Gerontology',
  'Hemodialysis',
  'High-Risk Obstetrics',
  'Home Health',
  'Hospice',
  'Infection Control',
  'Infusion Therapy',
  'Inpatient Obstetric',
  'Intensive Care Unit',
  'Interventional Radiology',
  'Labor and Delivery',
  'Lactation Consultant',
  'Maternal/Newborn',
  'Medical/Surgical',
  'Medical/Surgical/Telemetry',
  'Mother/Baby',
  'Neonatal Intensive Care',
  'Neonatal/Low-Risk',
  'Nephrology',
  'Neurointerventional Radiology',
  'Neuroscience',
  'Nurse Massage Therapist',
  'Nurse Supervisor',
  'Nursery',
  'Nutrition Support',
  'Occupational Health',
  'Oncology',
  'Operating Room',
  'Ophthalmic',
  'Orthopedic',
  'Ostomy Care',
  'Otorhinolaryngology & Head-Neck',
  'Pain Management',
  'Pediatric Intensive Care Unit',
  'Pediatric Oncology',
  'Pediatrics',
  'Perinatal',
  'Perioperative',
  'Plastic Surgery',
  'Post Anesthesia Care Unit',
  'Postpartum',
  'Progressive Care Unit',
  'Psych/Mental Health',
  'Psychiatric',
  'Rehabilitation',
  'Reproductive Endocrinology/Infertility',
  'School',
  'Telemetry',
  'Urology',
  'Wound Care',
  'Other',
] as const;

export const specialtiesByType: Record<ProviderUserRole, readonly string[] | null> = {
  [UserRole.App]: appSpecialties,
  [UserRole.Nurse]: nurseSpecialties,
  [UserRole.Other]: null,
  [UserRole.Physician]: physicianSpecialties,
  [UserRole.Therapist]: null,
};
