import React from 'react';
import { Row, Col, Statistic, Tag, Space } from 'antd';
import { MobileOutlined, MailOutlined, EnvironmentOutlined, LinkOutlined } from '@ant-design/icons';
import Heading from '@openloop/limbic/Heading';
import { useIntl } from 'react-intl';

import ViewCVModal from '~Components/ViewCVButton';
import { useAuthContext } from '~Context/AuthContext';
import CvUploadButton from '~Core/CvUploadButton';
import Paragraph from '~Core/Paragraph';
import { CurrentUserQuery, ProviderProfessionalContext } from '~Data';
import { getAddressForDisplay } from '~Helpers/address';
import { formatPhone } from '~Helpers/phoneNumber';
import { getProviderSpecialty, getNpiLink } from '~Helpers/users';
import ProfilePhoto from '~Modules/provider/ProfilePhoto';
import { intlMessages } from './intlMessages';

import styles from './BasicUserInfo.module.less';

interface Props {
  user: CurrentUserQuery['currentUser'];
}

const BasicUserInfo = ({
  user: {
    id,
    email,
    profile: { address, bio, cv, displayName, image, phone, professionalContext },
  },
}: Props) => {
  const { formatMessage } = useIntl();
  const { currentUser } = useAuthContext();
  const specialty = getProviderSpecialty(professionalContext as ProviderProfessionalContext);
  const location = address ? getAddressForDisplay(address) : null;
  const { employmentHistory, jobTypeSeeking, professionalReferencesText } =
    professionalContext as ProviderProfessionalContext;

  return (
    <Row gutter={[24, 64]}>
      <Col xs={24} sm={8} md={6}>
        <ProfilePhoto currentImage={image?.location} />
      </Col>
      <Col xs={24} sm={16} md={12}>
        <Heading level="h1" size="h2">
          {displayName}
        </Heading>
        {specialty && <Heading level="h4">{specialty}</Heading>}
        {phone && (
          <Paragraph>
            <MobileOutlined /> {formatPhone(phone)}
          </Paragraph>
        )}
        <Paragraph>
          <MailOutlined /> {email}
        </Paragraph>
        {location && (
          <Paragraph>
            <EnvironmentOutlined /> {location}
          </Paragraph>
        )}
      </Col>
      <Col xs={24} md={6}>
        <Space className={styles.actionContainer} direction="vertical">
          {currentUser && currentUser.id === id && (
            <>
              <CvUploadButton
                buttonProps={{ block: true, variant: 'primary' }}
                className={styles.uploadButton}
                hasCv={!!cv}
                userId={currentUser.id}
              />
              {cv && <ViewCVModal userId={currentUser.id} className={styles.viewCVButton} />}
            </>
          )}
        </Space>
      </Col>
      <Col span={24}>
        <Row gutter={64}>
          {'npi' in professionalContext && (
            <Col>
              <Statistic
                // Don't format the `npi`
                formatter={(value) => {
                  if (value) {
                    return (
                      <a href={getNpiLink(value)} target="_blank" rel="noopener noreferrer">
                        {value} <LinkOutlined />
                      </a>
                    );
                  }
                  return 'N/A';
                }}
                value={professionalContext.npi || ''}
                title="NPI #"
              />
            </Col>
          )}
          {jobTypeSeeking && (
            <Col>
              <Statistic
                title="Availability"
                valueRender={() =>
                  jobTypeSeeking.map((jobType) => <Tag key={jobType}>{jobType}</Tag>)
                }
              />
            </Col>
          )}
        </Row>
      </Col>
      {bio && (
        <Col span={24}>
          <Heading level="h2">{formatMessage(intlMessages.introduction)}</Heading>
          <Paragraph className={styles.formattedContent}>{bio}</Paragraph>
        </Col>
      )}
      {employmentHistory && (
        <Col span={24}>
          <Heading level="h2">{formatMessage(intlMessages.employmentHistory)}</Heading>
          <Paragraph className={styles.formattedContent}>{employmentHistory}</Paragraph>
        </Col>
      )}
      {professionalReferencesText && (
        <Col span={24}>
          <Heading level="h2">{formatMessage(intlMessages.professionalReferences)}</Heading>
          <Paragraph className={styles.formattedContent}>{professionalReferencesText}</Paragraph>
        </Col>
      )}
    </Row>
  );
};

export default BasicUserInfo;
