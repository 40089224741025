import React, { ComponentProps } from 'react';
import { useField } from 'formik';

import { RequiredBy } from '~Types/utils';
import FormItemControl from './FormItemControl';

interface Props extends RequiredBy<ComponentProps<typeof FormItemControl>, 'label'> {
  name: string;
}

const FormItem = ({ name, ...rest }: Props) => {
  const [, { error, touched }] = useField(name);

  return <FormItemControl error={error && touched ? error : undefined} {...rest} />;
};

export default FormItem;
