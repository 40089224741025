import React, { useCallback, useMemo, ComponentProps } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Divider } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { Form } from 'formik-antd';
import * as yup from 'yup';
import Button from '@openloop/limbic/Button';
import LimbicForm from '@openloop/limbic/Form';
import Heading from '@openloop/limbic/Heading';
import toast from '@openloop/limbic/Toast';
import { useIntl } from 'react-intl';

import messages from '~Constants/messages';
import CommentsList from '~Core/CommentsList';
import {
  AdminUserDocument,
  AdminUserQueryVariables,
  useAdminCreateUserNoteMutation,
  useAdminUserQuery,
} from '~Data';
import { intlMessages } from './intlMessages';

import styles from './Notes.module.less';

const { Textarea } = LimbicForm;

interface FormValues {
  message: string;
}

const validationSchema = yup.object().shape({
  message: yup.string().required('Message is required'),
});

const Notes = () => {
  const { formatMessage } = useIntl();
  const { userId } = useParams<{ userId: string }>();
  const { data } = useAdminUserQuery({ variables: { userId } });

  const [createNote, { loading: createNoteLoading }] = useAdminCreateUserNoteMutation({
    onError: () => {
      toast({ message: messages.noteCreateError, variant: 'danger' });
    },
    refetchQueries: [
      { query: AdminUserDocument, variables: { userId } as AdminUserQueryVariables },
    ],
  });

  const handleSubmit = useCallback<
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void
  >(
    ({ message }, { resetForm }) => {
      createNote({
        onCompleted: () => {
          toast({ message: messages.noteCreateSuccessful, variant: 'success' });
          resetForm();
        },
        variables: { input: { message, userId } },
      });
    },
    [createNote, userId],
  );

  const comments = useMemo<ComponentProps<typeof CommentsList>['comments']>(() => {
    if (!data?.adminUser.notes) {
      return [];
    }
    return data.adminUser.notes.map(
      ({
        id,
        createdAt,
        creator: {
          profile: { displayName },
        },
        message,
      }) => ({
        author: displayName,
        createdAt,
        id,
        message,
      }),
    );
  }, [data]);

  return (
    <>
      {data?.adminUser.notes && (
        <div>
          <Heading level="h2">{formatMessage(intlMessages.notes)}</Heading>
          {data.adminUser.notes.length > 0 && (
            <div className={styles.notesContainer}>
              <Card>
                <CommentsList comments={comments} />
              </Card>
            </div>
          )}
          <Divider />
          <Formik<FormValues>
            enableReinitialize
            initialValues={{ message: '' }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ dirty, isValid }) => (
              <Form layout="vertical">
                <Form.Item label={formatMessage(intlMessages.addNote)} name="message">
                  <Textarea name="message" placeholder="Enter text here..." />
                </Form.Item>
                <Button
                  disabled={!dirty || !isValid}
                  type="submit"
                  loading={createNoteLoading}
                  variant="primary"
                >
                  {formatMessage(intlMessages.comment)}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default Notes;
