import React, { FC, HTMLAttributes, SVGProps } from 'react';

import {
  BulletedListIcon,
  ChatOutlinedIcon,
  ChatTypingOutlinedIcon,
  CheckCircleOutlinedIcon,
  CheckOutlinedIcon,
  DoubleLeftOutlinedIcon,
  DoubleRightOutlinedIcon,
  ErrorOutlinedIcon,
  FirstAidKitOutlinedIcon,
  HeadsetIcon,
  HospitalOutlinedIcon,
  LetterIcon,
  MedicalProtectionOutlinedIcon,
  NurseOutlinedIcon,
  PaperPlaneOutlinedIcon,
  PaymentOutlinedIcon,
  PhoneIcon,
  PluginOutlinedIcon,
  ScheduleOutlinedIcon,
  SearchOutlinedIcon,
  StarIcon,
  StethoscopeOutlinedIcon,
  MedicationsPillIcon,
  ProfileIcon,
  JobOpportunitiesIcon,
  ProviderNetworkIcon,
  CredentialingIcon,
  LicensingIcon,
  NoteArrowIcon,
} from '~Assets/icons';
import { TypeVariant } from '~Core/Types';
import useClassNames from '~Hooks/useClassNames';

import styles from './Icon.module.less';

export enum Icons {
  BulletedList = 'bulleted-list',
  ChatOutlined = 'chat-outlined',
  ChatTypingOutlined = 'chat-typing-outlined',
  CheckCircleOutlined = 'check-circle-outlined',
  CheckOutlined = 'check-outlined',
  DoubleLeftOutlined = 'double-left-outlined',
  DoubleRightOutlined = 'double-right-outlined',
  ErrorOutlined = 'error-outlined',
  FirstAidKitOutlined = 'first-aid-kit-outlined',
  Headset = 'headset',
  HospitalOutlined = 'hospital-outlined',
  Letter = 'letter',
  MedicalProtectionOutlined = 'medical-protection-outlined',
  NurseOutlined = 'nurse-outlined',
  PaperPlaneOutlined = 'paper-plane',
  PaymentOutlined = 'payment-outlined',
  Phone = 'phone',
  PluginOutlined = 'plugin-outlined',
  ScheduleOutlined = 'schedule-outlined',
  SearchOutlined = 'search-outlined',
  Star = 'star',
  StethoscopeOutlined = 'stethoscope-outlined',
  MedicationsPill = 'medicationsPill',
  Profile = 'profile',
  JobOpportunities = 'job-opportunities',
  ProviderNetwork = 'provider-network',
  Credentialing = 'credentialing',
  Licensing = 'licensing',
  NoteArrow = 'note-arrow',
}

interface Props extends HTMLAttributes<SVGSVGElement> {
  name: Icons;
  type?: TypeVariant;
}

const iconMap: Record<Icons, FC<SVGProps<SVGSVGElement>>> = {
  [Icons.BulletedList]: BulletedListIcon,
  [Icons.ChatOutlined]: ChatOutlinedIcon,
  [Icons.ChatTypingOutlined]: ChatTypingOutlinedIcon,
  [Icons.CheckCircleOutlined]: CheckCircleOutlinedIcon,
  [Icons.CheckOutlined]: CheckOutlinedIcon,
  [Icons.DoubleLeftOutlined]: DoubleLeftOutlinedIcon,
  [Icons.DoubleRightOutlined]: DoubleRightOutlinedIcon,
  [Icons.ErrorOutlined]: ErrorOutlinedIcon,
  [Icons.FirstAidKitOutlined]: FirstAidKitOutlinedIcon,
  [Icons.Headset]: HeadsetIcon,
  [Icons.HospitalOutlined]: HospitalOutlinedIcon,
  [Icons.Letter]: LetterIcon,
  [Icons.MedicalProtectionOutlined]: MedicalProtectionOutlinedIcon,
  [Icons.MedicationsPill]: MedicationsPillIcon,
  [Icons.NurseOutlined]: NurseOutlinedIcon,
  [Icons.PaperPlaneOutlined]: PaperPlaneOutlinedIcon,
  [Icons.PaymentOutlined]: PaymentOutlinedIcon,
  [Icons.Phone]: PhoneIcon,
  [Icons.PluginOutlined]: PluginOutlinedIcon,
  [Icons.Profile]: ProfileIcon,
  [Icons.ScheduleOutlined]: ScheduleOutlinedIcon,
  [Icons.SearchOutlined]: SearchOutlinedIcon,
  [Icons.Star]: StarIcon,
  [Icons.StethoscopeOutlined]: StethoscopeOutlinedIcon,
  [Icons.JobOpportunities]: JobOpportunitiesIcon,
  [Icons.ProviderNetwork]: ProviderNetworkIcon,
  [Icons.Credentialing]: CredentialingIcon,
  [Icons.Licensing]: LicensingIcon,
  [Icons.NoteArrow]: NoteArrowIcon,
};

const displayName = 'Core.Icon';

const Icon = ({ className, name, type, ...rest }: Props) => {
  const classNames = useClassNames(styles, className, { type });
  const SvgElement = iconMap[name];
  return <SvgElement className={classNames} data-testid={displayName} {...rest} />;
};

Icon.displayName = displayName;

export default Icon;
