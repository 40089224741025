import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  csvExport: {
    defaultMessage: 'CSV Export',
    description: 'CSV Export',
    id: 'components.AllUsersDownloadButton.csvExport',
  },
  buildingCsv: {
    defaultMessage: 'Building CSV...',
    description: 'Building CSV...',
    id: 'components.AllUsersDownloadButton.buildingCsv',
  },
  generateReport: {
    defaultMessage: 'Generate Report',
    description: 'Generate Report',
    id: 'components.AllUsersDownloadButton.generateReport',
  },
});
