import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './LoadingScreen.module.less';

const spinIndicator = <LoadingOutlined className={styles.spinIndicator} spin />;

const LoadingScreen = () => (
  <div className={styles.main}>
    <div>
      <Spin indicator={spinIndicator} />
    </div>
  </div>
);

export default LoadingScreen;
