import React from 'react';
import { Button, Card, Heading } from '@openloop/limbic';
import { useWizard } from 'react-use-wizard';
import classNames from 'classnames/bind';
import { useFormikContext } from 'formik';
import { generatePath } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { sharedRoutes } from '~Constants/routes';
import { ClinicianData, JobObject } from '../types';
import ExitWizardButton from '../ExitWizardButton';

import styles from '../InviteAndSuggest.module.scss';
import pageStyles from './ConfirmationScreens.module.scss';
import { intlMessages } from './intlMessages';

const cx = classNames.bind(styles);

const ConfirmInfoScreen = () => {
  const { formatMessage } = useIntl();
  const { previousStep } = useWizard();

  const {
    status,
    values: { firstName, lastName, preferredName, email, clinicianType },
  } = useFormikContext<ClinicianData>();

  const { clinicianAdvocate, jobs } = status;

  return (
    <div className={styles.main}>
      <Card className={styles.card}>
        <div className={styles.cardInnerContainer}>
          <Heading level="h2" size="h3" className={styles.heading}>
            {formatMessage(intlMessages.confirmTitle)}
          </Heading>
          <div className={cx(pageStyles.contentSection, pageStyles.row)}>
            <div className={pageStyles.columnOne}>
              <Heading level="h3" className={pageStyles.confirmFieldHeadings} uppercase>
                {formatMessage(intlMessages.name)}
              </Heading>
              <p className={pageStyles.confirmFieldValue}>{`${firstName} ${lastName}`}</p>
              <Heading level="h3" className={pageStyles.confirmFieldHeadings} uppercase>
                {formatMessage(intlMessages.knownAs)}
              </Heading>
              <p className={pageStyles.confirmFieldValue}>{preferredName}</p>
              <Heading level="h3" className={pageStyles.confirmFieldHeadings} uppercase>
                {formatMessage(intlMessages.email)}
              </Heading>
              <a
                href={`mailto:${email}`}
                target="_blank"
                className={pageStyles.confirmFieldValueLink}
                rel="noreferrer"
              >
                {email}
              </a>
              <Heading level="h3" className={pageStyles.confirmFieldHeadings} uppercase>
                {formatMessage(intlMessages.clinicianType)}
              </Heading>
              <p className={pageStyles.confirmFieldValue}>{clinicianType}</p>
              <Heading level="h3" className={pageStyles.confirmFieldHeadings} uppercase>
                {formatMessage(intlMessages.ca)}
              </Heading>
              {clinicianAdvocate ? (
                <p className={pageStyles.confirmFieldValue}>{clinicianAdvocate}</p>
              ) : null}
            </div>
            {jobs?.length === 0 ? null : (
              <div className={pageStyles.columnTwo}>
                <Heading level="h3" className={pageStyles.confirmFieldHeadings} uppercase>
                  {formatMessage(intlMessages.suggestedJobs)}
                </Heading>
                {jobs?.map(({ id: jobId, title: jobTitle }: JobObject) => (
                  <a
                    key={jobId}
                    href={generatePath(sharedRoutes.jobDescription, { jobId })}
                    target="_blank"
                    className={pageStyles.confirmFieldValueLink}
                    rel="noreferrer"
                  >
                    {jobTitle}
                  </a>
                ))}
              </div>
            )}
          </div>
          <footer>
            <div className={styles.buttonRow}>
              <Button variant="secondary" className={styles.button} onClick={previousStep}>
                {formatMessage(intlMessages.previous)}
              </Button>
              <Button type="submit" className={styles.button}>
                {formatMessage(intlMessages.send)}
              </Button>
            </div>
            <ExitWizardButton />
          </footer>
        </div>
      </Card>
    </div>
  );
};

export default ConfirmInfoScreen;
