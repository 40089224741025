import React, { ReactNode, HTMLAttributes } from 'react';

import { TypeVariant } from '~Core/Types';
import useClassNames from '~Hooks/useClassNames';
import styles from './Paragraph.module.less';

interface Props {
  type?: TypeVariant;
  strong?: boolean;
  className?: string;
  children: ReactNode;
}

const Paragraph = ({
  type = 'primary',
  strong = false,
  className,
  children,
  ...rest
}: Props & HTMLAttributes<HTMLParagraphElement>) => {
  const classNames = useClassNames(styles, className, {
    type,
  });

  const finalChildren = strong ? <strong>{children}</strong> : children;

  return (
    <p className={classNames} {...rest}>
      {finalChildren}
    </p>
  );
};

export default Paragraph;
