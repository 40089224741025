import React, { ReactNode } from 'react';
import { Row, Col } from 'antd';

import images from '~Constants/images';
import Link from '~Core/Link';

import styles from './FullScreen.module.less';

interface Props {
  children: ReactNode;
  withHeader?: boolean;
}

const FullScreen = ({ children, withHeader = true }: Props) => (
  <div className={styles.main}>
    {withHeader && (
      <div>
        <Row justify="center">
          <Col span={22} className={styles.imageCol}>
            <Link to="/">
              <img alt="OpenLoop" className={styles.image} src={images.logo} />
            </Link>
          </Col>
        </Row>
      </div>
    )}
    <div className={styles.contentContainer}>{children}</div>
  </div>
);

export default FullScreen;
