import React from 'react';
import { Row } from 'antd';
import Button, { ButtonProps } from '@openloop/limbic/Button';

import { useIntl } from 'react-intl';
import Icon, { Icons } from '~Core/Icon';
import Link from '~Core/Link';
import { PartialBy } from '~Types/utils';

import styles from './WizardFooter.module.less';
import { intlMessages } from './intlMessages';

interface WizardFooterProps {
  cancelRoute?: string;
  cancelText?: string;
  isFirstStep?: boolean;
  prevButtonProps?: PartialBy<ButtonProps, 'children'>;
  nextButtonProps?: PartialBy<ButtonProps, 'children'>;
  nextButtonText?: string;
}

const WizardFooter = ({
  cancelRoute,
  cancelText = 'Cancel',
  isFirstStep = true,
  prevButtonProps = {},
  nextButtonProps = {},
  nextButtonText = 'Next',
}: WizardFooterProps) => {
  const { formatMessage } = useIntl();

  return (
    <Row className={styles.actionButtons}>
      <div>
        {!isFirstStep ? (
          <Button
            icon={<Icon name={Icons.DoubleLeftOutlined} />}
            variant="secondary-outline"
            {...prevButtonProps}
          >
            {formatMessage(intlMessages.prevButtonText)}
          </Button>
        ) : (
          <>
            {cancelRoute && (
              <Link to={cancelRoute} buttonVariant="secondary-outline">
                {cancelText}
              </Link>
            )}
          </>
        )}
      </div>
      <Button aria-label={!nextButtonProps.children ? 'Next' : undefined} {...nextButtonProps}>
        {nextButtonText}
      </Button>
    </Row>
  );
};

export default WizardFooter;
