import React from 'react';
import { Formik } from 'formik';
import { Wizard } from 'react-use-wizard';
import { Form, toast } from '@openloop/limbic';

import { useCreateUserInvitationMutation } from './ClinicianInviteWizard.generated';
import ClinicianInfo from '../../ClinicianInfo';
import JobSearchAndSelectScreen from '../../JobSearchAndSelectScreen';
import { ConfirmInfoScreen, SuccessScreen } from '../../ConfirmationScreens';
import { InviteClinicianValues } from '../../types';
import { inviteClinicianInitialValues, inviteClinicianValidationSchema } from '../../utils';

const ClinicianInviteWizard = () => {
  const [createUserInvitation, { loading }] = useCreateUserInvitationMutation();

  return (
    <Formik<InviteClinicianValues>
      initialValues={inviteClinicianInitialValues}
      initialStatus={{ isSubmited: false }}
      validationSchema={inviteClinicianValidationSchema}
      onSubmit={(values, { setStatus }) => {
        const { addJobs, clinicId, ...userInvitationValues } = values;
        createUserInvitation({
          variables: {
            input: userInvitationValues,
          },
          onCompleted: () => {
            setStatus({ isSubmited: true });
          },
          onError: (e) => {
            toast({ variant: 'danger', message: e.message });
          },
        });
      }}
    >
      {({ values: { addJobs }, status: { isSubmited } }) =>
        loading || isSubmited ? (
          <SuccessScreen flowType={addJobs ? 'inviteAndSuggest' : 'invite'} isLoading={loading} />
        ) : (
          <Form>
            <Wizard>
              <ClinicianInfo />
              {addJobs && <JobSearchAndSelectScreen />}
              <ConfirmInfoScreen />
            </Wizard>
          </Form>
        )
      }
    </Formik>
  );
};

export default ClinicianInviteWizard;
