import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  name: {
    defaultMessage: 'Name',
    description: 'Form label Name',
    id: 'components.ClinicContactModalFields.name',
  },
  email: {
    defaultMessage: 'Email',
    description: 'Form label Email',
    id: 'components.ClinicContactModalFields.email',
  },
  phone: {
    defaultMessage: 'Phone',
    description: 'Form label Phone',
    id: 'components.ClinicContactModalFields.phone',
  },
  title: {
    defaultMessage: 'Title',
    description: 'Form label Title',
    id: 'components.ClinicContactModalFields.title',
  },
  save: {
    defaultMessage: 'Save',
    description: 'Button label of form',
    id: 'components.ClinicContactModalFields.save',
  },
});
