import React, { ComponentProps } from 'react';
import MediaCard from '@openloop/limbic/MediaCard';
import Icon from '@openloop/limbic/Icon';
import { useIntl } from 'react-intl';
import Link from '~Core/Link';

import images from '~Constants/images';

import SectionCard from '../SectionCard';
import { intlMessages } from './intlMessages';

import styles from './Resources.module.scss';

interface ReadMoreActionProps {
  to: ComponentProps<typeof Link>['to'];
}

const { telehealthVisit, resumeTips, whitepaperVisual } = images;

const ReadMoreAction = ({ to }: ReadMoreActionProps) => {
  const { formatMessage } = useIntl();
  return (
    <a className={styles.action} href={to} target="_blank" rel="noreferrer">
      <span>{formatMessage(intlMessages.readMore)}</span>{' '}
      <Icon name="ChevronRight" size="smallest" variant="secondary" aria-hidden />
    </a>
  );
};

const Resources = () => {
  const { formatDate, formatMessage } = useIntl();

  return (
    <SectionCard iconName="Book" title={formatMessage(intlMessages.title)}>
      <div className={styles.container}>
        <MediaCard
          className={styles.card}
          image={{
            src: telehealthVisit,
            alt: 'Female nurse with glasses smiling down at documents on mobile video call',
          }}
          title="The Ultimate Checklist for Providers in Telehealth"
          metadata={[
            formatDate(new Date(2021, 9, 14)),
            formatMessage(intlMessages.readTime, { time: 6 }),
          ]}
          actions={
            <ReadMoreAction to="https://openloophealth.com/blog/the-ultimate-checklist-for-providers-in-telehealth/" />
          }
        >
          <p>{formatMessage(intlMessages.telehealthChecklist)}</p>
        </MediaCard>
        <MediaCard
          className={styles.card}
          image={{ src: resumeTips, alt: '' }}
          title="How to Build an Outstanding Clinician Resume"
          metadata={[
            formatDate(new Date(2021, 9, 7)),
            formatMessage(intlMessages.readTime, { time: 7 }),
          ]}
          actions={
            <ReadMoreAction to="https://openloophealth.com/blog/how-to-build-an-outstanding-clinician-resume/" />
          }
        >
          <p>{formatMessage(intlMessages.resumeTips)}</p>
        </MediaCard>
        <MediaCard
          className={styles.card}
          image={{ src: whitepaperVisual, alt: 'Locum Tenens: A Growing Industry Whitepaper' }}
          title="Locum Tenens: A Growing Industry"
          metadata={[
            formatDate(new Date(2020, 11, 2)),
            formatMessage(intlMessages.readTime, { time: 15 }),
          ]}
          actions={
            <ReadMoreAction to="https://openloophealth.com/whitepaper/locum-tenens-a-growing-industry/" />
          }
        >
          <p>{formatMessage(intlMessages.locumTenensWhitepaper)}</p>
        </MediaCard>
      </div>
    </SectionCard>
  );
};

export default Resources;
