import React from 'react';
import { FormikConfig, Formik, Form as FormikForm } from 'formik';

import FormItem, { FormItemControl } from './FormItem';

interface Props<V> extends FormikConfig<V> {}

const Form = <V extends Record<string, any>>({ children, ...props }: Props<V>) => (
  <Formik<V> {...props}>
    {(injectedProps) => (
      <FormikForm style={{ width: '100%' }}>
        {typeof children === 'function' ? children(injectedProps) : children}
      </FormikForm>
    )}
  </Formik>
);

Form.Item = FormItem;
Form.ItemControl = FormItemControl;

export default Form;
