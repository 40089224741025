import * as yup from 'yup';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  phone: yup.string().phone('Must be a valid phone number'),
  websiteUrl: yup.string().url('Must be a valid URL, and must start with "http"'),
  zohoUrl: yup.string().url('Must be a valid URL, and must start with "http"'),
});

export default validationSchema;
