import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  applicationsTabMenuItem: {
    defaultMessage: 'Applications',
    description: 'Tab menu item',
    id: 'Modules.admin.UserDetail.applicationsTabMenuItem',
  },
  profileTabMenuItem: {
    defaultMessage: 'Profile',
    description: 'Tab menu item',
    id: 'Modules.admin.UserDetail.profileTabMenuItem',
  },
  licensesTabMenuItem: {
    defaultMessage: 'Licensing & Credentialing',
    description: 'Tab menu item',
    id: 'Modules.admin.UserDetail.licensesTabMenuItem',
  },
  notesTabMenuItem: {
    defaultMessage: 'Notes',
    description: 'Tab menu item',
    id: 'Modules.admin.UserDetail.notesTabMenuItem',
  },
  sharingTokensTabMenuItem: {
    defaultMessage: 'Sharing Tokens',
    description: 'Tab menu item',
    id: 'Modules.admin.UserDetail.sharingTokensTabMenuItem',
  },
  errorMessage: {
    defaultMessage: 'There was an error loading this user.',
    description: 'Error Message',
    id: 'components.admin.UserDetail.errorMessage',
  },
  updateAdvocateBelow: {
    defaultMessage: 'Update clinician advocate below',
    description: 'Update clinician advocate below',
    id: 'components.admin.UserDetail.updateAdvocateBelow',
  },
  updateNetworkBelow: {
    defaultMessage: 'Update clinician network status below',
    description: 'Update clinician network status below',
    id: 'components.admin.UserDetail.updateNetworkBelow',
  },
});
