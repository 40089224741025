import * as yup from 'yup';

const PHONE_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

function phone(message?: string) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return this.test({
    name: 'phone',
    message: message || 'Invalid phone number',
    test(value: string) {
      return (
        typeof value === 'undefined' ||
        (value.match(PHONE_REGEX) && value.length >= 5 && value.length <= 15)
      );
    },
  });
}

yup.addMethod(yup.string, 'phone', phone);
