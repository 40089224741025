import React, { HTMLAttributes } from 'react';
import { FormattedRelativeTime } from 'react-intl';

interface Props extends Omit<HTMLAttributes<HTMLSpanElement>, 'children'> {
  updateInterval?: number;
  value: Date;
}

const nearestIntervalOf = (value: number, increment: number) =>
  Math.round(value / increment) * increment;

const TimeRelative = ({ value: valueProp, updateInterval = 15, ...rest }: Props) => {
  const valueInSeconds = Math.floor(
    nearestIntervalOf(
      (new Date(valueProp).getTime() - new Date().getTime()) / 1000,
      updateInterval,
    ),
  );

  return (
    <span {...rest}>
      <FormattedRelativeTime
        numeric="auto"
        updateIntervalInSeconds={updateInterval}
        value={valueInSeconds}
      />
    </span>
  );
};

export default TimeRelative;
