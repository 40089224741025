import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  integrations: {
    defaultMessage: 'Integrations',
    description: 'Integrations',
    id: 'components.clinic.IntegrationDetails.integrations',
  },
  active: {
    defaultMessage: 'Active',
    description: 'Active',
    id: 'components.clinic.IntegrationDetails.active',
  },
});
