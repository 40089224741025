import React from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Card, Grid, Spinner } from '@openloop/limbic';
import { useIntl } from 'react-intl';

import ClinicianLicenseTag from '~Components/ClinicianLicenseTag';
import { useAdminUserQuery, LicenseStatus, LicenseFieldsFragment } from '~Data';
import { filterLicensesByStatus } from '~Helpers/licenses';

import styles from './Licenses.module.scss';
import { intlMessages } from './intlMessages';

const { Row, Col } = Grid;

interface LicenseTagFields extends LicenseFieldsFragment {
  DEAStatus?: LicenseStatus;
}

const Licenses = () => {
  const { formatMessage } = useIntl();
  const { userId } = useParams<{ userId: string }>();
  const { data, error, loading } = useAdminUserQuery({ variables: { userId } });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <h1>{formatMessage(intlMessages.errorMessage)}</h1>;
  }

  if (!data) {
    return null;
  }

  const {
    adminUser: {
      profile: {
        firstName,
        lastName,
        licenses: selfReportedLicenses,
        DEALicenses: selfReportedDEALicenses,
      },
      verifiedActiveLicenses,
    },
  } = data;

  const licenses: LicenseTagFields[] = [
    ...selfReportedLicenses.reduce((acc, selfReportedLicense) => {
      const { stateId } = selfReportedLicense;
      const verifiedLicense = verifiedActiveLicenses.find(
        ({ stateId: verifiedStateId }) => verifiedStateId === stateId,
      );
      const DEALicense = selfReportedDEALicenses.find(
        ({ stateId: verifiedStateId }) => verifiedStateId === stateId,
      );
      if (!verifiedLicense) {
        acc.push(
          DEALicense
            ? { ...selfReportedLicense, DEAStatus: DEALicense.status }
            : selfReportedLicense,
        );
      }
      return acc;
    }, [] as LicenseTagFields[]),
    ...verifiedActiveLicenses.map((verifiedLicense) => {
      const { stateId } = verifiedLicense;
      const DEALicense = selfReportedDEALicenses.find(
        ({ stateId: verifiedStateId }) => verifiedStateId === stateId,
      );
      if (DEALicense) return { ...verifiedLicense, DEAStatus: DEALicense.status };
      return verifiedLicense;
    }, [] as LicenseTagFields[]),
  ].sort((a, b) => {
    if (a.state.shortName < b.state.shortName) {
      return -1;
    }
    if (a.state.shortName > b.state.shortName) {
      return 1;
    }
    return 0;
  });

  const verifiedActive = licenses.filter(filterLicensesByStatus(LicenseStatus.VerifiedActive));
  const selfReportedActive = licenses.filter(
    filterLicensesByStatus(LicenseStatus.SelfReportedActive),
  );
  const selfReportedPending = licenses.filter(
    filterLicensesByStatus(LicenseStatus.SelfReportedPending),
  );
  return (
    <div className={styles.main}>
      <Card className={styles.allLicensesCard}>
        <h2 className={styles.sectionHeading}>{formatMessage(intlMessages.subTitle1)}</h2>
        <ul className={styles.verifiedSection}>
          <h3 className={styles.cardHeading}>
            {formatMessage(intlMessages.licenseTitle1, {
              total: verifiedActive.length,
              dea: verifiedActive.filter(
                ({ DEAStatus }) => DEAStatus === LicenseStatus.VerifiedActive,
              ).length,
            })}
            :
          </h3>
          {verifiedActive.map(({ state, stateId, status, DEAStatus }) => (
            <li key={stateId}>
              <ClinicianLicenseTag state={state} status={status} DEAStatus={DEAStatus} />
            </li>
          ))}
        </ul>
        <ul className={styles.activeSection}>
          <h3 className={styles.cardHeading}>
            {formatMessage(intlMessages.licenseTitle2, {
              total: selfReportedActive.length,
              dea: selfReportedActive.filter(
                ({ DEAStatus }) => DEAStatus === LicenseStatus.SelfReportedActive,
              ).length,
            })}
            :
          </h3>
          {selfReportedActive.map(({ state, stateId, status, DEAStatus }) => (
            <li key={stateId}>
              <ClinicianLicenseTag state={state} status={status} DEAStatus={DEAStatus} />
            </li>
          ))}
        </ul>
        <ul className={styles.pendingSection}>
          <h3 className={styles.cardHeading}>
            {formatMessage(intlMessages.licenseTitle3, {
              total: selfReportedPending.length,
              dea: selfReportedPending.filter(
                ({ DEAStatus }) => DEAStatus === LicenseStatus.SelfReportedPending,
              ).length,
            })}
            :
          </h3>
          {selfReportedPending.map(({ state, stateId, status, DEAStatus }) => (
            <li key={stateId}>
              <ClinicianLicenseTag state={state} status={status} DEAStatus={DEAStatus} />
            </li>
          ))}
        </ul>
      </Card>
      <h2 className={styles.sectionHeading}>{formatMessage(intlMessages.subTitle2)}</h2>
      <p className={styles.sectionSubheading}>
        {firstName} {lastName} {formatMessage(intlMessages.isCredentialed)}:
      </p>
      <div className={styles.licenseCards}>
        {verifiedActiveLicenses && (
          <Row gutter={[16, 16]} align="stretch">
            {[...verifiedActiveLicenses].map(
              ({
                expirationDate,
                issueDate,
                licenseNumber,
                state: { name: stateName, shortName },
                stateId,
              }) => (
                <Col xs={24} sm={12} md={8} lg={6} key={stateId}>
                  <Card className={`${styles.licenseCard} ${styles.maxHeight}`}>
                    <h3 className={styles.licenseCardTitle}>
                      {stateName} ({shortName})
                    </h3>
                    <div className={styles.licenseCardDetail}>
                      <div className={styles.licenseCardGroup}>
                        <h4 className={styles.licenseCardHeading}>
                          {formatMessage(intlMessages.license)}:
                        </h4>
                        #{licenseNumber}
                      </div>
                      <div className={styles.licenseCardGroup}>
                        <h4 className={styles.licenseCardHeading}>
                          {formatMessage(intlMessages.issued)}:
                        </h4>
                        {issueDate ? moment(issueDate).format('DD MMM YYYY') : undefined}
                      </div>
                      <div className={styles.licenseCardGroup}>
                        <h4 className={styles.licenseCardHeading}>
                          {formatMessage(intlMessages.expires)}:
                        </h4>
                        {expirationDate ? moment(expirationDate).format('DD MMM YYYY') : undefined}
                      </div>
                    </div>
                  </Card>
                </Col>
              ),
            )}
          </Row>
        )}
      </div>
    </div>
  );
};

export default Licenses;
