import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Heading, Icon } from '@openloop/limbic';
import { useIntl } from 'react-intl';

import { adminRoutes } from '~Constants/routes';

import styles from './HomeScreen.module.scss';
import { intlMessages } from './intlMessages';

const HomeScreen = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <Card className={styles.flowContainer} padding="large">
        <Heading level="h1" size="h4">
          {formatMessage(intlMessages.inviteTitle)}
        </Heading>
        <div className={styles.description}>
          <div>
            <Heading level="h2" className={styles.strong}>
              {formatMessage(intlMessages.youWouldNeed)}:
            </Heading>
            <ul>
              <li>{formatMessage(intlMessages.needInviteItem1)}</li>
              <li>{formatMessage(intlMessages.needInviteItem2)}</li>
              <li>{formatMessage(intlMessages.needInviteItem3)}</li>
            </ul>
          </div>
          <div>
            <Heading level="h2" className={styles.strong}>
              {formatMessage(intlMessages.ableTo)}:
            </Heading>
            <ul>
              <li>{formatMessage(intlMessages.ableToInviteItem1)}</li>
              <li>{formatMessage(intlMessages.ableToInviteItem2)}</li>
            </ul>
          </div>
        </div>
        <Button
          className={styles.cta}
          iconPlacement="after"
          icon={<Icon name="ChevronRight" />}
          onClick={() => navigate(adminRoutes.inviteClinician)}
        >
          {formatMessage(intlMessages.getStartedButton)}
        </Button>
      </Card>
      <Card className={styles.flowContainer} padding="large">
        <Heading level="h1" size="h4">
          {formatMessage(intlMessages.suggestJobs)}
        </Heading>
        <div className={styles.description}>
          <div>
            <Heading level="h2" className={styles.strong}>
              {formatMessage(intlMessages.youWouldNeed)}:
            </Heading>
            <ul>
              <li>{formatMessage(intlMessages.needSuggestItem1)}</li>
              <li>{formatMessage(intlMessages.needSuggestItem2)}</li>
            </ul>
          </div>
          <div>
            <Heading level="h2" className={styles.strong}>
              {formatMessage(intlMessages.ableTo)}:
            </Heading>
            <ul>
              <li>{formatMessage(intlMessages.ableToSuggestItem1)}</li>
              <li>{formatMessage(intlMessages.ableToSuggestItem2)}</li>
            </ul>
          </div>
        </div>
        <Button
          className={styles.cta}
          iconPlacement="after"
          icon={<Icon name="ChevronRight" />}
          onClick={() => navigate(adminRoutes.suggestJobs)}
        >
          {formatMessage(intlMessages.getStartedButton)}
        </Button>
      </Card>
    </div>
  );
};

export default HomeScreen;
