import React from 'react';
import classnames from 'classnames';
import Badge from '@openloop/limbic/Badge';
import { useIntl } from 'react-intl';

import { providerRoutes } from '~Constants/routes';
import SEO from '~Core/SEO';
import { useCurrentUserTasksQuery, UserNetworkStatus, UserTask } from '~Data';
import useViewport from '~Hooks/useViewport';
import { useAuthContext } from '~Context/AuthContext';

import Welcome from './Welcome/Welcome';
import { TaskCardProps } from './TaskCard/TaskCard';
import Tasks from './Tasks';
import Contact from './Contact';
import Resources from './Resources';
import JobApplications from './JobApplications';
import { intlMessages } from './intlMessages';

import styles from './Home.module.scss';

type TaskItemProps = Omit<TaskCardProps, 'stacked'>;

const TASKS: Record<UserTask, TaskItemProps> = Object.freeze({
  [UserTask.SubmitVerification]: {
    description:
      "We still need a few more quick items from you before we can verify your account and licensure. Let's get them added!",
    linkText: 'Complete info',
    route: providerRoutes.editProfile,
    title: 'Submit your verification information',
  },
  [UserTask.PersonalizeProfile]: {
    description:
      "It looks like your profile has some areas that could be strengthened. Let's make sure your CV and profile picture are uploaded.",
    linkText: 'Personalize profile',
    route: providerRoutes.myProfile,
    title: 'Personalize your profile',
  },
  [UserTask.AddLicenses]: {
    description:
      "Most employers narrow their search based on which states clinicians are licensed in. Let's confirm all of yours are listed.",
    linkText: 'Add licenses',
    route: providerRoutes.licenses,
    title: 'Add your licenses',
  },
});

const ANCHOR_TASKS = 'tasks';
const ANCHOR_JOB_APPS = 'jobapplications';
const ANCHOR_CONTACT = 'contact';

const Home = () => {
  const { formatMessage } = useIntl();
  const { currentUser } = useAuthContext();
  const { data: tasksData } = useCurrentUserTasksQuery();
  const { width } = useViewport();

  const isMobile = width < 768;

  if (!currentUser) {
    return null;
  }

  const {
    profile: { networkStatus },
  } = currentUser;

  const tasks = tasksData?.currentUser.tasks
    ? tasksData.currentUser.tasks.map((taskName) => TASKS[taskName])
    : [];

  const showInteractableContent =
    networkStatus !== UserNetworkStatus.NotNowNetwork &&
    networkStatus !== UserNetworkStatus.NoNetwork;

  return (
    <>
      <SEO title={tasks.length > 0 ? `(${tasks.length}) Dashboard` : 'Dashboard'} />
      <main
        className={classnames(styles.main, {
          [styles.showInteractableContent]: showInteractableContent,
        })}
      >
        <nav className={styles.onPageNav}>
          {showInteractableContent && (
            <>
              <a
                className={classnames(styles.navItem, {
                  [styles.navItemPadRight]: tasks.length > 0,
                })}
                href={`#${ANCHOR_TASKS}`}
              >
                <Badge value={tasks.length}>{formatMessage(intlMessages.tasks)}</Badge>
              </a>
              <a className={styles.navItem} href={`#${ANCHOR_JOB_APPS}`}>
                {formatMessage(intlMessages.jobTracker)}
              </a>
            </>
          )}
          <a className={styles.navItem} href={`#${ANCHOR_CONTACT}`}>
            {formatMessage(intlMessages.contact)}
          </a>
        </nav>

        <div className={styles.welcome}>
          <Welcome />
        </div>

        {showInteractableContent && (
          <>
            <div className={styles.tasks} id={ANCHOR_TASKS}>
              <Tasks isStacked={isMobile} taskData={tasks} />
            </div>
            <div className={styles.table} id={ANCHOR_JOB_APPS}>
              <JobApplications />
            </div>
          </>
        )}

        <div className={styles.contact} id={ANCHOR_CONTACT}>
          <Contact />
        </div>
        <div className={styles.resources}>
          <Resources />
        </div>
      </main>
    </>
  );
};

export default Home;
