import React from 'react';
import Heading from '@openloop/limbic/Heading';
import MDEditor from '@uiw/react-md-editor';
import { defineMessages, useIntl } from 'react-intl';

import { JobQuery } from '~Data';

import styles from './JobDetails.module.scss';

const intlMessages = defineMessages({
  applyLabel: {
    defaultMessage: 'Apply',
    description: 'Apply button label',
    id: 'Components.JobDetails.applyLabel',
  },
  meetOLSectionTitle: {
    defaultMessage: 'Meet OpenLoop',
    description: 'Meet OpenLoop section title',
    id: 'Components.JobDetails.meetOLSectionTitle',
  },
  aboutPositionSectionTitle: {
    defaultMessage: 'About the Position',
    description: 'About the Position section title',
    id: 'Components.JobDetails.aboutPositionSectionTitle',
  },
  responsibilitiesSectionTitle: {
    defaultMessage: 'Responsibilities',
    description: 'Responsibilities section title',
    id: 'Components.JobDetails.responsibilitiesSectionTitle',
  },
  requirementsSectionTitle: {
    defaultMessage: 'Requirements',
    description: 'Requirements section title',
    id: 'Components.JobDetails.requirementsSectionTitle',
  },
  payAndPerksSectionTitle: {
    defaultMessage: 'Pay & Perks',
    description: 'Pay & Perks section title',
    id: 'Components.JobDetails.payAndPerksSectionTitle',
  },
  otherInfoSectionTitle: {
    defaultMessage: 'Other Info',
    description: 'Other Info section title',
    id: 'Components.JobDetails.otherInfoSectionTitle',
  },
});
interface Section {
  sectionTitle?: string;
  sectionData?: string | null | undefined;
  showApplyButton?: boolean;
}

const JobRequirements = ({ sections }: { sections: Array<Section> }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {sections.map(
        ({ sectionData, sectionTitle, showApplyButton }) =>
          sectionData && (
            <div className={styles.descriptionSections} key={sectionTitle}>
              {sectionTitle && (
                <Heading level="h2" size="h3" className={styles.sectionTitle}>
                  {sectionTitle}
                </Heading>
              )}
              <div className={styles.sectionData}>
                <MDEditor.Markdown
                  warpperElement={{
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    'data-color-mode': 'light',
                  }}
                  source={sectionData}
                />
              </div>
              {showApplyButton && (
                <a href="#form" className={styles.applyButton}>
                  {formatMessage(intlMessages.applyLabel)}
                </a>
              )}
            </div>
          ),
      )}
    </>
  );
};

const JobDetails = ({ job }: JobQuery) => {
  const { formatMessage } = useIntl();
  const { aboutJob, description, otherInfo, payAndPerks, requirements, responsibilities } = job;

  const sections: Array<Section> = [
    {
      sectionTitle: '',
      sectionData: description,
      showApplyButton: true,
    },
    {
      sectionTitle: formatMessage(intlMessages.meetOLSectionTitle),
      sectionData:
        'OpenLoop was founded with the vision to power healing anywhere. We do so by thoughtfully pairing leading clinicians (like you!) with innovative telehealth companies providing care in all 50 states. Our team of Clinician Advocates and full-service support staff are dedicated to helping you land the right virtual care positions aligned to your expertise, passions, and schedule. Consider the OpenLoop Network your all-access pass to top shifts, great pay, and the flexibility you’ve been looking for.',
    },
    {
      sectionTitle: formatMessage(intlMessages.aboutPositionSectionTitle),
      sectionData: aboutJob,
    },
    {
      sectionTitle: formatMessage(intlMessages.responsibilitiesSectionTitle),
      sectionData: responsibilities,
    },
    {
      sectionTitle: formatMessage(intlMessages.requirementsSectionTitle),
      sectionData: requirements,
    },
    {
      sectionTitle: formatMessage(intlMessages.payAndPerksSectionTitle),
      sectionData: payAndPerks,
    },
    {
      sectionTitle: formatMessage(intlMessages.otherInfoSectionTitle),
      sectionData: otherInfo,
    },
  ];

  return <JobRequirements sections={sections} />;
};

export default JobDetails;
