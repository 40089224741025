import React from 'react';
import { useField } from 'formik';

import Input, { InputProps } from '@openloop/limbic/Form/Input/Input';

const PreferredNameInput = ({ id, name }: InputProps) => {
  const [{ value }] = useField(name);
  return (
    <Input id={id} name={name} placeholder="e.g. John, Dr. Scott, Mr. Wu, etc" value={value} />
  );
};

export default PreferredNameInput;
