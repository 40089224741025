import * as Types from '../../../Data/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserBySharingTokenQueryVariables = Types.Exact<{
  tokenId: Types.Scalars['String'];
}>;


export type UserBySharingTokenQuery = { userBySharingToken: { clientDescription?: string | null, jobApplication?: { id: string, job: { id: string, title: string, clinic: { id: string, name: string } } } | null, user: { id: string, profile: { displayName: string, firstName?: string | null, lastName?: string | null, userId: string, cv?: { filename: string, key: string } | null, image?: { filename: string, key: string, location: string } | null, licenses: Array<{ stateId: string, status: Types.LicenseStatus, state: { id: string, name: string, shortName: string } }>, professionalContext: { jobTypeSeeking?: Array<string> | null, specialty?: string | null, type: Types.UserRole, appLevelOfTraining?: Types.LevelOfTrainingApp | null } | { type: Types.UserRole } | { jobTypeSeeking?: Array<string> | null, specialty?: string | null, type: Types.UserRole, nurseLevelOfTraining?: Types.LevelOfTrainingNurse | null } | { jobTypeSeeking?: Array<string> | null, profession?: string | null, professionOther?: string | null, type: Types.UserRole } | { jobTypeSeeking?: Array<string> | null, providerType?: string | null, specialty?: string | null, type: Types.UserRole, physicianLevelOfTraining?: Types.LevelOfTrainingPhysician | null } | { jobTypeSeeking?: Array<string> | null, providerType?: string | null, specialty?: string | null, type: Types.UserRole, therapistLevelOfTraining?: string | null } }, verifiedActiveLicenses: Array<{ stateId: string, status: Types.LicenseStatus, state: { id: string, name: string, shortName: string } }> } } | { isExpired: boolean, message: string } | { isNotFound: boolean, message: string } };


export const UserBySharingTokenDocument = gql`
    query UserBySharingToken($tokenId: String!) {
  userBySharingToken(tokenId: $tokenId) {
    ... on SharableUserProfileLinkExpired {
      isExpired
      message
    }
    ... on SharableUserProfileLinkNotFound {
      isNotFound
      message
    }
    ... on SharableUserProfile {
      clientDescription
      jobApplication {
        id
        job {
          id
          title
          clinic {
            id
            name
          }
        }
      }
      user {
        id
        profile {
          cv {
            filename
            key
          }
          displayName @client
          firstName
          image {
            filename
            key
            location
          }
          lastName
          licenses {
            state {
              id
              name
              shortName
            }
            stateId
            status
          }
          professionalContext {
            ... on APPProfessionalContext {
              appLevelOfTraining: levelOfTraining
              jobTypeSeeking
              specialty
              type
            }
            ... on ClinicProfessionalContext {
              type
            }
            ... on NurseProfessionalContext {
              jobTypeSeeking
              nurseLevelOfTraining: levelOfTraining
              specialty
              type
            }
            ... on OtherProfessionalContext {
              jobTypeSeeking
              profession
              professionOther
              type
            }
            ... on PhysicianProfessionalContext {
              jobTypeSeeking
              physicianLevelOfTraining: levelOfTraining
              providerType
              specialty
              type
            }
            ... on TherapistProfessionalContext {
              jobTypeSeeking
              providerType
              specialty
              therapistLevelOfTraining: levelOfTraining
              type
            }
          }
          userId
        }
        verifiedActiveLicenses {
          state {
            id
            name
            shortName
          }
          stateId
          status
        }
      }
    }
  }
}
    `;

/**
 * __useUserBySharingTokenQuery__
 *
 * To run a query within a React component, call `useUserBySharingTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBySharingTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBySharingTokenQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useUserBySharingTokenQuery(baseOptions: Apollo.QueryHookOptions<UserBySharingTokenQuery, UserBySharingTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserBySharingTokenQuery, UserBySharingTokenQueryVariables>(UserBySharingTokenDocument, options);
      }
export function useUserBySharingTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserBySharingTokenQuery, UserBySharingTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserBySharingTokenQuery, UserBySharingTokenQueryVariables>(UserBySharingTokenDocument, options);
        }
export type UserBySharingTokenQueryHookResult = ReturnType<typeof useUserBySharingTokenQuery>;
export type UserBySharingTokenLazyQueryHookResult = ReturnType<typeof useUserBySharingTokenLazyQuery>;
export type UserBySharingTokenQueryResult = Apollo.QueryResult<UserBySharingTokenQuery, UserBySharingTokenQueryVariables>;