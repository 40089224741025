import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  resetPassword: {
    defaultMessage: 'Reset Password',
    description: 'Reset Password heading',
    id: 'components.guest.ForgotPassword.resetPassword',
  },
  paragraph: {
    defaultMessage: 'Please enter the email address you used when creating your account.',
    description: 'Paragraph',
    id: 'components.guest.ForgotPassword.paragraph',
  },
  email: {
    defaultMessage: 'Email',
    description: 'Email label',
    id: 'components.guest.ForgotPassword.email',
  },
  sendResetEmail: {
    defaultMessage: 'Send Reset Email',
    description: 'Send Reset Email',
    id: 'components.guest.ForgotPassword.sendResetEmail',
  },
  returnMessage: {
    defaultMessage: 'Return to Sign In',
    description: 'Return Message',
    id: 'components.guest.ForgotPassword.returnMessage',
  },
});
