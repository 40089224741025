import React from 'react';
import { RouteObject } from 'react-router-dom';

import { guestRoutes, providerRoutes } from '~Constants/routes';
import AuthGuard from '~Auth';

import ForgotPassword from '~Modules/guest/ForgotPassword';
import ResetPassword from '~Modules/guest/ResetPassword';
import Login from '~Modules/guest/Login';
import Register from '~Modules/provider/Register';
import InviteRegister from '~Modules/provider/InviteRegister';

export const guestRoutesObject: RouteObject[] = [
  {
    path: guestRoutes.forgotPassword,
    element: (
      <AuthGuard userTypesAllowed={[]}>
        <ForgotPassword />
      </AuthGuard>
    ),
  },
  {
    path: guestRoutes.resetPassword,
    element: (
      <AuthGuard userTypesAllowed={[]}>
        <ResetPassword />
      </AuthGuard>
    ),
  },
  {
    path: guestRoutes.login,
    element: (
      <AuthGuard userTypesAllowed={[]}>
        <Login />
      </AuthGuard>
    ),
  },
  {
    path: providerRoutes.register,
    element: (
      <AuthGuard userTypesAllowed={[]}>
        <Register />
      </AuthGuard>
    ),
  },
  {
    path: providerRoutes.inviteRegister,
    element: (
      <AuthGuard userTypesAllowed={[]}>
        <InviteRegister />
      </AuthGuard>
    ),
  },
];
