import React, { ReactNode } from 'react';
import classnames from 'classnames';
import Tag from '@openloop/limbic/Tag';

import { TagStatus } from '~Constants/TagStatus';

import styles from './PatientCardTag.module.scss';

const cx = classnames.bind(styles);

interface PatientCardTagProps {
  status: TagStatus;
  children?: ReactNode;
  className?: string;
}

const PatientCardTag = ({ status, children, className }: PatientCardTagProps) => {
  const tagClassName = cx(styles.defaultTagColor, className, {
    [styles.warning]: [TagStatus.LEFT_VOICEMAIL].includes(status),
    [styles.warning600]: [TagStatus.VOICEMAIL_FULL].includes(status),
    [styles.warning500]: [TagStatus.NO_RESPONSE].includes(status),
    [styles.review]: [TagStatus.CALL_BACK, TagStatus.RETURNING_PATIENT].includes(status),
    [styles.pink100]: [TagStatus.NEED_ASSISTANCE].includes(status),
    [styles.success200]: [TagStatus.APPROVED].includes(status),
    [styles.denied]: [TagStatus.DENIED].includes(status),
  });

  return (
    <Tag className={tagClassName}>
      {status}
      {children}
    </Tag>
  );
};

export default PatientCardTag;
