import React, { useCallback, useEffect, useState } from 'react';
import { FileExcelOutlined } from '@ant-design/icons';
import { Modal, notification, Spin } from 'antd';
import Button from '@openloop/limbic/Button';
import { useIntl } from 'react-intl';

import { AdminReportType, useAdminGenerateReportMutation } from '~Data';
import Paragraph from '~Core/Paragraph';

import styles from './AllUsersDownloadButton.module.less';
import { intlMessages } from './intlMessages';

const AllUsersDownloadButton = () => {
  const { formatMessage } = useIntl();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [generateReport, { data, loading }] = useAdminGenerateReportMutation({
    variables: { input: { reportType: AdminReportType.UsersReport } },
  });

  const handleButtonClick = useCallback(() => {
    generateReport();
  }, [generateReport]);

  useEffect(() => {
    if (data && data.adminGenerateReport && !loading) {
      data.adminGenerateReport.forEach((file) => window.open(file));

      setIsVisible(false);
      notification.success({
        description:
          'The report should be automatically downloaded. If not, please contact a system admin.',
        message: 'Report successfully generated',
      });
    }
  }, [data, loading]);

  return (
    <>
      <Button icon={<FileExcelOutlined />} onClick={() => setIsVisible(true)}>
        {formatMessage(intlMessages.csvExport)}
      </Button>
      <Modal
        closable={!loading}
        onCancel={() => setIsVisible(false)}
        footer={null}
        visible={isVisible}
        title="Generate a Detailed User Report"
      >
        {loading ? (
          <Paragraph className={styles.loadingCopy}>
            {formatMessage(intlMessages.buildingCsv)}
            <br />
            <Spin />
          </Paragraph>
        ) : (
          <Button type="submit" onClick={handleButtonClick} loading={loading} variant="primary">
            {formatMessage(intlMessages.generateReport)}
          </Button>
        )}
      </Modal>
    </>
  );
};

export default AllUsersDownloadButton;
