import React, { ComponentProps } from 'react';
import { Row, Col } from 'antd';
import Input from '@openloop/limbic/Form/Input';
import { Form } from 'formik-antd';
import * as yup from 'yup';
import { useIntl } from 'react-intl';

import { intlMessages } from './intlMessages';

export interface FormValues {
  degreeObtained: string;
  graduatingInstitution: string;
}

/* eslint-disable no-template-curly-in-string */
export const validationSchema = yup.object().shape({
  degreeObtained: yup.string(),
  graduatingInstitution: yup.string(),
});

const EducationField = ({
  label,
  name,
}: Pick<ComponentProps<typeof Input>, 'name'> & { label: string }) => (
  <Col xs={24} sm={12}>
    <Form.Item label={label} name={name}>
      <Input name={name} />
    </Form.Item>
  </Col>
);

const EducationFields = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Form.Item
        label={formatMessage(intlMessages.graduatingInstitution)}
        name="graduatingInstitution"
      >
        <Input name="graduatingInstitution" />
      </Form.Item>
      <Row gutter={24}>
        <EducationField label={formatMessage(intlMessages.degreeObtained)} name="degreeObtained" />
      </Row>
    </>
  );
};

export default EducationFields;
