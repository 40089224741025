import React from 'react';
import Card from '@openloop/limbic/Card';
import { defineMessages, useIntl } from 'react-intl';

import images from '~Constants/images';
import Icon, { Icons } from '~Core/Icon';
import { useCurrentUserQuery } from '~Data';
import { formatPhone } from '~Helpers/phoneNumber';
import useViewport from '~Hooks/useViewport';

import SectionCard from '../SectionCard';

import styles from './Contact.module.scss';

const intlMessages = defineMessages({
  title: {
    defaultMessage: 'OpenLoop Contact',
    description: 'Contact Container Title',
    id: 'Modules.provider.Home.Contact.title',
  },
  clinicianAdvocate: {
    defaultMessage: 'Your Clinician Advocate is:',
    description: 'Clinician Advocate',
    id: 'Modules.provider.Home.Contact.clinicianAdvocate',
  },
});

const Contact = () => {
  const { formatMessage } = useIntl();
  const { data } = useCurrentUserQuery();
  const { width } = useViewport();

  if (!data) return null;

  const isTabletUp = width >= 768;

  const {
    currentUser: { clinicianAdvocate },
  } = data;

  return (
    <SectionCard iconName="Headset" title={formatMessage(intlMessages.title)}>
      <div className={styles.container}>
        {clinicianAdvocate && (
          <Card className={styles.card}>
            <p className={styles.clinicianAnnoucement}>
              {formatMessage(intlMessages.clinicianAdvocate)}
            </p>
            <img
              src={clinicianAdvocate.profile?.image?.location || images.placeholderContact}
              className={styles.contactImage}
              alt=""
            />
            {clinicianAdvocate.profile.displayName}
            {clinicianAdvocate.profile.phone && (
              <span className={styles.contactInfo}>
                <Icon name={Icons.Phone} className={styles.icons} aria-hidden />
                <a href={`tel:${clinicianAdvocate.profile.phone}`}>
                  {formatPhone(clinicianAdvocate.profile.phone)}
                </a>
              </span>
            )}
            <span className={styles.contactInfo}>
              <Icon name={Icons.Letter} className={styles.icons} aria-hidden />
              <a href={`mailto:${clinicianAdvocate.email}`}>{clinicianAdvocate.email}</a>
            </span>
          </Card>
        )}
        <Card className={styles.card}>
          <img src={images.logo} className={styles.logo} alt="OpenLoop Logo" />
          <address>
            {/* eslint-disable-next-line @calm/react-intl/missing-formatted-message */}
            317 6th Ave, Ste 400 <br /> Des Moines, IA 50309 <br />
          </address>
          <span className={styles.contactInfo}>
            <Icon className={styles.icons} name={Icons.Letter} aria-hidden />
            {/* eslint-disable-next-line @calm/react-intl/missing-formatted-message */}
            <a href="mailto:clinicianadvocate@openloophealth.com">
              clinicianadvocate@openloophealth.com
            </a>
          </span>
        </Card>
        {isTabletUp && (
          <div className={styles.imageCard}>
            <img src={images.laptopPhone} alt="" />
          </div>
        )}
      </div>
    </SectionCard>
  );
};

export default Contact;
