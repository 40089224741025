import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  callPatientButtonText: {
    defaultMessage: 'Call Patient',
    description: 'Call patient button text',
    id: 'Modules.provider.Patients.PatientInfoBox.callPatientButtonText',
  },
  enlargePatientIdCardButtonAriaLabel: {
    defaultMessage: 'Enlarge Patient ID Card',
    description: 'Enlarge Patient Id Button aria label',
    id: 'Modules.provider.Patients.PatientInfoBox.enlargePatientIdCardButtonAriaLabel',
  },
  medicationsHeading: {
    defaultMessage: 'Medications',
    description: 'Medications section heading',
    id: 'Modules.provider.Patients.PatientInfoBox.medicationsHeading',
  },
  allergiesHeading: {
    defaultMessage: 'Allergies',
    description: 'Allergies section heading',
    id: 'Modules.provider.Patients.PatientInfoBox.allergiesHeading',
  },
  patientIdHeading: {
    defaultMessage: 'Patient Id',
    description: 'Patient ID Heading',
    id: 'Modules.provider.Patients.PatientInfoBox.patientIdHeading',
  },
  patientAgeText: {
    defaultMessage: `{years, number} Yrs. {months, number} mo.`,
    description: 'Patient age text',
    id: 'Modules.provider.Patients.PatientInfobox.patientAgeText',
  },
  genderText: {
    defaultMessage: 'Gender',
    description: 'Gender text',
    id: 'Modules.provider.Patients.PatientInfobox.genderText',
  },
});
