import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  tasks: {
    defaultMessage: 'Tasks',
    description: 'Tasks badge',
    id: 'components.provider.Home.tasks',
  },
  jobTracker: {
    defaultMessage: 'Job Tracker',
    description: 'Job Tracker anchor',
    id: 'components.provider.Home.jobTracker',
  },
  contact: {
    defaultMessage: 'Contact',
    description: 'Contact anchor',
    id: 'components.provider.Home.contact',
  },
});
