import React from 'react';
import ReactDOM from 'react-dom';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import TagManager from 'react-gtm-module';

import './validations';
import App from './App';

import './index.scss';

if (import.meta.env.VITE_FULLSTORY_ORG_ID) {
  FullStory.init({ orgId: import.meta.env.VITE_FULLSTORY_ORG_ID });
} else {
  console.info('Not initializing FullStory - Organization ID not provided.');
}

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
  });
} else {
  console.info('Not initializing Sentry - DSN not provided');
}

if (import.meta.env.VITE_GTM_ID) {
  const tagManagerArgs = {
    gtmId: import.meta.env.VITE_GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
