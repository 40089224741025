import React from 'react';
import { NavLink as ReactRouterNavLink, NavLinkProps } from 'react-router-dom';

import styles from './NavLink.module.scss';

const NavLink = ({ className, ...rest }: Omit<NavLinkProps, 'component'>) => (
  <ReactRouterNavLink
    className={className ? `${styles.main} ${className}` : styles.main}
    {...rest}
  />
);

export default NavLink;
