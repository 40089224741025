import { defineMessages } from 'react-intl';

export const intlMessagesNotAllowed = defineMessages({
  notAllowed: {
    defaultMessage: 'Sorry! We are no longer accepting applicants for this position.',
    description: 'Job Applications Not Allowed',
    id: 'components.JobApplicationsNotAllowed.notAllowed',
  },
});

export const intlMessagesLoggedInForm = defineMessages({
  jobApplicationTracker: {
    defaultMessage: 'Job Application Tracker',
    description: 'Job Application Tracker',
    id: 'components.LoggedInJobApplicationForm.jobApplicationTracker',
  },
  paragraph: {
    defaultMessage:
      "You're all set! You applied to this job on {moment}. Please check your {link} for updates.",
    description: 'Paragraph',
    id: 'components.LoggedInJobApplicationForm.paragraph',
  },
});
