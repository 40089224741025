import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  clinicPortal: {
    defaultMessage: 'Clinic Portal',
    description: 'Clinic Portal heading',
    id: 'components.clinic.Dashboard.clinicPortal',
  },
  createLink: {
    defaultMessage: 'Create New Clinic',
    description: 'Create link',
    id: 'components.clinic.Dashboard.createLink',
  },
  or: {
    defaultMessage: 'Or',
    description: 'Or divider',
    id: 'components.clinic.Dashboard.or',
  },
  question: {
    defaultMessage: 'Need to join an existing clinic?',
    description: 'First question',
    id: 'components.clinic.Dashboard.question',
  },
  paragraph: {
    defaultMessage: 'Ask an admin to add you as an "Authorized User"',
    description: 'Paragraph',
    id: 'components.clinic.Dashboard.paragraph',
  },
});
