import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  introduction: {
    defaultMessage: 'Introduction',
    description: 'Introduction text',
    id: 'components.provider.Profile.BasicUserInfo.introduction',
  },
  employmentHistory: {
    defaultMessage: 'Employment History',
    description: 'Employment History text',
    id: 'components.provider.Profile.BasicUserInfo.employmentHistory',
  },
  professionalReferences: {
    defaultMessage: 'Professional References',
    description: 'Professional References text',
    id: 'components.provider.Profile.BasicUserInfo.professionalReferences',
  },
});
