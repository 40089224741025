import React from 'react';

import Combobox, { ComboboxProps } from '@openloop/limbic/Form/Select/Combobox';

import { ehrChoices } from '~Constants/ehrCompatibility';

interface Props extends Omit<ComboboxProps, 'options'> {}

const emrCompatibilityOptions = Object.entries(ehrChoices).map(([itemValue, label]) => ({
  value: itemValue,
  label,
}));

const EmrCompatibilitySelect = ({ placeholder = 'Select all that apply', ...rest }: Props) => (
  <Combobox placeholder={placeholder} options={emrCompatibilityOptions} {...rest} />
);

export default EmrCompatibilitySelect;
