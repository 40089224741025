import { LicenseFieldsFragment, LicenseStatus } from '~Data';

export const sortLicensesByState = (direction: 'asc' | 'desc' = 'asc') => {
  return (a: LicenseFieldsFragment, b: LicenseFieldsFragment): number => {
    if (a.state.name < b.state.name) {
      return direction === 'asc' ? -1 : 1;
    }
    if (a.state.name > b.state.name) {
      return direction === 'asc' ? 1 : -1;
    }
    return 0;
  };
};

export const filterLicensesByStatus = (status: LicenseStatus) => {
  return (license: LicenseFieldsFragment) => license.status === status;
};
