import { ProviderUserRole, UserRole } from '~Data';

export type ProviderUserRoleChoices = {
  [key in ProviderUserRole]: string;
};

export const providerUserRoleChoices: ProviderUserRoleChoices = {
  [UserRole.App]: 'APP',
  [UserRole.Nurse]: 'Nurse',
  [UserRole.Other]: 'Other',
  [UserRole.Physician]: 'Physician',
  [UserRole.Therapist]: 'Therapist',
};
