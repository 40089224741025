import React from 'react';
import Grid from '@openloop/limbic/Grid';
import { useIntl } from 'react-intl';

import images from '~Constants/images';
import { guestRoutes } from '~Constants/routes';
import { useAuthContext } from '~Context/AuthContext';
import Link from '~Core/Link';
import { intlMessages } from './intlMessages';

import styles from './Header.module.scss';

const { Col, Row } = Grid;

const Header = () => {
  const { formatMessage } = useIntl();
  const { currentUser: loggedIn } = useAuthContext();
  return (
    <header className={styles.header}>
      <Row justify="center">
        <Col xs={22} lg={20}>
          <Row align="middle" justify="space-between">
            <Col span={12}>
              <img alt="OpenLoop logo" className={styles.image} src={images.logo} />
            </Col>
            <Col span={12}>
              {!loggedIn && (
                <p className={styles.loginSection}>
                  <span className={styles.member}>{formatMessage(intlMessages.questionSpan)} </span>
                  <Link to={guestRoutes.login} className={styles.login}>
                    {formatMessage(intlMessages.logIn)}
                  </Link>
                </p>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </header>
  );
};

export default Header;
