import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CaretLeftOutlined } from '@ant-design/icons';
import Grid from '@openloop/limbic/Grid';
import { message } from 'antd';
import { useIntl } from 'react-intl';

import headerImage from '~Assets/img/jobFormHeaderVisual.png';
import JobApplicationFormContainer from '~Components/JobApplicationFormContainer';
import JobDetails from '~Components/JobDetails';
import JobTitle from '~Components/JobTitle/JobTitle';
import { providerRoutes } from '~Constants/routes';
import Link from '~Core/Link';
import { useAuthContext } from '~Context/AuthContext';
import { useJobContext, JobProvider } from '~Context/JobContext';
import Header from '~Modules/guest/Header';

import LoadingScreen from '../LoadingScreen';
import { intlMessages } from './intlMessages';

import styles from './JobDescription.module.scss';

const { Col, Row } = Grid;

const JobDescriptionContent = () => {
  const { formatMessage } = useIntl();
  const { jobData, jobError, jobLoading } = useJobContext();
  const { isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (jobError) {
      message.error('Could not load job');
    }
  }, [jobError]);

  if (jobLoading) {
    return <LoadingScreen />;
  }

  if (!jobData) {
    return null;
  }

  const { title, employmentType } = jobData;

  return (
    <>
      <Header />
      <main className={styles.mainContent}>
        <Row justify="center">
          <Col xs={24} md={22} lg={20}>
            <Row justify="center">
              <Col xs={22} md={12} lg={14} className={styles.descriptionColumn}>
                {isAuthenticated && (
                  <Link to={providerRoutes.dashboard} className={styles.returnHome}>
                    <CaretLeftOutlined aria-hidden /> {formatMessage(intlMessages.backToHome)}
                  </Link>
                )}
                <section className={styles.rowBackgroundImage}>
                  <JobTitle
                    title={title}
                    employmentType={employmentType}
                    isAuthenticated={isAuthenticated}
                  />
                </section>
                <section className={styles.jobDetails}>
                  <JobDetails job={jobData} />
                </section>
              </Col>
              <Col xs={24} md={12} lg={10}>
                <section className={styles.formCol} id="form">
                  <img
                    src={headerImage}
                    alt="Female doctor wearing a headset and stethoscope smiling"
                    className={styles.headerVisual}
                  />
                  <JobApplicationFormContainer customClass={styles.formContainer} />
                </section>
              </Col>
            </Row>
          </Col>
        </Row>
      </main>
    </>
  );
};

const JobDescription = () => {
  const { jobId } = useParams<{ jobId: string }>();
  return (
    <JobProvider jobId={jobId}>
      <JobDescriptionContent />
    </JobProvider>
  );
};

export default JobDescription;
