import React from 'react';
import { RouteObject } from 'react-router-dom';

import { providerRoles, UserRole } from '~Data';
import { providerRoutes } from '~Constants/routes';
import AuthGuard from '~Auth';

import EditProfile from '~Modules/provider/EditProfile';
import Home from '~Modules/provider/Home';
import Licenses from '~Modules/provider/Licenses';
import Patients from '~Modules/provider/Patients';
import Prescribe from '~Modules/provider/Prescribe';
import Profile from '~Modules/provider/Profile';
import Wrapper from '~Modules/provider/Wrapper';

export const providerRoutesObject: RouteObject[] = [
  {
    path: '/provider',
    element: (
      <AuthGuard userTypesAllowed={[...providerRoles, UserRole.Admin]}>
        <Wrapper />
      </AuthGuard>
    ),
    children: [
      { index: true, element: <Home /> },
      { path: providerRoutes.editProfile, element: <EditProfile /> },
      { path: providerRoutes.myProfile, element: <Profile /> },
      { path: providerRoutes.licenses, element: <Licenses /> },
      { path: providerRoutes.patients, element: <Patients /> },
      { path: providerRoutes.prescribe, element: <Prescribe /> },
    ],
  },
];
