import React from 'react';

import Combobox, { ComboboxProps } from '@openloop/limbic/Form/Select/Combobox';

import { specialtiesByType } from '~Constants/specialties';
import { ProviderUserRole } from '~Data';

interface Props extends Omit<ComboboxProps, 'options'> {
  userRole: ProviderUserRole | ProviderUserRole[];
}

const mapSpecialties = (
  role: ProviderUserRole,
  roleSpecialties: string[],
  prefixValueWithRole = false,
) =>
  roleSpecialties.map((roleSpecialty) => ({
    label: roleSpecialty,
    value: prefixValueWithRole ? `${role}-${roleSpecialty}` : roleSpecialty,
  }));

const SpecialtiesSelect = ({ placeholder = 'Choose specialties', userRole, ...rest }: Props) => {
  const specialties = Array.isArray(userRole)
    ? userRole.reduce((specialtiesAcc, role) => {
        const roleSpecialties = specialtiesByType[role];
        if (Array.isArray(roleSpecialties)) {
          return [
            ...specialtiesAcc,
            {
              label: role,
              options: mapSpecialties(role, roleSpecialties, true),
            },
          ];
        }
        return specialtiesAcc;
      }, [] as Array<{ label: string; options: Array<{ label: string; value: string }> }>)
    : mapSpecialties(userRole, [...(specialtiesByType[userRole] || [])]);

  return <Combobox placeholder={placeholder} options={specialties} {...rest} />;
};

export default SpecialtiesSelect;
