import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  underConstruction: {
    defaultMessage: 'Under Construction',
    description: 'Heading',
    id: 'components.shared.UnderConstruction.underConstruction',
  },
  paragraph: {
    defaultMessage: 'This page is currently under construction. Please check back soon.',
    description: 'Paragraph',
    id: 'components.shared.UnderConstruction.paragraph',
  },
  returnMessage: {
    defaultMessage: 'Return to Login',
    description: 'Return Message',
    id: 'components.shared.UnderConstruction.returnMessage',
  },
});
