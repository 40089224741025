import React, { ComponentProps, useCallback, useMemo } from 'react';
import Chart from 'react-frappe-charts';
import moment, { Moment } from 'moment';

import { AdminStatsQuery } from '~Data';
import { enumerateDaysBetweenDates } from '~Helpers/dates';

interface Props {
  range: [Moment, Moment];
  adminStats: AdminStatsQuery['adminStats'];
}

const labelFormat = 'M/D';

const ActivityChart = ({ range, adminStats: { clinics, jobs, users } }: Props) => {
  const [rangeStart, rangeEnd] = range;
  const labels = useMemo<string[]>(
    () => enumerateDaysBetweenDates(rangeStart, rangeEnd).map((date) => date.format(labelFormat)),
    [rangeEnd, rangeStart],
  );

  const itemReducer = useCallback(
    (accum: number[], { createdAt }: { createdAt: Date }) => {
      const prev = accum;
      const labelIdx = labels.indexOf(moment(createdAt).startOf('day').format(labelFormat));
      if (labelIdx >= 0) {
        prev[labelIdx] = accum[labelIdx] + 1;
      }
      return prev;
    },
    [labels],
  );

  const clinicsData = useMemo(
    () =>
      clinics.reduce(
        itemReducer,
        labels.map(() => 0),
      ),
    [clinics, itemReducer, labels],
  );
  const jobsData = useMemo(
    () =>
      jobs.reduce(
        itemReducer,
        labels.map(() => 0),
      ),
    [itemReducer, jobs, labels],
  );
  const usersData = useMemo(
    () =>
      users.reduce(
        itemReducer,
        labels.map(() => 0),
      ),
    [itemReducer, labels, users],
  );

  const data = useMemo<ComponentProps<typeof Chart>['data']>(() => {
    return {
      labels,
      datasets: [
        { name: 'Users', values: usersData },
        { name: 'Jobs', values: jobsData },
        { name: 'Clinics', values: clinicsData },
      ],
    };
  }, [clinicsData, jobsData, labels, usersData]);

  return <Chart data={data} axisOptions={{ xAxisMode: 'tick', xIsSeries: 1 }} type="line" />;
};

export default ActivityChart;
