import React, { useMemo } from 'react';
import Card from '@openloop/limbic/Card';
import Heading from '@openloop/limbic/Heading';
import { Col, Row } from 'antd';
import { Moment } from 'moment';
import { defineMessages, useIntl } from 'react-intl';

import Link from '~Core/Link';
import common from '~Constants/common';
import { adminRoutes, externalRoutes } from '~Constants/routes';
import { JobApplicationRulingDecision, useAdminStatsQuery, UserNetworkStatus } from '~Data';

import ActivityChart from './ActivityChart';
import UserList from './UserList';
import ClinicList from './ClinicList';
import JobList from './JobList';

import styles from './Metrics.module.scss';

interface Props {
  range: [Moment, Moment];
}

const intlMessages = defineMessages({
  linkAriaLabelAmApproval: {
    defaultMessage: 'View clinicians awaiting AM approval',
    description: 'Aria label for link to view clinicians awaiting AM approval',
    id: 'admin.metrics.linkAriaLabelAmApproval',
  },
  linkAriaLabelNetworkApproval: {
    defaultMessage: 'View clinicians awaiting network approval',
    description: 'Aria label for link to view clinicians awaiting network approval',
    id: 'admin.metrics.linkAriaLabelNetworkApproval',
  },
  linkLabelAmApproval: {
    defaultMessage: 'AM Approval',
    description: 'Label for link to view clinicians awaiting AM approval',
    id: 'admin.metrics.linkLabelAmApproval',
  },
  linkLabelNetworkApproval: {
    defaultMessage: 'Network Approval',
    description: 'Label for link to view clinicians awaiting network approval',
    id: 'admin.metrics.linkLabelNetworkApproval',
  },
  linkViewList: {
    defaultMessage: 'View list',
    description: 'Label for link to view list',
    id: 'admin.metrics.linkViewList',
  },
  titleCliniciansAwaiting: {
    defaultMessage: 'Clinicians Awaiting',
    description: 'Title for clinicians awaiting approval',
    id: 'admin.metrics.titleCliniciansAwaiting',
  },
  titleNewClinics: {
    defaultMessage: 'New Clinics',
    description: 'Title for new clinics',
    id: 'admin.metrics.titleNewClinics',
  },
  titleNewJobs: {
    defaultMessage: 'New Jobs',
    description: 'Title for new jobs',
    id: 'admin.metrics.titleNewJobs',
  },
  titleNewUsers: {
    defaultMessage: 'New Users',
    description: 'Title for new users',
    id: 'admin.metrics.titleNewUsers',
  },
  titleShortcuts: {
    defaultMessage: 'Shortcuts',
    description: 'Title for shortcuts',
    id: 'admin.metrics.titleShortcuts',
  },
  titleUserActivity: {
    defaultMessage: 'User Activity',
    description: 'Title for user activity',
    id: 'admin.metrics.titleUserActivity',
  },
});

const getRouteWithParams = ({
  origin,
  querystringParams,
  relativePath,
}: {
  origin: string;
  querystringParams: Array<[string, string]>;
  relativePath: string;
}): string => {
  const url = new URL(relativePath, origin);

  querystringParams.forEach(([key, value]) => url.searchParams.append(key, value));

  return `${url.pathname}${url.search}`;
};

const Metrics = ({ range }: Props) => {
  const { formatMessage } = useIntl();

  const [rangeStart, rangeEnd] = range;
  const { data } = useAdminStatsQuery({
    variables: {
      rangeStart: rangeStart.utc().format(common.requestDateFormat),
      rangeEnd: rangeEnd.utc().format(common.requestDateFormat),
    },
  });

  const shortcuts = useMemo<Array<{ label: string; linkAriaLabel: string; url: string }>>(() => {
    let origin = externalRoutes.app;
    if (typeof window !== 'undefined') {
      const {
        location: { origin: locationOrigin },
      } = window;
      origin = locationOrigin;
    }
    return [
      {
        label: formatMessage(intlMessages.linkLabelNetworkApproval),
        linkAriaLabel: formatMessage(intlMessages.linkAriaLabelNetworkApproval),
        url: getRouteWithParams({
          origin,
          querystringParams: [['status', UserNetworkStatus.PendingReview]],
          relativePath: adminRoutes.users,
        }),
      },
      {
        label: formatMessage(intlMessages.linkLabelAmApproval),
        linkAriaLabel: formatMessage(intlMessages.linkAriaLabelAmApproval),
        url: getRouteWithParams({
          origin,
          querystringParams: [
            ['userJobRuling', JobApplicationRulingDecision.AwaitingAccountManager],
          ],
          relativePath: adminRoutes.users,
        }),
      },
    ];
  }, [formatMessage]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xs={{ order: 1, span: 24 }} lg={{ order: 2, span: 6 }}>
          <Card
            className={styles.card}
            title={
              <Heading level="h2" size="h6" uppercase>
                {formatMessage(intlMessages.titleShortcuts)}
              </Heading>
            }
          >
            <Heading level="h3" size="h5" variant="secondary">
              {formatMessage(intlMessages.titleCliniciansAwaiting)}
            </Heading>
            {shortcuts.map(({ label, linkAriaLabel, url }) => (
              <div key={label} className={styles.shortcutRow}>
                <span className={styles.shortcutLabel}>{label}</span>
                <Link className={styles.shortcutLink} to={url} aria-label={linkAriaLabel}>
                  {formatMessage(intlMessages.linkViewList)}
                </Link>
              </div>
            ))}
          </Card>
        </Col>
        <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 18 }}>
          <Card
            className={styles.card}
            title={
              <Heading level="h1" size="h6" uppercase>
                {formatMessage(intlMessages.titleUserActivity)}
              </Heading>
            }
          >
            {data && data.adminStats && (
              <ActivityChart adminStats={data.adminStats} range={range} />
            )}
          </Card>
        </Col>
        <Col order={3} xs={24} lg={8}>
          <Card
            className={styles.card}
            title={
              <Heading level="h2" size="h6" uppercase>
                {formatMessage(intlMessages.titleNewUsers)}
              </Heading>
            }
          >
            {data && data.adminStats && <UserList users={data.adminStats.users} />}
          </Card>
        </Col>
        <Col order={4} xs={24} lg={8}>
          <Card
            className={styles.card}
            title={
              <Heading level="h2" size="h6" uppercase>
                {formatMessage(intlMessages.titleNewClinics)}
              </Heading>
            }
          >
            {data && data.adminStats && <ClinicList clinics={data.adminStats.clinics} />}
          </Card>
        </Col>
        <Col order={5} xs={24} lg={8}>
          <Card
            className={styles.card}
            title={
              <Heading level="h2" size="h6" uppercase>
                {formatMessage(intlMessages.titleNewJobs)}
              </Heading>
            }
          >
            {data && data.adminStats && <JobList jobs={data.adminStats.jobs} />}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Metrics;
