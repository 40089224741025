import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  typeProfession: {
    defaultMessage: 'Type of Profession',
    description: 'Type of Profession',
    id: 'components.provider.ProfessionalFields.typeProfession',
  },
  clinicianType: {
    defaultMessage: 'Clinician Type',
    description: 'Clinician Type',
    id: 'components.provider.ProfessionalFields.clinicianType',
  },
  specialty: {
    defaultMessage: 'Specialty',
    description: 'Specialty',
    id: 'components.provider.ProfessionalFields.specialty',
  },
  clickToFind: {
    defaultMessage: 'Click here to find your NPI',
    description: 'Click here to find your NPI',
    id: 'components.provider.ProfessionalFields.clickToFind',
  },
  electronicHealth: {
    defaultMessage: 'Electronic Health Record (EHR) proficiency',
    description: 'Electronic Health',
    id: 'components.provider.ProfessionalFields.electronicHealth',
  },
  currentEmployment: {
    defaultMessage: 'Current Employment Status',
    description: 'Current Employment Status',
    id: 'components.provider.ProfessionalFields.currentEmployment',
  },
  yearLastPracticed: {
    defaultMessage: 'Year Last Practiced',
    description: 'Year Last Practiced',
    id: 'components.provider.ProfessionalFields.yearLastPracticed',
  },
  shortNote: {
    defaultMessage: 'Short note on employment history',
    description: 'Short note on employment history',
    id: 'components.provider.ProfessionalFields.shortNote',
  },
  activeLicenses: {
    defaultMessage: 'Active Licenses',
    description: 'Active Licenses',
    id: 'components.provider.ProfessionalFields.specialty',
  },
  pendingLicenses: {
    defaultMessage: 'Pending Licenses',
    description: 'Pending Licenses',
    id: 'components.provider.ProfessionalFields.pendingLicenses',
  },
  activeDeaLicenses: {
    defaultMessage: 'Active DEA Licenses',
    description: 'Active DEA Licenses',
    id: 'components.provider.ProfessionalFields.activeDeaLicenses',
  },
  pendingDeaLicenses: {
    defaultMessage: 'Pending DEA Licenses',
    description: 'Pending DEA Licenses',
    id: 'components.provider.ProfessionalFields.pendingDeaLicenses',
  },
});
