import React, { useCallback } from 'react';
import { useId } from '@reach/auto-id';
import { Modal } from 'antd';
import Select from '@openloop/limbic/Form/Select/Select';
import toast from '@openloop/limbic/Toast';
import { FormikHelpers } from 'formik';
import { defineMessages, useIntl } from 'react-intl';

import { UserNetworkStatus, useSetNetworkStatusMutation } from '~Data';
import Form from '~Core/Form';
import { networkStatusChoices } from '~Constants/networkStatuses';

import styles from './NetworkStatusSelect.module.scss';

const intlMessages = defineMessages({
  setNetworkStatusError: {
    defaultMessage: 'Error setting network status',
    description: 'Error message for setting network status',
    id: 'Modules.admin.userDetail.profile.setNetworkStatusError',
  },
  setNetworkStatusSuccess: {
    defaultMessage: 'Network status set successfully',
    description: 'Success message for setting network status',
    id: 'Modules.admin.userDetail.profile.setNetworkStatusSuccess',
  },
  statusChangeConfirmationQuestion: {
    defaultMessage: "Are you sure you want to change this clinician's network status?",
    description: 'Confirmation question for changing clinician network status',
    id: 'Modules.admin.userDetail.profile.statusChangeConfirmationQuestion',
  },
  displayNameLabel: {
    defaultMessage: 'Clinician',
    description: 'Label for clinician name',
    id: 'Modules.admin.userDetail.profile.displayNameLabel',
  },
  currentStatusLabel: {
    defaultMessage: 'Current Status',
    description: 'Label for current status',
    id: 'Modules.admin.userDetail.profile.currentStatusLabel',
  },
  newStatusLabel: {
    defaultMessage: 'New Status',
    description: 'Label for new status',
    id: 'Modules.admin.userDetail.profile.newStatusLabel',
  },
});

interface NetworkStatusFormValues {
  status: UserNetworkStatus;
}

interface NetworkStatusSelectProps {
  userId: string;
  networkStatus: UserNetworkStatus;
  completion: number;
  displayName: string;
}

const NetworkStatusSelect = ({
  userId,
  networkStatus,
  completion,
  displayName,
}: NetworkStatusSelectProps) => {
  const selectId = useId()?.toString() || '';
  const { formatMessage } = useIntl();

  const [setNetworkStatus, { loading: setNetworkStatusLoading }] = useSetNetworkStatusMutation({
    onCompleted: () => {
      toast({ variant: 'success', message: formatMessage(intlMessages.setNetworkStatusSuccess) });
    },
    onError: () => {
      toast({ variant: 'danger', message: formatMessage(intlMessages.setNetworkStatusError) });
    },
  });

  const handleNetworkStatusSubmit = useCallback(
    (
      { status }: NetworkStatusFormValues,
      { resetForm }: FormikHelpers<NetworkStatusFormValues>,
    ) => {
      const modalInstance = Modal.confirm({
        okButtonProps: {
          onClick: () => {
            setNetworkStatus({ variables: { input: { networkStatus: status, userId } } });
            modalInstance.destroy();
          },
          loading: setNetworkStatusLoading,
        },
        okText: `Yes, I'm sure`,
        onCancel: () => {
          resetForm();
        },
        autoFocusButton: 'cancel',
        content: (
          <>
            <p>{formatMessage(intlMessages.statusChangeConfirmationQuestion)}</p>
            <p>
              <span className={styles.bold}>{formatMessage(intlMessages.displayNameLabel)}:</span>{' '}
              {displayName}
              <br />
              <span className={styles.bold}>
                {formatMessage(intlMessages.currentStatusLabel)}:
              </span>{' '}
              {networkStatusChoices[networkStatus] || 'N/A'}
              <br />
              <span className={styles.bold}>
                {formatMessage(intlMessages.newStatusLabel)}:
              </span>{' '}
              {networkStatusChoices[status]}
            </p>
          </>
        ),
        title: `Change Clinician's Network Status`,
      });
    },
    [displayName, formatMessage, networkStatus, setNetworkStatus, setNetworkStatusLoading, userId],
  );

  return (
    <div>
      <Form<NetworkStatusFormValues>
        enableReinitialize
        initialValues={{
          status: networkStatus || UserNetworkStatus.Incomplete,
        }}
        onSubmit={handleNetworkStatusSubmit}
      >
        {({ handleSubmit }) => (
          <Select
            id={selectId}
            name="status"
            onChange={() => handleSubmit()}
            options={Object.entries(networkStatusChoices).map(([value, label]) => ({
              isDisabled: value === UserNetworkStatus.YesNetwork && completion && completion < 100,
              label,
              value,
            }))}
          />
        )}
      </Form>
    </div>
  );
};

export default NetworkStatusSelect;
