import React from 'react';
import { Menu, Row, Col, Avatar } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useLocation } from 'react-router-dom';
import { FormOutlined, HomeOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import classNames from 'classnames/bind';
import { defineMessages, useIntl } from 'react-intl';

import images from '~Constants/images';
import { providerRoutes } from '~Constants/routes';
import { useAuthContext } from '~Context/AuthContext';
import Link from '~Core/Link';
import NavLink from '~Core/NavLink';
import { FeatureFlag, useFeatureFlag } from '~Hooks/useFeatureFlag';

import styles from './Header.module.scss';

const intlMenu = defineMessages({
  menuHome: {
    id: 'provider.Header.menuHome',
    defaultMessage: 'Home',
    description: 'Nav link',
  },
  menuMyInformation: {
    id: 'provider.Header.menuMyInformation',
    defaultMessage: 'My Information',
    description: 'Nav link',
  },
  menuMyProfile: {
    id: 'provider.Header.menuMyProfile',
    defaultMessage: 'My Profile',
    description: 'Nav link',
  },
  menuLicenses: {
    id: 'provider.Header.menuLicenses',
    defaultMessage: 'Licenses',
    description: 'Nav link',
  },
  menuViewPatients: {
    id: 'provider.Header.menuViewPatients',
    defaultMessage: 'View Patients',
    description: 'Nav link',
  },
  menuLogOut: {
    id: 'provider.Header.menuLogOut',
    defaultMessage: 'Log Out',
    description: 'Nav link',
  },
});

const cx = classNames.bind(styles);
const { SubMenu } = Menu;

const Header = () => {
  const { formatMessage } = useIntl();
  const isAsyncQueueEnabled = useFeatureFlag(FeatureFlag.AsyncQueue);

  const { pathname } = useLocation();
  const { lg } = useBreakpoint();

  const { currentUser, logout } = useAuthContext();

  const showDesktopMenu = lg;

  return (
    <Row align="middle" gutter={24} justify="space-between">
      <Col className={styles.logoColumn}>
        <Link to={providerRoutes.dashboard}>
          <img alt="OpenLoop" className={styles.logo} src={images.logo} />
        </Link>
      </Col>
      <Col flex={1}>
        <Row className={styles.menuRow}>
          {showDesktopMenu && (
            <>
              <Col>
                <Menu className={styles.menu} mode="horizontal" selectedKeys={[pathname]}>
                  <Menu.Item
                    key={providerRoutes.dashboard}
                    className={cx({ [styles.active]: pathname === providerRoutes.dashboard })}
                  >
                    <NavLink to={providerRoutes.dashboard}>
                      {formatMessage(intlMenu.menuHome)}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key={providerRoutes.editProfile}
                    className={cx({ [styles.active]: pathname === providerRoutes.editProfile })}
                  >
                    <NavLink to={providerRoutes.editProfile}>
                      {' '}
                      {formatMessage(intlMenu.menuMyInformation)}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key={providerRoutes.myProfile}
                    className={cx({ [styles.active]: pathname === providerRoutes.myProfile })}
                  >
                    <NavLink to={providerRoutes.myProfile}>
                      {formatMessage(intlMenu.menuMyProfile)}
                    </NavLink>
                  </Menu.Item>

                  {/* <Menu.Item
                    key={providerRoutes.licenses}
                    className={cx({ [styles.active]: pathname === providerRoutes.licenses })}
                  >
                    <NavLink to={providerRoutes.licenses}>
                      {formatMessage(intlMenu.menuLicenses)}
                    </NavLink>
                  </Menu.Item> */}

                  {isAsyncQueueEnabled && (
                    <Menu.Item
                      key={providerRoutes.patients}
                      className={cx({ [styles.active]: pathname === providerRoutes.patients })}
                    >
                      <NavLink to={providerRoutes.patients}>
                        {formatMessage(intlMenu.menuViewPatients)}
                      </NavLink>
                    </Menu.Item>
                  )}
                </Menu>
              </Col>
              <Col>
                <Menu className={`${styles.menu} ${styles.logOutDesktopWidth}`} mode="horizontal">
                  <Menu.Item
                    data-testid="logout-button-nav-clinician"
                    icon={<UserOutlined className={styles.logOutIcon} />}
                    key="logout"
                    onClick={logout}
                  >
                    {formatMessage(intlMenu.menuLogOut)}
                  </Menu.Item>
                </Menu>
              </Col>
            </>
          )}
          {!showDesktopMenu && (
            <>
              <Col>
                <Menu className={styles.menu} mode="horizontal" selectedKeys={[pathname]}>
                  <SubMenu
                    className={styles.userMenu}
                    title={
                      <Avatar
                        size="large"
                        src={
                          currentUser && currentUser.profile.image
                            ? currentUser.profile.image.location
                            : images.placeholderDoctor
                        }
                      />
                    }
                  >
                    <Menu.Item icon={<HomeOutlined />} key={providerRoutes.dashboard}>
                      <NavLink to={providerRoutes.dashboard}>
                        {formatMessage(intlMenu.menuHome)}
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item icon={<FormOutlined />} key={providerRoutes.editProfile}>
                      <NavLink to={providerRoutes.editProfile}>
                        {formatMessage(intlMenu.menuMyInformation)}
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item icon={<UserOutlined />} key={providerRoutes.myProfile}>
                      <NavLink to={providerRoutes.myProfile}>
                        {formatMessage(intlMenu.menuMyProfile)}
                      </NavLink>
                    </Menu.Item>
                    {/* <Menu.Item icon={<ProfileOutlined />} key={providerRoutes.licenses}>
                      <NavLink to={providerRoutes.licenses}>
                        {formatMessage(intlMenu.menuLicenses)}
                      </NavLink>
                    </Menu.Item> */}

                    {isAsyncQueueEnabled && (
                      <Menu.Item key={providerRoutes.patients}>
                        <NavLink to={providerRoutes.patients}>
                          {formatMessage(intlMenu.menuViewPatients)}
                        </NavLink>
                      </Menu.Item>
                    )}
                    <Menu.Divider key="divider-final" />
                    <Menu.Item
                      data-testid="logout-button-nav-clinician"
                      icon={<LogoutOutlined />}
                      key="logout"
                      onClick={logout}
                    >
                      {formatMessage(intlMenu.menuLogOut)}
                    </Menu.Item>
                  </SubMenu>
                </Menu>
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default Header;
