import React, { useEffect, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import { useIntl } from 'react-intl';
import { useId } from '@reach/auto-id';
import Heading from '@openloop/limbic/Heading';
import { useFormikContext } from 'formik';
import * as yup from 'yup';

import { userTypeChoices, userTypeIcons } from '~Constants/userTypes';
import Form from '~Core/Form';
import Icon from '~Core/Icon';
import Paragraph from '~Core/Paragraph';
import Radio from '~Core/Radio';
import { ProviderUserRole } from '~Data';

import { intlMessagesUserType } from './intlMessages';
import { initialValues as nextValues } from './StepMoreInfo';

import styles from './StepUserType.module.less';

export type FormValues = {
  userType: ProviderUserRole | '';
};

export const validationSchema: yup.SchemaOf<FormValues> = yup.object().shape({
  userType: yup
    .mixed<ProviderUserRole>()
    .oneOf(Object.keys(userTypeChoices) as ProviderUserRole[], 'User role not valid')
    .required('User type is required'),
});

const StepUserType = () => {
  const {
    values: { userType },
    setValues,
    validateForm,
  } = useFormikContext<FormValues>();
  const { handleStep } = useWizard();
  const { formatMessage } = useIntl();
  const [initialRole] = useState(userType);

  const inputId = useId()?.toString() || '';
  const hiddenLabelId = useId()?.toString();

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  handleStep(() => {
    setValues((prev) => {
      if (!prev.userType || prev.userType === initialRole) return prev;

      const newValues = nextValues[prev.userType];
      return { ...prev, ...newValues };
    });
  });

  return (
    <>
      <Heading level="h1" size="h2">
        {formatMessage(intlMessagesUserType.heading)}
      </Heading>
      <Paragraph>{formatMessage(intlMessagesUserType.paragraph)}</Paragraph>
      <Form.Item
        inputId={inputId}
        name="userType"
        label={`${formatMessage(intlMessagesUserType.iAmA)}...`}
        required
      >
        {({ labelId }) => (
          <>
            <span className={styles.srOnly} id={hiddenLabelId}>
              {formatMessage(intlMessagesUserType.questionSpan)}
            </span>
            <Radio
              arialabelledBy={`${hiddenLabelId} ${labelId}`}
              className={styles.buttonChoices}
              direction="horizontal"
              name="userType"
              type="button"
            >
              {Object.entries(userTypeChoices).map(([role, label]) => (
                <Radio.Item
                  icon={<Icon name={userTypeIcons[role as ProviderUserRole]} />}
                  key={role}
                  name="userType"
                  value={role}
                >
                  {label}
                </Radio.Item>
              ))}
            </Radio>
          </>
        )}
      </Form.Item>
    </>
  );
};

export default StepUserType;
