import React from 'react';
import { useField } from 'formik';

import Input, { InputProps } from '@openloop/limbic/Form/Input/Input';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us';

const maskConfig = {
  phone: true,
  phoneRegionCode: 'US',
  delimiter: '-',
};

const PhoneInput = ({ id, name }: InputProps) => {
  const [{ value }] = useField(name);
  return (
    <Input
      id={id}
      maskConfig={maskConfig}
      name="phone"
      placeholder="555-555-5555"
      type="tel"
      value={value}
    />
  );
};

export default PhoneInput;
