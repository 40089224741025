import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import Input from '@openloop/limbic/Form/Input';
import Heading from '@openloop/limbic/Heading';
import * as yup from 'yup';

import { useFormikContext } from 'formik';
import Form from '~Core/Form';
import Paragraph from '~Core/Paragraph';
import PhoneInput from '~Core/PhoneInput';
import SalutationSelect from '~Components/SalutationSelect';

import { intlMessagesUserName } from './intlMessages';

import styles from './StepUsername.module.less';

export interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: number | '';
  salutation: string;
}

export const validationSchema: yup.SchemaOf<FormValues> = yup.object().shape({
  email: yup.string().email('Please enter a valid email address').required('Email is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  password: yup
    .string()
    .min(8, 'Password must contain at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase character')
    .matches(/[0-9]/, 'Password must contain at least one numerical character')
    .required('Password is required'),
  phone: yup
    .number()
    .required('Phone is required')
    .typeError('Must be a valid phone number')
    .test(
      'len',
      'Phone number must be 10 or 11 digits',
      (val) =>
        typeof val === 'undefined' ||
        (typeof val === 'number' && val.toString().length === 10) ||
        val.toString().length === 11,
    ),
  salutation: yup.string().required('Title is required'),
});

export const initialValues: FormValues = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  phone: '',
  salutation: '',
};

const StepUsername = () => {
  const { setTouched, validateForm } = useFormikContext<FormValues>();
  const { formatMessage } = useIntl();

  useEffect(() => {
    setTouched({
      email: false,
      firstName: false,
      lastName: false,
      password: false,
      phone: false,
      salutation: false,
    });
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Heading level="h1" size="h2">
        {formatMessage(intlMessagesUserName.createAccount)}
      </Heading>
      <Paragraph>{formatMessage(intlMessagesUserName.paragraph)}</Paragraph>
      <div className={styles.formFieldsContainer}>
        <Form.Item
          className={styles.firstName}
          label={formatMessage(intlMessagesUserName.firstName)}
          name="firstName"
          required
        >
          {({ inputId }) => <Input id={inputId} name="firstName" placeholder="First name" />}
        </Form.Item>
        <Form.Item
          className={styles.lastName}
          label={formatMessage(intlMessagesUserName.lastName)}
          name="lastName"
          required
        >
          {({ inputId }) => <Input id={inputId} name="lastName" placeholder="Last name" />}
        </Form.Item>
        <Form.Item
          className={styles.salutation}
          label={formatMessage(intlMessagesUserName.titleSalutation)}
          name="salutation"
          required
        >
          {({ inputId }) => <SalutationSelect id={inputId} name="salutation" />}
        </Form.Item>
        <Form.Item
          className={styles.phone}
          label={formatMessage(intlMessagesUserName.phone)}
          name="phone"
          required
        >
          {({ inputId }) => <PhoneInput id={inputId} name="phone" />}
        </Form.Item>
        <Form.Item
          className={styles.email}
          label={formatMessage(intlMessagesUserName.email)}
          name="email"
          required
        >
          {({ inputId }) => <Input id={inputId} name="email" placeholder="Email" type="email" />}
        </Form.Item>
        <Form.Item
          className={styles.password}
          label={formatMessage(intlMessagesUserName.password)}
          name="password"
          required
        >
          {({ inputId }) => (
            <Input id={inputId} name="password" placeholder="Password" type="password" />
          )}
        </Form.Item>
      </div>
    </>
  );
};

export default StepUsername;
