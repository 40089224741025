import React, { useEffect } from 'react';
import { message } from 'antd';
import * as yup from 'yup';
import Button from '@openloop/limbic/Button';
import Form from '@openloop/limbic/Form';
import { useIntl } from 'react-intl';

import FormikForm from '~Core/Form/Form';
import FormItem from '~Core/Form/FormItem';
import {
  ClinicDrChronoIntegrationCustomization,
  useCustomizeClinicDrChronoIntegrationMutation,
} from '~Data';
import { intlMessagesCustomization } from './intlMessages';

interface Props {
  clinicId: string;
  customizations: ClinicDrChronoIntegrationCustomization | null | undefined;
}

interface FormValues {
  primaryButtonBgColor?: string;
}

const validationSchema: yup.SchemaOf<FormValues> = yup.object().shape({
  primaryButtonBgColor: yup.string(),
});

const DrChronoCustomization = ({ clinicId, customizations }: Props) => {
  const { formatMessage } = useIntl();
  const [customizeClinicDrChronoIntegration, { data, error, loading }] =
    useCustomizeClinicDrChronoIntegrationMutation();

  useEffect(() => {
    if (error) {
      message.error('There was an error saving your customizations.');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      message.success('Customizations saved successfully.');
    }
  }, [data]);

  const handleSubmit = (values: FormValues) => {
    customizeClinicDrChronoIntegration({ variables: { input: { clinicId, ...values } } });
  };

  return (
    <FormikForm<FormValues>
      initialValues={{ primaryButtonBgColor: customizations?.primaryButtonBgColor || '' }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, isValid }) => (
        <>
          <FormItem
            label={formatMessage(intlMessagesCustomization.formLabel)}
            name="primaryButtonBgColor"
          >
            <Form.Input name="primaryButtonBgColor" type="color" />
          </FormItem>
          <Button disabled={!dirty || !isValid} loading={loading} type="submit">
            {formatMessage(intlMessagesCustomization.save)}
          </Button>
        </>
      )}
    </FormikForm>
  );
};

export default DrChronoCustomization;
