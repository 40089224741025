import React, { useEffect } from 'react';

import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { Formik } from 'formik';
import Button from '@openloop/limbic/Button';
import Card from '@openloop/limbic/Card';
import Grid from '@openloop/limbic/Grid';
import Heading from '@openloop/limbic/Heading';
import Form from '@openloop/limbic/Form';
import { toast } from '@openloop/limbic';

import { FeatureFlag, useFeatureFlag } from '~Hooks/useFeatureFlag';
import useQuerystringParams from '~Hooks/useQuerystringParams';
import LoadingScreen from '~Modules/shared/LoadingScreen';
import { AppMessages } from '~I18n';
import messages from '~Constants/messages';
import { guestRoutes, externalRoutes } from '~Constants/routes';
import {
  useVerifyUserInvitationTokenQuery,
  useSignUpByInvitationMutation,
} from './InviteRegister.generated';
import FormItem from '~Core/Form/FormItem/FormItem';
import Paragraph from '~Core/Paragraph';
import CAAvatar from '~Components/CAAvatar';
import { Navigation } from '~Components/Navigation';
import ExpiredTokenScreen from './ExpiredTokenScreen';
import Footer from './Footer';
import { SignUpByInvitationInput } from '~Data';
import { useAccessToken } from '~Hooks/useAccessToken';
import { getUtmParams } from '~Helpers/utmParams';
import { intlMessages } from './intlMessages';

import styles from './InviteRegister.module.scss';

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, 'Minimum 8 characters required')
    .matches(/[A-Z]/, 'At least one uppercase letter required')
    .matches(/[0-9]/, 'At least one number required')
    .required(messages.passwordRequired),
  confirmPassword: yup
    .string()
    .required(messages.passwordRequired)
    .when('password', {
      is: (val: string) => !!(val && val.length > 0),
      then: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
    }),
});

const { Row } = Grid;

const InviteRegister = () => {
  const isInviteAndSuggestEnabled = useFeatureFlag(FeatureFlag.InviteAndSuggest);
  const navigate = useNavigate();
  const queryParams = useQuerystringParams();

  const utm = getUtmParams(queryParams);

  useEffect(() => {
    if (!isInviteAndSuggestEnabled) {
      navigate(guestRoutes.login, { replace: true });
    }
  }, [isInviteAndSuggestEnabled, navigate]);

  const { token } = useParams<{ token: string }>();
  const { formatMessage } = useIntl();

  const [signUpByInvitationMutation, { loading: loadingInvitation }] =
    useSignUpByInvitationMutation();
  const [, setAccessToken] = useAccessToken();

  const { data, loading, error } = useVerifyUserInvitationTokenQuery({
    variables: {
      token,
    },
  });

  useEffect(() => {
    let message;
    if (error) message = error.message;
    if (!data && !loading) message = formatMessage(intlMessages.requestError);
    if (data && 'isNotFound' in data.verifyUserInvitationToken)
      message = data.verifyUserInvitationToken.message;

    if (message) toast({ variant: 'danger', message });
  }, [data, error, formatMessage, loading]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error || !data || 'isNotFound' in data.verifyUserInvitationToken)
    return <Navigate to={guestRoutes.login} />;

  const { verifyUserInvitationToken } = data;

  if ('isExpired' in verifyUserInvitationToken) {
    const {
      userInvitation: { id },
    } = verifyUserInvitationToken;
    return <ExpiredTokenScreen userInvitationId={id} />;
  }

  const {
    id: userInvitationId,
    firstName: clinicianFirstName,
    clinicianAdvocate: {
      profile: { firstName: caFirstName, lastName: caLastName, image },
    },
  } = verifyUserInvitationToken;

  const handleSubmit = (input: SignUpByInvitationInput) => {
    signUpByInvitationMutation({
      variables: { input },
      onCompleted: ({ signUpByInvitation }) => {
        if (signUpByInvitation?.token) {
          setAccessToken(signUpByInvitation.token);
        }
      },
      onError: (e) => {
        toast({ variant: 'danger', message: e.message });
      },
    });
  };

  return (
    <>
      <Navigation logoRoute="/" />
      <div className={styles.content}>
        <Row justify="center">
          <Card padding="none" className={styles.inviteContainer}>
            <CAAvatar
              caAvatarUrl={image?.location}
              caName={`${caFirstName} ${caLastName}`}
              className={styles.clinicianAdvocate}
            />
            <div className={styles.divider} />
            <Heading level="h1" size="h2" className={styles.textCentered}>
              {formatMessage(intlMessages.ctaInviteTitle, { name: clinicianFirstName, br: <br /> })}
            </Heading>
            <Paragraph className={styles.inviteSubtitle}>
              {formatMessage(intlMessages.ctaInviteSubtitle)}
            </Paragraph>
            <Card
              title={
                <Paragraph className={styles.formTitle}>
                  {formatMessage(intlMessages.ctaFormTitle)}
                </Paragraph>
              }
              padding="none"
              className={styles.formContainer}
            >
              <Formik<SignUpByInvitationInput>
                initialValues={{
                  userInvitationId,
                  password: '',
                  confirmPassword: '',
                  utm,
                  rememberMe: false,
                }}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ dirty, isValid }) => (
                  <Form>
                    <FormItem
                      name="password"
                      label={formatMessage(intlMessages.formInputLabelPassword)}
                      className={styles.input}
                    >
                      <Form.Input name="password" type="password" />
                    </FormItem>
                    <FormItem
                      name="confirmPassword"
                      label={formatMessage(intlMessages.formInputLabelVerifyPassword)}
                      className={styles.input}
                    >
                      <Form.Input name="confirmPassword" type="password" />
                    </FormItem>
                    <div className={styles.checkboxContainer}>
                      <Form.Checkbox
                        name="rememberMe"
                        label={formatMessage(intlMessages.formCheckboxRememberMe)}
                        className={styles.checkbox}
                      />
                    </div>
                    <Row justify="center">
                      <Button
                        className={styles.submit}
                        disabled={!dirty || !isValid}
                        type="submit"
                        loading={loadingInvitation}
                        size="lg"
                        variant="primary"
                      >
                        {formatMessage({ id: AppMessages.btnLogIn })}
                      </Button>
                    </Row>
                    <Paragraph className={styles.termsNote}>
                      {formatMessage(intlMessages.formTermsAgreementNotice, {
                        privacyPolicyLink: (
                          <a href={externalRoutes.privacyPolicy} target="_blank" rel="noreferrer">
                            {formatMessage(intlMessages.openloopPrivacPolicy)}
                          </a>
                        ),
                        termsOfUseLink: (
                          <a href={externalRoutes.termsOfUse} target="_blank" rel="noreferrer">
                            {formatMessage(intlMessages.termsOfUse)}
                          </a>
                        ),
                      })}
                    </Paragraph>
                  </Form>
                )}
              </Formik>
            </Card>
          </Card>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default InviteRegister;
