import React from 'react';
import classnames from 'classnames';
import { Tag, Tooltip } from '@openloop/limbic';

import { LicenseStatus } from '~Data';
import styles from './ClinicianLicenseTag.module.scss';

const cx = classnames.bind(styles);

interface LicenseTagProps {
  className?: string;
  state: { name: string; shortName: string };
  status: LicenseStatus;
  DEAStatus?: LicenseStatus;
  tooltipPosition?: 'left' | 'right' | 'top' | 'bottom';
}

const ClinicianLicenseTag = ({
  className,
  state,
  status,
  DEAStatus,
  tooltipPosition,
  ...rest
}: LicenseTagProps) => {
  const statusText = {
    [LicenseStatus.SelfReportedActive]: 'Self-Reported - Active',
    [LicenseStatus.SelfReportedPending]: 'Self-Reported - Pending',
    [LicenseStatus.VerifiedActive]: 'Verified - Active',
  };
  const DEAStatusText = {
    [LicenseStatus.SelfReportedActive]: 'DEA - Self-Reported Active',
    [LicenseStatus.SelfReportedPending]: 'DEA - Self-Reported Pending',
    [LicenseStatus.VerifiedActive]: 'DEA - Verified Active',
  };

  const tagClassName = cx(styles.tagDefault, {
    [styles.selfReportedActive]: LicenseStatus.SelfReportedActive === status,
    [styles.selfReportedPending]: LicenseStatus.SelfReportedPending === status,
    [styles.verifiedActive]: LicenseStatus.VerifiedActive === status,
    [styles.selfReportedActiveDEA]: LicenseStatus.SelfReportedActive === DEAStatus,
    [styles.selfReportedPendingDEA]: LicenseStatus.SelfReportedPending === DEAStatus,
    [styles.verifiedActiveDEA]: LicenseStatus.VerifiedActive === DEAStatus,
  });

  return (
    <Tooltip
      tooltipTitle={state.name}
      tooltipDescription={
        <div>
          <p className={styles.tooltipDescription}>{statusText[status]}</p>
          {DEAStatus && <p className={styles.tooltipDescription}>{DEAStatusText[DEAStatus]}</p>}
        </div>
      }
      tooltipPosition={tooltipPosition || 'top'}
      {...rest}
    >
      <Tag className={tagClassName} variant="none">
        {state.shortName}
      </Tag>
    </Tooltip>
  );
};

export default ClinicianLicenseTag;
