import React from 'react';
import classnames from 'classnames';
import Heading from '@openloop/limbic/Heading';

import images from '~Constants/images';

import styles from './Empty.module.scss';

export interface EmptyProps {
  className?: string;
  title?: string;
  description?: string;
  image?: string;
}

const Empty = ({ className, description, image, title }: EmptyProps) => (
  <div className={classnames(styles.main, className)}>
    <div className={styles.contentItem}>
      <img className={styles.image} src={image || images.laptopLamp} alt="" />
    </div>
    {(description || title) && (
      <div className={styles.contentItem}>
        {title && (
          <Heading className={styles.heading} level="h5">
            {title}
          </Heading>
        )}
        {description && <p className={styles.body}>{description}</p>}
      </div>
    )}
  </div>
);

export default Empty;
