import React from 'react';
import { Row, Col, Space, Divider } from 'antd';
import {
  FacebookOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from '@ant-design/icons';
import { useIntl } from 'react-intl';

import { externalRoutes, providerRoutes } from '~Constants/routes';
import Link from '~Core/Link';
import { intlMessages } from './intlMessages';

import styles from './Footer.module.less';

const Footer = () => {
  const { formatMessage } = useIntl();
  return (
    <Row justify="center">
      <Col className={styles.links}>
        <Space direction="vertical" size="large">
          <div>
            <Space split={<Divider type="vertical" />}>
              <Link to={providerRoutes.dashboard}>{formatMessage(intlMessages.homeLink)}</Link>
              <Link to={providerRoutes.myProfile}>{formatMessage(intlMessages.profileLink)}</Link>
              <a href={externalRoutes.blog} target="_blank" rel="noopener noreferrer">
                {formatMessage(intlMessages.blogLink)}
              </a>
            </Space>
          </div>
          <div>
            <Space size="large">
              <a href={externalRoutes.facebook} target="_blank" rel="noopener noreferrer">
                <FacebookOutlined />
              </a>
              <a href={externalRoutes.instagram} target="_blank" rel="noopener noreferrer">
                <InstagramOutlined />
              </a>
              <a href={externalRoutes.twitter} target="_blank" rel="noopener noreferrer">
                <TwitterOutlined />
              </a>
              <a href={externalRoutes.linkedIn} target="_blank" rel="noopener noreferrer">
                <LinkedinOutlined />
              </a>
            </Space>
          </div>
        </Space>
      </Col>
    </Row>
  );
};

export default Footer;
