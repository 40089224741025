import React from 'react';
import { Row, Col, Space } from 'antd';
import {
  FacebookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  LinkedinOutlined,
} from '@ant-design/icons';

import { externalRoutes } from '~Constants/routes';

import styles from './Footer.module.less';

const Footer = () => (
  <footer>
    <Row justify="center">
      <Col className={styles.links}>
        <div>
          <Space size="large">
            <a href={externalRoutes.facebook} target="_blank" rel="noopener noreferrer">
              <FacebookOutlined />
            </a>
            <a href={externalRoutes.instagram} target="_blank" rel="noopener noreferrer">
              <InstagramOutlined />
            </a>
            <a href={externalRoutes.twitter} target="_blank" rel="noopener noreferrer">
              <TwitterOutlined />
            </a>
            <a href={externalRoutes.linkedIn} target="_blank" rel="noopener noreferrer">
              <LinkedinOutlined />
            </a>
          </Space>
        </div>
      </Col>
    </Row>
  </footer>
);

export default Footer;
