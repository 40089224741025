import React, { useEffect } from 'react';
import { message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { defineMessages, useIntl } from 'react-intl';

import messages from '~Constants/messages';
import Paragraph from '~Core/Paragraph';
import { ClinicContact, useEditClinicContactMutation } from '~Data';
import ClinicContactModalFields from '~Components/ClinicContactModalFields';

const intlMessages = defineMessages({
  modalContent: {
    defaultMessage: 'Use contacts to associate jobs with hiring managers.',
    description: 'Clinic contact modal content',
    id: 'Components.EditClinicContactModal.modalContent',
  },
});

interface Props {
  contactId: string;
  contactName: ClinicContact['name'];
  contactEmail: ClinicContact['email'];
  contactPhone: ClinicContact['phone'];
  contactTitle: ClinicContact['title'];
  isVisible: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface FormValues {
  email?: string;
  name?: string;
  phone?: string;
  title?: string;
}

const EditClinicContactModal = ({
  contactId,
  contactName,
  contactEmail,
  contactPhone,
  contactTitle,
  isVisible,
  setIsOpen,
}: Props) => {
  const { formatMessage } = useIntl();

  const [editClinicContact, { data, error, loading }] = useEditClinicContactMutation();

  const handleSubmit = (values: FormValues) => {
    editClinicContact({ variables: { input: { ...values, id: contactId } } });
  };

  useEffect(() => {
    if (data && !loading) {
      setIsOpen(false);
      message.success(messages.clinicContactEditSuccess);
    }
  }, [data, loading, setIsOpen]);

  useEffect(() => {
    if (error && !loading) {
      message.error(messages.clinicContactEditError);
    }
  }, [error, loading]);

  return (
    <Modal
      destroyOnClose
      visible={isVisible}
      footer={false}
      onCancel={() => setIsOpen(false)}
      title="Edit Contact"
    >
      <Paragraph>{formatMessage(intlMessages.modalContent)}</Paragraph>
      <ClinicContactModalFields
        handleSubmit={handleSubmit}
        loading={loading}
        initialEmail={contactEmail}
        initialName={contactName}
        initialPhone={contactPhone}
        initialTitle={contactTitle}
      />
    </Modal>
  );
};

export default EditClinicContactModal;
