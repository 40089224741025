import React, { FC, memo, MutableRefObject, ReactNode, useEffect } from 'react';
import { motion, Variants } from 'framer-motion';
import { useWizard } from 'react-use-wizard';

const variants: Variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 125 : -125,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 125 : -125,
      opacity: 0,
    };
  },
};

type AnimatedStepProps = {
  previousStep: MutableRefObject<number>;
  children: ReactNode;
};

const AnimatedStep: FC<AnimatedStepProps> = ({ children, previousStep }) => {
  const { activeStep } = useWizard();

  useEffect(() => {
    return () => {
      // eslint-disable-next-line no-param-reassign
      previousStep.current = activeStep;
    };
  }, [activeStep, previousStep]);

  return (
    <motion.div
      custom={activeStep - previousStep.current}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        type: 'spring',
        stiffness: 300,
        damping: 30,
      }}
    >
      {children}
    </motion.div>
  );
};

export default memo(AnimatedStep);
