import React from 'react';
import { useIntl } from 'react-intl';

import Paragraph from '~Core/Paragraph';
import { intlMessagesNotAllowed } from './intlMessages';

const JobApplicationsNotAllowed = () => {
  const { formatMessage } = useIntl();
  return <Paragraph>{formatMessage(intlMessagesNotAllowed.notAllowed)}</Paragraph>;
};
export default JobApplicationsNotAllowed;
