import drChronoLogoSrc from '~Assets/img/clinicIntegrations/drchrono_logo_black_600x171.04dc62f8dc8c.png';

import { ClinicIntegrations } from '~Data';

interface IntegrationMetadata {
  description: string;
  logoUrl: string;
  title: string;
}

export const clinicIntegrationsMetadata: Record<keyof ClinicIntegrations, IntegrationMetadata> = {
  drChrono: {
    description: 'DrChrono patient scheduling portal.',
    logoUrl: drChronoLogoSrc,
    title: 'DrChrono',
  },
};
