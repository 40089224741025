import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { guestRoutes } from '~Constants/routes';
import {
  adminRoutesObject,
  clinicRoutesObject,
  guestRoutesObject,
  providerRoutesObject,
  sharedRoutesObject,
} from '~Routes';

const AppRouter = () => {
  const routes = useRoutes([
    ...adminRoutesObject,
    ...clinicRoutesObject,
    ...guestRoutesObject,
    ...providerRoutesObject,
    ...sharedRoutesObject,
    { path: '*', element: <Navigate to={guestRoutes.login} /> },
  ]);

  return routes;
};

export default AppRouter;
