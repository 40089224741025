import React from 'react';

import { jobApplicationsNotAllowed } from '~Checks/jobs';
import { useJobContext } from '~Context/JobContext';

import crossImage from '~Assets/img/cross.png';
import JobApplicationForm from './JobApplicationForm';
import LoggedInJobApplicationForm from './LoggedInJobApplicationForm';
import JobApplicationsNotAllowed from './JobApplicationsNotAllowed';

import styles from './JobApplicationFormContainer.module.scss';

interface Props {
  customClass?: string;
}
const JobApplicationFormContainer = ({ customClass }: Props) => {
  const { jobData } = useJobContext();

  if (!jobData) {
    return null;
  }

  const { status: jobStatus, applied, applicationDate } = jobData;

  return (
    <aside className={`${styles.jobAppContainer} ${customClass}`}>
      <img src={crossImage} alt="" className={styles.crossImage} />
      {jobApplicationsNotAllowed(jobStatus) ? (
        <JobApplicationsNotAllowed />
      ) : (
        <>
          <LoggedInJobApplicationForm alreadyApplied={applied} applicationDate={applicationDate} />
          <JobApplicationForm />
        </>
      )}
    </aside>
  );
};

export default JobApplicationFormContainer;
