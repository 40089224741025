import React, { ComponentProps, useMemo } from 'react';
import { generatePath } from 'react-router-dom';

import { clinicRoutes } from '~Constants/routes';
import { AdminStatsQuery } from '~Data';

import SimpleList from './SimpleList';

interface Props {
  clinics: AdminStatsQuery['adminStats']['clinics'];
}

const ClinicList = ({ clinics }: Props) => {
  const listData = useMemo<ComponentProps<typeof SimpleList>['listData']>(
    () =>
      clinics.map(({ createdAt, id: clinicId, name }) => ({
        createdAt,
        href: generatePath(clinicRoutes.clinicDetail, { clinicId }),
        key: clinicId,
        title: name,
      })),
    [clinics],
  );

  return <SimpleList listData={listData} />;
};

export default ClinicList;
