import { OneJob } from '~Data';
import { JobFormValues } from '~Modules/clinic/Forms/JobFields';

export const defaultValues: JobFormValues = {
  aboutJob: undefined,
  clinicContactId: undefined,
  clinicianAdvocateId: undefined,
  clinicianType: undefined,
  description: undefined,
  desiredCertifications: undefined,
  desiredHours: undefined,
  desiredTelehealthExperience: undefined,
  education: undefined,
  employmentType: undefined,
  emrProviders: undefined,
  interviewDetails: undefined,
  isInternal: false,
  isTelehealth: false,
  location: undefined,
  minYearsExperience: undefined,
  numberToFill: undefined,
  numberToFillIsUnlimited: undefined,
  onboardingDetails: undefined,
  orientationDetails: undefined,
  otherInfo: undefined,
  payAndPerks: undefined,
  preferredLicenseStateIds: undefined,
  requiredEquipment: undefined,
  requiredLicenseStateIds: undefined,
  requirements: undefined,
  responsibilities: undefined,
  salaryDetails: undefined,
  schedulingDetails: undefined,
  shiftDetails: undefined,
  specialties: undefined,
  startAt: undefined,
  status: undefined,
  stateId: undefined,
  title: '',
  trainingLevel: undefined,
  zohoUrl: undefined,
};

export const useInitialValuesJob = (job: OneJob | null): JobFormValues => {
  if (!job) {
    return defaultValues;
  }
  return Object.entries(defaultValues).reduce((accum, [key, defaultValue]) => {
    const existingValue = job[key as keyof typeof job];
    const newValue = existingValue !== null ? existingValue : defaultValue;
    return {
      ...accum,
      [key]: newValue,
    };
  }, {} as JobFormValues);
};
