import { useCallback, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import { accessTokenReactiveVar } from '~Data/reactiveVars';
import {
  TOKEN_KEY,
  setPersistedAccessToken,
  unsetPersistedAccessToken,
} from '~LocalStorage/accessToken';

export const useAccessToken = (): [string | null, (authToken: string) => void, () => void] => {
  const accessToken = useReactiveVar(accessTokenReactiveVar);

  const setAccessToken = useCallback<(authToken: string) => void>((authToken) => {
    setPersistedAccessToken(authToken);
  }, []);

  /**
   * Keep the access token in sync with local storage. Useful when a user logs out in one tab, but has other authenticated tabs open.
   */
  useEffect(() => {
    const syncAccessToken = (evt: any) => {
      if (evt.key === TOKEN_KEY) {
        accessTokenReactiveVar(evt.newValue);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('storage', syncAccessToken);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('storage', syncAccessToken);
      }
    };
  }, []);

  const removeAccessToken = useCallback<() => void>(() => {
    unsetPersistedAccessToken();
  }, []);

  return [accessToken, setAccessToken, removeAccessToken];
};
