import React, { useEffect } from 'react';
import { message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { defineMessages, useIntl } from 'react-intl';

import messages from '~Constants/messages';
import Paragraph from '~Core/Paragraph';
import { useCreateClinicContactMutation } from '~Data';
import cacheUpdates from '~Data/cacheUpdates';
import ClinicContactModalFields from '~Components/ClinicContactModalFields';

const intlMessages = defineMessages({
  modalContent: {
    defaultMessage: 'Use contacts to associate jobs with hiring managers.',
    description: 'Clinic contact modal content',
    id: 'Components.CreateClinicContactModal.modalContent',
  },
});
interface Props {
  clinicId: string;
  isVisible: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface FormValues {
  email?: string;
  name?: string;
  phone?: string;
  title?: string;
}

const CreateClinicContactModal = ({ clinicId, isVisible, setIsOpen }: Props) => {
  const { formatMessage } = useIntl();

  const [createClinicContact, { data, error, loading }] = useCreateClinicContactMutation({
    update: cacheUpdates.createClinicContact(clinicId),
  });

  const handleSubmit = (values: FormValues) => {
    createClinicContact({ variables: { input: { ...values, clinicId } } });
  };

  useEffect(() => {
    if (data && !loading) {
      setIsOpen(false);
      message.success(messages.clinicContactCreateSuccess);
    }
  }, [data, loading, setIsOpen]);

  useEffect(() => {
    if (error && !loading) {
      message.error(messages.clinicContactCreateError);
    }
  }, [error, loading]);

  return (
    <Modal
      destroyOnClose
      footer={false}
      visible={isVisible}
      onCancel={() => setIsOpen(false)}
      title="Add Contact"
    >
      <Paragraph>{formatMessage(intlMessages.modalContent)}</Paragraph>
      <ClinicContactModalFields handleSubmit={handleSubmit} loading={loading} />
    </Modal>
  );
};

export default CreateClinicContactModal;
