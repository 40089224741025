import React, { FC, ReactNode, useMemo } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useWizard } from 'react-use-wizard';
import { useIntl } from 'react-intl';

import Form from '~Core/Form';
import { getUtmParams } from '~Helpers/utmParams';
import useQuerystringParams from '~Hooks/useQuerystringParams';
import { Salutation, useProviderSignUpMutation } from '~Data';
import WizardFooter from '~Components/WizardFooter/WizardFooter';
import Paragraph from '~Core/Paragraph';
import { guestRoutes, externalRoutes } from '~Constants/routes';

import { FormValues as RoleForm, validationSchema as validationRole } from './StepUserType';
import {
  FormValues as ProfessionForm,
  validationSchema as validationProfession,
} from './StepMoreInfo';
import {
  FormValues as UserForm,
  initialValues,
  validationSchema as validationUser,
} from './StepUsername';
import { intlMessagesRegister } from './intlMessages';
import Link from '~Core/Link';

import styles from './StepControl.module.less';

type FormValues = RoleForm & ProfessionForm & UserForm;

const schemas = [validationRole, validationProfession, validationUser] as const;

type StepControlProps = {
  children?: ReactNode;
};

const StepControl: FC<StepControlProps> = ({ children }) => {
  const { formatMessage } = useIntl();
  const { activeStep, isLoading, isLastStep, isFirstStep, previousStep, nextStep } = useWizard();
  const [signUpUser, { loading: isSubmitting }] = useProviderSignUpMutation();
  const queryParams = useQuerystringParams();

  const validationSchema = useMemo(() => {
    const [firstValidation, ...rest] = schemas;

    const validation = rest
      .slice(0, activeStep)
      .reduce((acc, schema) => acc.concat(schema), firstValidation);

    return validation;
  }, [activeStep]);

  const agreeAndJoin = isLastStep ? 'Agree & Join' : undefined;
  const loading = isLoading || isSubmitting;
  const utm = getUtmParams(queryParams);

  const onSubmit = (values: FormValues) => {
    if (isLastStep) {
      const { userType: role, email, password, phone, salutation, ...fields } = values;

      signUpUser(
        { email, password, role },
        {
          phone: phone.toString(),
          salutation: salutation as Salutation,
          ...fields,
          ...(utm && { utm }),
        },
      );
    } else nextStep();
  };

  return (
    <>
      <Form<FormValues>
        initialValues={{
          userType: '',
          ...initialValues,
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isValid }) => (
          <>
            <AnimatePresence initial={false} exitBeforeEnter>
              {children}
            </AnimatePresence>

            <WizardFooter
              isFirstStep={isFirstStep}
              prevButtonProps={{
                onClick: previousStep,
                loading,
              }}
              nextButtonProps={{
                disabled: !isValid,
                type: 'submit',
                loading,
              }}
              nextButtonText={agreeAndJoin}
            />
          </>
        )}
      </Form>

      {isFirstStep && (
        <div className={styles.loginContent}>
          <Paragraph>
            {formatMessage(intlMessagesRegister.alreadyQuestion)}{' '}
            <Link to={guestRoutes.login} type="cta">
              {formatMessage(intlMessagesRegister.loginLink)}
            </Link>
          </Paragraph>
        </div>
      )}

      {isLastStep && (
        <div className={styles.tosContent}>
          <Paragraph>
            {formatMessage(intlMessagesRegister.paragraphAgree, {
              signupButtonCopy: 'Agree & Join',
            })}{' '}
            <a href={externalRoutes.privacyPolicy} target="_blank" rel="noreferrer">
              {formatMessage(intlMessagesRegister.privacyPolicy)}
            </a>{' '}
            {formatMessage(intlMessagesRegister.and)}{' '}
            <a href={externalRoutes.termsOfUse} target="_blank" rel="noreferrer">
              {formatMessage(intlMessagesRegister.termsOfUse)}
            </a>
            .
          </Paragraph>
        </div>
      )}
    </>
  );
};

export default StepControl;
