import { useEffect } from 'react';

export enum FeatureFlag {
  AsyncQueue = 'AsyncQueue',
  SharingTokensV2 = 'SharingTokensV2',
  InviteAndSuggest = 'InviteAndSuggest',
}

interface FeatureFlagState {
  expires: Date;
  isEnabled: boolean;
}

const featureFlagSettings: Record<FeatureFlag, FeatureFlagState> = Object.freeze({
  [FeatureFlag.AsyncQueue]: {
    expires: new Date('2022-07-01'),
    isEnabled: import.meta.env.VITE_FEATURE_FLAG_ASYNC_QUEUE === 'true',
  },
  [FeatureFlag.SharingTokensV2]: {
    expires: new Date('2022-05-28'),
    isEnabled: import.meta.env.VITE_FEATURE_FLAG_SHARING_TOKENS_V2 === 'true',
  },
  [FeatureFlag.InviteAndSuggest]: {
    expires: new Date('2022-12-31'),
    isEnabled: import.meta.env.VITE_FEATURE_FLAG_INVITE_AND_SUGGEST === 'true',
  },
});

export const useFeatureFlag = (flag: FeatureFlag): boolean => {
  const { isEnabled, expires } = featureFlagSettings[flag];
  const isExpired = expires < new Date();

  useEffect(() => {
    if (isExpired) {
      console.warn(`Feature flag ${flag} is expired.`);
    }
  }, [flag, isExpired]);
  return isEnabled;
};
