import { defineMessages } from 'react-intl';

export const intlMessages = defineMessages({
  confirmTitle: {
    defaultMessage: 'Confirm Before Sending',
    description: 'Card heading',
    id: 'components.InviteAndSuggest.ConfirmInfoScreen.confirmTitle',
  },
  name: {
    defaultMessage: 'Name',
    description: 'Name',
    id: 'components.InviteAndSuggest.ConfirmInfoScreen.name',
  },
  knownAs: {
    defaultMessage: 'Known as',
    description: 'Known as',
    id: 'components.InviteAndSuggest.ConfirmInfoScreen.knownAs',
  },
  email: {
    defaultMessage: 'Email',
    description: 'Email',
    id: 'components.InviteAndSuggest.ConfirmInfoScreen.email',
  },
  clinicianType: {
    defaultMessage: 'clinician type',
    description: 'Clinician type heading',
    id: 'components.InviteAndSuggest.ConfirmInfoScreen.clinicianType',
  },
  ca: {
    defaultMessage: 'ca',
    description: 'ca heading',
    id: 'components.ConfirmInfoScreen.ca',
  },
  suggestedJobs: {
    defaultMessage: 'suggested jobs',
    description: 'Suggested jobs heading',
    id: 'components.InviteAndSuggest.ConfirmInfoScreen.suggestedJobs',
  },
  previous: {
    defaultMessage: 'Previous',
    description: 'Previous button',
    id: 'components.InviteAndSuggest.ConfirmInfoScreen.previous',
  },
  send: {
    defaultMessage: 'Send',
    description: 'Send button',
    id: 'components.InviteAndSuggest.ConfirmInfoScreen.send',
  },
});

export const intlSuccessMessages = defineMessages({
  sentTitle: {
    defaultMessage: 'Sent',
    description: 'Sent title',
    id: 'components.InviteAndSuggest.SuccessScreen.sentTitle',
  },
  sentDescription: {
    defaultMessage: 'We’ve sent an email to',
    description: 'Sent description',
    id: 'components.InviteAndSuggest.SuccessScreen.sentDescription',
  },
  inviteItem1: {
    defaultMessage: "They've been invited to join the network",
    description: 'Invite Item 1',
    id: 'components.InviteAndSuggest.SuccessScreen.inviteItem1',
  },
  inviteAndSuggestItem1: {
    defaultMessage:
      'They’ve been invited to join the network, and the jobs you suggested have been their Job Trackers so that they can easily apply after logging in.',
    description: 'Invite And Suggest Item 1',
    id: 'components.InviteAndSuggest.SuccessScreen.inviteAndSuggestItem1',
  },
  inviteAndSuggestItem2: {
    defaultMessage:
      'You will also see them listed as “Suggested” in candidate lists, and you will see jobs you suggested in the Applications tab of their Clinician Profile.',
    description: 'Invite And Suggest Item 2',
    id: 'components.InviteAndSuggest.SuccessScreen.inviteAndSuggestItem2',
  },
  suggestItem1: {
    defaultMessage:
      'The jobs you suggested have been added to their Job Trackers so that they can apply after logging in.',
    description: 'Suggest Item 1',
    id: 'components.InviteAndSuggest.SuccessScreen.suggestItem1',
  },
  suggestItem2: {
    defaultMessage:
      'You will also see them listed as “Suggested” in candidate lists, and you will see jobs you suggested in the Applications tab of their Clinician Profile.',
    description: 'Suggest Item 2',
    id: 'components.InviteAndSuggest.SuccessScreen.suggestItem2',
  },
  backButton: {
    defaultMessage: 'back to invite & suggest home',
    description: 'Back Button',
    id: 'components.InviteAndSuggest.SuccessScreen.backButton',
  },
});
