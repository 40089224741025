import React, { useEffect, useMemo } from 'react';
import { message, Spin, Tabs } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ApiOutlined, BookOutlined, ToolOutlined } from '@ant-design/icons';

import {
  useAuthenticateClinicDrChronoIntegrationMutation,
  useClinicIntegrationsQuery,
} from '~Data';
import useQuerystringParams from '~Hooks/useQuerystringParams';

import DrChronoLogin from './DrChronoLogin';
import DrChronoClientInfo from './DrChronoClientInfo';
import DrChronoDetails from './DrChronoDetails';
import DrChronoCustomization from './DrChronoCusomization';
import DrChronoManagement from './DrChronoManagement';

const { TabPane } = Tabs;

interface Props {
  clinicId: string;
}

const ClinicIntegrationDrChrono = ({ clinicId }: Props) => {
  const navigate = useNavigate();

  const { data, loading } = useClinicIntegrationsQuery({ variables: { clinicId } });
  const [
    authenticateClinicDrChronoIntegration,
    {
      data: authenticateClinicDrChronoIntegrationData,
      error: authenticateClinicDrChronoIntegrationError,
      loading: authenticateClinicDrChronoIntegrationLoading,
    },
  ] = useAuthenticateClinicDrChronoIntegrationMutation();

  const queryParams = useQuerystringParams();
  const code = queryParams.get('code');

  const redirectUri = useMemo<string | undefined>(() => {
    if (typeof window !== 'undefined') {
      const {
        location: { href },
      } = window;
      return encodeURI(href);
    }
    return undefined;
  }, []);

  useEffect(() => {
    if (authenticateClinicDrChronoIntegrationData) {
      message.success('DrChrono setup successful.');
    }
  }, [authenticateClinicDrChronoIntegrationData]);

  useEffect(() => {
    if (authenticateClinicDrChronoIntegrationError) {
      message.error('DrChrono authentication failed. Please try again.');
    }
  }, [authenticateClinicDrChronoIntegrationError]);

  useEffect(() => {
    if (code) {
      authenticateClinicDrChronoIntegration({
        variables: { input: { clinicId, code } },
      });
      queryParams.delete('code');
      navigate({ search: queryParams.toString() }, { replace: true });
    }
  }, [authenticateClinicDrChronoIntegration, clinicId, code, navigate, queryParams]);

  if (loading) {
    return <Spin />;
  }

  if (!data || !redirectUri) {
    return null;
  }

  if (authenticateClinicDrChronoIntegrationLoading) {
    return <Spin />;
  }

  const {
    clinic: { integrations },
  } = data;

  const isClientInfoSaved = !!(
    integrations?.drChrono?.config?.clientId && integrations?.drChrono?.config?.clientSecret
  );
  const isActive = integrations?.drChrono?.isActive;

  return isActive ? (
    <Tabs defaultActiveKey="1" destroyInactiveTabPane>
      <TabPane
        tab={
          <>
            <BookOutlined /> Details
          </>
        }
        key="1"
      >
        <DrChronoDetails clinicId={clinicId} />
      </TabPane>
      <TabPane
        tab={
          <>
            <ToolOutlined /> Customize
          </>
        }
        key="2"
      >
        <DrChronoCustomization
          clinicId={clinicId}
          customizations={data.clinic.integrations?.drChrono?.customization}
        />
      </TabPane>
      <TabPane
        key="3"
        tab={
          <>
            <ApiOutlined /> Manage
          </>
        }
      >
        <DrChronoManagement clinicId={clinicId} />
      </TabPane>
    </Tabs>
  ) : (
    <>
      {isClientInfoSaved ? (
        <DrChronoLogin
          drChronoIntegration={data.clinic.integrations?.drChrono}
          redirectUri={redirectUri}
        />
      ) : (
        <DrChronoClientInfo clinicId={clinicId} redirectUri={redirectUri} />
      )}
    </>
  );
};

export default ClinicIntegrationDrChrono;
