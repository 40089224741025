import React from 'react';
import { useIntl } from 'react-intl';

import Form from '@openloop/limbic/Form/Form';

import FormItem from '~Core/Form/FormItem';
import PhoneInput from '~Core/PhoneInput';
import { intlMessages } from './intlMessages';

const { Input } = Form;

const ClinicFields = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <FormItem required label={formatMessage(intlMessages.name)} name="name">
        {({ inputId }) => <Input id={inputId} name="name" placeholder="Clinic Name" />}
      </FormItem>
      <FormItem label={formatMessage(intlMessages.phoneNumber)} name="phone">
        {({ inputId }) => <PhoneInput id={inputId} name="phone" />}
      </FormItem>
      <FormItem label={formatMessage(intlMessages.website)} name="websiteUrl">
        {({ inputId }) => <Input id={inputId} name="websiteUrl" />}
      </FormItem>
      <FormItem label={formatMessage(intlMessages.zohoUrl)} name="zohoUrl">
        {({ inputId }) => <Input id={inputId} name="zohoUrl" />}
      </FormItem>
    </>
  );
};

export default ClinicFields;
