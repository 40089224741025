import React, { PropsWithChildren } from 'react';
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryPropsWithComponent,
  FallbackProps,
} from 'react-error-boundary';
import Button from '@openloop/limbic/Button';
import { defineMessages, useIntl } from 'react-intl';

import Icon, { Icons } from '~Core/Icon';
import Paragraph from '~Core/Paragraph';
import { PartialBy } from '~Types/utils';

import styles from './ErrorBoundary.module.less';

const intlMessages = defineMessages({
  errorMessageLineOne: {
    defaultMessage: 'Something Went Wrong',
    description: 'Error message line one',
    id: 'Core.ErrorBoundry.errorMessageLineOne',
  },
  errorMessageLineTwo: {
    defaultMessage: 'Please try again',
    description: 'Error message line two',
    id: 'Core.ErrorBoundry.errorMessageLineTwo',
  },
  buttonLabel: {
    defaultMessage: 'Try Again',
    description: 'Button label',
    id: 'Core.ErrorBoundry.buttonLabel',
  },
});

const handleError = (error: Error, info: { componentStack: string }) => {
  console.error(error, info);
};

const DefaultFallbackComponent = ({ resetErrorBoundary }: FallbackProps) => {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.defaultFallback} role="alert">
      <Icon className={styles.icon} name={Icons.ErrorOutlined} type="danger" />
      <Paragraph strong>{formatMessage(intlMessages.errorMessageLineOne)}</Paragraph>
      <Paragraph>{formatMessage(intlMessages.errorMessageLineTwo)}</Paragraph>
      <Button onClick={resetErrorBoundary}>{formatMessage(intlMessages.buttonLabel)}</Button>
    </div>
  );
};

const ErrorBoundary = ({
  FallbackComponent = DefaultFallbackComponent,
  ...props
}: PropsWithChildren<PartialBy<ErrorBoundaryPropsWithComponent, 'FallbackComponent'>>) => (
  <ReactErrorBoundary onError={handleError} FallbackComponent={FallbackComponent} {...props} />
);

export default ErrorBoundary;
