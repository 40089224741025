import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { List } from 'antd';
import moment from 'moment';

import common from '~Constants/common';

interface ListDataItem {
  createdAt: Date;
  href: string;
  key: string;
  title: ReactNode;
}

interface Props {
  listData: ListDataItem[];
}

const SimpleList = ({ listData }: Props) => (
  <List
    dataSource={listData}
    pagination={{ pageSize: 5, size: 'small' }}
    renderItem={({ createdAt, href, key, title }) => (
      <List.Item
        key={key}
        style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}
      >
        <Link to={href}>{title}</Link>
        <div>{moment(createdAt).format(common.dateFormat)}</div>
      </List.Item>
    )}
  />
);

export default SimpleList;
