import React, { ComponentProps } from 'react';
import Form from '@openloop/limbic/Form';
import Select from '@openloop/limbic/Form/Select';
import Col from '@openloop/limbic/Grid/Col/Col';
import Row from '@openloop/limbic/Grid/Row/Row';
import Heading from '@openloop/limbic/Heading';
import { Card } from 'antd';
import * as yup from 'yup';
import { useIntl } from 'react-intl';

import { useFormikContext } from 'formik';
import { employmentTypeChoices } from '~Constants/employmentTypes';
import { minYearsExperienceChoices } from '~Constants/experienceLevels';
import { jobStatusChoices } from '~Constants/jobStatus';
import { providerUserRoleChoices } from '~Constants/providerUserRoles';
import { desiredTelehealthExperienceChoices } from '~Constants/telehealthExperience';
import ClinicianTypeSelect from '~Core/ClinicianTypeSelect';
import FormItem from '~Core/Form/FormItem';
import RichEditor from '~Core/RichEditor';
import {
  Certification,
  DesiredTelehealthExperience,
  EhrCompatibility,
  EmploymentType,
  JobStatus,
  NumYearsExperience,
  ProviderUserRole,
  JobLocation,
} from '~Data';
import { RequiredBy } from '~Types/utils';
import { intlMessages } from './intlMessages';

import styles from './JobFields.module.scss';
import { jobLocationChoices } from '~Constants/location';
import StateSelect from '~Components/StateSelect';

const { Combobox, Input, Switch } = Form;

export interface JobFormValues {
  aboutJob?: string;
  clinicianAdvocateId?: string;
  clinicianType?: ProviderUserRole;
  clinicContactId?: string;
  description?: string;
  desiredCertifications?: Array<Certification>;
  desiredHours?: string;
  desiredTelehealthExperience?: DesiredTelehealthExperience | '';
  education?: string;
  employmentType?: Array<EmploymentType>;
  emrProviders?: Array<EhrCompatibility>;
  interviewDetails?: string;
  isInternal?: boolean;
  isTelehealth?: boolean;
  location?: JobLocation;
  minYearsExperience?: NumYearsExperience;
  numberToFill?: number | '';
  numberToFillIsUnlimited?: boolean;
  onboardingDetails?: string;
  orientationDetails?: string;
  otherInfo?: string;
  payAndPerks?: string;
  preferredLicenseStateIds?: Array<string>;
  requiredEquipment?: string;
  requiredLicenseStateIds?: Array<string>;
  requirements?: string;
  responsibilities?: string;
  salaryDetails?: string;
  schedulingDetails?: string;
  shiftDetails?: string;
  specialties?: Array<string>;
  startAt?: string;
  stateId?: string;
  status?: JobStatus;
  title: string;
  trainingLevel?: string;
  zohoUrl?: string;
}

export const jobValidationSchema: yup.SchemaOf<JobFormValues> = yup
  .object({
    aboutJob: yup.string().notRequired(),
    clinicContactId: yup.string().notRequired(),
    clinicianAdvocateId: yup.string().notRequired(),
    clinicianType: yup
      .mixed<ProviderUserRole>()
      .oneOf(
        Object.keys(providerUserRoleChoices) as ProviderUserRole[],
        'Please select a valid clinician type',
      )
      .notRequired(),
    description: yup.string().notRequired(),
    desiredCertifications: yup.array().of(yup.string()).notRequired(),
    desiredHours: yup.string().notRequired(),
    desiredTelehealthExperience: yup
      .mixed<DesiredTelehealthExperience>()
      .oneOf(
        Object.keys(desiredTelehealthExperienceChoices) as DesiredTelehealthExperience[],
        'Please select a valid experience type',
      )
      .notRequired(),
    education: yup.string().notRequired(),
    employmentType: yup.array().of(yup.string()).notRequired(),
    emrProviders: yup.array().of(yup.string()).notRequired(),
    interviewDetails: yup.string().notRequired(),
    isInternal: yup.boolean().notRequired(),
    isTelehealth: yup.boolean().notRequired(),
    location: yup
      .mixed<JobLocation>()
      .oneOf(Object.keys(jobLocationChoices) as JobLocation[], 'Please select a valid location')
      .notRequired(),
    minYearsExperience: yup
      .mixed<NumYearsExperience>()
      .oneOf(
        Object.keys(minYearsExperienceChoices) as NumYearsExperience[],
        'Please select a valid experience range',
      )
      .notRequired(),
    numberToFill: yup.mixed<number | ''>().when('numberToFillIsUnlimited', {
      is: true,
      then: yup.string(),
      otherwise: yup
        .number()
        .min(1, 'Must be at least 1')
        .integer()
        .typeError('Must be an integer'),
    }),
    numberToFillIsUnlimited: yup.boolean().notRequired(),
    onboardingDetails: yup.string().notRequired(),
    orientationDetails: yup.string().notRequired(),
    otherInfo: yup.string().notRequired(),
    payAndPerks: yup.string().notRequired(),
    preferredLicenseStateIds: yup.array().of(yup.string()).notRequired(),
    requiredEquipment: yup.string().notRequired(),
    requiredLicenseStateIds: yup.array().of(yup.string()).notRequired(),
    requirements: yup.string().notRequired(),
    responsibilities: yup.string().notRequired(),
    salaryDetails: yup.string().notRequired(),
    schedulingDetails: yup.string().notRequired(),
    shiftDetails: yup.string().notRequired(),
    specialties: yup.array().of(yup.string()).notRequired(),
    startAt: yup.string().notRequired(),
    stateId: yup.string().notRequired(),
    status: yup
      .mixed<JobStatus>()
      .oneOf(Object.keys(jobStatusChoices) as JobStatus[], 'Please select a valid status')
      .notRequired(),
    title: yup.string().required('Title is required'),
    trainingLevel: yup.string().notRequired(),
    zohoUrl: yup.string().notRequired(),
  })
  .defined();

const FieldGroup = (cardProps: RequiredBy<ComponentProps<typeof Card>, 'children' | 'title'>) => (
  <Card style={{ marginBottom: '2rem' }} {...cardProps} />
);

const JobFields = () => {
  const { formatMessage } = useIntl();
  const {
    values: { isInternal, location },
  } = useFormikContext<JobFormValues>();
  return (
    <>
      <div className={styles.privateFields}>
        <Heading level="h2" className={styles.fieldsHeading}>
          {formatMessage(intlMessages.firstHeading)}
        </Heading>
        <FieldGroup title="Zoho Link">
          <FormItem label={formatMessage(intlMessages.link)} name="zohoUrl">
            {({ inputId }) => <Input id={inputId} type="text" name="zohoUrl" />}
          </FormItem>
          <Row className={styles.shareFields}>
            <Col className={styles.requirementFields}>
              <FormItem
                label={formatMessage(intlMessages.clinicianType)}
                name="clinicianType"
                required
                className={styles.customWidthDropdown}
              >
                {({ inputId }) => <ClinicianTypeSelect id={inputId} name="clinicianType" />}
              </FormItem>
              <FormItem
                label={formatMessage(intlMessages.jobOpeningStatus)}
                name="status"
                className={styles.customWidthDropdown}
              >
                {({ inputId }) => (
                  <Select
                    id={inputId}
                    name="status"
                    options={Object.entries(jobStatusChoices).map(([value, label]) => ({
                      label,
                      value,
                    }))}
                    placeholder="Select a status"
                  />
                )}
              </FormItem>
            </Col>
            <Col>
              <FormItem
                label={formatMessage(intlMessages.shareIndeed)}
                name="isInternal"
                className={styles.indeedSwitch}
              >
                <Switch name="isInternal" label={formatMessage(intlMessages.yes)} />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem
                label={formatMessage(intlMessages.jobLocation)}
                name="location"
                className={styles.customWidthDropdown}
              >
                {({ inputId }) => (
                  <Select
                    id={inputId}
                    name="location"
                    options={Object.entries(jobLocationChoices).map(([value, label]) => ({
                      label,
                      value,
                    }))}
                    placeholder="Select a location"
                    isDisabled={!isInternal}
                  />
                )}
              </FormItem>
            </Col>
            <Col>
              {isInternal && location === JobLocation.Statewide ? (
                <FormItem label={formatMessage(intlMessages.state)} name="stateId" required>
                  <StateSelect name="stateId" id="licenseState" />
                </FormItem>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </FieldGroup>
      </div>
      <div className={styles.publicFields}>
        <Heading level="h2" className={styles.fieldsHeading}>
          {formatMessage(intlMessages.secondHeading)}
        </Heading>
        <FieldGroup title="Job Info">
          <FormItem label={formatMessage(intlMessages.jobTitle)} name="title" required>
            {({ inputId }) => <Input id={inputId} type="text" name="title" />}
          </FormItem>
          <FormItem label={formatMessage(intlMessages.jobType)} name="employmentType">
            {({ inputId }) => (
              <Combobox
                id={inputId}
                isClearable
                name="employmentType"
                options={Object.entries(employmentTypeChoices).map(([value, label]) => ({
                  label,
                  value,
                }))}
                placeholder="Select Job Types"
              />
            )}
          </FormItem>
          <FormItem label={formatMessage(intlMessages.briefSummary)} name="description">
            {({ inputId }) => <RichEditor name="description" id={inputId} />}
          </FormItem>
          <FormItem label={formatMessage(intlMessages.aboutPosition)} name="aboutJob">
            {({ inputId }) => <RichEditor name="aboutJob" id={inputId} />}
          </FormItem>
          <FormItem label={formatMessage(intlMessages.responsibilities)} name="responsibilities">
            {({ inputId }) => <RichEditor name="responsibilities" id={inputId} />}
          </FormItem>
          <FormItem label={formatMessage(intlMessages.requirements)} name="requirements">
            {({ inputId }) => <RichEditor name="requirements" id={inputId} />}
          </FormItem>
          <FormItem label={formatMessage(intlMessages.payPerks)} name="payAndPerks">
            {({ inputId }) => <RichEditor name="payAndPerks" id={inputId} />}
          </FormItem>
          <FormItem label={formatMessage(intlMessages.otherInfo)} name="otherInfo">
            {({ inputId }) => <RichEditor name="otherInfo" id={inputId} />}
          </FormItem>
          <FormItem label={formatMessage(intlMessages.education)} name="education">
            {({ inputId }) => <RichEditor name="education" id={inputId} />}
          </FormItem>
          <FormItem label={formatMessage(intlMessages.salary)} name="salaryDetails">
            {({ inputId }) => <RichEditor name="salaryDetails" id={inputId} />}
          </FormItem>
        </FieldGroup>
      </div>
    </>
  );
};

export default JobFields;
