import React from 'react';
import { useIntl } from 'react-intl';
import Heading from '@openloop/limbic/Heading';
import { intlMessages } from './intlMessages';

import styles from './WelcomeText.module.scss';

const Incomplete = () => {
  const { formatMessage } = useIntl();
  const heading = formatMessage(intlMessages.heading);
  const firstParagraph = formatMessage(intlMessages.firstParagraph, {
    anywhere: <i>{formatMessage(intlMessages.anywhere)}</i>,
  });
  const secondParagraph = formatMessage(intlMessages.secondParagraph);

  return (
    <div>
      <Heading className={styles.welcomeIncompleteText} level="h2">
        {heading}
      </Heading>
      <Heading className={styles.welcomeTextOpenloop} level="h2">
        {formatMessage(intlMessages.atOpenloop)}
      </Heading>
      <p>{firstParagraph}</p>
      <p>{secondParagraph}</p>
    </div>
  );
};

export default Incomplete;
