import React, { useCallback } from 'react';
import { Form } from 'formik-antd';
import { useFormikContext } from 'formik';
import { useId } from '@reach/auto-id';
import * as yup from 'yup';
import { useIntl } from 'react-intl';

import LimbicForm from '@openloop/limbic/Form';
import Icon from '@openloop/limbic/Icon';

import StatesSelect from '~Components/StatesSelect';
import EmrCompatibilitySelect from '~Core/EmrCompatibilitySelect';
import EmploymentStatusSelect from '~Core/EmploymentStatusSelect';
import OtherProfessionSelect from '~Core/OtherProfessionSelect';
import PhysicianTypeSelect from '~Core/PhysicianTypeSelect';
import SpecialtySelect from '~Core/SpecialtySelect';
import TrainingLevelSelect from '~Core/TrainingLevelSelect';
import { EhrCompatibility, EmploymentStatus, LevelOfTrainingPhysician, UserRole } from '~Data';
import { intlMessages } from './intlMessages';

const { Input, Textarea } = LimbicForm;

export interface FormValues {
  EHRCompatibility: EhrCompatibility[];
  employmentHistory: string;
  employmentStatus: EmploymentStatus | '';
  levelOfTraining: string;
  npi: string;
  profession: string;
  professionOther: string;
  providerType: string;
  specialty: string;
  yearLastPracticed: string;
  currentActiveLicenses: string[];
  currentPendingLicenses: string[];
  currentActiveDEALicenses: string[];
  currentPendingDEALicenses: string[];
}

interface Props {
  userRole: UserRole;
}

const handleLicenses = (
  stateLicenses: string[] | undefined,
  schema: yup.ArraySchema<yup.StringSchema<string | undefined>>,
) =>
  schema.test(
    'no-duplicates',
    'There are repeated licenses as pending',
    (value) =>
      typeof value === 'undefined' ||
      !value.some((state) => !state || stateLicenses?.includes(state)),
  );

const handleDEALicenses = (
  stateLicenses: string[] | undefined,
  schema: yup.ArraySchema<yup.StringSchema<string | undefined>>,
) =>
  schema.test(
    'not-same-location',
    'You cannot have an active DEA license in a state you are not currently licensed in.',
    (value) =>
      typeof value === 'undefined' ||
      value.every((state) => !state || stateLicenses?.includes(state)),
  );

const currentYear = new Date().getFullYear();

export const validationSchema = yup.object().shape({
  EHRCompatibility: yup.array().of(yup.string()),
  employmentHistory: yup.string(),
  employmentStatus: yup.string(),
  levelOfTraining: yup.string(),
  npi: yup
    .number()
    .typeError('Must be a number')
    .test(
      'len',
      'Must be 10 digits',
      (val) =>
        typeof val === 'undefined' || (typeof val === 'number' && val.toString().length === 10),
    ),
  profession: yup.string(),
  professionOther: yup.string(),
  providerType: yup.string(),
  specialty: yup.string(),
  yearLastPracticed: yup
    .number()
    .typeError('Must be a number')
    .test(
      'len',
      'Must be exactly 4 digits',
      (val) =>
        typeof val === 'undefined' || (typeof val === 'number' && val.toString().length === 4),
    )
    .min(
      currentYear - 100,
      `Year last practiced must be greater than or equal to ${currentYear - 100}`,
    )
    .max(currentYear, `Year last practiced must be less than or equal to ${currentYear}`),
  currentActiveLicenses: yup
    .array()
    .of(yup.string())
    .when('currentPendingLicenses', handleLicenses),
  currentPendingLicenses: yup.array().of(yup.string()),
  currentActiveDEALicenses: yup
    .array()
    .of(yup.string())
    .when('currentActiveLicenses', handleDEALicenses)
    .when('currentPendingDEALicenses', handleLicenses),
  currentPendingDEALicenses: yup.array().of(yup.string()),
});

const ProfessionalFields = <V extends FormValues>({ userRole }: Props) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<V>();
  const clinicianTypeId = useId()?.toString() || '';

  const onChangeEmploymentStatus = useCallback(() => {
    if (values.employmentStatus !== EmploymentStatus.Retired && values.yearLastPracticed) {
      setFieldValue('yearLastPracticed', '');
    }
    if (
      values.employmentStatus !== EmploymentStatus.NotCurrentlyEmployed &&
      values.employmentHistory
    ) {
      setFieldValue('employmentHistory', '');
    }
  }, [setFieldValue, values.employmentHistory, values.employmentStatus, values.yearLastPracticed]);

  return (
    <>
      {userRole === UserRole.Other && (
        <>
          <Form.Item label={formatMessage(intlMessages.typeProfession)} name="profession">
            <OtherProfessionSelect id="ProfessionalFieldsProfession" name="profession" />
          </Form.Item>
          {values.profession === 'Other' && (
            <Form.Item name="professionOther" style={{ marginTop: -15 }}>
              <Input name="professionOther" placeholder="Enter your profession type" />
            </Form.Item>
          )}
        </>
      )}
      {userRole === UserRole.Physician && (
        <>
          <Form.Item label={formatMessage(intlMessages.clinicianType)} name="providerType">
            <PhysicianTypeSelect id={clinicianTypeId} name="providerType" />
          </Form.Item>
          <Form.Item label={formatMessage(intlMessages.specialty)} name="specialty">
            <SpecialtySelect
              id="ProfessionalFieldsSpecialty"
              name="specialty"
              userRole={UserRole.Physician}
            />
          </Form.Item>
        </>
      )}
      {(userRole === UserRole.App || userRole === UserRole.Nurse) && (
        <Form.Item label={formatMessage(intlMessages.specialty)} name="specialty">
          <Input name="specialty" placeholder="SICU, MICU, Heme/Onc, General, etc." />
        </Form.Item>
      )}
      <TrainingLevelSelect name="levelOfTraining" userRole={userRole} />
      {[UserRole.App, UserRole.Nurse, UserRole.Physician].includes(userRole) &&
        values.levelOfTraining !== LevelOfTrainingPhysician.MedStudent && (
          <Form.Item
            extra={
              <a href="https://npiregistry.cms.hhs.gov/" rel="noopener noreferrer" target="_blank">
                {formatMessage(intlMessages.clickToFind)} <Icon name="ExternalLink" />
              </a>
            }
            // eslint-disable-next-line @calm/react-intl/missing-formatted-message
            label="NPI #"
            name="npi"
          >
            <Input max={10} name="npi" placeholder="Enter 10 digits" />
          </Form.Item>
        )}
      {[UserRole.App, UserRole.Nurse, UserRole.Physician, UserRole.Therapist].includes(
        userRole,
      ) && (
        <Form.Item label={formatMessage(intlMessages.electronicHealth)} name="EHRCompatibility">
          <EmrCompatibilitySelect id="emr" name="EHRCompatibility" />
        </Form.Item>
      )}
      <Form.Item label={formatMessage(intlMessages.currentEmployment)} name="employmentStatus">
        <EmploymentStatusSelect
          id="employmentStatus"
          onChange={onChangeEmploymentStatus}
          name="employmentStatus"
        />
      </Form.Item>
      {values.employmentStatus && values.employmentStatus === EmploymentStatus.Retired && (
        <Form.Item label={formatMessage(intlMessages.yearLastPracticed)} name="yearLastPracticed">
          <Input name="yearLastPracticed" placeholder="Year last practiced" />
        </Form.Item>
      )}
      {values.employmentStatus &&
        values.employmentStatus === EmploymentStatus.NotCurrentlyEmployed && (
          <Form.Item label={formatMessage(intlMessages.shortNote)} name="employmentHistory">
            <Textarea name="employmentHistory" placeholder="Short note on employment history" />
          </Form.Item>
        )}
      {[UserRole.App, UserRole.Nurse, UserRole.Physician, UserRole.Therapist].includes(
        userRole,
      ) && (
        <>
          <Form.Item
            label={formatMessage(intlMessages.activeLicenses)}
            name="currentActiveLicenses"
          >
            <StatesSelect id="current-active-licenses" name="currentActiveLicenses" />
          </Form.Item>
          <Form.Item
            label={formatMessage(intlMessages.activeDeaLicenses)}
            name="currentActiveDEALicenses"
          >
            <StatesSelect id="current-active-dea-icenses" name="currentActiveDEALicenses" />
          </Form.Item>
          <Form.Item
            label={formatMessage(intlMessages.pendingLicenses)}
            name="currentPendingLicenses"
          >
            <StatesSelect id="current-pending-licenses" name="currentPendingLicenses" />
          </Form.Item>
          <Form.Item
            label={formatMessage(intlMessages.pendingDeaLicenses)}
            name="currentPendingDEALicenses"
          >
            <StatesSelect id="current-pending-dea-licenses" name="currentPendingDEALicenses" />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default ProfessionalFields;
