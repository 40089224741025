import React from 'react';
import classNames from 'classnames/bind';

import { MenuItem, MenuItemProps } from '~Components/Navigation';

import styles from './DesktopNav.module.scss';

const cx = classNames.bind(styles);

interface DesktopNavProps {
  leftMenuItems: MenuItemProps[];
  rightMenuItems?: MenuItemProps[];
  className?: string;
}

export const DesktopNav = ({ leftMenuItems, rightMenuItems, className }: DesktopNavProps) => (
  <nav className={cx(styles.mainNavigation, className)}>
    <ul className={styles.menu} role="menu">
      {leftMenuItems?.map(({ title, route, onClick, icon, subMenu }) => {
        return (
          <MenuItem
            key={title}
            title={title}
            icon={icon}
            route={route}
            onClick={onClick}
            subItems={subMenu}
          />
        );
      })}
    </ul>
    <ul className={styles.menu} role="menu">
      {rightMenuItems?.map(({ title, route, onClick, icon, subMenu }) => (
        <MenuItem
          key={title}
          title={title}
          icon={icon}
          route={route}
          onClick={onClick}
          subItems={subMenu}
          isRightMenu
        />
      ))}
    </ul>
  </nav>
);
